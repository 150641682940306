import {
  validateCompanyIdMen003,
  validateSortMen003,
  validateCompanyNameMen003,
  validateCompanyNameKanaMen003,
  validateSortCompanyNameMen003,
  validatePostalCodeMen003,
  validateAddressMen003,
  validateTelMen003,
  validateFaxMen003,
  validateRepresentativePositionMen003,
  validateRepresentativeNameMen003,
  validateBillContactNameMen003,
  validateContactNameMen003,
  validateContactTelMen003,
  validateContactFaxMen003,
  validateEmailMen003,
  validateBankNameMen003,
  validateBankCodeMen003,
  validateBranchNameMen003,
  validateBranchCodeMen003,
  validateAccountTypeMen003,
  validateAccountNoMen003,
  validateAccountHolderNameKanjiMen003,
  validateAccountHolderNameKanaMen003,
  validateJoinDtMen003,
  validateLeavingDtMen003,
  validateCompanyGroupNameMen003,
  validateKyosaiTypeCdMen003,
  validateRequestClassifyMen003,
  validatePaymentMethodMen003,
  validatePaymentTermsMen003,
  validateShiharaijokenShimebiMen003,
  validateShiharaijokenShiharaibiMen003,
  validateRecoveryMethodMen003,
  validatePaymentCalClassifyMen003,
  validateBillingPeriodMen003,
  validateSeikyutaishokikanKaishigetsuMen003,
  validateBillIssueDtMen003,
  validateKaishujokenNyukinSiteMen003,
  validateKaishujokenNyukimbiMen003,
  validateKeihiShiharaijokenShimebiMen003,
  validateKeihiShiharaijokenShiharaiSiteMen003,
  validateKeihiShiharaijokenShiharaibiMen003,
  validateNoteMen003
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validateMEN003 = (obj, fieldValidate) => {
  //men003
  if (fieldValidate?.includes('companyIdMen003')) {
    obj.companyIdMen003 = validateCompanyIdMen003;
  }
  if (fieldValidate?.includes('sortMen003')) {
    obj.sortMen003 = validateSortMen003;
  }
  if (fieldValidate?.includes('companyNameMen003')) {
    obj.companyNameMen003 = validateCompanyNameMen003;
  }
  if (fieldValidate?.includes('companyNameKanaMen003')) {
    obj.companyNameKanaMen003 = validateCompanyNameKanaMen003;
  }
  if (fieldValidate?.includes('sortCompanyNameMen003')) {
    obj.sortCompanyNameMen003 = validateSortCompanyNameMen003;
  }
  if (fieldValidate?.includes('postalCodeMen003')) {
    obj.postalCodeMen003 = validatePostalCodeMen003;
  }
  if (fieldValidate?.includes('addressMen003')) {
    obj.addressMen003 = validateAddressMen003;
  }
  if (fieldValidate?.includes('telMen003')) {
    obj.telMen003 = validateTelMen003;
  }
  if (fieldValidate?.includes('faxMen003')) {
    obj.faxMen003 = validateFaxMen003;
  }
  if (fieldValidate?.includes('representativePositionMen003')) {
    obj.representativePositionMen003 = validateRepresentativePositionMen003;
  }
  if (fieldValidate?.includes('representativeNameMen003')) {
    obj.representativeNameMen003 = validateRepresentativeNameMen003;
  }
  if (fieldValidate?.includes('billContactNameMen003')) {
    obj.billContactNameMen003 = validateBillContactNameMen003;
  }
  if (fieldValidate?.includes('contactNameMen003')) {
    obj.contactNameMen003 = validateContactNameMen003;
  }
  if (fieldValidate?.includes('contactTelMen003')) {
    obj.contactTelMen003 = validateContactTelMen003;
  }
  if (fieldValidate?.includes('contactFaxMen003')) {
    obj.contactFaxMen003 = validateContactFaxMen003;
  }
  if (fieldValidate?.includes('emailMen003')) {
    obj.emailMen003 = validateEmailMen003;
  }
  if (fieldValidate?.includes('bankNameMen003')) {
    obj.bankNameMen003 = validateBankNameMen003;
  }
  if (fieldValidate?.includes('bankCodeMen003')) {
    obj.bankCodeMen003 = validateBankCodeMen003;
  }
  if (fieldValidate?.includes('branchNameMen003')) {
    obj.branchNameMen003 = validateBranchNameMen003;
  }
  if (fieldValidate?.includes('branchCodeMen003')) {
    obj.branchCodeMen003 = validateBranchCodeMen003;
  }
  if (fieldValidate?.includes('accountTypeMen003')) {
    obj.accountTypeMen003 = validateAccountTypeMen003;
  }
  if (fieldValidate?.includes('accountNoMen003')) {
    obj.accountNoMen003 = validateAccountNoMen003;
  }
  if (fieldValidate?.includes('accountHolderNameKanjiMen003')) {
    obj.accountHolderNameKanjiMen003 = validateAccountHolderNameKanjiMen003;
  }
  if (fieldValidate?.includes('accountHolderNameKanaMen003')) {
    obj.accountHolderNameKanaMen003 = validateAccountHolderNameKanaMen003;
  }
  if (fieldValidate?.includes('joinDtMen003')) {
    obj.joinDtMen003 = validateJoinDtMen003;
  }
  if (fieldValidate?.includes('leavingDtMen003')) {
    obj.leavingDtMen003 = validateLeavingDtMen003;
  }
  if (fieldValidate?.includes('companyGroupNameMen003')) {
    obj.companyGroupNameMen003 = validateCompanyGroupNameMen003;
  }
  // if (fieldValidate?.includes('companyGroupNameMen003')) {
  //   obj.leavingDtMen003 = validateCompanyGroupNameMen003;
  // }
  if (fieldValidate?.includes('kyosaiTypeCdMen003')) {
    obj.kyosaiTypeCdMen003 = validateKyosaiTypeCdMen003;
  }
  if (fieldValidate?.includes('requestClassifyMen003')) {
    obj.requestClassifyMen003 = validateRequestClassifyMen003;
  }
  if (fieldValidate?.includes('paymentMethodMen003')) {
    obj.paymentMethodMen003 = validatePaymentMethodMen003;
  }
  if (fieldValidate?.includes('paymentTermsMen003')) {
    obj.paymentTermsMen003 = validatePaymentTermsMen003;
  }
  if (fieldValidate?.includes('shiharaijokenShimebiMen003')) {
    obj.shiharaijokenShimebiMen003 = validateShiharaijokenShimebiMen003;
  }
  if (fieldValidate?.includes('shiharaijokenShiharaibiMen003')) {
    obj.shiharaijokenShiharaibiMen003 = validateShiharaijokenShiharaibiMen003;
  }
  if (fieldValidate?.includes('recoveryMethodMen003')) {
    obj.recoveryMethodMen003 = validateRecoveryMethodMen003;
  }
  if (fieldValidate?.includes('paymentCalClassifyMen003')) {
    obj.paymentCalClassifyMen003 = validatePaymentCalClassifyMen003;
  }
  if (fieldValidate?.includes('billingPeriodMen003')) {
    obj.billingPeriodMen003 = validateBillingPeriodMen003;
  }
  if (fieldValidate?.includes('seikyutaishokikanKaishigetsuMen003')) {
    obj.seikyutaishokikanKaishigetsuMen003 = validateSeikyutaishokikanKaishigetsuMen003;
  }
  if (fieldValidate?.includes('billIssueDtMen003')) {
    obj.billIssueDtMen003 = validateBillIssueDtMen003;
  }
  if (fieldValidate?.includes('kaishujokenNyukinSiteMen003')) {
    obj.kaishujokenNyukinSiteMen003 = validateKaishujokenNyukinSiteMen003;
  }
  if (fieldValidate?.includes('kaishujokenNyukimbiMen003')) {
    obj.kaishujokenNyukimbiMen003 = validateKaishujokenNyukimbiMen003;
  }
  // if (fieldValidate?.includes('keihiShiharaijokenShimebiMen003')) {
  //   obj.keihiShiharaijokenShimebiMen003 = validateKeihiShiharaijokenShimebiMen003;
  // }
  // if (fieldValidate?.includes('keihiShiharaijokenShiharaiSiteMen003')) {
  //   obj.keihiShiharaijokenShiharaiSiteMen003 = validateKeihiShiharaijokenShiharaiSiteMen003;
  // }
  // if (fieldValidate?.includes('keihiShiharaijokenShiharaibiMen003')) {
  //   obj.keihiShiharaijokenShiharaibiMen003 = validateKeihiShiharaijokenShiharaibiMen003;
  // }
  if (fieldValidate?.includes('noteMen003')) {
    obj.noteMen003 = validateNoteMen003;
  }

  // END MEN003

  return obj;
};
