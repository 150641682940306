import { format } from 'date-fns';
import Const from '../../config/constant';
import { arrayFilter } from '../../utils/common/ArrayUtils';

const nameMaster = (value, arrMaster) => {
  const objName = arrMaster.find(item => item?.hanyo_kubun_cd === value);
  const name = objName?.hyoji_name;
  return name;
};

// const data_account_type =
//   arrayFilter(
//     JSON.parse(localStorage.getItem('code_master')),
//     'hanyo_bunrui_cd',
//     '0017',
//   ) || [];
// const data_payment_web =
//   arrayFilter(
//     JSON.parse(localStorage.getItem('code_master')),
//     'hanyo_bunrui_cd',
//     '0203',
//   ) || [];
// const data_kakekinsanshutsu_kubun =
//   arrayFilter(
//     JSON.parse(localStorage.getItem('code_master')),
//     'hanyo_bunrui_cd',
//     '0205',
//   ) || [];

export const formatDataRes = dataListMasterCompany => {
  let formatData = dataListMasterCompany?.data?.companies.map(item => {
    item.invalid_flg = +item?.invalid_flg;
    if (item?.invalid_flg === 1) {
      item.is_disable_text = '無効';
    } else {
      item.is_disable_text = ' ';
    }

    // if (item?.account_type) {
    //   item.account_type = nameMaster(item.account_type, data_account_type);
    // } else {
    //   item.account_type = '';
    // }

    // if (item?.payment_web) {
    //   item.payment_web = nameMaster(item.payment_web, data_payment_web);
    // } else {
    //   item.payment_web = '';
    // }

    // if (item?.kakekinsanshutsu_kubun) {
    //   item.kakekinsanshutsu_kubun = nameMaster(
    //     item.kakekinsanshutsu_kubun,
    //     data_kakekinsanshutsu_kubun,
    //   );
    // } else {
    //   item.kakekinsanshutsu_kubun = '';
    // }

    // item.create_dt_time = item?.create_dt_time
    //   ? format(new Date(item?.create_dt_time), Const?.longDateFormat?.LFS)
    //   : null;
    // item.update_dt_time = item?.update_dt_time
    //   ? format(new Date(item?.update_dt_time), Const?.longDateFormat?.LFS)
    //   : null;
    // item.leaving_dt = item?.leaving_dt
    //   ? format(new Date(item?.leaving_dt), Const?.longDateFormat?.LFS)
    //   : null;
    // item.join_dt = item?.join_dt
    //   ? format(new Date(item?.join_dt), Const?.longDateFormat?.LFS)
    //   : null;
    if (
      item.postal_cd !== null &&
      item.postal_cd !== undefined &&
      item.postal_cd !== ''
    )
      item.postal_cd = `${item.postal_cd.substring(
        0,
        3,
      )}-${item.postal_cd.substring(3, 7)}`;
    return item;
  });
  return formatData;
};
