import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function ShowMesNetWork({ messkey }) {
  return (
    <div className="overlay">
      <div className="modal-confirm" style={{ top: '50%' }}>
        <p
          className="title-confirm"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <FormattedMessage {...messages?.[messkey]} />
          {/* {messContent} */}
        </p>
      </div>
    </div>
  );
}
ShowMesNetWork.propTypes = {
  messkey: PropTypes.string,
};

ShowMesNetWork.defaultProps = {
  messkey: 'network',
};

export default ShowMesNetWork;
