//message error system
const isRequire = 'isRequiredC0000001';
const lengthPassC0000078 = 'lengthPassC0000078';
const maxValue = 'maxValueC0000007';
const numberValue = 'numberValueC0000006';
const kanaHaflsizeValue = 'kanaHaflsizeValueC0000026';
const numberAndCharacterHaftsizeValue =
  'numberAndCharacterHaftsizeValueC0000018';
const kanjiValue = 'kanjiValue';
const kanaFullsizeValue = 'kanaFullsizeValueC0000002';
const birthDayIsAfterCurrenDate = 'birthDayIsAfterCurrenDateC0000040';
const dateTimeValue = 'dateTimeValueC0000004';

//men002
const labelUserId = 'labelUserId';
const labelPassword = 'password';
const userIdMax = 'userIdMax';
const groupCompanyId = 'lableGroupCompanyId';
const groupCompanyName = 'lableGroupCompanyName';
const sourceNameFile = 'lableSourceNameFile';
const address = 'lableAddress';
const addressTwo = 'lableAddressTwo';
const tel = 'lableTel';
const fax = 'lableFax';
const representativeShoulderBook = 'lableRepresentativeShoulderBook';
const nameOfRepresentative = 'lableNameOfRepresentative';
const nameOfExecutivePos = 'lableNameOfExecutivePos';
const nameOfExecutiveNm = 'lableNameOfExecutiveNm';
const contactNameInvoice = 'lableContactNameInvoice';
const contactName = 'lableContactName';
const personTEL = 'lablePersonTEL';
const personFAX = 'lablePersonFAX';
const mailAddress = 'lableMailAddress';
const bank = 'lableBank';
const bankCode = 'lableBankCode';
const branch = 'lableBranch';
const branchCode = 'lableBranchCode';
const numberAccount = 'lableNumberAccount';
const accountHolderKana = 'lableAccountHolderKana';
const consignorCode = 'lableConsignorCode';
const nameOfIsKyufusummary = 'labelOfIsKyufusummary';
const noteMen002 = 'lableRemarks';
const companySealFile = 'lableCompanySealFile';
const symbolFile = 'lableSymbolFile';
const companyId = 'labelCompanyId';
const companyName = 'labelCompanyName';

//men003
const companyIdMen003 = 'labelCompanyIdMen003';
const sort = 'labelSort';
const companyNameMen003 = 'labelCompanyNameMen003';
const companyNameKanaMen003 = 'labelCompanyNameKanaMen003';
const sortCompanyNameMen003 = 'labelSortCompanyNameMen003';
const postalCodeMen003 = 'labelPostalCodeMen003';
const addressMen003 = 'labelAddressMen003';
const telMen003 = 'labelTelMen003';
const faxMen003 = 'labelFaxMen003';
const representativePositionMen003 = 'labelRepresentativePositionMen003';
const representativeNameMen003 = 'labelRepresentativeNameMen003';
const billContactNameMen003 = 'labelBillContactNameMen003';
const contactNameMen003 = 'labelContactNameMen003';
const contactTelMen003 = 'labelContactTelMen003';
const contactFaxMen003 = 'labelContactFaxMen003';
const emailMen003 = 'labelEmailMen003';
const bankNameMen003 = 'labelBankNameMen003';
const bankCodeMen003 = 'labelBankCodeMen003';
const branchNameMen003 = 'labelBranchNameMen003';
const branchCodeMen003 = 'labelBranchCodeMen003';
const accountTypeMen003 = 'labelAccountTypeMen003';
const accountNoMen003 = 'labelAccountNoMen003';
const accountHolderNameKanjiMen003 = 'labelAccountHolderNameKanjiMen003';
const accountHolderNameKanaMen003 = 'labelAccountHolderNameKanaMen003';
const joinDtMen003 = 'labelJoinDtMen003';
const leavingDtMen003 = 'labelLeavingDtMen003';
const labelCompanyGroupNameMen003 = 'labelCompanyGroupNameMen003';

//men004
const employeeNumber = 'labelEmployeeNumber';
const companyCode = 'labelCompanyCode';
const fullName = 'labelFullName';
const fullNameKana = 'labelFullNameKana';
const oldName = 'labelOldName';
const labelBirthday = 'labelBirthday';

const userId_C0000001 = 'userId_C0000001';
const password_C0000001 = 'password_C0000001';
const userId_C0000007 = 'userId_C0000007';
const password_C0000067 = 'password_C0000067';
const currentPassword = 'currentPassword';
const newPassword = 'newPassword';
const confirmNewPassword = 'confirmNewPassword';
const matchPass_C0000080 = 'matchPass_C0000080';

const regxPassCurrentPassC0000079 = 'regxPassCurrentPassC0000079';
const regxNewPassC0000079 = 'regxPassCurrentPassC0regxNewPassC0000079000079';
const regxConfirmPassC0000079 = 'regxConfirmPassC0000079';
const currentPassC0000078 = 'currentPassC0000078';
const newPassC0000078 = 'newPassC0000078';
const confirmPassC0000078 = 'confirmPassC0000078';

export const fieldNameKyufuKingaku_Ben = {
  BEN003: '出産祝金',
  BEN002: '結婚祝金',
  BEN004: '家族弔慰金',
  BEN009: '災害見舞金',
  BEN007: '発生日',
  BEN010: '盗難見舞金',
  BEN011: '退会餞別金',
  BEN008: '健診補助金',
};
export const jiyuhassei_ymd_Ben = {
  BEN003: '出生日',
  BEN002: '入籍日',
  BEN004: '逝去日',
  BEN009: '被災日',
  BEN007: '発生日',
  BEN010: '発生日',
  BEN006: '発生日',
  BEN005: '逝去日',
  BEN008: '受診日',
  BEN011: '退会日',
};
export const taishoshaFullname_Ben = {
  BEN003: '子供氏名',
  BEN002: '配偶者氏名',
  BEN004: '故人氏名',
  BEN007: '傷病名',
  BEN008: '配偶者氏名',
};

export default {
  isRequire,
  maxValue,
  numberValue,
  kanaHaflsizeValue,
  numberAndCharacterHaftsizeValue,
  kanjiValue,
  kanaFullsizeValue,
  labelUserId,
  userIdMax,
  groupCompanyId,
  groupCompanyName,
  sourceNameFile,
  address,
  addressTwo,
  tel,
  fax,
  representativeShoulderBook,
  nameOfRepresentative,
  nameOfExecutivePos,
  nameOfExecutiveNm,
  contactNameInvoice,
  contactName,
  personTEL,
  personFAX,
  mailAddress,
  bank,
  bankCode,
  branch,
  branchCode,
  numberAccount,
  accountHolderKana,
  consignorCode,
  nameOfIsKyufusummary,
  noteMen002,
  companySealFile,
  symbolFile,
  companyId,
  companyName,
  employeeNumber,
  companyCode,
  fullName,
  fullNameKana,
  oldName,
  labelBirthday,
  birthDayIsAfterCurrenDate,
  userId_C0000001,
  password_C0000001,
  userId_C0000007,
  password_C0000067,
  labelPassword,
  currentPassword,
  newPassword,
  confirmNewPassword,
  matchPass_C0000080,
  lengthPassC0000078,
  regxPassCurrentPassC0000079,
  regxNewPassC0000079,
  regxConfirmPassC0000079,
  currentPassC0000078,
  newPassC0000078,
  confirmPassC0000078,
  companyIdMen003,
  sort,
  companyNameMen003,
  companyNameKanaMen003,
  sortCompanyNameMen003,
  postalCodeMen003,
  addressMen003,
  telMen003,
  faxMen003,
  representativePositionMen003,
  representativeNameMen003,
  billContactNameMen003,
  contactNameMen003,
  contactTelMen003,
  contactFaxMen003,
  emailMen003,
  bankNameMen003,
  bankCodeMen003,
  branchNameMen003,
  branchCodeMen003,
  accountTypeMen003,
  accountNoMen003,
  accountHolderNameKanjiMen003,
  accountHolderNameKanaMen003,
  joinDtMen003,
  dateTimeValue,
  leavingDtMen003,
  fieldNameKyufuKingaku_Ben,
};
