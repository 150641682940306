/**
 *
 * Table
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './index.css';
import * as wjGrid from '@grapecity/wijmo.react.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import * as wjFilter from '@grapecity/wijmo.react.grid.filter';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import '@grapecity/wijmo.styles/wijmo.css';
import { Clipboard } from '@grapecity/wijmo';
import messages from './messages';
import CellTemplate from './cellTemplate';
import Const from './Const';
import Loading from '../LoadingIndicator';
import usePrevious from 'helper/usePrevious';
import {
  configColumnTable,
  configCellTemplate,
  formatItemGroupField,
  formatItem,
  addGroupFooters,
} from './utils';
import { CHK_TABLE_CHECKED } from '../../containers/Iku001/const';

function Table({
  isReadOnly,
  allowAdd,
  allowMerging,
  allowResizing,
  headersVisibility,
  selectionMode,
  showSelectedHeaders,
  dataConfigTable,
  totalFooter,
  showMarquee,
  stickyHeaders,
  datas,
  isI18n,
  intl,
  isAggregates,
  autoClipboard,
  isShowFilter,
  // select row at the same time
  isMainTable,
  selectedRowId,
  setSelectedRowId,
  selectedProperty,
  onEditData,
  isGroupField,
  isFormatItem,
  sortDescriptions,
  groupDescriptions,
  groupFieldConfig,
  onInit,
  disableSort,
  additionalProps,
  groupFieldSubTotalConfig,
  id,
  dataCell,
  configGroupTotal,
  listKeyCanModify,
  handleCheckValidate,
  keyHeaderCenter,
  funcAddGroupFooters,
  autoSizeColumns,
  loadedRows,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [flexGridGlobal, setFlexGridGlobal] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [isSaveExcel, setIsSaveExcel] = useState(false);
  const onLoadedRows = grid => {
    // grid.autoSizeColumns(11);
    autoSizeColumns?.map((col, index) => {
      grid.autoSizeColumns(col);
    });
    loadedRows(grid);
  };
  const onCellEditEnded = (grid, e) => {
    const rowIndex = e.row;
    const dataKey = grid.columns[e.col].binding;
    const rowData = grid.rows[e.row].dataItem;
    onEditData(rowIndex, dataKey, rowData);
    // grid.autoSizeColumn(e.col);
  };
  const onRowEditEnded = grid => {
    // grid.autoSizeColumns();
  };
  const onRefreshing = grid => {
    // grid.autoSizeColumns();
  };

  // const getRowFromId = (listItem, selectedId) => {
  //   return listItem.findIndex(item => item[selectedProperty] === selectedId);
  // };

  const initializedGrid = flex => {
    onInit(flex);
    if (isGroupField) {
      formatItemGroupField(flex, groupFieldConfig, groupFieldSubTotalConfig);
    }
    if (configGroupTotal) {
      if (funcAddGroupFooters) {
        funcAddGroupFooters(flex, configGroupTotal);
      } else {
        addGroupFooters(flex, configGroupTotal);
      }
    }
    if (isFormatItem) {
      formatItem(flex);
    }
    if (!flexGridGlobal) {
      setFlexGridGlobal(flex);
    }
    flex.beginningEdit.addHandler((s, e) => {
      const column = s.columns[e.col];
      if (!listKeyCanModify.includes(column._binding._key)) {
        e.cancel = true;
      }

      // if(listKeyCanModify.includes(column._binding._key)){
      //   let res = handleCheckValidate(listKeyCanModify, s._activeCell.innerText);
      //   console.log('res : ', res)
      //   if(res && Object.keys(res).length > 0){
      //     console.log('bbbbbbbbbb')
      //     e.cancel = true;
      //     alert(res.message);
      //   }
      // }
      // if (column.isReadOnly) {
      //   e.cancel = true;
      // }
    });

    isMainTable &&
      flex.addEventListener(flex.hostElement, 'mousedown', e => {
        const ht = flex.hitTest(e);
        setCurrentRow(ht.row);
      });

    if (isAggregates) {
      flex.columnFooters.rows.push(new wjcGrid.GroupRow());
    }
    if (disableSort) {
      flex.allowSorting = false;
    }
    Object.keys(additionalProps).forEach(key => {
      flex[key] = additionalProps[key];
    });
    // flex.bottomLeftCells.setCellData(0, 0, '合計');
  };
  const copying = (s, e) => {
    const text = s.getClipString(null, false, true, false);
    Clipboard.copy(text);
    // prevent the grid from overwriting our clipboard content
    e.cancel = true;
  };
  const totalTemplate = textFooter => () => (
    <div className="text-align-right">{textFooter}</div>
  );

  // const initializedFilter = filter => {
  //   const objFilter = filter;
  //   objFilter.defaultFilterType = wjcGridFilter.FilterType.Condition;
  //   objFilter.showSortButtons = false;
  //   return objFilter;
  // };
  const previousSelectedRowId = usePrevious(selectedRowId);

  useEffect(() => {
    if (flexGridGlobal) {
      const id = flexGridGlobal.rows[currentRow].dataItem[selectedProperty];
      setSelectedRowId(id);
    }
  }, [currentRow]);

  useEffect(() => {
    if (flexGridGlobal) {
      if (selectedRowId === -1 || !selectedRowId) return;
      if (!isMainTable) {
        const rowId = flexGridGlobal.rows.findIndex(
          item => item.dataItem['line'] === selectedRowId,
        );
        const previousRowId = flexGridGlobal.rows.findIndex(
          item => item.dataItem['line'] === previousSelectedRowId,
        );
        if (previousRowId >= 0)
          flexGridGlobal.rows[previousRowId].isSelected = false;
        flexGridGlobal.rows[rowId].isSelected = true;
      }
    }
  }, [flexGridGlobal, selectedRowId]);

  const gridData = isGroupField
    ? new wjcCore.CollectionView(datas, {
        sortDescriptions,
        groupDescriptions,
      })
    : datas;

  const selectionModeAutoClipboard =
    selectionMode !== undefined ? selectionMode : Const.selectionMode.CellRange;
  const selectionModeTmp =
    selectionMode !== undefined ? selectionMode : Const.selectionMode.Row;
  return (
    <>
      <wjGrid.FlexGrid
        itemsSource={gridData}
        isReadOnly={isReadOnly}
        allowAddNew={allowAdd}
        allowMerging={allowMerging}
        allowResizing={allowResizing}
        headersVisibility={
          autoClipboard ? Const.headersVisibility.All : headersVisibility
        }
        autoClipboard={autoClipboard}
        selectionMode={
          autoClipboard ? selectionModeAutoClipboard : selectionModeTmp
        }
        showMarquee={showMarquee}
        showSelectedHeaders={showSelectedHeaders}
        stickyHeaders={stickyHeaders}
        loadedRows={onLoadedRows}
        cellEditEnded={onCellEditEnded}
        rowEditEnded={onRowEditEnded}
        refreshing={onRefreshing}
        initialized={initializedGrid}
        copying={copying}
        id={id}
      >
        {isShowFilter && (
          // <wjFilter.FlexGridFilter initialized={initializedFilter} />
          // <wjFilter.FlexGridFilter />
          <wjFilter.FlexGridFilter filterApplied={totalFooter}
          />
        )}
        {isLoading && <Loading />}
        {dataConfigTable?.map((item, index) => {
          const objConfigColumn = configColumnTable(
            item,
            isI18n,
            intl,
            messages,
          );
          let isCenter = keyHeaderCenter.includes(item.binding)
            ? 'center'
            : null;
          return (
            <wjGrid.FlexGridColumn
              {...objConfigColumn}
              key={index}
              isReadOnly={isReadOnly}
              align={isCenter}
            >
              {item?.cellHeaderTemplate && (
                <wjGrid.FlexGridCellTemplate
                  cellType="ColumnHeader"
                  template={item?.cellHeaderTemplate}
                />
              )}
              {item?.classNameHeader && (
                <wjGrid.FlexGridCellTemplate
                  cellType="ColumnHeader"
                  template={context => {
                    return (
                      <CellTemplate
                        context={context}
                        dataCell={objConfigColumn?.header}
                        className={item?.classNameHeader}
                      >
                        {objConfigColumn?.header}
                      </CellTemplate>
                    );
                  }}
                />
              )}

              {item?.cellTemplate && (
                <wjGrid.FlexGridCellTemplate
                  cellType="Cell"
                  template={item?.cellTemplate}
                />
              )}
              {item?.cellTemplateType && (
                <wjGrid.FlexGridCellTemplate
                  cellType="Cell"
                  template={context => {
                    const cellTemplateConfig = configCellTemplate(
                      item,
                      context,
                    );
                    return (
                      <CellTemplate {...cellTemplateConfig}>
                        {context.item?.[item.binding]}
                      </CellTemplate>
                    );
                  }}
                />
              )}

              {item?.isShowFooter && (
                <wjGrid.FlexGridCellTemplate
                  cellType="ColumnFooter"
                  template={
                    item?.textFooter ? totalTemplate(item?.textFooter) : null
                  }
                />
              )}
            </wjGrid.FlexGridColumn>
          );
        })}
      </wjGrid.FlexGrid>
    </>
  );
}

Table.propTypes = {
  allowAdd: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  allowMerging: PropTypes.string,
  allowResizing: PropTypes.string,
  headersVisibility: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectionMode: PropTypes.number,
  showMarquee: PropTypes.bool,
  showSelectedHeaders: PropTypes.number,
  stickyHeaders: PropTypes.bool,
  isI18n: PropTypes.bool,
  datas: PropTypes.array,
  dataConfigTable: PropTypes.array,
  totalFooter: PropTypes.func,
  isAggregates: PropTypes.bool,
  autoClipboard: PropTypes.bool,
  isShowFilter: PropTypes.bool,
  //
  setSelectedRowId: PropTypes.func,
  selectedRowId: PropTypes.number,
  selectedProperty: PropTypes.string,
  isMainTable: PropTypes.bool,
  onEditData: PropTypes.func,
  onInit: PropTypes.func,
  disableSort: PropTypes.bool,
  additionalProps: PropTypes.object,
  dataCell: PropTypes.any,
  listKeyCanModify: PropTypes.array,
  keyHeaderCenter: PropTypes.array,
  autoSizeColumns: PropTypes.array,
  loadedRows: PropTypes.func,
};

Table.defaultProps = {
  autoClipboard: false,
  allowAdd: false,
  isReadOnly: true,
  allowMerging: 'All',
  allowResizing: 'Both',
  headersVisibility: Const.headersVisibility.Column,
  // selectionMode: Const.selectionMode.Row,
  showMarquee: true,
  showSelectedHeaders: Const.showSelectedHeaders.None,
  stickyHeaders: false,
  datas: [],
  dataConfigTable: [],
  autoSizeColumns: [],
  isI18n: false,
  isAggregates: false, // show footer total
  isShowFilter: false,
  // select row at the same time
  setSelectedRowId: id => id,
  selectedRowId: null,
  selectedProperty: '',
  isMainTable: false,
  onEditData: () => {},
  onInit: () => {},
  disableSort: false,
  additionalProps: {},
  listKeyCanModify: [],
  keyHeaderCenter: [],
  loadedRows: () => {}
};
export default injectIntl(Table);
