import { validateUserId, validatePasswordLogin } from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validateCom002 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('userId')) {
    obj.userId = validateUserId;
  }
  if (fieldValidate?.includes('password')) {
    obj.password = validatePasswordLogin;
  }
};
