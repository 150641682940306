import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
function Footer({ keyTitle }) {
  return (
    <footer className="footer">
      <hr className="footer-hr"/>
      <FormattedMessage {...messages?.[keyTitle]} />
    </footer>
  );
}

Footer.propTypes = {
  keyTitle: PropTypes.string,
};

export default Footer;
