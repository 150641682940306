import ConstReg from 'helper/form/constantRegex';
import { getMessValidate } from 'helper/form/messValidate';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import * as Yup from 'yup';

const checkValidateNumber = (txtField, length = 0, isRequired = false) => {
  return Yup.mixed().test({
    name: 'checkValidateNumber',
    test: function(value) {
      const msgCheckNum = getMessValidate('C0000006', [txtField]);
      const msgRequired = getMessValidate('C0000001', [txtField]);
      const msgRegNum = getMessValidate('C0000003', [txtField]);
      const msgLength = getMessValidate('C0000007', [txtField]);
      if (!isEmptyAndBlank(value)) {
        if (!isNaN(value)) {
          if (parseInt(value) < 0) {
            return this.createError({
              message: msgCheckNum,
            });
          }
        }
        const flagIsNumber = ConstReg.regxNumberOnly.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgRegNum,
          });
        }
        if (length) {
          if (value.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else if (isRequired) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

Yup.addMethod(Yup.string, 'checkValidateNumber', checkValidateNumber);
