import { initialState as stateCom002 } from 'containers/Com002/reducer';
import { isEmptyAndBlank } from '../../utils/common/StringUtils';
import ConstRoot from 'config/constant';
import {
  fieldNameKyufuKingaku_Ben,
  jiyuhassei_ymd_Ben,
  taishoshaFullname_Ben,
} from './constant';
export const getMessValidate = (keyMess, arrKey, arrCustomReplace) => {
  const messList = stateCom002?.messList || {};
  let mess = messList?.[keyMess]?.message_mongon_1 || '';
  if (arrKey) {
    arrKey?.map((item, index) => {
      if (!isEmptyAndBlank(item)) {
        mess = mess.replace(`{${index}}`, item);
      }
    });
  }
  if (arrCustomReplace) {
    arrCustomReplace?.map((item, index) => {
      if (!isEmptyAndBlank(item)) {
        mess = mess.replace(item?.textReplace, item?.text);
      }
    });
  }
  return mess;
};
export const getMsgLengthKyufuKingaku = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN004]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN009]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN010]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN011]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN002]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000007', [fieldNameKyufuKingaku_Ben.BEN008]);
  }
};
export const getMsgNumKyufuKingaku = valueCodeScreen => {
  if (
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011 ||
    ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)
  ) {
    return getMessValidate('C0000028');
  }
};
export const getMsgRegNumKyufuKingaku = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN004]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN009]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN010]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN011]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN002]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000003', [fieldNameKyufuKingaku_Ben.BEN008]);
  }
};
export const getMsgRequiredKyufuKingaku = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN004]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN009]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN010]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN011]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN002]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000001', [fieldNameKyufuKingaku_Ben.BEN008]);
  }
};
export const getMessageCheckAfterDay = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000077');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000016');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000066');
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000063');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000063');
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000041');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006) {
    return getMessValidate('C0000061');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000059');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000037');
  }
};
//Ngay phát sinh ＜ Man hinh. Ngay tham gia
export const getMessageCheckAfterJoinDate = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000038');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000015');
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000015');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000065');
  } else if (
    ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen) ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006
  ) {
    return getMessValidate('C0000062');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000062');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000036');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000042');
  }
};
//  Ngày phát sinh sau ngay roi khoi cong ty
export const getCheckLeaveDateCompany = valueCodeScreen => {
  if (
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009 ||
    ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen) ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010 ||
    ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen) ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008 ||
    valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011
  ) {
    return getMessValidate('W0000026');
  }
};
// Ngày phát sinh sau ngày xác định
export const getCheckArisingAfterDetermined = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006) {
    return getMessValidate('C0000064');
  }
};

//Ngay phat sinh la ngay sau ngay yêu cầu.
export const getCheckRequestDt = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000077');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000016');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000066');
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000063');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000063');
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000041');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000037');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000059');
  }
};
// kiem tra date co hop le khong
export const getCheckValidDate = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN004]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN009]);
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN007]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN010]);
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN005]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN008]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN011]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000004', [jiyuhassei_ymd_Ben.BEN002]);
  }
};
// kiem tra length
export const getMaxLength = (valueCodeScreen, nameFiled) => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006) {
    return getMessValidate('C0000007', nameFiled);
  }
};
// kiem tra co nhap khong jiyuhassei_ymd
export const getCheckRequiredJiyuhassei = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN004]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN009]);
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN007]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN006]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN010]);
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN005]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN008]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN011]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000001', [jiyuhassei_ymd_Ben.BEN002]);
  }
};
//Ngay phat sinh la ngay hơn 1 nam trưoc tinh tu ngay yeu cau
//画面.申請日を基準に１年以上前の過去日付なら
export const getCheckGreaterThanNumberYear = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return '出生日が申請日より1年以上前の過去日付です';
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return '逝去日が申請日より１年以上前の過去日付です';
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN009) {
    return getMessValidate('W0000006');
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('W0000005');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN010) {
    return getMessValidate('W0000005');
  } else if (ConstRoot?.screenCodeBen?.BEN005_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('W0000015');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('W0000021');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011) {
    return getMessValidate('W0000016');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('W0000023');
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN006) {
    return getMessValidate('W0000024');
  }
};

export const getMessCheckRequiredTaishoshaFullname = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000001', [taishoshaFullname_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000001', [taishoshaFullname_Ben.BEN004]);
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000001', [taishoshaFullname_Ben.BEN007]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000001', [taishoshaFullname_Ben.BEN008]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000001', [taishoshaFullname_Ben.BEN002]);
  }
};
export const getMessCheckMaxLengthTaishoshaFullname = valueCodeScreen => {
  if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN003) {
    return getMessValidate('C0000007', [taishoshaFullname_Ben.BEN003]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN004) {
    return getMessValidate('C0000007', [taishoshaFullname_Ben.BEN004]);
  } else if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
    return getMessValidate('C0000007', [taishoshaFullname_Ben.BEN007]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN008) {
    return getMessValidate('C0000007', [taishoshaFullname_Ben.BEN008]);
  } else if (valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002) {
    return getMessValidate('C0000007', [taishoshaFullname_Ben.BEN002]);
  }
};
