import * as Yup from 'yup';
import { getMessValidate } from '../messValidate';
export const validateKyufuShubetsuCdMen006 = Yup.string()
  .required(getMessValidate('C0000001', ['給付種別']))
  .max(20, getMessValidate('C0000007', ['給付種別']));
export const validateShosaikubunCdMen006 = Yup.string()
  .required(getMessValidate('C0000001', ['区分']))
  .max(10, getMessValidate('C0000007', ['区分']));
export const validateEShoruiNameMen006 = Yup.string().max(
  200,
  getMessValidate('C0000007', ['提出書類名']),
);
