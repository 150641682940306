import ROUTERS from 'config/routes';
const TYPE_CELL = {
  NONE: 'NONE',
  HIDE: 'HIDE',
  LINK: 'LINK',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  CUSTOM_VALUE: 'CUSTOM_VALUE',
  CUSTOM_ON_OFF: 'CUSTOM_ON_OFF',
  COLUMN_FOOTER: 'ColumnFooter',
  FORMAT_THOUSAND: 'FORMAT_THOUSAND',
  FORMAT_ZIP_CODE: 'FORMAT_ZIP_CODE',
  BIND_HTML: 'BIND_HTML',
};
const TYPE_MODAL = {
  CONFIRM: 1,
  BEN012: 2,
  MESERROR: 3,
  SEARCH: 4,
  NETWORK: 5,
};
const TYPE_SCREEN = {
  NEW: 'new',
  EDIT: 'edit',
  VIEW: 'view',
};
const SCREEN_USE_USER_PERMISSION = [
  ROUTERS?.APA002,
  ROUTERS?.APA003,
  ROUTERS?.MEN006,
  ROUTERS?.MEN005,
  ROUTERS?.MEN004,
  ROUTERS?.MEN003,
  ROUTERS?.MEN002,
  ROUTERS?.MEN001,
  ROUTERS?.MSE003,
  ROUTERS?.MSE002,
  ROUTERS?.MSE001,
  ROUTERS?.MSE005,
  ROUTERS?.MSE004,
  ROUTERS?.MSE006,
];
const TYPE_STATUS = {
  OK: 'OK',
  NG: 'NG',
};
const TYPE_MESSAGE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};
const TYPE_MODAL_BENEFIT = {
  weddingGift: 1,
  maternityGift: 2,
  familyCondolence: 3,
  selfCondolence: 4,
  deathCompensation: 5,
  severalDisabilityCompensation: 6,
  sicknessPayment: 7,
  admissionCompensation: 8,
  hospitalCompensation: 9,
  differenceBedCharge: 10,
  disasterDonation: 11,
  theftMoney: 12,
  spouseMedicalExamSubsidy: 13,
  cancellation: 14,
};
const TYPE_MODAL_SEARCH = {
  SEA001: 'SEA001',
  SEA002: 'SEA002',
  MSE003: 'MSE003',
};
const AUTHOR = {
  SYSTEM: '01',
};
const MODE_INPUT_READONLY = {
  SHOWTOOLTIP: 1,
  NONE: 0,
};
export default {
  TYPE_MODAL_BENEFIT,
  TYPE_MODAL,
  TYPE_SCREEN,
  TYPE_STATUS,
  TYPE_MESSAGE,
  TYPE_MODAL_SEARCH,
  TYPE_CELL,
  AUTHOR,
  SCREEN_USE_USER_PERMISSION,
  MODE_INPUT_READONLY,
};
