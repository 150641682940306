import * as Yup from 'yup';
import ConstRoot from 'config/constant';
import ConstReg from 'helper/form/constantRegex';
import { getMessValidate } from 'helper/form/messValidate';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import { isValidateDate } from 'utils/common/DateUtils';
import isValid from 'date-fns/isValid';
const checkDateOfPaymentPer = () => {
  return Yup.mixed().test({
    name: 'checkDateOfPaymentPer',
    test: function(value) {
      const isValidDate = ConstReg.regYearAndMonth.test(value);
      const msgRequired = getMessValidate('C0000001', ['支払年月']);
      const msgCheckValidDate = getMessValidate('C0000004', ['支払年月']);
      if (!isEmptyAndBlank(value)) {
        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else {
          try {
            const arrDate = value?.split('/');
            if (!isValid(new Date(value))) {
              return this.createError({
                message: msgCheckValidDate,
              });
            }
          } catch (e) {
            console.log(e, '----e----');
          }
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};
const checkDateOfPaymentPer006 = () => {
  return Yup.mixed().test({
    name: 'checkDateOfPaymentPer006',
    test: function(value) {
      const isValidDate = ConstReg.regYearAndMonth.test(value);
      const msgCheckValidDate = getMessValidate('C0000004', ['年月']);
      if (!isEmptyAndBlank(value)) {
        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else {
          try {
            const arrDate = value?.split('/');
            if (!isValid(new Date(value))) {
              return this.createError({
                message: msgCheckValidDate,
              });
            }
          } catch (e) {
            console.log(e, '----e----');
          }
        }
      }
      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkDateOfPaymentPer', checkDateOfPaymentPer);
Yup.addMethod(Yup.string, 'checkDateOfPaymentPer006', checkDateOfPaymentPer006);
