/**
 *
 * TopBarMaintenanceDetail
 * Author: LanVt
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ButtonClose from 'components/ButtonClose';
import TitleTopBar from './titleTopBar';
import ButtonHandle from './buttonHandle';
import ENUM from './../../config/enum';
import Const from './../../config/constant';
import { makeSelectUserInfo } from '../../containers/Com002/selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import './index.css';
import { makeSelectUserPermisson } from '../../containers/App/selectors';
import { openLink } from 'utils/common/WindowUtils';
import ROUTERS from 'config/routes';

function TopBarMaintenanceDetail({
  type,
  keyTitle,
  handleSubmit,
  handleEdit,
  handleCancel,
  handleClose,
  isShowListScreen,
  resetPass,
  handleResetPass,
  isHandleBackPreScreen,
  userPermisson,
  linkAdd,
  errors,
}) {
  const isUserSys = userPermisson => {
    return userPermisson?.system_per_cd === Const.userSys ? true : false;
  };

  return (
    <div className="top-bar top-bar-maintenance-detail">
      <h2> {TitleTopBar(type, isShowListScreen, keyTitle)}</h2>
      {isShowListScreen ? (
        <>
          {isUserSys(userPermisson) && (
            <div className="top_bar_primary_btn_wrap">
              <Button
                className="btn_primary"
                type="button"
                buttonName="buttonNew"
                onClick={() => openLink(linkAdd)}
              />
            </div>
          )}
          {isHandleBackPreScreen ? (
            <div className="top_bar_secondary_btn_wrap">
              <Button
                type="button"
                buttonName="buttonClose"
              />
            </div>
          ) : (
            <ButtonClose
              className="top_bar_secondary_btn_wrap"
            />
          )}
        </>
      ) : (
        <>
          {ButtonHandle(
            type,
            isUserSys(userPermisson),
            handleSubmit,
            handleCancel,
            handleEdit,
            handleResetPass,
            resetPass,
            errors
          )}
        </>
      )}
    </div>
  );
}

TopBarMaintenanceDetail.propTypes = {
  keyTitle: PropTypes.string,
  linkAdd: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleResetPass: PropTypes.func,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  type: PropTypes.string,
  isShowListScreen: PropTypes.bool,
  resetPass: PropTypes.bool,
  isHandleBackPreScreen: PropTypes.bool,
};

TopBarMaintenanceDetail.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  handleResetPass: () => {},
  handleEdit: () => {},
  type: ENUM.TYPE_SCREEN.NEW,
  isShowListScreen: false,
  resetPass: false,
  isHandleBackPreScreen: false,
  linkAdd: ROUTERS?.HOME,
};

const mapStateToProps = createStructuredSelector({
  userPermisson: makeSelectUserPermisson(),
  userInfo: makeSelectUserInfo(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(TopBarMaintenanceDetail);
