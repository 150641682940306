import commonValidateMethod from './validateCommonMethod';
import {
  validatekoiShogaiTokyuCd,
  validateKyufuKingakuBen006,
  validateRequestDateDeterminBen006,
  validateDateOfDetermined,
  validateDateOfArising,
} from './validateCommon';
import { validateShobyoKubun, validateShobyoName } from '../validateCommonBen';
export const validateBen006 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('jiyuhassei_ymd')) {
    obj.jiyuhassei_ymd = validateDateOfArising;
  }
  if (fieldValidate?.includes('shogai_nintei_ymd')) {
    obj.shogai_nintei_ymd = validateDateOfDetermined;
  }
  if (fieldValidate?.includes('shinsei_kaishi_ymd')) {
    obj.shinsei_kaishi_ymd  = validateRequestDateDeterminBen006;
  }
  if (fieldValidate?.includes('shobyo_kubun')) {
    obj.shobyo_kubun = validateShobyoKubun;
  }
  if (fieldValidate?.includes('shobyo_name')) {
    obj.shobyo_name = validateShobyoName;
  }
  if (fieldValidate?.includes('koishogai_tokyu_cd')) {
    obj.koishogai_tokyu_cd = validatekoiShogaiTokyuCd;
  }
  if (fieldValidate?.includes('kyufu_kingaku')) {
    obj.kyufu_kingaku = validateKyufuKingakuBen006;
  }
};
