import { getMessI18n, createMessObj } from 'helper/messages';
import { addFieldObj } from 'utils/common/ObjectUtils';
import { Row, GroupRow } from '@grapecity/wijmo.grid';
import { formatDate } from '../../utils/common/DateUtils';

export const configColumnTable = (dataConfig, isI18n, intl, messages) => {
  const headerStr = getHeaderNameTable(dataConfig, isI18n, intl, messages);
  const objConfigColum = {
    binding: dataConfig.binding,
    header: headerStr,
    minWidth: 110,
    isReadOnly: !dataConfig.editable,
  };

  addFieldObj('format', dataConfig, objConfigColum);
  addFieldObj('aggregate', dataConfig, objConfigColum);
  addFieldObj('dataType', dataConfig, objConfigColum);
  addFieldObj('minWidth', dataConfig, objConfigColum);
  addFieldObj('maxWidth', dataConfig, objConfigColum);
  addFieldObj('formatColumn', dataConfig, objConfigColum);
  addFieldObj('width', dataConfig, objConfigColum);
  addFieldObj('allowSorting', dataConfig, objConfigColum);
  addFieldObj('cssClass', dataConfig, objConfigColum);
  return objConfigColum;
};
export const getHeaderNameTable = (dataConfig, isI18n, intl, messages) => {
  const headerNameObj = dataConfig?.headerNameObj
    ? getMessI18n(createMessObj(dataConfig?.headerNameObj), intl, isI18n)
    : '';
  const headerName = getMessI18n(
    messages?.[dataConfig?.headerName],
    intl,
    isI18n,
  );
  const headerStr = isI18n
    ? headerNameObj || headerName || dataConfig?.headerName
    : dataConfig?.headerName || dataConfig?.headerNameObj;
  return headerStr;
};
export const configCellTemplate = (dataConfig, context) => {
  return {
    type: dataConfig?.cellTemplateType,
    context,
    fieldCheckOn: dataConfig?.fieldCheckOn,
    valueCheckOn: dataConfig?.valueCheckOn,
    cssClassAllCheckOn: dataConfig?.cssClassAllCheckOn,
    dataCell: context?.item?.[dataConfig?.binding],
    formatCell: dataConfig?.formatCell,
    funcSetValue: dataConfig?.funcSetValue,
    funcOnClick: dataConfig?.funcOnClick,
    className: dataConfig?.className,
    isHidden: dataConfig?.isHidden,
  };
};
// class used to identify footer rows
const ROW_FOOTER_CLASS = 'wj-footer';
const ROW_ALIGN_RIGHT_CLASS = ' wj-align-right';
export const formatItemGroupField = (
  flex,
  groupFieldConfig,
  groupFieldSubTotalConfig,
) => {
  flex.formatItem.addHandler((s, e) => {
    // cells and column footer panels only
    if (e.panel == s.cells) {
      // get row, column, and data item (or group description)
      let r = s.rows[e.row],
        c = s.columns[e.col],
        item = s.rows[e.row].dataItem,
        group = r instanceof GroupRow ? item : null; // assume value is not negative

      groupFieldConfig?.map((data, index) => {
        if (c.binding == data?.name && group) {
          e.cell.textContent = getTextContent(data?.objTexContent, group);
        } else if (r.cssClassAll == ROW_FOOTER_CLASS) {
          e.cell.className = e.cell.className + ' wj-align-right';
        }
      });
    }
  });
  if (groupFieldSubTotalConfig) {
    updateGroupsBelow(flex);
  }
};
export const formatItem = flex => {
  flex.formatItem.addHandler((s, e) => {
    // cells and column footer panels only

    if (e.panel == s.cells) {
      // get row, column, and data item (or group description)
      let r = s.rows[e.row],
        c = s.columns[e.col],
        item = s.rows[e.row].dataItem,
        group = r instanceof GroupRow ? item : null; // assume value is not negative
      let classNm = '';
      if (e.col == 0) {
        classNm = item?.classNameAllCell0 || '';
      } else if (e.col == 1) {
        classNm = item?.classNameAllCell1 || '';
      } else if (e.col == 2) {
        classNm = item?.classNameAllCell2 || '';
      }
      e.cell.className = `${e.cell.className} ${classNm}`;
    }
  });
};

const getTextContent = (objTexContent, group) => {
  let str = objTexContent?.textContent;
  const itemValue = group?.items?.[0];
  objTexContent?.arrConfig?.map((item, index) => {
    let strReplace = item?.text || itemValue?.[item?.name] || group?.name;
    if (item?.formatDate && strReplace) {
      strReplace = formatDate(new Date(strReplace), item?.formatDate);
    }
    if (item?.nameFields) {
      item?.nameFields?.map((value, index2) => {
        str = str.replace(`{${value?.index || 0}}`, itemValue[(value?.name)]);
      });
    }

    if (item?.isShowTotal) {
      strReplace = group?.items?.length;
    }
    str = str.replace(
      `{${item?.indexShowTotal || item?.index || 0}}`,
      strReplace,
    );
  });
  return str;
};

// add group footers
export const addGroupFooters = (flex, configGroupTotal) => {
  flex.beginUpdate();
  // let sum = 0;
  // for (var r = 0; r < flex.rows.length; r++) {
  //   var row = flex.rows[r];
  //   if (row instanceof GroupRow && row.cssClassAll != ROW_FOOTER_CLASS) {
  //     if (row?.level === 0) {
  //       row.visible = false;
  //     } else {
  //       sum += addFooterGroup(row, r, flex);
  //     }
  //   }
  // }
  // console.log(flex.columnFooters, '---  flex.columnFooters ---');
  // flex.columnFooters.columns[0].visible = false;

  if (!flex.columnFooters.rows[0]) {
    var newRow = new GroupRow();
    flex.columnFooters.rows.push(newRow);
    flex.columnFooters.setCellData(0, 0, configGroupTotal?.column1);

    flex.columnFooters.setCellData(0, 1, configGroupTotal?.column2);
    flex.columnFooters.setCellData(0, 2, configGroupTotal?.column3);
  }

  flex.endUpdate();
};
const addFooterGroup = (row, r, flex) => {
  // add footer row to the grid
  // add some content to footer row
  let group = row.dataItem;
  let items = group.items;
  let sum = 0;
  let shiharai_hoho = '';
  let shinsei_kubun = '';
  let kaisha_group_name = '';
  let shiharai_ymd = '';
  let length = items?.length;
  let index = findFooterIndex(r, flex);
  const rowNew2 = index + 1;
  const rowNew3 = rowNew2 + 1;
  const rowNew4 = rowNew3 + 1;
  items?.map((item, index) => {
    sum += item?.kyufu_kingaku || 0;
    shiharai_hoho = item?.shiharai_hoho_name;
    shinsei_kubun = item?.shinsei_kubun_name;
    kaisha_group_name = item?.kaisha_group_name;
    shiharai_ymd = formatDate(new Date(item?.shiharai_ymd), 'MM/dd');
  });
  const subTotalItems = `小計 ${length}社`;
  const contentRow1 = `${shiharai_hoho}${shinsei_kubun}${subTotalItems}`;
  const contentRow2 = `${shiharai_hoho}${subTotalItems}`;
  const contentRow3 = `${kaisha_group_name}${subTotalItems}`;
  const contentRow4 = `${shiharai_ymd}${subTotalItems}`;
  addRowSubTotal(flex, index, row, sum, contentRow1);
  addRowSubTotal(flex, rowNew2, row, sum, contentRow2);
  addRowSubTotal(flex, rowNew3, row, sum, contentRow3);
  addRowSubTotal(flex, rowNew4, row, sum, contentRow4);
  return sum;
};
const addRowSubTotal = (flex, index, row, sum, content) => {
  var newRow = new GroupRow();
  newRow.level = row.level;
  newRow.cssClassAll = ROW_FOOTER_CLASS;
  flex.rows.insert(index, newRow);
  flex.setCellData(index, 1, '', true);
  flex.setCellData(index, 3, sum, false);
  flex.setCellData(index, 2, content, false);
};
// find the index where the group footer should be inserted
export const findFooterIndex = (r, flex) => {
  const { level } = flex.rows[r];
  for (let i = r + 1; i < flex.rows.length; i++) {
    const row = flex.rows[i];
    if (row instanceof GroupRow) {
      // if this is *not* a footer and the level is <=, insert here
      if (row.cssClassAll != ROW_FOOTER_CLASS && row.level <= level) {
        return i;
      }
      if (row.cssClassAll == ROW_FOOTER_CLASS && row.level < level) {
        return i;
      }
    }
  }

  // insert at the bottom
  return flex.rows.length;
};

export const updateGroupsBelow = flex => {
  // add group footers after loading rows
  flex.loadedRows.addHandler(function() {
    if (flex.collectionView.groupDescriptions.length) {
      addGroupFooters(flex);
    }
  });
};
