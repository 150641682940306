import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import SERVICEURLS from 'config/serviceUrls';
import authHeader from 'helper/auth-header';
import { searchCodeCompanySuccess, searchCodeCompanyError } from './actions';
import { SEARCH_CODE_COMPANY_REQUEST } from './constants';
import { get } from 'utils/request';

function* searchCompanyCodeRequestSaga(params) {
  const headers = authHeader();
  const body = {
    group_cd: params?.params?.groupCompany || '',
    company_nm: params?.params?.kaisha_name || '',
  };
  try {
    const data = yield get(`${SERVICEURLS?.COMPANY}companies`, body, headers);
    yield put(searchCodeCompanySuccess(data));
  } catch (error) {
    yield put(searchCodeCompanyError(error));
  }
}

// Individual exports for testing
export default function* sea001Saga() {
  yield takeLatest(SEARCH_CODE_COMPANY_REQUEST, searchCompanyCodeRequestSaga);
}
