import * as Yup from 'yup';
import ConstReg from 'helper/form/constantRegex';
import { isEmptyAndBlank } from 'utils/common/StringUtils';

const checkKaishaCdOrJugyoinNoSea = (msgIsNumber, msgLength, length) => {
  return Yup.mixed().test({
    name: 'checkKaishaCdOrJugyoinNoSea',
    exclusive: false,
    test: function(value) {
      if (!isEmptyAndBlank(value)) {
        if (value.length > length) {
          return this.createError({
            message: msgLength,
          });
        }
        const flagIsNumber = ConstReg.regNumberHalfsize.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgIsNumber,
          });
        }
      }
      return true;
    },
  });
};

// 2020.05.19 append by ALEX_Kikawa CT137 Start
const checkJugyoinNoSea = (msgIsAlpha, msgLength, length) => {
  return Yup.mixed().test({
    name: 'checkJugyoinNoSea',
    exclusive: false,
    test: function(value) {
      if (!isEmptyAndBlank(value)) {
        if (value.length > length) {
          return this.createError({
            message: msgLength,
          });
        }
        const flagIsAlpha = ConstReg.regAlphaAndNumberHalfSize.test(value);
        if (!flagIsAlpha) {
          return this.createError({
            message: msgIsAlpha,
          });
        }
      }
      return true;
    },
  });
};
// 2020.05.19 append by ALEX_Kikawa CT137 End

Yup.addMethod(
  Yup.string,
  'checkKaishaCdOrJugyoinNoSea',
  checkKaishaCdOrJugyoinNoSea,
);
Yup.addMethod(Yup.string, 'checkJugyoinNoSea', checkJugyoinNoSea);  // 2020.05.19 append by ALEX_Kikawa CT137
