const headersVisibility = {
  None: 0,
  Column: 1,
  Row: 2,
  All: 3,
};
const showSelectedHeaders = {
  None: 0,
  Column: 1,
  Row: 2,
  All: 3,
};
const selectionMode = {
  None: 0,
  Cell: 1,
  CellRange: 2,
  Row: 3,
  RowRange: 4,
  ListBox: 5,
};
export default {
  headersVisibility,
  selectionMode,
  showSelectedHeaders,
};
