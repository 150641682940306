import commonValidateMethod from './validateCommonMethod';
import {
  validateKyugyoKaishiYmd,
  validateKyugyoShuryoYmd,
  validateShinseiKikanNissu,
  validateNyuinKaishiYmd,
  validateNyuinShuryoYmd,
  validateNyuinDays,
  validateGaihakuDays,
  validateActualHospitalizeDays,
  validateTsuinKaishiYmd,
  validateTsuinShuryoYmd,
  validateByoinTsuinDays,
  validateSeikotsuinTsuinDays,
  validateSagakubedShiyoKaishiYmd,
  validateSagakubedShiyoShuryoYmd,
  validateSagakubedShiyoDays,
  validateShiharaigaku,
  validateSicknessMoney,
  validateHospitalMoney,
  validateHospitalDays,
  validateBenefitMoney,
  validateExaminationMoney,
  validateServiceBedMoney,
} from './validateCommon';
export const validateBen007 = (obj, fieldValidate) => {
  //men006
  if (fieldValidate?.includes('kyugyo_kaishi_ymd')) {
    obj.kyugyo_kaishi_ymd = validateKyugyoKaishiYmd;
  }
  if (fieldValidate?.includes('kyugyo_shuryo_ymd')) {
    obj.kyugyo_shuryo_ymd = validateKyugyoShuryoYmd;
  }
  if (fieldValidate?.includes('shinsei_kikan_nissu')) {
    obj.shinsei_kikan_nissu = validateShinseiKikanNissu;
  }
  if (fieldValidate?.includes('nyuin_kaishi_ymd')) {
    obj.nyuin_kaishi_ymd = validateNyuinKaishiYmd;
  }
  if (fieldValidate?.includes('nyuin_shuryo_ymd')) {
    obj.nyuin_shuryo_ymd = validateNyuinShuryoYmd;
  }
  if (fieldValidate?.includes('nyuin_days')) {
    obj.nyuin_days = validateNyuinDays;
  }
  if (fieldValidate?.includes('gaihaku_days')) {
    obj.gaihaku_days = validateGaihakuDays;
  }
  if (fieldValidate?.includes('nyuin_actual_days')) {
    obj.nyuin_actual_days = validateActualHospitalizeDays;
  }
  if (fieldValidate?.includes('tsuin_kaishi_ymd')) {
    obj.tsuin_kaishi_ymd = validateTsuinKaishiYmd;
  }
  if (fieldValidate?.includes('tsuin_shuryo_ymd')) {
    obj.tsuin_shuryo_ymd = validateTsuinShuryoYmd;
  }
  if (fieldValidate?.includes('byoin_tsuin_days')) {
    obj.byoin_tsuin_days = validateByoinTsuinDays;
  }
  if (fieldValidate?.includes('seikotsuin_tsuin_days')) {
    obj.seikotsuin_tsuin_days = validateSeikotsuinTsuinDays;
  }
  if (fieldValidate?.includes('sagakubed_shiyo_kaishi_ymd')) {
    obj.sagakubed_shiyo_kaishi_ymd = validateSagakubedShiyoKaishiYmd;
  }
  if (fieldValidate?.includes('sagakubed_shiyo_shuryo_ymd')) {
    obj.sagakubed_shiyo_shuryo_ymd = validateSagakubedShiyoShuryoYmd;
  }
  if (fieldValidate?.includes('sagakubed_shiyo_days')) {
    obj.sagakubed_shiyo_days = validateSagakubedShiyoDays;
  }
  if (fieldValidate?.includes('shiharaigaku')) {
    obj.shiharaigaku = validateShiharaigaku;
  }
  if (fieldValidate?.includes('sickness_money')) {
    obj.sickness_money = validateSicknessMoney;
  }
  if (fieldValidate?.includes('hospital_money')) {
    obj.hospital_money = validateHospitalMoney;
  }
  if (fieldValidate?.includes('hospital_days')) {
    obj.hospital_days = validateHospitalDays;
  }
  if (fieldValidate?.includes('benefit_money')) {
    obj.benefit_money = validateBenefitMoney;
  }
  if (fieldValidate?.includes('examination_money')) {
    obj.examination_money = validateExaminationMoney;
  }
  if (fieldValidate?.includes('service_bed_money')) {
    obj.service_bed_money = validateServiceBedMoney;
  }
};
