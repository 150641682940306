import { validateYear, validateKaisha } from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validatePer004 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('year')) {
    obj.year = validateYear;
  }

  if (fieldValidate?.includes('kaisha_cd')) {
    obj.kaisha_cd = validateKaisha;
  }

  return obj;
};
