import * as Yup from 'yup';
import { getMessValidate } from './messValidate';
import { isBefore, isToday } from 'date-fns';
import ConstReg from './constantRegex';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
export const validateFormatDate = Yup.string().test(
  'is-valid-date',
  getMessValidate('C0000004', ['支払日']),
  value => {
    if (isEmptyAndBlank(value)) {
      return true;
    }
    const isValidDate = isValidateDate(value);
    return isValidDate ? ConstReg.regDate.test(value) : isValidDate;
  },
);

export const validateYMFormatDate = Yup.string()
  .required(getMessValidate('Q0000041'))
  .test('is-valid-date', getMessValidate('C0000117'), value => {
    if (isEmptyAndBlank(value)) {
      return true;
    }

    return ConstReg.regYearAndMonth.test(value);
  });

export const validateImpSubmitDate = Yup.string()
  .required(getMessValidate('C0000001', ['支払日付']))
  .test('is-valid-date', getMessValidate('C0000004', ['支払日付']), value => {
    if (isEmptyAndBlank(value)) {
      return true;
    }

    return ConstReg.regDate.test(value);
  });

export const validateApaFormatDate = Yup.string()
  .required(getMessValidate('C0000001', ['支払日 ']))
  .test({
      name: 'is-valid-date',
      test: function(value) {
        if (isEmptyAndBlank(value)) {
          return true;
        }

        if (!ConstReg.regDate.test(value)) {
          return this.createError({
            message: getMessValidate('C0000004', ['支払日']),
          });
        }
        return true;
      },
  });

export const validateApaDate = Yup.string()
  .required(getMessValidate('C0000001', ['支払日付 ']))
  .test({
    name: 'is-valid-date',
    test: function(value) {
      try {
        if (isEmptyAndBlank(value)) {
          return true;
        }

        if(!isValidateDate(value)){
          return this.createError({
            message: getMessValidate('C0000004', ['支払日付']),
          });
        }

        if (!ConstReg.regDate.test(value)) {
          return this.createError({
            message: getMessValidate('C0000004', ['支払日付']),
          });
        }

        const targetDate = new Date(value);
        const now = new Date();
        if (isToday(targetDate)) {
          return true;
        }
        if (isBefore(targetDate, now)) {
          return this.createError({
            message: getMessValidate('C0000111'),
          });
        }

        return true;
      }catch (e) {
        return  false;
      }
    },
  });
