/*
 * TopBarMaintenanceDetail Messages
 *
 * This contains all the text for the TopBarMaintenanceDetail component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.TopBarMaintenanceDetail';

export default defineMessages({
  titleMEN005: {
    id: `${scope}.title_MEN005`,
    defaultMessage: 'titleMEN005',
  },

  titleMEN002: {
    id: `${scope}.title_MEN002`,
    defaultMessage: 'titleMEN002',
  },
  titleMEN002New: {
    id: `${scope}.title_MEN002_new`,
    defaultMessage: 'titleMEN002 new',
  },
  titleMEN002Edit: {
    id: `${scope}.title_MEN002_edit`,
    defaultMessage: 'titleMEN002 edit',
  },
  titleMEN002View: {
    id: `${scope}.title_MEN002_view`,
    defaultMessage: 'titleMEN002 view',
  },
  titleMSE002List: {
    id: `${scope}.title_MSE002_list`,
    defaultMessage: 'titleMSE002 list',
  },
  titleMEN001: {
    id: `${scope}.titleMEN001`,
    defaultMessage: 'titleMEN001',
  },
  titleMEN001View: {
    id: `${scope}.titleMEN001View`,
    defaultMessage: 'titleMEN001View',
  },
  titleMEN001Edit: {
    id: `${scope}.titleMEN001Edit`,
    defaultMessage: 'titleMEN001Edit',
  },

  titleMSE003List: {
    id: `${scope}.title_MSE003_list`,
    defaultMessage: 'titleMSE003_list',
  },
  titleMEN003New: {
    id: `${scope}.title_MEN003_new`,
    defaultMessage: 'titleMEN003 new',
  },
  titleMEN003Edit: {
    id: `${scope}.title_MEN003_edit`,
    defaultMessage: 'titleMEN003 edit',
  },
  titleMEN003View: {
    id: `${scope}.title_MEN003_view`,
    defaultMessage: 'titleMEN003 view',
  },
  titleMSE001List: {
    id: `${scope}.titleMSE001List`,
    defaultMessage: 'titleMSE001',
  },
  titleMSE005List: {
    id: `${scope}.titleMSE005List`,
    defaultMessage: 'titleMSE005List',
  },
  titleMEN005View: {
    id: `${scope}.titleMEN005View`,
    defaultMessage: 'titleMEN005View',
  },
  titleMEN005Edit: {
    id: `${scope}.titleMEN005Edit`,
    defaultMessage: 'titleMEN005Edit',
  },

  titleMSE004List: {
    id: `${scope}.title_MSE004_list`,
    defaultMessage: 'titleMSE004_list',
  },
  titleMEN004New: {
    id: `${scope}.title_MEN004_new`,
    defaultMessage: 'titleMEN004 new',
  },
  titleMEN004Edit: {
    id: `${scope}.title_MEN004_edit`,
    defaultMessage: 'titleMEN004 edit',
  },
  titleMEN004View: {
    id: `${scope}.title_MEN004_view`,
    defaultMessage: 'titleMEN004 view',
  },
  titleMSE006List: {
    id: `${scope}.titleMSE006List`,
    defaultMessage: 'titleMSE006List',
  },

  titleMEN006: {
    id: `${scope}.titleMEN006`,
    defaultMessage: 'titleMEN006',
  },
  titleMEN006View: {
    id: `${scope}.titleMEN006View`,
    defaultMessage: 'titleMEN006View',
  },
  titleMEN006Edit: {
    id: `${scope}.titleMEN006Edit`,
    defaultMessage: 'titleMEN006Edit',
  },
});
