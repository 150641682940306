import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the sea001 state domain
 */

const selectSea002Domain = state => state.sea002 || initialState;
const searchEmpoyeeCode = state => {
  return state?.sea002?.dataSearch || initialState?.dataSearch;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Sea001
 */

const makeSelectSea002 = () =>
  createSelector(
    selectSea002Domain,
    substate => substate,
  );
const makeSelectSearchEmployeeCode = () =>
  createSelector(
    searchEmpoyeeCode,
    substate => substate,
  );

export default makeSelectSea002;
export { selectSea002Domain, makeSelectSearchEmployeeCode };
