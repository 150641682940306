/*
 *
 * Com002 constants
 * Author: Truongtx
 */

export const LOGIN_REQUEST = 'app/Com002/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Com002/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Com002/LOGIN_ERROR';
export const CLEAR_USER = 'app/Com002/CLEAR_USER';
