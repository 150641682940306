/**
 *
 * Sea001
 * Author: LanVt
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import './index.css';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectSea001, {
  makeSelectListGroupCompanys,
  makeSelectSearchCompanys,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { searchCodeCompanyRequest, resetData } from './actions';
import { formikEnhancer } from 'helper/form/validateForm';
import SelectAndLabel from 'components/SelectAndLabel';
import InputLabel from 'components/InputLabel';
import Table from 'components/Table';
import { dataConfigTable } from './constants';
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';

export function Sea001({
  getListGroupCompany,
  listGroupCompanys,
  searchCodeCompany,
  listCompanys,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  intl,
  resetForm,
  funcOnClick,
  handleHide,
  sea001,
  resetData,
}) {
  useInjectReducer({ key: 'sea001', reducer });
  useInjectSaga({ key: 'sea001', saga });
  useEffect(() => {
    resetForm();
    resetData();
    searchCodeCompany(values);  // 2020.05.19 append by ALEX_Kikawa CT13
  }, []);
  const onSubmitForm = e => {
    e.preventDefault();
    searchCodeCompany(values);
    handleSubmit(e);
  };
  const onClickCell = (context, value) => {
    funcOnClick(context?.item);
  };
  return (
    <div className="popup_bg_wapper search-popup sea001">
      {sea001?.isLoadingSearch && (
        <LoadingIndicator className="overlay-loading" />
      )}
      <div className="popup_content">
        <form className="" onSubmit={onSubmitForm}>
          <div className="top-bar h-32">
            <h2>会社コード検索</h2>
          </div>
          <div className="main mt-52">
            <div className="basic_data">
              <div className="row row2 non_bd mt-32">
                <SelectAndLabel
                  className="left left2"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyMess="labelGroupCompany"
                  name="groupCompany"
                  keyMessSelect="kaisha_group_name"
                  keyValue="kaisha_group_cd"
                  data={listGroupCompanys}
                  isI18n={false}
                />
                <div className="center center2" />
                <div className="right right2" />
                <div className="more_right">
                  <Button
                    className="button btn_gray"
                    onClick={handleHide}
                    buttonName="buttonClose"
                  />
                </div>
              </div>

              <div className="row row2 non_bd mt-2">
                <InputLabel
                  className="left left2 d-f w-55"
                  classNameInput=" w-100"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyMess="labelCompanyName"
                  name="kaisha_name"
                />
                <div className=" d-f">
                  <Button
                    // className="button btn_gray ml-10"
                    type="submit"
                    buttonName="buttonSearch"
                  />
                </div>
                {/* <span className="ml-10">{sea001?.messSearch}</span> */}
              </div>
            </div>
          </div>
        </form>
        <div className="table-fix">
          <Table
            dataConfigTable={dataConfigTable(onClickCell)}
            datas={listCompanys}
            isI18n={true}
            funcOnClick={onClickCell}
          />
        </div>
      </div>
    </div>
  );
}

Sea001.propTypes = {
  dispatch: PropTypes.func.isRequired,
  funcOnClick: PropTypes.func.isRequired,
  handleHide: PropTypes.func,
};
Sea001.defaultProps = {
  funcOnClick: () => {},
  funcSubmit: () => {},
};

const mapStateToProps = createStructuredSelector({
  sea001: makeSelectSea001(),
  listGroupCompanys: makeSelectListGroupCompanys(),
  listCompanys: makeSelectSearchCompanys(),
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    searchCodeCompany: param => dispatch(searchCodeCompanyRequest(param)),
    resetData: param => dispatch(resetData()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  formikEnhancer,
)(Sea001);
