import { arrayFilter } from '../../utils/common/ArrayUtils';
import ConstCommon from '../../config/constant';

export const CHK_TABLE_CHECKED = '1';
export const CHK_TABLE_UNCHECKED = '0';

export const relationshipEmployees = arrayFilter(
  JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage.code_master)),
  'hanyo_bunrui_cd',
  '0030',
);

export const relationships = arrayFilter(
  JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage.code_master)),
  'hanyo_bunrui_cd',
  '0031',
);

export const currentYearStatus = arrayFilter(
  JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage.code_master)),
  'hanyo_bunrui_cd',
  '0028',
);

export const getCurrentYearStatusBy = data => {
  const result = {};

  data.forEach(item => {
    result[item.hanyo_kubun_cd] = item;
  });

  return result;
};

export const terminationReason = arrayFilter(
  JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage.code_master)),
  'hanyo_bunrui_cd',
  '0029',
);

export const params = new URLSearchParams(window.location.search);

export const MODE = {
  EDIT: 'edit',
  NEW: 'new',
};

export const mapTable = {
  column1: 'is_kakuninzumi',
  column2: 'taishosha_fullname',
  column3: 'seinengappi_ymd',
  column4: 'iji_tsuzukigara_cd',
  column5: 'zennendo_genkyo_cd',
  column6: 'tonendo_genkyo_cd',
  column7: 'shomeisho_hitsuyo_mark',
  column8: 'uchikiri_riyu_cd',
  column9: 'kyufu_kingaku',
  column10: 'shorijotai_cd',
  column11: 'iji_biko',
};

export const dataTableNew = [
  {
    [mapTable.column10]: '10',
    uketsuke_id: null,
  },
];

export const mapTableLabel = {
  column1: '確',
  column2: '対象者氏名',
  column3: '生年月日',
  column4: '続柄',
  column5: '前年度現況',
  column6: '当年度現況',
  column7: '証',
  column8: '打ち切り理由',
  column9: '給付金額',
  column10: 'ステータス',
  column11: '備考',
};

export const genArr = (data, keyItem, val = '') => {
  const len = data.length;

  return [...Array(len)].map((key, index) =>
    data[index] ? data[index][keyItem] : val,
  );
};

export const RES_STATUS = {
  WARN: 'WARN',
  OK: 'OK',
  NG: 'NG',
  NOT_FOUND: 'NOT_FOUND',
};

export const benefitStatusBy = () => {
  const result = {};

  const benefitStatus = arrayFilter(
    JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage.code_master)),
    'hanyo_bunrui_cd',
    '0102',
  );

  benefitStatus.forEach(item => {
    result[item.hanyo_kubun_cd] = item;
  });

  return result;
};

export const YEAR_STATUS = {
  // 2021.01.15 delete Alex_Kikawa Start
  //NURSERY: '11',
  //KINDER_GARDEN: '21',
  // 2021.01.15 delete Alex_Kikawa End
  HIGH_1: '51',
};

export const isEnabled = val => Object.values(YEAR_STATUS).includes(val);
