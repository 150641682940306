import {
  validatePasswordBeforeChange,
  validateNewPassword,
  validateConfirmationPassword,
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validateCom003 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('currentPassword')) {
    obj.currentPassword = validatePasswordBeforeChange;
  }
  if (fieldValidate?.includes('newPassword')) {
    obj.newPassword = validateNewPassword;
  }
  if (fieldValidate?.includes('confirmNewPassword')) {
    obj.confirmNewPassword = validateConfirmationPassword;
  }
};
