// import ENUMS from '../../config/enum';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import subDays from 'date-fns/subDays';
import differenceInDays from 'date-fns/differenceInDays';
import ConstReg from 'helper/form/constantRegex';
import ConstRoot from 'config/constant';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const parseDate = (str, format, locale) => {
  const obj = locale ? { locale } : null;
  const parsed = dateFnsParse(str, format, new Date(), obj);
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

export const DateDiff = {
  inDays: (d1, d2) => {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000));
  },

  inWeeks: (d1, d2) => {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
  },

  inMonths: (d1, d2) => {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
  },

  inYears: (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
  },
};
const buildValueObject = (
  yDiff,
  mDiff,
  dDiff,
  hourDiff,
  minDiff,
  secDiff,
  firstDateWasLater,
) => {
  return {
    years: yDiff,
    months: mDiff,
    days: dDiff,
    hours: hourDiff,
    minutes: minDiff,
    seconds: secDiff,
    firstDateWasLater: firstDateWasLater,
  };
};
export const preciseDiff = function(d1, d2, numberOfDays) {
  let e = new Date(d1),
    b = new Date(d2);
  e.setDate(e.getDate() + 1)
  let bMonth = b.getMonth(),
    bYear = b.getFullYear(),
    eYear = e.getFullYear(),
    eMonth = e.getMonth(),
    bDay = b.getDate(),
    eDay = e.getDate();
  if (
    eMonth == 0 ||
    eMonth == 2 ||
    eMonth == 4 ||
    eMonth == 6 ||
    eMonth == 7 ||
    eMonth == 9 ||
    eMonth == 11
  ) {
    var eDays = 31;
  }

  if (eMonth == 3 || eMonth == 5 || eMonth == 8 || eMonth == 10) {
    var eDays = 30;
  }

  if (
    (eMonth == 1 && (eYear % 4 == 0 && eYear % 100 != 0)) ||
    eYear % 400 == 0
  ) {
    var eDays = 29;
  }

  if (eMonth == 1 && (eYear % 4 != 0 || eYear % 100 == 0)) {
    var eDays = 28;
  }

  if (
    bMonth == 0 ||
    bMonth == 2 ||
    bMonth == 4 ||
    bMonth == 6 ||
    bMonth == 7 ||
    bMonth == 9 ||
    bMonth == 11
  ) {
    var bDays = 31;
  }

  if (bMonth == 3 || bMonth == 5 || bMonth == 8 || bMonth == 10) {
    var bDays = 30;
  }

  if (
    (bMonth == 1 && (bYear % 4 == 0 && bYear % 100 != 0)) ||
    bYear % 400 == 0
  ) {
    var bDays = 29;
  }

  if (bMonth == 1 && (bYear % 4 != 0 || bYear % 100 == 0)) {
    var bDays = 28;
  }

  var FirstMonthDiff = bDays - bDay + 1;

  if (eDay - bDay < 0) {
    eMonth = eMonth - 1;
    eDay = eDay + eDays;
  }

  var daysDiff = eDay - bDay;

  if (eMonth - bMonth < 0) {
    eYear = eYear - 1;
    eMonth = eMonth + 12;
  }
  var monthDiff = eMonth - bMonth;

  var yearDiff = eYear - bYear;

  if (daysDiff == eDays) {
    daysDiff = 0;
    monthDiff = monthDiff + 1;

    if (monthDiff == 12) {
      monthDiff = 0;
      yearDiff = yearDiff + 1;
    }
  }

  if (FirstMonthDiff != bDays && eDay - 1 == eDays) {
    daysDiff = FirstMonthDiff;
  }
  return buildValueObject(yearDiff, monthDiff, daysDiff, 0, 0, 0);
};

export const getFormatedStringFromDays = (d1, d2, numberOfDays) => {
  if (numberOfDays > 0) {
    const dateDiff = preciseDiff(d1, d2);
    const yearsDisplay = dateDiff?.years >= 0 ? dateDiff?.years : '';
    const monthsDisplay = dateDiff?.months >= 0 ? dateDiff?.months : '';
    const daysDisplay = dateDiff?.days >= 0 ? dateDiff?.days : '';
    return `${yearsDisplay}年${monthsDisplay}ヵ月${daysDisplay}日`;
  } else if (numberOfDays == 0) {
    return `0年0ヵ月1日`;
  }
};

export const getYearDisplay = (d1, d2, numberOfDays) => {
  if (numberOfDays > 0) {
    const dateDiff = preciseDiff(d2, d1);
    const yearsDisplay = dateDiff?.years >= 0 ? dateDiff?.years : '';
    return yearsDisplay;
  }
  return 0;
};

export const formatDate = (date, format, locale) => {
  return dateFnsFormat(date, format, { locale });
};
export const formatStrDate = (str, format = ConstRoot.longDateFormat.L) => {
  let dt = str;
  try {
    if (dt) {
      dt = dateFnsFormat(new Date(str), format);
    }
  } catch (e) {
    // console.log(e, '---e---', dt);
  }
  return dt;
};
export const formatDateString = (strDate, strJoin) => {
  const d = new Date(strDate);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join(strJoin);
};

export const isValidateDate = (value, format = ConstRoot.longDateFormat.L) => {
  const isValidFormat = ConstReg.regDate.test(value);
  try {
    const date = parse(value, format, new Date());
    return isValidFormat ? isValid(date) : isValidFormat;
  } catch (e) {
    return false;
  }
};
export const subDaysAmount = (value, amount) => {
  const isValidFormat = isValidateDate(value);
  return isValidFormat
    ? new Date(value).addDays(amount > 0 ? amount - 1 : amount)
    : '';
};
export const calcDayRange = (day1, day2) => {
  const date1 = parse(day1, ConstRoot.longDateFormat.L, new Date());
  const date2 = parse(day2, ConstRoot.longDateFormat.L, new Date());
  const isValidDate1 = isValid(date1);
  const isValidDate2 = isValid(date2);
  const isValidDateAfter = isAfter(date1, date2); //the first date is after the second date
  if (isValidDate1 && isValidDate2 && !isValidDateAfter) {
    const numDifferent = Math.abs(differenceInDays(date1, date2)) + 1;
    return numDifferent;
  }
  return 0;
};
export const convertStringDate = (
  strDate,
  indexYear = 1,
  indexMonth = 2,
  indexDay = 3,
  pattern = ConstReg.patternDate,
  format = ConstRoot.longDateFormat.L,
) => {
  const arrayDate = strDate.match(pattern);
  try {
    if (arrayDate[indexMonth] > 12) {
      return strDate;
    }
    const dt = new Date(
      arrayDate[indexYear],
      arrayDate[indexMonth] - 1,
      arrayDate[indexDay],
    );
    return formatDate(dt, format);
  } catch (e) {}
  return strDate;
};

export const getMaxDate = (arr, key, format = ConstRoot.longDateFormat.LDB) => {
  const dates = arr.map(item => item?.[key]);
  const result = Math.max.apply(
    null,
    dates.map(function(item) {
      return new Date(item).getTime();
    }),
  );
  const resultDate = formatStrDate(result, format);
  return resultDate;
};
