import React, { memo, useState, useEffect } from 'react';
import InputLabelCheckBox from '../../components/InputLabelCheckBox';
import SelectAndLabel from '../../components/SelectAndLabel';
import InputLabel from '../../components/InputLabel';
import CheckBox from '../../components/CheckBox';
import Button from '../../components/Button';
import Table from '../../components/Table';
import { dataConfigTable } from './constants';

export function Body(props) {
  const {
    values,
    handleChange,
    handleBlur,
    setValues,
    handleSubmit,
    errors,
    listCompanyMaster,
    onClickCellMse003,
    typeGroupCompany
  } = props;
  return (
    <div className="main">
      <div className="basic_data mt-32" />

      <div className="row row2 mt-2">
        <InputLabelCheckBox
          className={`left left2 d-f w-55`}
          classNameInput={`input_10`}
          nameInput={`groupCompanyIdListScreen`}
          nameCheckBox={`groupCompanyFlag`}
          keyMessLabel={`labelCompanyId`}
          keySpanCheckBox={`invalidCompanyGroup`}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          mess={errors?.groupCompanyIdListScreen || ''}
          isRequired={false}
          //autoFocus={isNew}
        />
      </div>

      <div className="row row2 mt-2">
        <SelectAndLabel
          className={`left left2 d-f w-55`}
          isRequired={false}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          keyMess={`labelGroupCompanyMse003`}
          name={`groupCompany`}
          keyMessSelect="kaisha_group_name"
          keyValue="kaisha_group_cd"
          data={typeGroupCompany}
          isI18n={false}
        />
      </div>

      <div className="row row2 mt-2">
        <InputLabel
          className={`left left2 d-f w-55`}
          classNameInput="input_63"
          isRequired={false}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          keyMess={`labelCompanyName`}
          name={`companyName`}
          isInputType={true}
          isReadonly={false}
          mess={errors?.companyName || ''}
        />
      </div>

      <div className="row row2 mt-2 position-relative ">
        <CheckBox
          className="checkbox"
          classNameLi="left d-f"
          keySpan="labelOutputIncludeHistory"
          name="outputIncludeHistory"
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isDisplayLableRight={false}
        />
        <div style={{ position: 'absolute', left: '240px' }}>
          <Button
            className="button"
            type="submit"
            buttonName="buttonSearch"
            onClick={handleSubmit}
          />
        </div>
      </div>

      <div className="table-fix">
        <Table
          dataConfigTable={dataConfigTable(onClickCellMse003)}
          datas={listCompanyMaster}
          isI18n={true}
          funcOnClick={onClickCellMse003}
          autoClipboard={true}
          isShowFilter = {true}
          autoSizeColumns={[46, 50]}
        />
      </div>
    </div>
  );
}

export default Body;
