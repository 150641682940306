/**
 *
 * Label
 * Author: LanVt
 *
 */

import React, { Children, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { createMessObj } from '../../helper/messages';
import './index.css';
function Label({
  keyMess,
  keyMessJson,
  isRequired,
  className,
  classNameSpan,
  onClickLabel,
  isI18n,
  mess,
  children,
  styleLabel,
}) {
  let messObj = keyMessJson ? createMessObj(keyMessJson) : null;
  if (isI18n) {
    const messObjTmp = keyMess ? messages?.[keyMess] : null;
    messObj = messObj || messObjTmp || keyMess;
  }
  return (
    <label onClick={onClickLabel} className={className} style={styleLabel}>
      {isI18n ? (
        <span className={classNameSpan}>
          {messObj && <FormattedMessage {...messObj} />}
        </span>
      ) : (
        <> {mess || keyMess}</>
      )}

      {isRequired && <span className="required">*</span>}
      {Children.toArray(children)}
    </label>
  );
}

Label.propTypes = {
  keyMess: PropTypes.string,
  className: PropTypes.string,
  classNameSpan: PropTypes.string,
  isRequired: PropTypes.bool,
  onClickLabel: PropTypes.func,
  isI18n: PropTypes.bool,
  mess: PropTypes.string,
  children: PropTypes.node,
  styleLabel: PropTypes.object,
};

Label.defaultProps = {
  className: 'lbl',
  classNameSpan: '',
  isRequired: false,
  isI18n: true,
  mess: '',
  onClickLabel: () => {},
  styleLabel: {},
};

export default Label;
