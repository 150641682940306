import compareDesc from 'date-fns/compareDesc';
import compareAsc from 'date-fns/compareAsc';
export const isArrayEmpty = array => {
  if (Array.isArray(array) && array.length > 0) {
    return false;
  }
  return true;
};
export const removeNull = array => {
  return array.filter(function(el) {
    return el != null;
  });
};

/* It iterates through each deep nested object and if finds object that has prop and value specified in objToFindBy
 argument, it pushes this object to the result array. When it finishes the walk, it returns the array.
 If none is found, it returns false. */
export const findAll = function(tree, childrenKey, objToFindBy) {
  const keyObjects = Object.keys(objToFindBy);
  const valueKeyObjects = objToFindBy?.[keyObjects?.[0]];
  const itemSelected = tree.find(function(item) {
    return findDeep([item], keyObjects, valueKeyObjects, childrenKey);
  });
  return itemSelected;
};

let findDeep = function(data, key, value, keyChildren) {
  return data?.some(function(e) {
    if (e?.[key] == value) return true;
    if (e?.[keyChildren])
      return findDeep(e?.[keyChildren], key, value, keyChildren);
  });
};
export const convertArrayPrices = (value, min, max) => {
  if (value) {
    const res = value.split(',');
    const minTmp = parseInt(res?.[0].replace(/^\D+/g, '') || min) || min;
    const maxTmp = parseInt(res?.[1].replace(/^\D+/g, '') || max) || max;
    return [minTmp, maxTmp];
  }
  return [min, max];
};
export const uniqueArray = arr => {
  if (!isArrayEmpty(arr)) {
    return arr.filter(function(item, pos) {
      return arr.indexOf(item) == pos;
    });
  }
};

export const arrayKeyBy = (data, strKey) => {
  let result = {};
  data?.map(item => {
    result = { ...result, [item?.[strKey]]: item };
  });
  return result;
};
export const arrayKeyByArray = (data, strKey) => {
  let result = {};
  data?.map(item => {
    const arrTmp = result[(item?.[strKey])] || [];
    result = { ...result, [item?.[strKey]]: [...arrTmp, item] };
  });
  return result;
};

export const pushOrSpliceArray = (arr, value) => {
  if (arr && value) {
    const indexOf = arr?.indexOf(value);
    if (indexOf > -1) {
      arr?.splice(indexOf, 1);
    } else {
      arr?.push(value);
    }
  } else if (value) {
    return [value];
  }
  return arr;
};

export const getArrValueByKey = (datas, name) => {
  const arr = [];
  if (datas) {
    datas?.map(item => {
      if (item?.[name]) {
        arr.push(item?.[name]);
      }
    });
  }

  return arr;
};
export const compareTwoArray = (A, B) => A?.every(e => B?.includes(e));
export const equals = (A, B) => {
  // if the other array is a falsy value, return
  if (!B || !A) return false;

  // compare lengths - can save a lot of time
  if (A.length != B.length) return false;

  for (let i = 0, l = A.length; i < l; i++) {
    // Check if we have nested arrays
    if (A[i] instanceof Array && B[i] instanceof Array) {
      // recurse into the nested arrays
      if (!A[i].equals(B[i])) return false;
    } else if (A[i] != B[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
export const arrayFilter = (arr, name, value) =>
  arr ? arr.filter(item => item?.[name] === value) : [];

export const arrayFilterMultiValue = (arr, name, values) =>
  arr ? arr.filter(item => values?.includes(item?.[name])) : [];

export const arraySort = (arr, key, type = 'asc') => {
  if (type === 'asc') {
    arr.sort((a, b) =>
      typeof a[key] === 'number' && typeof b[key] === 'number'
        ? Number(a[key]) - Number(b[key])
        : a[key].localeCompare(b[key]),
    );
  } else {
    arr.sort((a, b) =>
      typeof a[key] === 'number' && typeof b[key] === 'number'
        ? Number(b[key]) - Number(a[key])
        : b[key].localeCompare(a[key]),
    );
  }
  return arr;
};
export const arraySortDate = (arr, key, type = 'desc') => {
  if (!isArrayEmpty(arr) && arr?.length > 1) {
    if (type === 'asc') {
      arr.sort((a, b) => compareAsc(new Date(a[key]), new Date(b[key])));
    } else {
      arr.sort((a, b) => compareDesc(new Date(a[key]), new Date(b[key])));
    }
  }
  return arr;
};
export const arrayFilterMaxDate = (arr, key, type = 'desc') => {
  const arrSort = arraySortDate(arr, key, type);
  let maxDate = '';
  if (!isArrayEmpty(arrSort)) {
    maxDate = arrSort[0]?.[key];
    return arrayFilter(arrSort, key, maxDate);
  }
  return arrSort;
};
