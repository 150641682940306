import {
  validateKyufuShubetsuCdMen006,
  validateShosaikubunCdMen006,
  validateEShoruiNameMen006,
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validateMEN006 = (obj, fieldValidate) => {
  //men006
  if (fieldValidate?.includes('kyufu_shubetsu_cd_men006')) {
    obj.kyufu_shubetsu_cd_men006 = validateKyufuShubetsuCdMen006;
  }
  if (fieldValidate?.includes('shosaikubun_cd_men006')) {
    obj.shosaikubun_cd_men006 = validateShosaikubunCdMen006;
  }
  if (fieldValidate?.includes('eshorui_name_men006')) {
    obj.eshorui_name_men006 = validateEShoruiNameMen006;
  }
  return obj;
};
