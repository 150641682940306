/**
 *
 * ModalConfirm
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { createPortal } from 'react-dom';
import usePortal from '../../helper/usePortal';
import Confirm from './confirm';
import Benefit from './Benefit';
import Search from './Search';
import ENUMS from '../../config/enum';
import ShowMesError from './showMesError';
import ShowMesNetWork from './showMesNetWork';
import './index.css';
import Button from "../Button";

function Modal({
  id,
  handleHide,
  type,
  messContent,
  typeModal,
  handleOK,
  funcOnClick,
  intl,
  parentValues,
  messkey,
  typeStatusBenefit,
  isI18n,
  keyMessList,
  isShowButtonCancel,
  isLoading,
}) {
  const modal = () => {
    useEffect(() => {
      const myButton = document.getElementById('confirm-button');
      if (myButton) myButton.focus();
    }, [type]);
    let modalComponent = <> </>;
    switch (type) {
      case ENUMS?.TYPE_MODAL?.CONFIRM:
        modalComponent = (
          <Confirm
            handleHide={handleHide}
            messContent={messContent}
            handleOK={handleOK}
            isI18n={isI18n}
            keyMessList={keyMessList}
            isShowButtonCancel={isShowButtonCancel}
            isLoading={isLoading}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL?.BEN012:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL?.MESERROR:
        modalComponent = (
          <ShowMesError
            messContent={messContent}
            handleOK={handleOK}
            keyMessList={keyMessList}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL?.SEARCH:
        modalComponent = (
          <Search
            funcOnClick={funcOnClick}
            handleHide={handleHide}
            typeModal={typeModal}
            intl={intl}
            parentValues={parentValues}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL?.NETWORK:
        modalComponent = <ShowMesNetWork messkey={messkey}/>;
        break;
      default:
    }
    return modalComponent;
  };
  const target = usePortal(id);
  const modalComponent = modal();
  return createPortal(modalComponent, target);
}

Modal.propTypes = {
  id: PropTypes.string,
  type: PropTypes.number,
  typeModal: PropTypes.any,
  messContent: PropTypes.string,
  handleHide: PropTypes.func,
  handleOK: PropTypes.func,
  funcOnClick: PropTypes.func,
  parentValues: PropTypes.object,
  isLoading: PropTypes.bool,
};

Modal.defaultProps = {
  isLoading:false,
};

export default Modal;
