import { validateDateOfPaymentPer, validateYear } from './validateCommon';
import Const from 'config/constant';
import commonValidateMethod from 'helper/form/validateCommonMethod';
import { validateFormatDate } from 'helper/form/validateCommonDate';
import { validatePer003 } from './formPer003/validateFormPer003';
import { validatePer004 } from './formPer004/validateFormPer004';
import { validatePer006 } from './formPer006/validateFormPer006';

const validatePerCommon = (obj, fieldValidate) => {
  if (fieldValidate?.includes('dateOfPayment')) {
    obj.dateOfPayment = validateDateOfPaymentPer;
  }
  if (fieldValidate?.includes('year')) {
    obj.year = validateYear;
  }
  return obj;
};

export const validatePer = (obj, fieldValidate, screenCode) => {
  switch (screenCode) {
    case Const.screenCodeAll.PER003:
      validatePer003(obj, fieldValidate);
      break;
    case Const.screenCodeAll.PER004:
      validatePer004(obj, fieldValidate);
      break;
    case Const.screenCodeAll.PER006:
      validatePer006(obj, fieldValidate);
      break;
    case Const.screenCodeAll.PER:
      validatePerCommon(obj, fieldValidate);
      break;
    default:
  }
  return obj;
};
