import {
  validateKyosaiTypeName,
  validateKyosaiTypeCode,
  validateKakekin,
  validateNenrei,
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
import { validateBiko } from '../validateCommon';
export const validateMEN001 = (obj, fieldValidate) => {
  //Men001
  if (fieldValidate?.includes('kyosaiTypeName')) {
    obj.kyosaiTypeName = validateKyosaiTypeName;
  }

  if (fieldValidate?.includes('kyosaiTypeCode')) {
    obj.kyosaiTypeCode = validateKyosaiTypeCode;
  }

  if (fieldValidate?.includes('kakekin')) {
    obj.kakekin = validateKakekin;
  }

  if (fieldValidate?.includes('nenrei')) {
    obj.nenrei = validateNenrei;
  }

  if (fieldValidate?.includes('biko')) {
    obj.biko = validateBiko;
  }

  return obj;
};
