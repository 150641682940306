import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { initialState as stateApp } from 'containers/App/reducer';

/**
 * Direct selector to the mse003 state domain
 */

const selectMse003Domain = state => state.mse003 || initialState;
const selectCompanyMaster = state => {
  return state?.mse003?.infoListCompany || initialState?.infoListCompany;
};
const selectTypeGroupCompany = state => stateApp.groupCompany || [];
/**
 * Other specific selectors
 */

/**
 * Default selector used by Mse003
 */

const makeSelectMse003 = () =>
  createSelector(
    selectMse003Domain,
    substate => substate,
  );

const makeSelectCompanyMaster = () =>
    createSelector(
      selectCompanyMaster,
      substate => substate,
    )

const makeSelectGroupCompany = () =>
      createSelector(
        selectTypeGroupCompany,
        substate => substate,
      )

export { selectMse003Domain, makeSelectCompanyMaster, makeSelectMse003, makeSelectGroupCompany };
