import commonValidateMethod from './validateCommonMethod';
import {
  validateTonanhigaiKubun,
  validateTonanHigaigaku,
} from './validateCommon';
export const validateBen010 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('tonanhigai_kubun')) {
    obj.tonanhigai_kubun = validateTonanhigaiKubun;
  }
  if (fieldValidate?.includes('tonan_higaigaku')) {
    obj.tonan_higaigaku = validateTonanHigaigaku;
  }
};
