/**
 *
 * SelectAndLabel
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import Select from '../Select';
import './index.css';
function SelectAndLabel({
  keyMess,
  keyMessJson,
  isRequired,
  className,
  classNameSelect,
  name,
  nameSelectName,
  handleChange,
  handleBlur,
  values,
  keyMessSelect,
  data,
  keyValue,
  isI18n,
  valueRequireDefaultSelect,
  isDisable,
  touched,
  isSubmitting,
  messError,
  setFieldValue,
  isShowNone,
}) {
  return (
    <div className={className}>
      <Label
        keyMess={keyMess}
        keyMessJson={keyMessJson}
        isRequired={isRequired}
      />
      <div className="select_wrap">
        <Select
          className={classNameSelect}
          name={name}
          nameSelectName={nameSelectName}
          onChange={handleChange}
          handleBlur={handleBlur}
          valueDefault={values?.[name]}
          isDisable={isDisable}
          isShowNone={isShowNone}
          isI18n={isI18n}
          keyMess={keyMessSelect}
          data={data}
          keyValue={keyValue}
          valueRequireDefaultSelect={valueRequireDefaultSelect}
          isRequired={isRequired}
          keyMessLabel={keyMess}
          touched={touched}
          isSubmitting={isSubmitting}
          messError={messError}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
}

SelectAndLabel.propTypes = {
  className: PropTypes.string,
  classNameSelect: PropTypes.string,
  keyMess: PropTypes.string,
  keyMessSelect: PropTypes.string,
  name: PropTypes.string,
  nameSelectName: PropTypes.string,
  isRequired: PropTypes.bool,
  isShowNone: PropTypes.bool,
  values: PropTypes.any,
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  keyValue: PropTypes.string,
  isI18n: PropTypes.bool,
  isDisable: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  messError: PropTypes.string,
};
SelectAndLabel.defaultProps = {
  isRequired: false,
  isI18n: true,
  isDisable: false,
  isShowNone: true,
  classNameSelect: '25',
};

export default SelectAndLabel;
