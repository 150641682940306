import { CLEAR_PERMISSION, GET_USER_PERMISSION } from './constants';
import { createReduxActions } from 'helper/action';
export const [
  getUserPermissionRequest,
  getUserPermissionSuccess,
  getUserPermissionError,
] = createReduxActions(GET_USER_PERMISSION);

export function clearPermistion() {
  return {
    type: CLEAR_PERMISSION,
  };
}
