/*
 * Label Messages
 *
 * This contains all the text for the Label component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.Label';

export default defineMessages({
  labelUserId: {
    id: `${scope}.label_user_id`,
    defaultMessage: 'user id',
  },
  labelFullName: {
    id: `${scope}.label_full_name`,
    defaultMessage: 'user id',
  },
  labelEmail: {
    id: `${scope}.label_email`,
    defaultMessage: 'user id',
  },
  labelBusinessAuthority: {
    id: `${scope}.label_business_authority`,
    defaultMessage: 'business authority',
  },
  labelSystemAuthority: {
    id: `${scope}.label_system_authority`,
    defaultMessage: 'system authority',
  },
  labelOfficialSealFile: {
    id: `${scope}.label_official_seal_file`,
    defaultMessage: 'official seal file',
  },
  labelCreate: {
    id: `${scope}.label_create`,
    defaultMessage: 'create',
  },
  labelCreateFullName: {
    id: `${scope}.label_create_full_name`,
    defaultMessage: 'create full name',
  },
  labelUpdate: {
    id: `${scope}.label_update`,
    defaultMessage: 'update',
  },
  labelUpdateFullName: {
    id: `${scope}.label_update_full_name_fixed`,
    defaultMessage: 'update full name',
  },

  // men004
  labelEmployeeNumber: {
    id: `${scope}.label_employee_number`,
    defaultMessage: 'employee number',
  },

  labelCompanyCode: {
    id: `${scope}.label_company_code`,
    defaultMessage: 'company code ',
  },
  labelFullNameKana: {
    id: `${scope}.label_full_name_kana`,
    defaultMessage: 'name kana',
  },
  labelOldName: {
    id: `${scope}.label_old_name`,
    defaultMessage: 'old name',
  },
  labelGender: {
    id: `${scope}.label_gender`,
    defaultMessage: 'gender',
  },
  labelBirthday: {
    id: `${scope}.label_birthday`,
    defaultMessage: 'birthDay',
  },
  labelDateJoinGroup: {
    id: `${scope}.label_date_join_group`,
    defaultMessage: 'date join group',
  },
  labelDateJoinCompany: {
    id: `${scope}.label_date_join_company`,
    defaultMessage: 'date join company',
  },
  labelRetirementDate: {
    id: `${scope}.label_retirement_date`,
    defaultMessage: 'date retirement ',
  },
  labelDateLeaveGroup: {
    id: `${scope}.label_date_leave_group`,
    defaultMessage: 'date leave group ',
  },
  labelMarryStatus: {
    id: `${scope}.label_marry_status`,
    defaultMessage: 'marry status',
  },
  lableContractType: {
    id: `${scope}.label_contract_type`,
    defaultMessage: 'contract type',
  },
  lableReportInfomation: {
    id: `${scope}.label_report_infomation`,
    defaultMessage: 'report infomation',
  },

  lableContentReport: {
    id: `${scope}.label_content_report`,
    defaultMessage: 'content repost',
  },
  lableDocumentStore: {
    id: `${scope}.label_document_store`,
    defaultMessage: 'document store',
  },
  lableCreate: {
    id: `${scope}.label_create`,
    defaultMessage: 'create',
  },
  lableUpdate: {
    id: `${scope}.label_update`,
    defaultMessage: 'update',
  },
  isSentReport: {
    id: `${scope}.is_sent_report`,
    defaultMessage: 'is sent report',
  },
  lableSubscriptionPeriod: {
    id: `${scope}.lable_subscription_period`,
    defaultMessage: 'lable subscription period',
  },
  lableUnderwritingAge: {
    id: `${scope}.lable_underwriting_age`,
    defaultMessage: 'lable underwriting age',
  },
  lableCreateMen004: {
    id: `${scope}.label_create_men004`,
    defaultMessage: 'create',
  },
  lableCreateBy004: {
    id: `${scope}.create_by_men004`,
    defaultMessage: 'create by men004',
  },
  lableUpdateMen004: {
    id: `${scope}.label_update_men004`,
    defaultMessage: 'update',
  },
  lableUpdateBy004: {
    id: `${scope}.update_by_men004`,
    defaultMessage: 'update by men004',
  },

  lableWithdrawalFeePaymentNo: {
    id: `${scope}.lable_withdrawal_fee_payment_no`,
    defaultMessage: 'withdrawal fee payment no',
  },
  lableWithdrawalFeePaymentNoAfterReEmploy: {
    id: `${scope}.lable_withdrawal_fee_payment_no_after_re_employ`,
    defaultMessage: 'withdrawal fee payment no after re employ',
  },

  // men002
  lableGroupCompanyId: {
    id: `${scope}.lable_group_company_id`,
    defaultMessage: 'group company id',
  },
  lableGroupCompanyName: {
    id: `${scope}.lable_group_company_name`,
    defaultMessage: 'group company name',
  },
  lableSourceNameFile: {
    id: `${scope}.source_name_file`,
    defaultMessage: 'source name file',
  },
  lableAddress: {
    id: `${scope}.address`,
    defaultMessage: 'address',
  },
  lableAddressTwo: {
    id: `${scope}.address_two`,
    defaultMessage: 'address two',
  },
  lableTel: {
    id: `${scope}.tel`,
    defaultMessage: 'tel',
  },
  lableFax: {
    id: `${scope}.fax`,
    defaultMessage: 'fax',
  },
  lableRepresentativeShoulderBook: {
    id: `${scope}.representative_shoulder_book`,
    defaultMessage: 'lable representative shoulder book',
  },
  lableNameOfRepresentative: {
    id: `${scope}.name_of_representative`,
    defaultMessage: 'lable name of representative',
  },
  lableJimukyokuchoKatagaki: {
    id: `${scope}.jimukyokucho_katagaki`,
    defaultMessage: 'lable jimukyokucho katagaki',
  },
  lableJimukyokuchoFullname: {
    id: `${scope}.jimukyokucho_fullname`,
    defaultMessage: 'lable jimukyokucho fullname',
    },
  lableNameOfExecutivePos: {
    id: `${scope}.name_of_executive_pos`,
    defaultMessage: 'lable Name Of Executive pos',
  },
  lableNameOfExecutiveNm: {
    id: `${scope}.name_of_executive_nm`,
    defaultMessage: 'lable name of executive nm',
  },
  lableContactNameInvoice: {
    id: `${scope}.contact_name_invoice`,
    defaultMessage: 'lable contact name invoice',
  },
  lableContactName: {
    id: `${scope}.contact_name`,
    defaultMessage: 'lable contact name',
  },
  lablePersonTEL: {
    id: `${scope}.person_tel`,
    defaultMessage: 'lable person TEL',
  },
  lablePersonFAX: {
    id: `${scope}.person_fax`,
    defaultMessage: 'lable person FAX',
  },
  lableMailAddress: {
    id: `${scope}.mail_address`,
    defaultMessage: 'lable mail address',
  },
  labelSourceAccountInformation: {
    id: `${scope}.source_account_information`,
    defaultMessage: 'source account information',
  },
  lableBank: {
    id: `${scope}.bank`,
    defaultMessage: 'bank',
  },
  lableBankCode: {
    id: `${scope}.bank_code`,
    defaultMessage: 'bank code',
  },
  lableBranch: {
    id: `${scope}.branch`,
    defaultMessage: 'branch',
  },
  lableBranchCode: {
    id: `${scope}.branch_code`,
    defaultMessage: 'branch code',
  },
  lableTypeAccount: {
    id: `${scope}.type_account`,
    defaultMessage: 'type account',
  },
  lableNumberAccount: {
    id: `${scope}.number_account`,
    defaultMessage: 'number account',
  },
  lableAccountHolderKana: {
    id: `${scope}.account_holder_kana`,
    defaultMessage: 'account holder_kana',
  },
  lableConsignorCode: {
    id: `${scope}.consignor_code`,
    defaultMessage: 'consignor code',
  },
  labelOfIsKyufusummary: {
    id: `${scope}.is_kyufusummary`,
    defaultMessage: 'is kyufusummary',
  },
  lableRemarks: {
    id: `${scope}.remarks`,
    defaultMessage: 'note men002',
  },
  lableCompanySealFile: {
    id: `${scope}.company_seal_file`,
    defaultMessage: 'company seal file',
  },
  lableSymbolFile: {
    id: `${scope}.symbol_file`,
    defaultMessage: 'symbol file',
  },
  lableCreateMen002: {
    id: `${scope}.create_men002`,
    defaultMessage: 'create men002',
  },
  lableCreateBy: {
    id: `${scope}.create_by_men002`,
    defaultMessage: 'create by men002',
  },
  lableUpdateMen002: {
    id: `${scope}.update_men002`,
    defaultMessage: 'update men002',
  },
  lableUpdateBy: {
    id: `${scope}.update_by_men002`,
    defaultMessage: 'update by men002',
  },
  labelGroupCompany: {
    id: `${scope}.group_company`,
    defaultMessage: 'group company',
  },
  labelCompanyName: {
    id: `${scope}.company_name`,
    defaultMessage: 'company name ',
  },
  labelBenefitNo: {
    id: `${scope}.benefit_no`,
    defaultMessage: 'benefit no',
  },
  labelJoinDate: {
    id: `${scope}.join_date`,
    defaultMessage: 'join date',
  },
  labelSendOff: {
    id: `${scope}.send_off`,
    defaultMessage: 'send off',
  },
  labelWithdrawalDate: {
    id: `${scope}.withdrawal_date`,
    defaultMessage: 'withdrawal date',
  },
  labelKanjiName: {
    id: `${scope}.kanji_name`,
    defaultMessage: 'kanji name',
  },
  labelNameOfKana: {
    id: `${scope}.name_of_kana`,
    defaultMessage: 'name of kana',
  },
  labelDateOfPayment: {
    id: `${scope}.date_of_payment`,
    defaultMessage: 'date of payment',
  },
  apa001LabelDateOfPayment: {
    id: `${scope}.apa001_date_of_payment`,
    defaultMessage: 'date of payment',
  },
  apa003LabelDateOfPayment: {
    id: `${scope}.apa003_date_of_payment`,
    defaultMessage: 'date of payment',
  },
  apa003LabelComment: {
    id: `${scope}.apa003_label_comment`,
    defaultMessage: 'comment',
  },
  apa003LabelPaymentDate: {
    id: `${scope}.apa003_payment_date`,
    defaultMessage: 'date of payment',
  },
  apa003LabelDataCategory: {
    id: `${scope}.apa003_data_category`,
    defaultMessage: 'data category',
  },
  apa003LabelTotalSubsidize: {
    id: `${scope}.apa003_total_subsidize`,
    defaultMessage: 'data total subsidize',
  },
  apa003LabelManager: {
    id: `${scope}.apa003_manager`,
    defaultMessage: 'manager',
  },
  apa003LabelSuperior: {
    id: `${scope}.apa003_superior`,
    defaultMessage: 'superior',
  },
  apa003LabelDirector: {
    id: `${scope}.apa003_director`,
    defaultMessage: 'director',
  },
  apa003LabelStatus: {
    id: `${scope}.apa003_status`,
    defaultMessage: 'status',
  },

  labelEmployeeName: {
    id: `${scope}.employee_name`,
    defaultMessage: 'employee name',
  },
  userId: {
    id: `${scope}.userId`,
    defaultMessage: 'This is userID!',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'This is password!',
  },
  loginUser: {
    id: `${scope}.loginUser`,
    defaultMessage: 'This is loginUser!',
  },
  currentPassword: {
    id: `${scope}.currentPassword`,
    defaultMessage: 'This is currentPassword!',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'This is New password!',
  },
  confirmNewPassword: {
    id: `${scope}.confirmNewPassword`,
    defaultMessage: 'This is Confirmation Password!',
  },

  // mse003
  labelCompanyId: {
    id: `${scope}.lable_company_id`,
    defaultMessage: 'company id',
  },
  labelGroupCompanyMse003: {
    id: `${scope}.lable_group_company_mse_003`,
    defaultMessage: 'group company',
  },
  labelOutputIncludeHistory: {
    id: `${scope}.label_output_include_history`,
    defaultMessage: 'label Output Include History',
  },
  labelStatus: {
    id: `${scope}.status`,
    defaultMessage: 'status',
  },
  // men003
  labelCompanyIdMen003: {
    id: `${scope}.label_company_id_men003`,
    defaultMessage: 'company id',
  },
  labelSort: {
    id: `${scope}.label_sort`,
    defaultMessage: 'sort',
  },
  labelCompanyNameMen003: {
    id: `${scope}.label_company_name_men003`,
    defaultMessage: 'company name',
  },
  labelCompanyNameKanaMen003: {
    id: `${scope}.label_company_name_kana_men003`,
    defaultMessage: 'company name kana',
  },
  labelSortCompanyNameMen003: {
    id: `${scope}.label_sort_company_name_men003`,
    defaultMessage: 'sort company name',
  },
  labelPostalCodeMen003: {
    id: `${scope}.label_postal_code_men003`,
    defaultMessage: 'postal code',
  },
  labelAddressMen003: {
    id: `${scope}.label_address_men003`,
    defaultMessage: 'address',
  },
  labelTelMen003: {
    id: `${scope}.label_tel_men003`,
    defaultMessage: 'tel',
  },
  labelFaxMen003: {
    id: `${scope}.label_fax_men003`,
    defaultMessage: 'fax',
  },
  labelRepresentativePositionMen003: {
    id: `${scope}.label_representative_position_men003`,
    defaultMessage: 'representative position',
  },
  labelRepresentativeNameMen003: {
    id: `${scope}.label_representative_name_men003`,
    defaultMessage: 'representative name',
  },
  labelBillContactNameMen003: {
    id: `${scope}.label_bill_contact_name_men003`,
    defaultMessage: 'bill contact name',
  },
  labelContactNameMen003: {
    id: `${scope}.label_contact_name_men003`,
    defaultMessage: 'contact name',
  },
  labelContactTelMen003: {
    id: `${scope}.label_contact_tel_men003`,
    defaultMessage: 'contact tel',
  },
  labelContactFaxMen003: {
    id: `${scope}.label_contact_fax_men003`,
    defaultMessage: 'contact fax',
  },
  labelEmailMen003: {
    id: `${scope}.label_email_men003`,
    defaultMessage: 'email',
  },
  labelBankNameMen003: {
    id: `${scope}.label_bank_name_men003`,
    defaultMessage: 'bank name',
  },
  labelBankCodeMen003: {
    id: `${scope}.label_bank_code_men003`,
    defaultMessage: 'bank code',
  },
  labelBranchNameMen003: {
    id: `${scope}.label_branch_name_men003`,
    defaultMessage: 'branch name',
  },
  labelBranchCodeMen003: {
    id: `${scope}.label_branch_code_men003`,
    defaultMessage: 'branch code',
  },
  labelAccountTypeMen003: {
    id: `${scope}.label_account_type_men003`,
    defaultMessage: 'account type',
  },
  labelAccountNoMen003: {
    id: `${scope}.label_account_no_men003`,
    defaultMessage: 'account no',
  },
  labelAccountHolderNameKanjiMen003: {
    id: `${scope}.label_account_holder_name_kanji_men003`,
    defaultMessage: 'account holder name kanji',
  },
  labelAccountHolderNameKanaMen003: {
    id: `${scope}.label_account_holder_name_kana_men003`,
    defaultMessage: 'account holder name kana',
  },
  labelJoinDtMen003: {
    id: `${scope}.label_join_dt_men003`,
    defaultMessage: 'join date',
  },
  labelLeavingDtMen003: {
    id: `${scope}.label_leaving_dt_men003`,
    defaultMessage: 'leaving date',
  },
  labelCompanyGroupNameMen003: {
    id: `${scope}.label_company_group_name_men003`,
    defaultMessage: 'company group name',
  },
  labelKyosaiTypeCdMen003: {
    id: `${scope}.label_kyosai_type_cd_men003`,
    defaultMessage: 'kyosai type cd',
  },
  labelRequestClassifyMen003: {
    id: `${scope}.label_request_classify_men003`,
    defaultMessage: 'request classify',
  },
  labelPaymentMethodMen003: {
    id: `${scope}.label_payment_method_men003`,
    defaultMessage: 'payment method',
  },
  labelPaymentTermsMen003: {
    // dieu khoan thanh toan
    id: `${scope}.label_payment_terms_men003`,
    defaultMessage: 'Payment terms',
  },
  labelShiharaijokenShimebiMen003: {
    // ngay chot (thang truoc , thang sau)
    id: `${scope}.label_shiharaijoken_shimebi_men003`,
    defaultMessage: 'shiharaijoken_shimebi',
  },
  labelShiharaijokenShiharaibiMen003: {
    // ngay thanh toan
    id: `${scope}.label_shiharaijoken_shiharaibi_men003`,
    defaultMessage: 'shiharaijoken_shiharaibi',
  },
  lableDateOfPayment: {
    id: `${scope}.lable_date_of_payment`,
    defaultMessage: 'lable Date Of Payment',
  },
  labelRecoveryMethodMen003: {
    id: `${scope}.label_recovery_method_men003`,
    defaultMessage: 'recovery method',
  },
  labelPaymentCalClassifyMen003: {
    id: `${scope}.label_payment_cal_classify_men003`,
    defaultMessage: 'payment cal classify',
  },
  labelBillingPeriodMen003: {
    // Kỳ hạn đối tượng yêu cầu thanh toán
    id: `${scope}.label_billing_period_men003`,
    defaultMessage: 'Billing period',
  },
  labelSeikyutaishokikanKaishigetsuMen003: {
    // Tháng bắt đầu
    id: `${scope}.label_seikyutaishokikan_kaishigetsu_men003`,
    defaultMessage: 'seikyutaishokikan_kaishigetsu',
  },
  labelBillIssueDtMen003: {
    id: `${scope}.label_bill_issue_dt_men003`,
    defaultMessage: 'bill issue dt',
  },
  labelKaishujokenNyukinSiteMen003: {
    // dieu kien thu hoi
    id: `${scope}.label_kaishujoken_nyukin_site_men003`,
    defaultMessage: 'kaishujoken nyukin site',
  },
  labelKaishujokenNyukimbiMen003: {
    // ngay gui tien
    id: `${scope}.label_kaishujoken_nyukimbi_men003`,
    defaultMessage: 'kaishujoken nyukimbi',
  },
  labelKeihiShiharaijokenShimebiMen003: {
    // Điều kiện thanh toán kinh phí
    id: `${scope}.label_keihi_shiharaijoken_shimebi_men003`,
    defaultMessage: 'keihi shiharaijoken shimebi',
  },
  labelKeihiShiharaijokenShiharaiSiteMen003: {
    // ngay chot
    id: `${scope}.label_keihi_shiharaijoken_shiharai_site_men003`,
    defaultMessage: 'keihi shiharaijoken shiharai site',
  },
  labelKeihiShiharaijokenShiharaibiMen003: {
    // ngay thanh toan
    id: `${scope}.label_keihi_shiharaijoken_shiharaibi_men003`,
    defaultMessage: 'keihi shiharaijoken shiharaibi',
  },
  labelNoteMen003: {
    id: `${scope}.label_note_men003`,
    defaultMessage: 'note',
  },
  labelCreateDtTimeMen003: {
    id: `${scope}.label_create_dt_time_men003`,
    defaultMessage: 'create dt time',
  },
  labelCreateUserNameMen003: {
    id: `${scope}.label_create_user_name_men003`,
    defaultMessage: 'create user name',
  },
  labelUpdateDtTimeMen003: {
    id: `${scope}.label_update_dt_time_men003`,
    defaultMessage: 'update dt time',
  },
  labelUpdateUserNameMen003: {
    id: `${scope}.label_update_user_name_men003`,
    defaultMessage: 'update user name',
  },
  // mse002
  labelGroupCompanyMse002: {
    id: `${scope}.label_group_company_mse002`,
    defaultMessage: 'label GroupCompany Mse002',
  },
  labelOutputIncludeHistoryMse002: {
    id: `${scope}.label_output_include_history_mse002`,
    defaultMessage: 'label OutputIncludeHistory Mse002',
  },

  kyosaiTypeCode: {
    id: `${scope}.kyosaiTypeCode`,
    defaultMessage: 'This is kyosaiTypeCode!',
  },
  kyosaiTypeName: {
    id: `${scope}.kyosaiTypeName`,
    defaultMessage: 'This is kyosaiTypeName!',
  },
  kakekin: {
    id: `${scope}.kakekin`,
    defaultMessage: 'This is kakekin!',
  },
  nenrei: {
    id: `${scope}.nenrei`,
    defaultMessage: 'This is nenrei!',
  },
  biko: {
    id: `${scope}.biko`,
    defaultMessage: 'This is biko!',
  },
  create_men001: {
    id: `${scope}.create_men001`,
    defaultMessage: 'This is create_men001!',
  },
  update_men001: {
    id: `${scope}.update_men001`,
    defaultMessage: 'This is update_men001!',
  },
  includingHistory: {
    id: `${scope}.includingHistory`,
    defaultMessage: 'This is includingHistory!',
  },
  labelApplicationDate: {
    id: `${scope}.application_date`,
    defaultMessage: 'application date',
  },
  labelDateOfBirth: {
    id: `${scope}.date_of_birth`,
    defaultMessage: 'date of birth',
  },
  labelAgeAtBirth: {
    id: `${scope}.age_at_birth`,
    defaultMessage: 'age at birth',
  },
  labelSubscriptionPeriodDateOfBirth: {
    id: `${scope}.subscription_period_date_of_birth`,
    defaultMessage: 'subscription period date of birth',
  },
  labelAgeAtDisaster: {
    id: `${scope}.age_at_disaster`,
    defaultMessage: 'age At Disaster',
  },
  labelSubscriptionPeriodDateOfDisaster: {
    id: `${scope}.subscription_period_date_of_disaster`,
    defaultMessage: 'subscription period date of disaster',
  },
  labelChildName: {
    id: `${scope}.child_name`,
    defaultMessage: 'child name',
  },
  labelMaternityGift: {
    id: `${scope}.maternity_gift`,
    defaultMessage: 'maternity gift',
  },

  labelWeddingGift: {
    id: `${scope}.label_wedding_gift`,
    defaultMessage: 'wedding gift',
  },
  labelSpouseName: {
    id: `${scope}.label_spouse_name`,
    defaultMessage: 'spouse name',
  },
  labelMarriedDate: {
    id: `${scope}.label_married_date`,
    defaultMessage: 'married date',
  },
  labelAgeMarriedDate: {
    id: `${scope}.label_age_married_date`,
    defaultMessage: 'age married date',
  },
  labelSubscriptionPeriodMarriedDate: {
    id: `${scope}.label_subscription_period_married_date`,
    defaultMessage: 'subscription period married date',
  },
  labelTotalAmountOfBenefits: {
    id: `${scope}.total_amount_of_benefits`,
    defaultMessage: 'total amount of benefits',
  },
  labelFormerBenefitNo: {
    id: `${scope}.former_benefit_no`,
    defaultMessage: 'former benefit no',
  },
  labelApplicantRemarks: {
    id: `${scope}.applicant_remarks`,
    defaultMessage: 'applicant remarks ',
  },
  labelHROneRemarks: {
    id: `${scope}.HROne_remarks`,
    defaultMessage: 'HROne_remarks ',
  },
  labelRequiredDocuments: {
    id: `${scope}.required_documents`,
    defaultMessage: 'requiredDocuments',
  },
  labelAttachment1: {
    id: `${scope}.attachment_1`,
    defaultMessage: 'attachment 1',
  },
  labelAttachment2: {
    id: `${scope}.attachment_2`,
    defaultMessage: 'attachment 2',
  },
  labelAttachment3: {
    id: `${scope}.attachment_3`,
    defaultMessage: 'attachment 3',
  },
  labelPersonnelApproval: {
    id: `${scope}.personnel_approval`,
    defaultMessage: 'personnel approval',
  },
  labelSupervisorApproval: {
    id: `${scope}.supervisor_approval`,
    defaultMessage: 'supervisor approval',
  },
  labelSecretaryApproved: {
    id: `${scope}.secretary_approved`,
    defaultMessage: 'secretary approved',
  },
  labelWorkflowKey: {
    id: `${scope}.workflow_key`,
    defaultMessage: 'workflow key',
  },
  labelBenefitType: {
    id: `${scope}.benefit_type`,
    defaultMessage: 'benefit type',
  },
  labelInjuryDivision: {
    id: `${scope}.injury_division`,
    defaultMessage: 'injury division',
  },
  labelDateOfOccurrence: {
    id: `${scope}.date_of_occurrence`,
    defaultMessage: 'date of occurrence',
  },
  labelInjuryName: {
    id: `${scope}.injury_name`,
    defaultMessage: 'injury name',
  },
  labelSicknessIllnessPayment: {
    id: `${scope}.sickness_illness_payment`,
    defaultMessage: 'sickness illness payment',
  },
  labelHolidayPeriod: {
    id: `${scope}.holiday_period`,
    defaultMessage: 'holiday period',
  },
  labelDayOff: {
    id: `${scope}.day_off`,
    defaultMessage: 'day off',
  },
  labelSickAndIll: {
    id: `${scope}.sick_and_ill`,
    defaultMessage: 'sick and ill',
  },
  labelExaminationMoney: {
    id: `${scope}.examination_money`,
    defaultMessage: 'Examination Money',
  },
  labelVisitPeriod: {
    id: `${scope}.visit_period`,
    defaultMessage: 'visit period',
  },
  labelHospitalNumberDays: {
    id: `${scope}.hospital_number_days`,
    defaultMessage: 'hospital number days',
  },
  labelOsteopathVisits: {
    id: `${scope}.osteopath_visits`,
    defaultMessage: 'osteopath visits',
  },
  labelTotalHospitalVisits: {
    id: `${scope}.total_hospital_visits`,
    defaultMessage: 'totalHospitalVisits',
  },
  labelBenefitCalcDays: {
    id: `${scope}.benefit_calc_days`,
    defaultMessage: 'benefitCalcDays',
  },
  labelOutpatientCompensation: {
    id: `${scope}.outpatient_compensation`,
    defaultMessage: 'outpatientCompensation',
  },
  labelHospitalMoney: {
    id: `${scope}.hospital_money`,
    defaultMessage: 'hospital money',
  },
  labelHospitalStay: {
    id: `${scope}.hospital_stay`,
    defaultMessage: 'hospital stay',
  },
  labelHospitalizationDays: {
    id: `${scope}.hospitalization_days`,
    defaultMessage: 'hospitalization_days',
  },
  labelNumberOfOutpatientDays: {
    id: `${scope}.number_of_outpatient_days`,
    defaultMessage: 'number_of_outpatient_days',
  },
  labelNumberOfActualHospitalizeDays: {
    id: `${scope}.number_of_actual_hospitalize_days`,
    defaultMessage: 'number_of_actual_hospitalize_days',
  },
  labelHospitalizationCompensation: {
    id: `${scope}.hospitalization_compensation`,
    defaultMessage: 'hospitalization_compensation',
  },
  labelServiceBedCost: {
    id: `${scope}.service_bed_cost`,
    defaultMessage: 'service_bed_cost',
  },
  labelBedUseTime: {
    id: `${scope}.bed_use_time`,
    defaultMessage: 'bed_use_time',
  },
  labelNumberOfDaysUsingBed: {
    id: `${scope}.number_of_days_using_bed`,
    defaultMessage: 'number_of_days_using_bed',
  },
  labelPaymentAmount: {
    id: `${scope}.payment_amount`,
    defaultMessage: 'payment_amount',
  },
  labelCostOfServiceBed: {
    id: `${scope}.cost_of_service_bed`,
    defaultMessage: 'cost_of_service_bed',
  },
  labelDamageClassification: {
    id: `${scope}.damage_classification`,
    defaultMessage: 'damage classification',
  },
  labelTonanHigaigaku: {
    id: `${scope}.tonan_higaigaku`,
    defaultMessage: 'tonan higaigaku',
  },
  labelTheftMoney: {
    id: `${scope}.label_theft_money`,
    defaultMessage: 'label theft money',
  },
  // mse005
  labelUserIdMse005: {
    id: `${scope}.labelUserIdMse005`,
    defaultMessage: 'labelUserIdMse005',
  },
  labelInvalidDisplayMse005: {
    id: `${scope}.labelInvalidDisplayMse005`,
    defaultMessage: 'labelInvalidDisplayMse005',
  },
  lableFullNameMse005: {
    id: `${scope}.lableFullNameMse005`,
    defaultMessage: 'lableFullNameMse005',
  },
  lableIncludingHistoryMse005: {
    id: `${scope}.lableIncludingHistoryMse005`,
    defaultMessage: 'lableIncludingHistoryMse005',
  },
  labelCompany: {
    id: `${scope}.label_company`,
    defaultMessage: 'company',
  },

  // mse004
  labelNumberEmployee: {
    id: `${scope}.label_number_employee_mse004`,
    defaultMessage: 'Number Employee',
  },
  labelIdCompany: {
    id: `${scope}.label_id_company_mse004`,
    defaultMessage: 'Id Company',
  },
  labelGroupCompanyMse004: {
    id: `${scope}.label_group_company_mse004`,
    defaultMessage: 'Group Company Mse004',
  },
  labelNameMse004: {
    id: `${scope}.label_name_mse004`,
    defaultMessage: 'Name Mse004',
  },
  labelNameKanaMse004: {
    id: `${scope}.label_name_kana_mse004`,
    defaultMessage: 'Name kana Mse004',
  },
  labelOutputIncludeHistoryMse004: {
    id: `${scope}.label_output_include_history_mse004`,
    defaultMessage: 'Output Include History Mse004',
  },
  // mse006
  labelBenefitTypeMse006: {
    id: `${scope}.labelBenefitTypeMse006`,
    defaultMessage: 'labelBenefitTypeMse006',
  },
  labelInvalidDisplayMse006: {
    id: `${scope}.labelInvalidDisplayMse006`,
    defaultMessage: 'labelInvalidDisplayMse006',
  },
  lableIncludingHistoryMse006: {
    id: `${scope}.lableIncludingHistoryMse006`,
    defaultMessage: 'lableIncludingHistoryMse006',
  },
  labelGkyufuShubetsuCdMen006: {
    id: `${scope}.labelGkyufuShubetsuCdMen006`,
    defaultMessage: 'labelGkyufuShubetsuCdMen006',
  },
  labelShosaikubunCdMen006: {
    id: `${scope}.labelShosaikubunCdMen006`,
    defaultMessage: 'labelShosaikubunCdMen006',
  },
  labelEShoruiNameMen006: {
    id: `${scope}.labelEShoruiNameMen006`,
    defaultMessage: 'labelEShoruiNameMen006',
  },
  labelRCMS: {
    id: `${scope}.RCMS`,
    defaultMessage: 'RCMS',
  },
  labelZengin: {
    id: `${scope}.Zengin`,
    defaultMessage: 'Zengin',
  },

  // ben004
  labelDayOfDeath: {
    id: `${scope}.label_day_of_death`,
    defaultMessage: 'label Day Of Death',
  },
  labelNameHumanDeath: {
    id: `${scope}.label_name_human_death`,
    defaultMessage: 'label Name Human Death',
  },
  labelFamilyRelationship: {
    id: `${scope}.label_family_relationship`,
    defaultMessage: 'label Family Relationship',
  },
  labelFamilyCondolenceMoney: {
    id: `${scope}.label_family_condolenceMoney`,
    defaultMessage: 'label Family Relationship',
  },

  // ben009
  labelDisasterDay: {
    id: `${scope}.label_disaster_day`,
    defaultMessage: 'label Disaster Day',
  },
  labelStatusDisaster: {
    id: `${scope}.label_status_disaster`,
    defaultMessage: 'label Status Disaster',
  },
  labelNominalDistinction: {
    id: `${scope}.label_nominal_distinction`,
    defaultMessage: 'label Nominal Distinction',
  },
  labelHouseholdClassification: {
    id: `${scope}.label_household_classification`,
    defaultMessage: 'label Household Classification',
  },
  labelDisasterSeeDanceGold: {
    id: `${scope}.label_disaster_see_dance_gold`,
    defaultMessage: 'label Disaster See Dance Gold',
  },
  apa005LabelRcmsDate: {
    id: `${scope}.apa005_rcms_date`,
    defaultMessage: 'rcms date',
  },
  apa005LabelBankDate: {
    id: `${scope}.apa005_bank_date`,
    defaultMessage: 'bank date',
  },
  // ben006
  labelShobyoSubun: {
    id: `${scope}.labelShobyoSubun`,
    defaultMessage: 'labelShobyoSubun',
  },
  labelJiyuhasseiYmd: {
    id: `${scope}.labelJiyuhasseiYmd`,
    defaultMessage: 'labelJiyuhasseiYmd',
  },
  labelAgeAtTheDateOfOccurrence: {
    id: `${scope}.labelAgeAtTheDateOfOccurrence`,
    defaultMessage: 'labelAgeAtTheDateOfOccurrence',
  },
  labelShogaiNinteiYmd: {
    id: `${scope}.labelShogaiNinteiYmd`,
    defaultMessage: 'labelShogaiNinteiYmd',
  },
  labelAgeAtCertificationDate: {
    id: `${scope}.labelAgeAtCertificationDate`,
    defaultMessage: 'labelAgeAtCertificationDate',
  },
  labelCertificationDate: {
    id: `${scope}.labelCertificationDate`,
    defaultMessage: 'labelCertificationDate',
  },
  labelShobyoName: {
    id: `${scope}.labelShobyoName`,
    defaultMessage: 'labelShobyoName',
  },
  labelKoishogaiTokyuCd: {
    id: `${scope}.labelKoishogaiTokyuCd`,
    defaultMessage: 'labelKoishogaiTokyuCd',
  },
  labelKyufuKingaku: {
    id: `${scope}.labelKyufuKingaku`,
    defaultMessage: 'labelKyufuKingaku',
  },

  // imp
  impLabelDateImport: {
    id: `${scope}.imp_date_import`,
    defaultMessage: 'Import date',
  },
  impDateLabel: {
    id: `${scope}.imp_date_label`,
    defaultMessage: 'Date',
  },
  impSubmitDateLabel: {
    id: `${scope}.imp_submit_date_label`,
    defaultMessage: 'Date',
  },
  impFileLabelImport: {
    id: `${scope}.file_label`,
    defaultMessage: 'import',
  },
  impLabelImportDetail: {
    id: `${scope}.imp_import_detail`,
    defaultMessage: 'Import detail',
  },
  impLabelId: {
    id: `${scope}.imp_id`,
    defaultMessage: 'ID',
  },
  impLabelStatus: {
    id: `${scope}.imp_status`,
    defaultMessage: 'Status',
  },
  impLabelFileName: {
    id: `${scope}.file_name_label`,
    defaultMessage: 'Filename',
  },
  impLabelPaymentDate: {
    id: `${scope}.imp_payment_date`,
    defaultMessage: 'Payment date',
  },
  impLabelTotal: {
    id: `${scope}.imp_total`,
    defaultMessage: 'Total',
  },
  impLabelWarning: {
    id: `${scope}.imp_warning`,
    defaultMessage: 'Warning',
  },
  ageArising: {
    id: `${scope}.ageArising`,
    defaultMessage: 'ageArising',
  },
  subsciptionArising: {
    id: `${scope}.subsciptionArising`,
    defaultMessage: 'subsciptionArising',
  },
  ageDetermined: {
    id: `${scope}.ageDetermined`,
    defaultMessage: 'ageDetermined',
  },
  subsciptionDetermined: {
    id: `${scope}.subsciptionDetermined`,
    defaultMessage: 'subsciptionDetermined',
  },

  // ben005
  labelShobyoKubun005: {
    id: `${scope}.labelShobyoKubun005`,
    defaultMessage: 'labelShobyoKubun005',
  },

  labelJiyuhasseiYmd005: {
    id: `${scope}.labelJiyuhasseiYmd005`,
    defaultMessage: 'labelJiyuhasseiYmd005',
  },
  labelShiboGenin: {
    id: `${scope}.labelShiboGenin`,
    defaultMessage: 'labelShiboGenin',
  },
  labelShobyoName005: {
    id: `${scope}.labelShobyoName005`,
    defaultMessage: 'labelShobyoName005',
  },
  labelKyufuKingakuDeath: {
    id: `${scope}.labelKyufuKingakuDeath`,
    defaultMessage: 'labelKyufuKingakuDeath',
  },
  labelKyufuKingakuSelf: {
    id: `${scope}.labelKyufuKingakuSelf`,
    defaultMessage: 'labelKyufuKingakuSelf',
  },
  labelKyufuKingakuChild: {
    id: `${scope}.labelKyufuKingakuChild`,
    defaultMessage: 'labelKyufuKingakuChild',
  },

  // ben008
  labelDayOfConsultation: {
    id: `${scope}.label_day_of_consultation`,
    defaultMessage: 'Day Of Consultation',
  },
  labelDateOfBirthBen008: {
    id: `${scope}.label_date_of_birth_ben008`,
    defaultMessage: 'Date Of Birth',
  },
  labelAgeBen008: {
    id: `${scope}.label_age_ben008`,
    defaultMessage: 'Age',
  },
  labelGenderBen008: {
    id: `${scope}.label_gender_ben008`,
    defaultMessage: 'gender ben008',
  },
  labelPertionalContributionBen008: {
    id: `${scope}.label_pertional_contribution_ben008`,
    defaultMessage: 'pertional contribution',
  },
  labelMedicalExaminationSubsidyBen008: {
    id: `${scope}.label_medical_examination_subsidy_ben008`,
    defaultMessage: 'Medical examination subsidy',
  },
  labelSubscriptionPeriodDateOfBirthBen005: {
    id: `${scope}.labelSubscriptionPeriodDateOfBirthBen005`,
    defaultMessage: 'labelSubscriptionPeriodDateOfBirthBen005',
  },
  labelAgeAtBirthBen005: {
    id: `${scope}.labelAgeAtBirthBen005`,
    defaultMessage: 'labelAgeAtBirthBen005',
  },
  labelJiyuhasseiYmd011: {
    id: `${scope}.labelJiyuhasseiYmd011`,
    defaultMessage: 'labelJiyuhasseiYmd011',
  },
  labelKyufuKingaku011: {
    id: `${scope}.labelKyufuKingaku011`,
    defaultMessage: 'labelKyufuKingaku011',
  },
  labelWaveWfKey: {
    id: `${scope}.labelWaveWfKey`,
    defaultMessage: 'labelWaveWfKey',
  },
  labelSubscriptionPeriodDateOfBirthBen011: {
    id: `${scope}.labelSubscriptionPeriodDateOfBirthBen011`,
    defaultMessage: 'labelSubscriptionPeriodDateOfBirthBen011',
  },
  labelAgeAtBirthBen011: {
    id: `${scope}.labelAgeAtBirthBen011`,
    defaultMessage: 'labelAgeAtBirthBen011',
  },

  // per003
  labelYearMonth: {
    id: `${scope}.label_year_month_per_003`,
    defaultMessage: 'labelYearMonth',
  },
  labelInvoiceDate: {
    id: `${scope}.label_invoice_date_per_003`,
    defaultMessage: 'Invoice Date',
  },
  labelGroupCompanyPer03: {
    id: `${scope}.lable_group_company_mse_003`,
    defaultMessage: 'Group company per003',
  },
  labelApplicationDivitionPer03: {
    id: `${scope}.label_application_divition_per03`,
    defaultMessage: 'Application Divition Per03',
  },
  per002LabelDateOfPayment: {
    id: `${scope}.per002_date_of_payment`,
    defaultMessage: 'date of payment',
  },
  // per004
  labelYear: {
    id: `${scope}.labelYear`,
    defaultMessage: '年度',
  },
  fileMen005: {
    id: `${scope}.fileMen005`,
    defaultMessage: 'fileMen005',
  },
  //per005
  labelCompanyPer005: {
    id: `${scope}.labelCompanyPer005`,
    defaultMessage: '会社グループ',
  },
  labelNotifiedOut: {
    id: `${scope}.labelNotifiedOut`,
    defaultMessage: '通知済も出力',
  },
  //men007
  labelGroupCompanyMen007: {
    id: `${scope}.lable_group_company_mse_003`,
    defaultMessage: 'group company',
  },

});
