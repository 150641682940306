/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState, useEffect } from 'react';
import Loading from 'components/LoadingIndicator';
import { Switch, Route, withRouter, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import '@grapecity/wijmo.cultures/wijmo.culture.ja';
import { useInjectSaga } from 'utils/injectSaga';
import Com003Page from 'containers/Com003/Loadable';
import Ben012Page from 'containers/Ben012/Loadable';
import Ben003Page from 'containers/Ben003/Loadable';
import Ben007Page from 'containers/Ben007/Loadable';
import Ben002Page from 'containers/Ben002/Loadable';
import Ben004Page from 'containers/Ben004/Loadable';
import Ben006Page from 'containers/Ben006/Loadable';
import Ben005Page from 'containers/Ben005/Loadable';
import Ben008Page from 'containers/Ben008/Loadable';
import Ben009Page from 'containers/Ben009/Loadable';
import Ben010Page from 'containers/Ben010/Loadable';
import Ben011Page from 'containers/Ben011/Loadable';
import Men002 from 'containers/Men002/Loadable';
import Men005Page from 'containers/Men005/Loadable';
import Men004Page from 'containers/Men004/Loadable';
import Men003 from 'containers/Men003/Loadable';
import Men006 from 'containers/Men006/Loadable';
import Men001 from 'containers/Men001/Loadable';
import Men007 from 'containers/Men007/Loadable';
import Mse003 from 'containers/Mse003/Loadable';
import Mse001 from 'containers/Mse001/Loadable';
import Mse005 from 'containers/Mse005/Loadable';
import Mse002 from 'containers/Mse002/Loadable';
import Mse004 from 'containers/Mse004/Loadable';
import Mse006 from 'containers/Mse006/Loadable';
import Apa001 from 'containers/Apa001/Loadable';
import Apa002 from 'containers/Apa002/Loadable';
import Apa004 from 'containers/Apa004/Loadable';
import Apa005 from 'containers/Apa005/Loadable';
import Apa003 from 'containers/Apa003/Loadable';
import Sap001 from 'containers/Sap001/Loadable';
import Imp002 from 'containers/Imp/Imp002/Loadable';
import Imp003 from 'containers/Imp/Imp003/Loadable';
import Imp004 from 'containers/Imp/Imp004/Loadable';
import Imp005 from 'containers/Imp/Imp005/Loadable';
import Imp006 from 'containers/Imp/Imp006/Loadable';
import Sap002 from 'containers/Sap002/Loadable';
import Sap003 from 'containers/Sap003/Loadable';
import Sap004 from 'containers/Sap004/Loadable';
import Iku001 from 'containers/Iku001/Loadable';
import Iku003 from 'containers/Iku003/Loadable';
import Per002 from 'containers/Per002/Loadable';
import Per003 from 'containers/Per003/Loadable';
import Per001 from 'containers/Per001/Loadable';
import Per004 from 'containers/Per004/Loadable';
import Per005 from 'containers/Per005/Loadable';
import Per006 from 'containers/Per006/Loadable';
import Com001Page from 'containers/Com001/Loadable';
import Com002LoginPage from 'containers/Com002/Loadable';
import His001 from 'containers/His001/Loadable';
import His002 from 'containers/His002/Loadable';
import Page404 from 'containers/404/Loadable';
import Page500 from 'containers/500/Loadable';

import { getUserPermissionRequest } from './actions';
import saga from './saga';
import Modal from '../../components/Modal';
import ENUMS from '../../config/enum';
import EventEmitter, { SET_ERROR_MESSAGE } from '../../helper/EventEmitter';
import usePrevious from '../../helper/usePrevious';
import { isLoadingSelector } from '../LoadingProvider/selectors';
import {
  DEFAULT_TITLE,
  GET_USER_PERMISSION,
  mapScreenTitle,
  WIJIMO_KEY,
} from './constants';

import ROUTERS from '../../config/routes';
import PrivateRoute from '../../config/PrivateRoute';
import { AuthContext } from '../context/auth';
import { useNavigatorOnline } from '../../helper/useNavigatorOnline';
import NetWorking from '../../components/NetWork';
import './blue_new_btn.css';
import './index.scss';
import './custom.scss';
import { makeSelectLocation } from './selectors';
import { setLicenseKey } from '@grapecity/wijmo';
setLicenseKey(WIJIMO_KEY);

const App = ({ getUserPermissionRequest, isPermissionLoading, location }) => {
  useInjectSaga({ key: 'global', saga });
  const [errorMessage, setErrorMessage] = useState('');
  const [isRender, setIsRender] = useState(false);
  const prevUserPermissionLoading = usePrevious(isPermissionLoading);
  const { isOnline } = useNavigatorOnline();
  useEffect(() => {
    if (isPermissionLoading === false && prevUserPermissionLoading === true) {
      setIsRender(true);
    }
  }, [isPermissionLoading]);

  useEffect(() => {
    const hasToken = localStorage.getItem('token');
    if (
      location.pathname != ROUTERS?.PAGE500 &&
      location.pathname != ROUTERS?.PAGE404 &&
      location.pathname.toLowerCase() != ROUTERS?.COM002.toLowerCase() &&
      hasToken
    ) {
      getUserPermissionRequest();
    } else {
      setIsRender(true);
    }
    EventEmitter.subscribe(SET_ERROR_MESSAGE, setErrorMessage);
    return () => EventEmitter.unsubscribe(SET_ERROR_MESSAGE, setErrorMessage);
  }, []);

  useEffect(() => {
    const [path] = location.pathname.replace('/', '').split('/');
    document.title = mapScreenTitle[path.toUpperCase()] || DEFAULT_TITLE;
  }, [location.pathname]);

  if (!isRender) {
    return (
      <div className={isOnline ? 'container' : 'container-not-online'}>
        <div className="wrapcontainer">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={localStorage.getItem('user')}>
      <div className={isOnline ? 'container' : 'container-not-online'}>
        <div className="wrapcontainer">
          {!isOnline && <NetWorking />}
          {isOnline && (
            <Switch>
              <PrivateRoute exact path={ROUTERS?.HOME} component={Com001Page} />
              <PrivateRoute
                exact
                path={ROUTERS?.COM001}
                component={Com001Page}
              />
              <Route exact path={ROUTERS?.COM002} component={Com002LoginPage} />
              <PrivateRoute path={ROUTERS?.COM003} component={Com003Page} />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN012}
                component={Ben012Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN003}
                component={Ben003Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN002}
                component={Ben002Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN007}
                component={Ben007Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN006}
                component={Ben006Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN005}
                component={Ben005Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN004}
                component={Ben004Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN008}
                component={Ben008Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN009}
                component={Ben009Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN010}
                component={Ben010Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.BEN011}
                component={Ben011Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.MEN005}
                component={Men005Page}
              />
              <PrivateRoute
                exact
                path={ROUTERS?.MEN004}
                component={Men004Page}
              />
              <PrivateRoute exact path={ROUTERS?.MEN006} component={Men006} />
              <PrivateRoute exact path={ROUTERS?.MEN003} component={Men003} />
              <PrivateRoute exact path={ROUTERS?.MEN002} component={Men002} />
              <PrivateRoute exact path={ROUTERS?.MEN001} component={Men001} />
              <PrivateRoute exact path={ROUTERS?.MEN007} component={Men007} />
              <PrivateRoute exact path={ROUTERS?.MSE003} component={Mse003} />
              <PrivateRoute exact path={ROUTERS?.MSE002} component={Mse002} />
              <PrivateRoute exact path={ROUTERS?.MSE001} component={Mse001} />
              <PrivateRoute exact path={ROUTERS?.MSE005} component={Mse005} />
              <PrivateRoute exact path={ROUTERS?.MSE004} component={Mse004} />
              <PrivateRoute exact path={ROUTERS?.MSE006} component={Mse006} />
              <PrivateRoute exact path={ROUTERS?.APA001} component={Apa001} />
              <PrivateRoute exact path={ROUTERS?.APA002} component={Apa002} />
              <PrivateRoute exact path={ROUTERS?.APA004} component={Apa004} />
              <PrivateRoute exact path={ROUTERS?.APA005} component={Apa005} />
              <PrivateRoute exact path={ROUTERS?.APA003} component={Apa003} />
              {/* <Route exact path="" component={NotFoundPage} /> */}
              <PrivateRoute exact path={ROUTERS?.IMP002} component={Imp002} />
              <PrivateRoute exact path={ROUTERS?.IMP003} component={Imp003} />
              <PrivateRoute exact path={ROUTERS?.IMP004} component={Imp004} />
              <PrivateRoute exact path={ROUTERS?.IMP005} component={Imp005} />
              <PrivateRoute exact path={ROUTERS?.IMP006} component={Imp006} />
              <PrivateRoute exact path={ROUTERS?.SAP001} component={Sap001} />
              <PrivateRoute exact path={ROUTERS?.SAP002} component={Sap002} />
              <PrivateRoute exact path={ROUTERS?.SAP003} component={Sap003} />
              <PrivateRoute exact path={ROUTERS?.SAP004} component={Sap004} />
              <PrivateRoute exact path={ROUTERS?.IKU003} component={Iku003} />
              <PrivateRoute exact path={ROUTERS?.IKU001} component={Iku001} />
              <PrivateRoute exact path={ROUTERS?.PER003} component={Per003} />
              <PrivateRoute exact path={ROUTERS?.PER002} component={Per002} />
              <PrivateRoute exact path={ROUTERS?.PER001} component={Per001} />
              <PrivateRoute exact path={ROUTERS?.PER004} component={Per004} />
              <PrivateRoute exact path={ROUTERS?.PER005} component={Per005} />
              <PrivateRoute exact path={ROUTERS?.PER006} component={Per006} />
              <PrivateRoute exact path={ROUTERS?.HIS001} component={His001} />
              <PrivateRoute exact path={ROUTERS?.HIS002} component={His002} />
              {/* </AuthContext.Provider> */}
              <Route path={ROUTERS?.PAGE500} component={Page500} />
              <Route component={Page404} />
            </Switch>
          )}
        </div>
        {errorMessage && (
          <Modal
            id="error-modal"
            handleOK={() => setErrorMessage('')}
            type={ENUMS?.TYPE_MODAL?.MESERROR}
            messContent={errorMessage}
          />
        )}
      </div>
    </AuthContext.Provider>
  );
};

App.propTypes = {};

const mapStateToProps = createStructuredSelector({
  isPermissionLoading: isLoadingSelector([GET_USER_PERMISSION]),
  location: makeSelectLocation(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getUserPermissionRequest: () => dispatch(getUserPermissionRequest()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
)(App);
