/*
 * Select Messages
 *
 * This contains all the text for the Select component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.Select';

export default defineMessages({
  weddingGift: {
    id: `${scope}.wedding_gift`,
    defaultMessage: 'weddingGift!',
  },
  maternityGift: {
    id: `${scope}.maternity_gift`,
    defaultMessage: 'birthdayGift!',
  },
  familyCondolence: {
    id: `${scope}.family_condolence`,
    defaultMessage: 'familyCondolence!',
  },
  princessCondolence: {
    id: `${scope}.princess_condolence`,
    defaultMessage: 'princessCondolence!',
  },
  deathCompensation: {
    id: `${scope}.death_compensation`,
    defaultMessage: 'deathCompensation!',
  },
  severalDisabilityCompensation: {
    id: `${scope}.several_disability_compensation`,
    defaultMessage: 'severalDisabilityCompensation!',
  },
  sicknessPayment: {
    id: `${scope}.sickness_payment`,
    defaultMessage: 'sicknessPayment!',
  },
  admissionCompensation: {
    id: `${scope}.admission_compensation`,
    defaultMessage: 'admissionCompensation!',
  },
  hospitalCompensation: {
    id: `${scope}.hospital_compensation`,
    defaultMessage: 'hospitalCompensation!',
  },
  differenceBedCharge: {
    id: `${scope}.difference_bed_charge`,
    defaultMessage: 'differenceBedCharge!',
  },
  disasterDonation: {
    id: `${scope}.disaster_donation`,
    defaultMessage: 'disasterDonation!',
  },
  theftMoney: {
    id: `${scope}.theft_money`,
    defaultMessage: 'theftMoney!',
  },
  spouseMedicalExamSubsidy: {
    id: `${scope}.spouse_medical_exam_subsidy`,
    defaultMessage: 'spouseMedicalExamSubsidy!',
  },
  unsubFee: {
    id: `${scope}.unsub_fee`,
    defaultMessage: 'unsubFee!',
  },

  //MEN002
  currenAccount: {
    id: `${scope}.curren_account`,
    defaultMessage: 'currenAccount!',
  },

  usuallyAccount: {
    id: `${scope}.usually_account`,
    defaultMessage: 'usuallyAccount!',
  },

  //Men004
  men: {
    id: `${scope}.men`,
    defaultMessage: 'men!',
  },

  women: {
    id: `${scope}.women`,
    defaultMessage: 'women!',
  },

  married: {
    id: `${scope}.married`,
    defaultMessage: 'married!',
  },

  unMarried: {
    id: `${scope}.un_married`,
    defaultMessage: 'unmarried!',
  },

  //mse004
  allCompanyMse004: {
    id: `${scope}.all_company_mse004`,
    defaultMessage: 'all Company!',
  },
  nomalCompanyMse004: {
    id: `${scope}.nomal_company_mse004`,
    defaultMessage: 'nomal Company!',
  },
  ricoGMse004: {
    id: `${scope}.ri_co_G_mse004`,
    defaultMessage: 'ricoG!',
  },

  //mse003
  nomalCompany: {
    id: `${scope}.nomal_company`,
    defaultMessage: 'nomal Company!',
  },

  ricoG: {
    id: `${scope}.ri_co_G`,
    defaultMessage: 'ricoG!',
  },

  //men003
  type112: {
    id: `${scope}.type_112`,
    defaultMessage: 'type 112!',
  },

  type115: {
    id: `${scope}.type_115`,
    defaultMessage: 'type 115!',
  },

  type200: {
    id: `${scope}.type_200`,
    defaultMessage: 'type 200!',
  },

  waveApplication: {
    id: `${scope}.wave_application`,
    defaultMessage: 'Wave Application!',
  },

  paperApplication: {
    id: `${scope}.paper_application`,
    defaultMessage: 'Paper Application!',
  },

  allSilver: {
    id: `${scope}.all_silver`,
    defaultMessage: 'All Silver!',
  },

  rcms: {
    id: `${scope}.rcms`,
    defaultMessage: 'Rcms!',
  },

  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: 'Transfer!',
  },

  automaticWithdrawal: {
    id: `${scope}.automatic_withdrawal`,
    defaultMessage: 'Automatic Withdrawal!',
  },

  beforMonth: {
    id: `${scope}.befor_month`,
    defaultMessage: 'Befor Month!',
  },

  currentMonth: {
    id: `${scope}.current_month`,
    defaultMessage: 'Current Month!',
  },
  //

  monthCurrent: {
    id: `${scope}.month_current`,
    defaultMessage: 'month After!',
  },

  monthAfter: {
    id: `${scope}.month_after`,
    defaultMessage: 'month After!',
  },

  monthAfterAfter: {
    id: `${scope}.month_after_after`,
    defaultMessage: 'month After After!',
  },

  //month
  jan: {
    id: `${scope}.jan`,
    defaultMessage: 'jan!',
  },
  feb: {
    id: `${scope}.feb`,
    defaultMessage: 'feb!',
  },
  mar: {
    id: `${scope}.mar`,
    defaultMessage: 'mar!',
  },
  apr: {
    id: `${scope}.apr`,
    defaultMessage: 'apr!',
  },
  may: {
    id: `${scope}.may`,
    defaultMessage: 'may!',
  },
  jun: {
    id: `${scope}.jun`,
    defaultMessage: 'jun!',
  },
  july: {
    id: `${scope}.july`,
    defaultMessage: 'july!',
  },
  aug: {
    id: `${scope}.aug`,
    defaultMessage: 'aug!',
  },
  seb: {
    id: `${scope}.seb`,
    defaultMessage: 'seb!',
  },
  oct: {
    id: `${scope}.oct`,
    defaultMessage: 'oct!',
  },
  nov: {
    id: `${scope}.nov`,
    defaultMessage: 'nov!',
  },
  dec: {
    id: `${scope}.dec`,
    defaultMessage: 'dec!',
  },

  //date 31 day
  one: {
    id: `${scope}.one`,
    defaultMessage: 'one!',
  },
  two: {
    id: `${scope}.two`,
    defaultMessage: 'two!',
  },
  three: {
    id: `${scope}.three`,
    defaultMessage: 'three!',
  },
  four: {
    id: `${scope}.four`,
    defaultMessage: 'four!',
  },
  five: {
    id: `${scope}.five`,
    defaultMessage: 'five!',
  },
  six: {
    id: `${scope}.six`,
    defaultMessage: 'six!',
  },
  seven: {
    id: `${scope}.seven`,
    defaultMessage: 'seven!',
  },
  eight: {
    id: `${scope}.eight`,
    defaultMessage: 'eight!',
  },
  nine: {
    id: `${scope}.nine`,
    defaultMessage: 'nine!',
  },
  ten: {
    id: `${scope}.ten`,
    defaultMessage: 'ten!',
  },
  eleven: {
    id: `${scope}.eleven`,
    defaultMessage: 'eleven!',
  },
  twelve: {
    id: `${scope}.twelve`,
    defaultMessage: 'twelve!',
  },
  thirteen: {
    id: `${scope}.thir_teen`,
    defaultMessage: 'thirteen!',
  },
  fourteen: {
    id: `${scope}.four_teen`,
    defaultMessage: 'fourteen!',
  },
  fifteen: {
    id: `${scope}.fif_teen`,
    defaultMessage: 'fifteen!',
  },
  sixteen: {
    id: `${scope}.six_teen`,
    defaultMessage: 'sixteen!',
  },
  seventeen: {
    id: `${scope}.seven_teen`,
    defaultMessage: 'seventeen!',
  },
  eighteen: {
    id: `${scope}.eight_teen`,
    defaultMessage: 'eighteen!',
  },
  nineteen: {
    id: `${scope}.nine_teen`,
    defaultMessage: 'nineteen!',
  },
  twenty: {
    id: `${scope}.twenty`,
    defaultMessage: 'twenty!',
  },
  twentyOne: {
    id: `${scope}.twenty_one`,
    defaultMessage: 'twentyOne!',
  },
  twentyTwo: {
    id: `${scope}.twenty_two`,
    defaultMessage: 'twentyTwo!',
  },
  twentyThree: {
    id: `${scope}.twenty_three`,
    defaultMessage: 'twentyThree!',
  },
  twentyFour: {
    id: `${scope}.twenty_four`,
    defaultMessage: 'twentyFour!',
  },
  twentyFive: {
    id: `${scope}.twenty_five`,
    defaultMessage: 'twentyFive!',
  },
  twentySix: {
    id: `${scope}.twenty_six`,
    defaultMessage: 'twentySix!',
  },
  twentySeven: {
    id: `${scope}.twenty_seven`,
    defaultMessage: 'twentySeven!',
  },
  twentyEight: {
    id: `${scope}.twenty_eight`,
    defaultMessage: 'twentyEight!',
  },
  twentyNine: {
    id: `${scope}.twenty_nine`,
    defaultMessage: 'twentyNine!',
  },
  thirty: {
    id: `${scope}.thirty`,
    defaultMessage: 'thirty!',
  },
  thirtyOne: {
    id: `${scope}.thirty_one`,
    defaultMessage: 'thirtyOne!',
  },
  //

  //family relationship ben004
  husband: {
    id: `${scope}.husband`,
    defaultMessage: 'husband!',
  },
  wife: {
    id: `${scope}.wife`,
    defaultMessage: 'wife!',
  },
  child: {
    id: `${scope}.child`,
    defaultMessage: 'child!',
  },
  adoption: {
    id: `${scope}.adoption`,
    defaultMessage: 'adoption!',
  },
  realFather: {
    id: `${scope}.real_father`,
    defaultMessage: 'realFather!',
  },
  realMorther: {
    id: `${scope}.real_morther`,
    defaultMessage: 'realMorther!',
  },
  adoptiveFather: {
    id: `${scope}.adoptive_father`,
    defaultMessage: 'adoptive Father!',
  },
  fosterMother: {
    id: `${scope}.foster_mother`,
    defaultMessage: 'foster Mother!',
  },
  fatherInLaw: {
    id: `${scope}.father_In_law`,
    defaultMessage: 'father In Law!',
  },
  motherInLaw: {
    id: `${scope}.mother_in_law`,
    defaultMessage: 'mother In Law!',
  },
  brother: {
    id: `${scope}.brother`,
    defaultMessage: 'brother!',
  },
  sister: {
    id: `${scope}.sister`,
    defaultMessage: 'sister!',
  },
  youngerBrother: {
    id: `${scope}.younger_brother`,
    defaultMessage: 'younger Brother!',
  },
  youngerSister: {
    id: `${scope}.younger_sister`,
    defaultMessage: 'younger Sister!',
  },
  grandFather: {
    id: `${scope}.grand_father`,
    defaultMessage: 'grand Father!',
  },
  grandMother: {
    id: `${scope}.grand_mother`,
    defaultMessage: 'grand Mother!',
  },
  stillBirth: {
    id: `${scope}.still_birth`,
    defaultMessage: 'still Birth!',
  },
  //

  //type status disaster ben009
  burntDown: {
    id: `${scope}.burnt_down`,
    defaultMessage: 'burnt Down!',
  },
  completelyDestroyed: {
    id: `${scope}.completely_destroyed`,
    defaultMessage: 'completely Destroyed!',
  },
  allSpills: {
    id: `${scope}.all_spills`,
    defaultMessage: 'all Spills!',
  },
  halfBaked: {
    id: `${scope}.half_baked`,
    defaultMessage: 'half Baked!',
  },
  partialDestruction: {
    id: `${scope}.partial_destruction`,
    defaultMessage: 'partial Destruction!',
  },
  floodingOnTheFloor: {
    id: `${scope}.flooding_on_the_floor`,
    defaultMessage: 'flooding On The Floor!',
  },
  partiallyDamaged: {
    id: `${scope}.partially_damaged`,
    defaultMessage: 'partially Damaged!',
  },
  //

  //type Nominal Distinction ben009
  ownHouse: {
    id: `${scope}.own_house`,
    defaultMessage: 'own House!',
  },
  ownershipOfParentsAndSiblings: {
    id: `${scope}.ownership_of_parents_and_siblings`,
    defaultMessage: 'ownership Of Parents And Siblings!',
  },
  rentalCompanyHouse: {
    id: `${scope}.rental_company_house`,
    defaultMessage: 'rental Company House!',
  },
  singleDormitory: {
    id: `${scope}.single_dormitory`,
    defaultMessage: 'single Dormitory!',
  },
  none: {
    id: `${scope}.none`,
    defaultMessage: 'none!',
  },
  //

  //per003
  invoiceDateTen: {
    id: `${scope}.invoice_date_ten`,
    defaultMessage: 'invoice Date Ten!',
  },
  invoiceDateTwenty: {
    id: `${scope}.invoice_date_twenty`,
    defaultMessage: 'invoice Date Twenty!',
  },
  invoiceDateEnd: {
    id: `${scope}.invoice_date_end`,
    defaultMessage: 'invoice Date End!',
  },

  ricoPer3: {
    id: `${scope}.nomal_company_mse004`,
    defaultMessage: 'rico per3!',
  },
  generalClupPer3: {
    id: `${scope}.nomal_company`,
    defaultMessage: 'General Clup!',
  },

  waveApplicationPer3: {
    id: `${scope}.wave_application`,
    defaultMessage: 'wave ApplicationPer3!',
  },
  paperApplicationPer3: {
    id: `${scope}.paper_application`,
    defaultMessage: 'paper ApplicationPer3!',
  },

  export: {
    id: `${scope}.export_men007`,
    defaultMessage: 'export',
  },
  import: {
    id: `${scope}.import_men007`,
    defaultMessage: 'import',
  },

  //default value dropdown
  default: {
    id: `${scope}.default_dropdown`,
    defaultMessage: 'default dropdown!',
  },
});
