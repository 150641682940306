import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
import ConstReg from '../constantRegex';
import commonValidateMethodPer from './validateCommonMethod';
export const validateDateOfPaymentPer = Yup.string()
  .nullable()
  .checkDateOfPaymentPer();

export const validateYear = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['年度']));

// export const validateYearMonthPer03 = Yup.string()
//   .nullable()
//   .required(getMessValidate('C0000001', ['年月']))
//   .max(7, getMessValidate('C0000007', ['年月']))
//   .matches(
//     ConstReg.regTimeYearMonth,
//     getMessValidate('C0000004', ['年月']),
//   );

//   export const validateYearMonthPopupPer03 = Yup.string()
//   .nullable()
//   .max(7, getMessValidate('C0000007', ['年月']))
//   .matches(
//     ConstReg.regTimeYearMonth,
//     getMessValidate('C0000004', ['年月']),
//   );

//   export const validateInvoiceDatePer03 = Yup.string()
//   .nullable()
//   .test('is-empty-select', getMessValidate('C0000001', ['請求書発行日']), value => {
//     return !value || value === '0' ? false : true;
//   });
