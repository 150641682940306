/*
 *
 * Com002 reducer
 * Author: Truongtx
 */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CLEAR_USER,
} from './constants';
import ConstCommon from '../../config/constant';
import { arrayKeyBy, arrayFilter } from '../../utils/common/ArrayUtils';

const funcSubmit = values => {};

export const initialState = {
  isLoading: false,
  userData: {
    data: {
      user: JSON.parse(
        localStorage.getItem(ConstCommon.inforLocalStorage.user),
      ),
    },
  },
  messList: arrayKeyBy(
    JSON.parse(localStorage.getItem(ConstCommon.inforLocalStorage?.messages)),
    'message_id',
  ),
  benefitManagement: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.benefit_management),
  ),
  groupCompany: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.group_company),
  ),
  kyosai: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.kyosai),
  ),
  status: arrayKeyBy(
    arrayFilter(
      JSON.parse(
        localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
      ),
      'hanyo_bunrui_cd',
      '0102',
    ),
    'hanyo_kubun_cd',
  ),
  statusArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0102',
  ),
  injuryClassificationArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0023',
  ),
  docsExtract: arrayKeyBy(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.docs_extract),
    ),
    'kyufu_shubetsu_cd',
  ),
  benefitMaster: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.benefit_master),
  ),
  error: {},
  funcSubmit: funcSubmit,
};
/* eslint-disable default-case, no-param-reassign */
const com002Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_REQUEST:
        draft.isLoading = true;
        draft.error = {};
        break;

      case LOGIN_SUCCESS:
        draft.userData = action.user;
        draft.isLoading = false;
        draft.error = {};
        break;

      case LOGIN_ERROR:
        draft.error = action.error;
        draft.userData = {};
        draft.isLoading = false;
        break;

      case CLEAR_USER:
        draft.error = {};
        draft.userData = {};
        draft.isLoading = false;
        break;
    }
  });

export default com002Reducer;
