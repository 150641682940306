/*
 * CheckBox Messages
 *
 * This contains all the text for the CheckBox component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.CheckBox';

export default defineMessages({
  outpatientCompensation: {
    id: `${scope}.outpatient_compensation`,
    defaultMessage: 'outpatient Compensation',
  },
  disasterMoney: {
    id: `${scope}.disaster_money`,
    defaultMessage: 'disaster Money',
  },
  spousalMedical: {
    id: `${scope}.spousal_medical_exam_subsidy`,
    defaultMessage: 'Spousal medical examination subsidy',
  },
  maternityGift: {
    id: `${scope}.maternity_gift`,
    defaultMessage: 'maternity gift',
  },
  familyCondolenceMoney: {
    id: `${scope}.family_condolence_money`,
    defaultMessage: 'family condolence money',
  },
  severityDamageCompensation: {
    id: `${scope}.severity_damage_compensation`,
    defaultMessage: 'Severity damage compensation',
  },
  admissionCompensation: {
    id: `${scope}.admission_compensation`,
    defaultMessage: 'Admission compensation',
  },
  differentBedCharge: {
    id: `${scope}.different_bed_charge`,
    defaultMessage: 'Differential bed charge',
  },
  theftMoney: {
    id: `${scope}.theft_money`,
    defaultMessage: 'Theft money',
  },
  withdrawalFee: {
    id: `${scope}.withdrawal_fee`,
    defaultMessage: 'Withdrawal fee',
  },
  injurySeesDanceGold: {
    id: `${scope}.injury_sees_dance_gold`,
    defaultMessage: 'Injury sees dance gold',
  },
  deathCompensation: {
    id: `${scope}.death_compensation`,
    defaultMessage: 'Death compensation',
  },
  personalCondolenceMoney: {
    id: `${scope}.personal_condolence_money`,
    defaultMessage: 'personal Condolence Money',
  },
  weddingGift: {
    id: `${scope}.wedding_gift`,
    defaultMessage: 'Wedding gift',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'all',
  },
  invalid: {
    id: `${scope}.invalid`,
    defaultMessage: 'invalid',
  },
  isSentReport: {
    id: `${scope}.is_sent_report`,
    defaultMessage: 'is sent report',
  },
  //men003
  flagMen003: {
    id: `${scope}.flag_men003`,
    defaultMessage: 'invalid company id',
  },

  invalidCompanyGroup: {
    id: `${scope}.invalid_company_group`,
    defaultMessage: 'invalid company group',
  },
  invalid_company_group_men002: {
    id: `${scope}.invalid_company_group_men002`,
    defaultMessage: 'invalid_company_group_men002',
  },
  totaled_as_group_men002: {
    id: `${scope}.totaled_as_group_men002`,
    defaultMessage: 'totaled_as_group_men002',
  },
  invalidMse001: {
    id: `${scope}.invalidMse001`,
    defaultMessage: 'invalidMse001',
  },
  includingHistory: {
    id: `${scope}.includingHistory`,
    defaultMessage: 'includingHistory',
  },
  labelInvalidDisplayMse005: {
    id: `${scope}.labelInvalidDisplayMse005`,
    defaultMessage: 'labelInvalidDisplayMse005',
  },
  labelInvalidDisplayMse006: {
    id: `${scope}.labelInvalidDisplayMse006`,
    defaultMessage: 'labelInvalidDisplayMse006',
  },
});
