import {
  validateJugyoinNo,
  validateKaishaCd,
  validateKanjiName,
  validateNameOfKana,
} from './validateCommon';

export const validateSea = (obj, fieldValidate, screenCode) => {
  if (fieldValidate?.includes('kaisha_cd')) {
    obj.kaisha_cd = validateKaishaCd;
  }
  if (fieldValidate?.includes('jugyoin_no')) {
    obj.jugyoin_no = validateJugyoinNo;
  }
  if (fieldValidate?.includes('fullNameKana')) {
    obj.fullNameKana = validateNameOfKana;
  }
  if (fieldValidate?.includes('fullNameKanji')) {
    obj.fullNameKanji = validateKanjiName;
  }
  return obj;
};
