/**
 *
 * Input
 * Author: LanVt
 *
 */

import React, { useState, useEffect, useRef, Children } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import ENUMS from 'config/enum';
import {
  getClassInputFormatNumber,
  getClassInputByModeReadonly,
  getClassInputName,
} from './utils';
import './index.css';
function Input({
  className,
  type,
  name,
  values,
  valueInputReadOnly,
  handleChange,
  handleBlur,
  isReadonly,
  isDisable,
  autoFocus,
  mess,
  messWarning,
  maxLength,
  pattern,
  children,
  labelText,
  isSubmitting,
  touched,
  format,
  isShowFormat,
  nameParent,
  modeReadonly,
}) {
  const EMPTY = '';
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
      setIsFocus(false);
      setTimeout(() => {
        setIsFocus(false);
      }, 0);
    }
  }, [autoFocus]);
  let clssNameInput = getClassInputByModeReadonly(
    isReadonly,
    modeReadonly,
    className,
  );
  const id = 'tooltip-id' + name;
  const idInput = Math.floor(Math.random() * 10000 + 1) + 'input' + name;
  clssNameInput = getClassInputName(
    clssNameInput,
    mess,
    messWarning,
    touched?.[name],
    isSubmitting,
    isFocus,
  );
  const leftPosition = inputRef?.current?.offsetLeft + 'px';
  const width = inputRef?.current?.clientWidth + 'px';
  const height = inputRef?.current?.clientHeight + 'px';
  clssNameInput = getClassInputFormatNumber(
    clssNameInput,
    format,
    isShowFormat,
  );
  const objLabelStyles = isReadonly
    ? {
        left: leftPosition,
        position: 'absolute',
        color: '#000',
        width,
        height,
        zIndex: 1,
      }
    : {
        left: leftPosition,
        position: 'absolute',
        // color: '#000',
        width,
        height,
        zIndex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      };
  let value = !isEmptyAndBlank(values?.[name])
    ? values?.[name]
    : valueInputReadOnly || values?.[name];
  const nameUpdate = nameParent ? `${nameParent}.${name}` : name;
  const optionInput = isReadonly
    ? {
        tabIndex: '-1',
      }
    : {};
  return (
    <>
      <input
        // className={mess ? `${clssNameInput} input-error` : clssNameInput}
        className={clssNameInput}
        data-tooltip="There!"
        type={type}
        maxLength={maxLength}
        pattern={pattern}
        name={nameUpdate}
        value={value}
        onChange={e => {
          try {
            handleChange(e);
            setIsFocus(true);
          } catch (e) {
            console.log(nameUpdate, '---nameUpdate---');
          }
        }}
        onBlur={e => {
          if (
            !isReadonly ||
            modeReadonly === ENUMS?.MODE_INPUT_READONLY?.SHOWTOOLTIP
          ) {
            setIsFocus(false);
            // setClassTooltip('tooltip');
            handleBlur(e);
          }
        }}
        onFocus={e => {
          if (
            !isReadonly ||
            modeReadonly === ENUMS?.MODE_INPUT_READONLY?.SHOWTOOLTIP
          ) {
            setIsFocus(true);
          }
          // if (mess) {
          //   setClassTooltip('tooltip tooltip-focus');
          // }
        }}
        readOnly={isReadonly}
        {...optionInput}
        disabled={isDisable}
        autoFocus={autoFocus}
        ref={inputRef}
        aria-describedby={id}
        id={idInput}
        autoComplete={format ? 'off' : ''}
      />
      {type === 'file' && (
        <label
          htmlFor={idInput}
          className="lbl-btn-upload-file"
          disabled={isDisable}
        >
          <span>{labelText}</span>
        </label>
      )}
      {((isReadonly && isShowFormat) || (!isFocus && format)) && (
        <label
          htmlFor={idInput}
          className="show-format-num"
          style={objLabelStyles}
          disabled={isDisable}
        >
          {!isNaN(value) ? (
            <NumberFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              disabled={isDisable}
              className={isDisable ? 'span-disabled' : ' '}
            />
          ) : (
            <span
              disabled={isDisable}
              className={isDisable ? 'span-disabled' : ' '}
            >
              {' '}
              {value}{' '}
            </span>
          )}
        </label>
      )}
      {mess && isFocus && (
        <div
          id={id}
          className={'tooltip tooltip-focus'}
          role="tooltip"
          aria-hidden="true"
          style={{ left: leftPosition, fontWeight: 'normal' }}
        >
          {mess}
        </div>
      )}
      {Children.toArray(children)}
    </>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  mess: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  nameParent: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  values: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  valueInputReadOnly: PropTypes.string,
  maxLength: PropTypes.number,
  modeReadonly: PropTypes.number,
  pattern: PropTypes.string,
  labelText: PropTypes.string,
};
Input.defaultProps = {
  type: 'text',
  valueInputReadOnly: null,
  className: 'input_50 input_required tooltip--left',
  mess: '',
  labelText: '参照',
  modeReadonly: ENUMS?.MODE_INPUT_READONLY?.NONE,
  // handleChange: () => {},
};
export default Input;
