import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { initialState as stateApp } from 'containers/App/reducer';

/**
 * Direct selector to the com002 state domain
 */

const selectCom002Domain = state => state.com002 || initialState;

const funcSubmitCom002 = () => {
  return initialState?.funcSubmit;
};

const selectUserInfo = state => {
  return state?.com002?.userData?.data || initialState?.userData?.data;
};

const getMessList = state => {
  return state?.com002?.messList || initialState?.messList;
};

const gender = state => stateApp.typeGender || [];

/**
 * Other specific selectors
 */

/**
 * Default selector used by Com002
 */
const makeSelectUserInfo = () =>
  createSelector(
    selectUserInfo,
    substate => substate,
  );
const makeGetMessList = () =>
  createSelector(
    getMessList,
    substate => substate,
  );
const makeSelectCom002 = () =>
  createSelector(
    selectCom002Domain,
    substate => substate,
  );

  export const makeSelectTypeGender = () =>
  createSelector(
    gender,
    substate => substate,
  );

export default makeSelectCom002;
export {
  selectCom002Domain,
  funcSubmitCom002,
  makeSelectUserInfo,
  makeGetMessList,
};
