/**
 *
 * Input
 * Author: LanVt
 *
 */

import React, { useState, useEffect, useRef, Children } from 'react';
import PropTypes from 'prop-types';
import './index.css';

function Textarea({
  className,
  type,
  name,
  values,
  valueInputReadOnly,
  handleChange,
  handleBlur,
  isReadonly,
  autoFocus,
  mess,
  maxLength,
  pattern,
  children,
  labelText,
  isSubmitting,
  touched,
  isDisable,
  ischeckArea,
}) {
  const EMPTY = '';
  const inputRef = useRef(null);
  const [classTooltip, setClassTooltip] = useState('tooltip');
  const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (mess && (touched?.[name] || isSubmitting)) {
      setClassTooltip('tooltip tooltip-focus');
    } else {
      setClassTooltip('tooltip');
    }
  }, [mess, touched?.[name], isSubmitting]);

  let clssNameInput = isReadonly
    ? (ischeckArea ? `${className} rd  tooltip--left` : `rd  tooltip--left`)
    : `${className} tooltip--left`;

  const id = 'tooltip-id-textarea-' + name;
  const idInput = Math.floor(Math.random() * 10000 + 1) + 'input' + name;
  clssNameInput =
    mess && (touched?.[name] || isSubmitting)
      ? `${clssNameInput} input-error`
      : clssNameInput;

  const leftPosition = inputRef?.current?.offsetLeft + 'px';
  return (
    <>
      <textarea
        className={clssNameInput}
        data-tooltip="There!"
        type={type}
        maxLength={maxLength}
        pattern={pattern}
        name={name}
        value={values?.[name] || valueInputReadOnly}
        onChange={e => {
          handleChange(e);
        }}
        onBlur={e => {
          setIsFocus(false);
          setClassTooltip('tooltip');
          handleBlur(e);
        }}
        onFocus={() => {
          setIsFocus(true);
          // if (mess) {
          //   setClassTooltip('tooltip tooltip-focus');
          // }
        }}
        readOnly={isReadonly}
        autoFocus={autoFocus}
        disabled={isDisable}
        ref={inputRef}
        aria-describedby={id}
        id={idInput}
      />
      {mess && isFocus && (
        <div
          id={id}
          className={'tooltip tooltip-focus'}
          role="tooltip"
          aria-hidden="true"
          style={{ left: leftPosition, fontWeight: 'normal' }}
        >
          {mess}
        </div>
      )}
      {Children.toArray(children)}
    </>
  );
}

Textarea.propTypes = {
  className: PropTypes.string,
  mess: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  values: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  valueInputReadOnly: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  labelText: PropTypes.string,
  ischeckArea: PropTypes.bool,
};
Textarea.defaultProps = {
  type: 'text',
  valueInputReadOnly: '',
  className: 'tooltip--left',
  mess: '',
  labelText: '参照',
  ischeckArea: false,
};
export default Textarea;
