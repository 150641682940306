/*
 * Table Messages
 *
 * This contains all the text for the Table component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.Table';

export default defineMessages({
  code: {
    id: `${scope}.code`,
    defaultMessage: 'code',
  },
  companyGroup: {
    id: `${scope}.company_group`,
    defaultMessage: 'company group',
  },
  companyName: {
    id: `${scope}.company_name`,
    defaultMessage: 'company name',
  },
  withdrawalDate: {
    id: `${scope}.withdrawal_date`,
    defaultMessage: 'withdrawal date',
  },
  companyAbbreviation: {
    id: `${scope}.company_abbreviation`,
    defaultMessage: 'company abbreviation',
  },
  benefitType: {
    id: `${scope}.benefit_type`,
    defaultMessage: 'benefit type',
  },
  benefitNo: {
    id: `${scope}.benefit_no`,
    defaultMessage: 'benefit no',
  },
  employeeNo: {
    id: `${scope}.employee_no`,
    defaultMessage: 'employee no',
  },
  employeeName: {
    id: `${scope}.employee_name`,
    defaultMessage: 'employee name',
  },
  applicationDate: {
    id: `${scope}.application_date`,
    defaultMessage: 'application date',
  },
  eventDate: {
    id: `${scope}.event_date`,
    defaultMessage: 'event date',
  },
  relationship: {
    id: `${scope}.relationship`,
    defaultMessage: 'relationship',
  },
  targetPersonName: {
    id: `${scope}.target_person_name`,
    defaultMessage: 'target person name',
  },
  day: {
    id: `${scope}.day`,
    defaultMessage: 'day',
  },
  benefitAmount: {
    id: `${scope}.benefit_amount`,
    defaultMessage: 'benefit amount',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'status',
  },
  dateOfPayment: {
    id: `${scope}.date_of_payment`,
    defaultMessage: 'date of payment',
  },
  //mse002
  grCompanyCd: {
    id: `${scope}.gr_company_cd_mse002`,
    defaultMessage: 'gr Company Cd mse002',
  },
  inValidMse002: {
    id: `${scope}.is_disable_mse002`,
    defaultMessage: 'inValid Mse002',
  },
  grCompanyNm: {
    id: `${scope}.gr_company_nm_mse002`,
    defaultMessage: 'gr Company Nm mse002',
  },
  documentSrcNm: {
    id: `${scope}.document_src_nm_mse002`,
    defaultMessage: 'document SrcNm mse002',
  },
  postalCdMse002: {
    id: `${scope}.postal_cd_mse002`,
    defaultMessage: 'postalCd mse002',
  },
  addrMse002: {
    id: `${scope}.addr_mse002`,
    defaultMessage: 'addr Mse002',
  },
  phoneNoMse002: {
    id: `${scope}.phone_no_mse002`,
    defaultMessage: 'phoneNo Mse002',
  },
  faxNoMse002: {
    id: `${scope}.fax_no_mse002`,
    defaultMessage: 'faxNo Mse002',
  },
  representatorPosMse002: {
    id: `${scope}.representator_pos_mse002`,
    defaultMessage: 'representator Pos Mse002',
  },
  representatorNmMse002: {
    id: `${scope}.representator_nm_mse002`,
    defaultMessage: 'representator Nm Mse002',
  },
  jimukyokuchoKatagakiMse002: {
    id: `${scope}.jimukyokucho-katagaki_mse002`,
    defaultMessage: 'jimukyokuchoKatagaki Mse002',
  },
  jimukyokuchoFullnameMse002: {
    id: `${scope}.jimukyokucho-fullname_mse002`,
    defaultMessage: 'jimukyokuchoFullname Mse002',
    },
  executivePosMse002: {
    id: `${scope}.executive_pos_mse002`,
    defaultMessage: 'executive Pos Mse002',
  },
  executiveNmMse002: {
    id: `${scope}.executive_nm_mse002`,
    defaultMessage: 'executive Nm Mse002',
  },
  picInvoiceNmMse002: {
    id: `${scope}.pic_invoice_nm_mse002`,
    defaultMessage: 'picInvoice Nm Mse002',
  },
  picNmMse002: {
    id: `${scope}.pic_nm_mse002`,
    defaultMessage: 'pic Nm Mse002',
  },
  picPhoneNoMse002: {
    id: `${scope}.pic_phone_no_mse002`,
    defaultMessage: 'pic PhoneNo Mse002',
  },
  picFaxNoMse002: {
    id: `${scope}.pic_fax_no_mse002`,
    defaultMessage: 'pic FaxNo Mse002',
  },
  picEmailMse002: {
    id: `${scope}.pic_email_mse002`,
    defaultMessage: 'pic Email Mse002',
  },
  bankNmMse002: {
    id: `${scope}.bank_nm_mse002`,
    defaultMessage: 'bankNm Mse002',
  },
  bankCdMse002: {
    id: `${scope}.bank_cd_mse002`,
    defaultMessage: 'bankCd Mse002',
  },
  subBankNmMse002: {
    id: `${scope}.sub_bank_nm_mse002`,
    defaultMessage: 'subBank Nm Mse002',
  },
  subBankCdMse002: {
    id: `${scope}.sub_bank_cd_mse002`,
    defaultMessage: 'subBank Cd Mse002',
  },
  accountTypeMse002: {
    id: `${scope}.account_type_mse002`,
    defaultMessage: 'account Type Mse002',
  },
  accountNoMse002: {
    id: `${scope}.account_no_mse002`,
    defaultMessage: 'accountNo Mse002',
  },
  accountNmMse002: {
    id: `${scope}.account_nm_mse002`,
    defaultMessage: 'accountNm Mse002',
  },
  authorCdMse002: {
    id: `${scope}.author_cd_mse002`,
    defaultMessage: 'authorCd Mse002',
  },
  isKyufusummaryRgMse002: {
    id: `${scope}.is_kyufusummary_rg_mse002`,
    defaultMessage: 'is Kyufusummary Rg Mse002',
  },
  noteMse002: {
    id: `${scope}.note_mse002`,
    defaultMessage: 'note Mse002',
  },
  sealPathFileNmMse002: {
    id: `${scope}.seal_path_file_nm_mse002`,
    defaultMessage: 'sealPath File Nm Mse002',
  },
  societyLogoPathFileMse002: {
    id: `${scope}.society_logo_path_file_mse002`,
    defaultMessage: 'society Logo Path File Mse002',
  },
  createDtMse002: {
    id: `${scope}.create_dt_mse002`,
    defaultMessage: 'createDt Mse002',
  },
  createByIdMse002: {
    id: `${scope}.create_by_id_mse002`,
    defaultMessage: 'createById Mse002',
  },
  createByMse002: {
    id: `${scope}.create_by_mse002`,
    defaultMessage: 'createBy Mse002',
  },
  updateDtMse002: {
    id: `${scope}.update_dt_mse002`,
    defaultMessage: 'updateDt Mse002',
  },
  updateByIdMse002: {
    id: `${scope}.update_by_id_mse002`,
    defaultMessage: 'updateById Mse002',
  },
  updateByMse002: {
    id: `${scope}.update_by_mse002`,
    defaultMessage: 'updateBy Mse002',
  },

  //mse003
  companyCode: {
    id: `${scope}.company_code`,
    defaultMessage: 'company code',
  },
  inValidMse003: {
    id: `${scope}.in_valid_mse003`,
    defaultMessage: 'in valid mse003',
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: 'sort',
  },
  companyNameMse003: {
    id: `${scope}.company_name_mse003`,
    defaultMessage: 'company name mse003',
  },
  companyNameKana: {
    id: `${scope}.company_name_kana`,
    defaultMessage: 'company name kana',
  },
  shortCompanyName: {
    id: `${scope}.short_company_name`,
    defaultMessage: 'short company name',
  },
  postalCd: {
    id: `${scope}.postal_cd`,
    defaultMessage: 'postal cd',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'address',
  },
  tel: {
    id: `${scope}.tel`,
    defaultMessage: 'tel',
  },
  faxNo: {
    id: `${scope}.fax_no`,
    defaultMessage: 'fax no',
  },
  representativePosition: {
    id: `${scope}.representative_position`,
    defaultMessage: 'representative position',
  },
  representativeName: {
    id: `${scope}.representative_name`,
    defaultMessage: 'representative name',
  },
  billContactName: {
    id: `${scope}.bill_contact_name`,
    defaultMessage: 'bill contact name',
  },
  contactName: {
    id: `${scope}.contact_name`,
    defaultMessage: 'contact name',
  },
  contactTel: {
    id: `${scope}.contact_tel`,
    defaultMessage: 'contact tel',
  },
  contactFax: {
    id: `${scope}.contact_fax`,
    defaultMessage: 'contact fax',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'email',
  },
  bankName: {
    id: `${scope}.bank_name`,
    defaultMessage: 'bank name',
  },
  bankCd: {
    id: `${scope}.bank_cd`,
    defaultMessage: 'bank cd',
  },
  bankBranchName: {
    id: `${scope}.bank_branch_name`,
    defaultMessage: 'bank branch name',
  },
  bankBranchCd: {
    id: `${scope}.bank_branch_cd`,
    defaultMessage: 'bank branch cd',
  },
  accountType: {
    id: `${scope}.account_type`,
    defaultMessage: 'account type',
  },
  accountNo: {
    id: `${scope}.account_no`,
    defaultMessage: 'account no',
  },
  accountHolderName: {
    id: `${scope}.account_holder_name`,
    defaultMessage: 'account holder name',
  },
  accountHolderNameKana: {
    id: `${scope}.account_holder_name_kana`,
    defaultMessage: 'account holder name kana',
  },
  joinDt: {
    id: `${scope}.join_dt`,
    defaultMessage: 'join dt',
  },
  leavingDt: {
    id: `${scope}.leaving_dt`,
    defaultMessage: 'leaving dt',
  },
  companyGroupMse003: {
    id: `${scope}.company_group_mse_003`,
    defaultMessage: 'company group mse 003',
  },
  kyosaiType: {
    id: `${scope}.kyosai_type`,
    defaultMessage: 'kyosai type',
  },
  requestClassify: {
    id: `${scope}.request_classify`,
    defaultMessage: 'request classify',
  },
  paymentMethod: {
    id: `${scope}.payment_method`,
    defaultMessage: 'payment method',
  },
  paymentCdt: {
    id: `${scope}.payment_cdt`,
    defaultMessage: 'payment cdt',
  },
  paymentWeb: {
    id: `${scope}.payment_web`,
    defaultMessage: 'payment web',
  },
  recoveryMethod: {
    id: `${scope}.recovery_method`,
    defaultMessage: 'recovery method',
  },
  seikyutaishokikan: {
    id: `${scope}.seikyu_taisho_kikan`,
    defaultMessage: 'seikyutaishokikan',
  },

  depositWeb: {
    id: `${scope}.deposit_web`,
    defaultMessage: 'deposit web',
  },
  paymentCdtDt: {
    id: `${scope}.payment_cdt_dt`,
    defaultMessage: 'payment Cd tDt',
  },
  recoveryCdtDepositDt: {
    id: `${scope}.recovery_cdt_deposit_dt`,
    defaultMessage: 'recovery Cdt Deposit Dt',
  },
  kakekinsanshutsukubun: {
    id: `${scope}.kakekin_sanshutsu_kubun`,
    defaultMessage: 'kakekin sanshutsu kubun',
  },
  seikyushohakkobi: {
    id: `${scope}.seikyusho_hakkobi`,
    defaultMessage: 'seikyusho hakkobi',
  },

  expensePaymentCdt: {
    id: `${scope}.expense_payment_cdt`,
    defaultMessage: 'expense payment cdt',
  },
  expensePaymentWeb: {
    id: `${scope}.expense_payment_web`,
    defaultMessage: 'expense Payment Web',
  },
  expensePaymentCdtPaymentDt: {
    id: `${scope}.expense_payment_cdt_payment_dt`,
    defaultMessage: 'expense Payment Cdt Payment Dt',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'note mse003',
  },
  createDtTime: {
    id: `${scope}.create_dt_time`,
    defaultMessage: 'create Dt Time',
  },
  createUserId: {
    id: `${scope}.create_user_id`,
    defaultMessage: 'create User Id',
  },
  createUserName: {
    id: `${scope}.create_user_name`,
    defaultMessage: 'create User Name',
  },
  updateDtTime: {
    id: `${scope}.update_dt_time`,
    defaultMessage: 'update Dt Time',
  },
  updateUserId: {
    id: `${scope}.update_user_id`,
    defaultMessage: 'update User Id',
  },
  updateUserName: {
    id: `${scope}.update_user_name`,
    defaultMessage: 'update User Name',
  },

  employeeNumber: {
    id: `${scope}.employee_number`,
    defaultMessage: 'employee number',
  },
  fullName: {
    id: `${scope}.full_name`,
    defaultMessage: 'full name',
  },
  birthday: {
    id: `${scope}.birthday`,
    defaultMessage: 'birthday',
  },
  withdrawalDate2: {
    id: `${scope}.withdrawal_date_2`,
    defaultMessage: 'withdrawal date 2',
  },
  //mse001
  kyosai_type_cd: {
    id: `${scope}.kyosai_type_cd`,
    defaultMessage: 'kyosai_type_cd',
  },
  kyosai_type_name: {
    id: `${scope}.kyosai_type_name`,
    defaultMessage: 'kyosai_type_name',
  },
  kyosai_kakekin: {
    id: `${scope}.kyosai_kakekin`,
    defaultMessage: 'kyosai_kakekin',
  },
  kanyu_hikiuke_nenrei: {
    id: `${scope}.kanyu_hikiuke_nenrei`,
    defaultMessage: 'kanyu_hikiuke_nenrei',
  },
  biko: {
    id: `${scope}.biko`,
    defaultMessage: 'biko',
  },
  is_disable: {
    id: `${scope}.is_disable`,
    defaultMessage: 'is_disable',
  },
  shinki_sakusei_ts: {
    id: `${scope}.shinki_sakusei_ts`,
    defaultMessage: 'shinki_sakusei_ts',
  },
  shinki_sakusei_user_id: {
    id: `${scope}.shinki_sakusei_user_id`,
    defaultMessage: 'shinki_sakusei_user_id',
  },
  saishu_koshin_ts: {
    id: `${scope}.saishu_koshin_ts`,
    defaultMessage: 'saishu_koshin_ts',
  },
  saishu_koshin_user_id: {
    id: `${scope}.saishu_koshin_user_id`,
    defaultMessage: 'saishu_koshin_user_id',
  },
  shinki_sakusei_user_name: {
    id: `${scope}.shinki_sakusei_user_name`,
    defaultMessage: 'shinki_sakusei_user_name',
  },
  saishu_koshin_user_name: {
    id: `${scope}.saishu_koshin_user_name`,
    defaultMessage: 'saishu_koshin_user_name',
  },
  //mse005
  user_id_mse005: {
    id: `${scope}.user_id_mse005`,
    defaultMessage: 'user_id_mse005',
  },
  validValue_mse005: {
    id: `${scope}.validValue_mse005`,
    defaultMessage: 'validValue_mse005',
  },
  email_address_mse005: {
    id: `${scope}.email_address_mse005`,
    defaultMessage: 'email_address_mse005',
  },
  fullname_mse005: {
    id: `${scope}.fullname_mse005`,
    defaultMessage: 'fullname_mse005',
  },
  gyomu_kengen_cd_mse005: {
    id: `${scope}.gyomu_kengen_cd_mse005`,
    defaultMessage: 'gyomu_kengen_cd_mse005',
  },
  system_kengen_cd_mse005: {
    id: `${scope}.system_kengen_cd_mse005`,
    defaultMessage: 'system_kengen_cd_mse005',
  },
  shoin_path_file_name_mse005: {
    id: `${scope}.shoin_path_file_name_mse005`,
    defaultMessage: 'shoin_path_file_name_mse005',
  },
  shinki_sakusei_ts_mse005: {
    id: `${scope}.shinki_sakusei_ts_mse005`,
    defaultMessage: 'shinki_sakusei_ts_mse005',
  },
  shinki_sakusei_user_id_mse005: {
    id: `${scope}.shinki_sakusei_user_id_mse005`,
    defaultMessage: 'shinki_sakusei_user_id_mse005',
  },
  shinki_sakusei_user_nm_mse005: {
    id: `${scope}.shinki_sakusei_user_nm_mse005`,
    defaultMessage: 'shinki_sakusei_user_nm_mse005',
  },
  saishu_koshin_ts_mse005: {
    id: `${scope}.saishu_koshin_ts_mse005`,
    defaultMessage: 'saishu_koshin_ts_mse005',
  },
  saishu_koshin_user_id_mse005: {
    id: `${scope}.saishu_koshin_user_id_mse005`,
    defaultMessage: 'saishu_koshin_user_id_mse005',
  },
  saishu_koshin_user_nm_mse005: {
    id: `${scope}.saishu_koshin_user_nm_mse005`,
    defaultMessage: 'saishu_koshin_user_nm_mse005',
  },
  //mse004
  jugyoinNo: {
    id: `${scope}.jugyoin_no`,
    defaultMessage: 'jugyoin no mse004',
  },
  // validStatus: {
  //   id: `${scope}.valid_status`,
  //   defaultMessage: 'valid Status',
  // },
  isDisableMse004: {
    id: `${scope}.is_disable_table_mse004`,
    defaultMessage: 'is Disable',
  },
  kaishaCd: {
    id: `${scope}.kaisha_cd`,
    defaultMessage: 'kaishaCd',
  },
  kaishaNm: {
    id: `${scope}.kaisha_nm`,
    defaultMessage: 'kaishaNm',
  },
  fullnameKanji: {
    id: `${scope}.fullname_kanji`,
    defaultMessage: 'fullname Kanji',
  },
  fullnameKana: {
    id: `${scope}.fullname_kana`,
    defaultMessage: 'fullname Kana',
  },
  kyusei: {
    id: `${scope}.kyusei`,
    defaultMessage: 'kyusei',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'gender',
  },

  birthDate: {
    id: `${scope}.birth_date`,
    defaultMessage: 'birth Date',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'age',
  },
  nyukaiYmd: {
    id: `${scope}.nyukai_ymd`,
    defaultMessage: 'nyukai Ymd',
  },
  nyushaYmd: {
    id: `${scope}.nyusha_ymd`,
    defaultMessage: 'nyusha Ymd',
  },
  teinenSaikoyoYmd: {
    id: `${scope}.teinen_saikoyo_ymd`,
    defaultMessage: 'teinen SaikoyoYmd',
  },
  taikaiYmd: {
    id: `${scope}.taikai_ymd`,
    defaultMessage: 'taikai Ymd',
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: 'period',
  },
  keiyakuTypeName: {
    id: `${scope}.keiyaku_type_name`,
    defaultMessage: 'keiyaku Type Name',
  },
  isHandOver: {
    id: `${scope}.is_hand_over`,
    defaultMessage: 'is HandOver',
  },
  bikoKanyushaMaster: {
    id: `${scope}.biko_kanyusha_master`,
    defaultMessage: 'biko Kanyusha Master',
  },
  shoruiHokambasho: {
    id: `${scope}.shorui_hokambasho`,
    defaultMessage: 'shorui Hokambasho',
  },
  createTime: {
    id: `${scope}.create_time`,
    defaultMessage: 'create Time',
  },
  updateTime: {
    id: `${scope}.update_time`,
    defaultMessage: 'update Time',
  },
  userCreateId: {
    id: `${scope}.user_create_id`,
    defaultMessage: 'user CreateId',
  },
  userUpdateId: {
    id: `${scope}.user_update_id`,
    defaultMessage: 'user UpdateId',
  },
  updater: {
    id: `${scope}.updater`,
    defaultMessage: 'updater',
  },
  creater: {
    id: `${scope}.creater`,
    defaultMessage: 'creater',
  },
  seibetsuCd: {
    id: `${scope}.seibetsu_cd`,
    defaultMessage: 'seibetsu Cd',
  },
  koninJokyoCd: {
    id: `${scope}.konin_jokyo_cd`,
    defaultMessage: 'konin Jokyo Cd',
  },
  userNameCreate: {
    id: `${scope}.user_name_create`,
    defaultMessage: 'user Name Create',
  },
  userNameUpdate: {
    id: `${scope}.user_name_update`,
    defaultMessage: 'user Name Update',
  },
  kanyuHikiukeNenreiMse004: {
    id: `${scope}.kanyu_hikiuke_nenrei_mse004`,
    defaultMessage: 'kanyu Hikiuke Nenrei',
  },
  maritalStatus: {
    id: `${scope}.marital_status`,
    defaultMessage: 'marital Status',
  },
  withdrawalFee: {
    id: `${scope}.withdrawal_fee`,
    defaultMessage: 'withdrawal Fee',
  },
  withdrawalFeeRetired: {
    id: `${scope}.withdrawal_fee_retired`,
    defaultMessage: 'withdrawal Fee Retired',
  },
  kyufu_shubetsu_name_mse006: {
    id: `${scope}.kyufu_shubetsu_name_mse006`,
    defaultMessage: 'kyufu_shubetsu_name_mse006',
  },
  shosaikubun_cd_mse006: {
    id: `${scope}.shosaikubun_cd_mse006`,
    defaultMessage: 'shosaikubun_cd_mse006',
  },
  validValue_mse006: {
    id: `${scope}.validValue_mse006`,
    defaultMessage: 'validValue_mse006',
  },
  shorui_name_mse006: {
    id: `${scope}.shorui_name_mse006`,
    defaultMessage: 'shorui_name_mse006',
  },
  biko_mse006: {
    id: `${scope}.biko_mse006`,
    defaultMessage: 'biko_mse006',
  },
  shinki_sakusei_ts_mse006: {
    id: `${scope}.shinki_sakusei_ts_mse006`,
    defaultMessage: 'shinki_sakusei_ts_mse006',
  },
  shinki_sakusei_user_id_mse006: {
    id: `${scope}.shinki_sakusei_user_id_mse006`,
    defaultMessage: 'shinki_sakusei_user_id_mse006',
  },
  shinki_sakusei_user_nm_mse006: {
    id: `${scope}.shinki_sakusei_user_nm_mse006`,
    defaultMessage: 'shinki_sakusei_user_nm_mse006',
  },
  saishu_koshin_ts_mse006: {
    id: `${scope}.saishu_koshin_ts_mse006`,
    defaultMessage: 'saishu_koshin_ts_mse006',
  },
  saishu_koshin_user_id_mse006: {
    id: `${scope}.saishu_koshin_user_id_mse006`,
    defaultMessage: 'saishu_koshin_user_id_mse006',
  },
  saishu_koshin_user_nm_mse006: {
    id: `${scope}.saishu_koshin_user_nm_mse006`,
    defaultMessage: 'saishu_koshin_user_nm_mse006',
  },
  //apa
  paymentDate: {
    id: `${scope}.payment_date`,
    defaultMessage: 'Payment date',
  },
  subsidyType: {
    id: `${scope}.subsidy_type`,
    defaultMessage: 'Payment date',
  },
  subsidyNumber: {
    id: `${scope}.subsidy_number`,
    defaultMessage: 'Payment date',
  },
  // 2020.06.02 append by ALEX_Kikawa CT122 Start
  kaishaGroupName: {
    id: `${scope}.company_group`,
    defaultMessage: 'group company name',
  },
  // 2020.06.02 append by ALEX_Kikawa CT122 End
  //apa003
  apa003_total_benefit: {
    id: `${scope}.apa003_total_benefit`,
    defaultMessage: 'apa003_total_benefit',
  },
  apa003_company_name: {
    id: `${scope}.apa003_company_name`,
    defaultMessage: 'apa003_company_name',
  },
  apa003_company_code: {
    id: `${scope}.apa003_company_code`,
    defaultMessage: 'apa003_company_code',
  },
  apa003_amount: {
    id: `${scope}.apa003_amount`,
    defaultMessage: 'apa003_amount',
  },
  rcms: {
    id: `${scope}.rcms`,
    defaultMessage: 'Payment date',
  },
  createDateRCMS: {
    id: `${scope}.createDateRCMS`,
    defaultMessage: 'Payment date',
  },
  createDateBankNational: {
    id: `${scope}.createDateBankNational`,
    defaultMessage: 'Payment date',
  },
  bankNational: {
    id: `${scope}.bankNational`,
    defaultMessage: 'Payment date',
  },

  //sap001

  sap_line: {
    id: `${scope}.sap_line`,
    defaultMessage: 'Line',
  },

  sap_employee_no: {
    id: `${scope}.sap_employee_no`,
    defaultMessage: 'Employee No',
  },

  sap_company_cd: {
    id: `${scope}.sap_company_cd`,
    defaultMessage: 'Company cd',
  },

  sap_company_code_TEXT: {
    id: `${scope}.sap_company_code_TEXT`,
    defaultMessage: 'Company Code TEXT',
  },

  sap_full_name: {
    id: `${scope}.sap_full_name`,
    defaultMessage: 'Full Name',
  },

  sap_last_name_katakana: {
    id: `${scope}.sap_last_name_katakana`,
    defaultMessage: 'Last Name Katakana',
  },

  sap_first_name_katakana: {
    id: `${scope}.sap_first_name_katakana`,
    defaultMessage: 'First Name Katakana',
  },

  sap_gender_TEXT: {
    id: `${scope}.sap_gender_TEXT`,
    defaultMessage: 'Gender Text',
  },

  sap_birthday: {
    id: `${scope}.sap_birthday`,
    defaultMessage: 'Birthday',
  },

  sap_marital_status_TEXT: {
    id: `${scope}.sap_marital_status_TEXT`,
    defaultMessage: 'Marital Status TEXT',
  },

  sap_contract_type_TEXT: {
    id: `${scope}.sap_contract_type_TEXT`,
    defaultMessage: 'Contract Type TEXT',
  },

  sap_start_date: {
    id: `${scope}.sap_start_date`,
    defaultMessage: 'Start date',
  },

  sap_action_TEXT: {
    id: `${scope}.sap_action_TEXT`,
    defaultMessage: 'Action TEXT',
  },

  sap_start_date_2: {
    id: `${scope}.sap_start_date_2`,
    defaultMessage: 'Start date 2',
  },

  // imp003
  imp003_line: {
    id: `${scope}.imp003_line`,
    defaultMessage: 'Line',
  },

  imp003_ck: {
    id: `${scope}.imp003_ck`,
    defaultMessage: 'CK',
  },

  imp003_note_warning: {
    id: `${scope}.imp003_note_warning`,
    defaultMessage: 'Note warning',
  },

  imp003_master_name: {
    id: `${scope}.imp003_master_name`,
    defaultMessage: 'Master name',
  },

  imp003_no_subsidize: {
    id: `${scope}.imp003_no_subsidize`,
    defaultMessage: 'No subsidize',
  },

  imp003_company_name: {
    id: `${scope}.imp003_company_name`,
    defaultMessage: 'Company name',
  },

  imp003_status_req: {
    id: `${scope}.imp003_status_req`,
    defaultMessage: 'Status request',
  },

  imp003_date_req: {
    id: `${scope}.imp003_date_req`,
    defaultMessage: 'Date request',
  },

  imp003_employee_code: {
    id: `${scope}.imp003_employee_code`,
    defaultMessage: 'Employee code',
  },

  imp003_select_object: {
    id: `${scope}.imp003_select_object`,
    defaultMessage: 'Select object',
  },

  imp003_kanji_surname: {
    id: `${scope}.imp003_kanji_surname`,
    defaultMessage: 'Kanji surname',
  },

  imp003_kanji_name: {
    id: `${scope}.imp003_kanji_name`,
    defaultMessage: 'Kanji name',
  },

  imp003_age: {
    id: `${scope}.imp003_age`,
    defaultMessage: 'Age',
  },

  imp003_relationship_with_emp: {
    id: `${scope}.imp003_relationship_with_emp`,
    defaultMessage: 'Relationship with employee',
  },

  imp003_date_of_death: {
    id: `${scope}.imp003_date_of_death`,
    defaultMessage: 'Date of death',
  },

  imp003_money: {
    id: `${scope}.imp003_money`,
    defaultMessage: 'Money',
  },

  imp003_note: {
    id: `${scope}.imp003_note`,
    defaultMessage: 'Note',
  },

  imp003_item_name: {
    id: `${scope}.imp003_item_name`,
    defaultMessage: 'Item name',
  },

  imp003_type_error: {
    id: `${scope}.imp003_type_error`,
    defaultMessage: 'Type error',
  },

  imp003_content: {
    id: `${scope}.imp003_content`,
    defaultMessage: 'Content',
  },
  sap_employee_number: {
    id: `${scope}.sap_employee_number`,
    defaultMessage: 'Employee Number',
  },

  sap_subscriber_master_name: {
    id: `${scope}.sap_subscriber_master_name`,
    defaultMessage: 'Subscriber master name',
  },

  sap_item_name: {
    id: `${scope}.sap_item_name`,
    defaultMessage: 'Item Name',
  },

  sap_error_type: {
    id: `${scope}.sap_error_type`,
    defaultMessage: 'Error Type',
  },

  sap_content: {
    id: `${scope}.sap_content`,
    defaultMessage: 'Content',
  },

  //sap002
  sap002_column1: {
    id: `${scope}.sap002_column1`,
    defaultMessage: 'sap002_column1',
  },
  sap002_column2: {
    id: `${scope}.sap002_column2`,
    defaultMessage: 'sap002_column2',
  },

  sap002_column3: {
    id: `${scope}.sap002_column3`,
    defaultMessage: 'sap002_column3',
  },

  sap002_column4: {
    id: `${scope}.sap002_column4`,
    defaultMessage: 'sap002_column4',
  },

  sap002_column5: {
    id: `${scope}.sap002_column5`,
    defaultMessage: 'sap002_column5',
  },

  sap002_column6: {
    id: `${scope}.sap002_column6`,
    defaultMessage: 'sap002_column6',
  },

  sap002_column7: {
    id: `${scope}.sap002_column7`,
    defaultMessage: 'sap002_column7',
  },

  sap002_column8: {
    id: `${scope}.sap002_column8`,
    defaultMessage: 'sap002_column8',
  },

  sap002_column9: {
    id: `${scope}.sap002_column9`,
    defaultMessage: 'sap002_column9',
  },

  sap002_column10: {
    id: `${scope}.sap002_column10`,
    defaultMessage: 'sap002_column10',
  },

  sap002_column11: {
    id: `${scope}.sap002_column11`,
    defaultMessage: 'sap002_column11',
  },

  sap002_column12: {
    id: `${scope}.sap002_column12`,
    defaultMessage: 'sap002_column12',
  },

  sap002_column13: {
    id: `${scope}.sap002_column13`,
    defaultMessage: 'sap002_column13',
  },

  sap002_column14: {
    id: `${scope}.sap002_column14`,
    defaultMessage: 'sap002_column14',
  },

  sap002_column15: {
    id: `${scope}.sap002_column15`,
    defaultMessage: 'sap002_column15',
  },

  sap002_column16: {
    id: `${scope}.sap002_column16`,
    defaultMessage: 'sap002_column16',
  },

  sap002_column17: {
    id: `${scope}.sap002_column17`,
    defaultMessage: 'sap002_column17',
  },

  sap002_column18: {
    id: `${scope}.sap002_column18`,
    defaultMessage: 'sap002_column18',
  },

  sap002_column19: {
    id: `${scope}.sap002_column19`,
    defaultMessage: 'sap002_column19',
  },

  sap002_column20: {
    id: `${scope}.sap002_column20`,
    defaultMessage: 'sap002_column20',
  },

  sap002_column21: {
    id: `${scope}.sap002_column21`,
    defaultMessage: 'sap002_column21',
  },

  sap002_column22: {
    id: `${scope}.sap002_column22`,
    defaultMessage: 'sap002_column22',
  },

  sap002_column23: {
    id: `${scope}.sap002_column23`,
    defaultMessage: 'sap002_column23',
  },

  sap002_column24: {
    id: `${scope}.sap002_column24`,
    defaultMessage: 'sap002_column24',
  },

  sap002_column25: {
    id: `${scope}.sap002_column25`,
    defaultMessage: 'sap002_column25',
  },

  sap002_column26: {
    id: `${scope}.sap002_column26`,
    defaultMessage: 'sap002_column26',
  },

  sap002_column27: {
    id: `${scope}.sap002_column27`,
    defaultMessage: 'sap002_column27',
  },

  sap002_column28: {
    id: `${scope}.sap002_column28`,
    defaultMessage: 'sap002_column28',
  },

  sap002_column29: {
    id: `${scope}.sap002_column29`,
    defaultMessage: 'sap002_column29',
  },

  sap002_column30: {
    id: `${scope}.sap002_column30`,
    defaultMessage: 'sap002_column30',
  },

  sap002_column31: {
    id: `${scope}.sap002_column31`,
    defaultMessage: 'sap002_column31',
  },

  sap002_column32: {
    id: `${scope}.sap002_column32`,
    defaultMessage: 'sap002_column32',
  },

  sap002_column33: {
    id: `${scope}.sap002_column33`,
    defaultMessage: 'sap002_column33',
  },

  sap002_column34: {
    id: `${scope}.sap002_column34`,
    defaultMessage: 'sap002_column34',
  },

  sap002_column35: {
    id: `${scope}.sap002_column35`,
    defaultMessage: 'sap002_column35',
  },

  sap002_column36: {
    id: `${scope}.sap002_column36`,
    defaultMessage: 'sap002_column36',
  },

  sap002_column37: {
    id: `${scope}.sap002_column37`,
    defaultMessage: 'sap002_column37',
  },

  sap002_column38: {
    id: `${scope}.sap002_column38`,
    defaultMessage: 'sap002_column38',
  },

  sap002_column39: {
    id: `${scope}.sap002_column39`,
    defaultMessage: 'sap002_column39',
  },
  sap_cancellation_fee: {
    id: `${scope}.sap_cancellation_fee`,
    defaultMessage: 'Cancellation fee',
  },
  sap002_column40: {
    id: `${scope}.sap002_column40`,
    defaultMessage: 'sap002_column40',
  },

  sap002_column41: {
    id: `${scope}.sap002_column41`,
    defaultMessage: 'sap002_column41',
  },
  sap002_company_code: {
    id: `${scope}.sap002_company_code`,
    defaultMessage: 'sap002_company_code',
  },
  sap002_company_name: {
    id: `${scope}.sap002_company_name`,
    defaultMessage: 'sap002_company_name',
  },


  //end of sap002

  //sap003

  sap_condolence_money_for_relatives: {
    id: `${scope}.sap_condolence_money_for_relatives`,
    defaultMessage: 'Condolence money for relatives',
  },

  sap_other: {
    id: `${scope}.sap_other`,
    defaultMessage: 'Other',
  },
  sap003_column1: {
    id: `${scope}.sap003_column1`,
    defaultMessage: 'sap003_column1',
  },

  sap003_column2: {
    id: `${scope}.sap003_column2`,
    defaultMessage: 'sap003_column2',
  },

  sap003_column3: {
    id: `${scope}.sap003_column3`,
    defaultMessage: 'sap003_column3',
  },

  sap003_column4: {
    id: `${scope}.sap003_column4`,
    defaultMessage: 'sap003_column4',
  },

  sap003_column5: {
    id: `${scope}.sap003_column5`,
    defaultMessage: 'sap003_column5',
  },

  sap003_column6: {
    id: `${scope}.sap003_column6`,
    defaultMessage: 'sap003_column6',
  },

  sap003_group_column1: {
    id: `${scope}.sap003_group_column1`,
    defaultMessage: 'sap003_group_column1',
  },

  sap003_group_column2: {
    id: `${scope}.sap003_group_column2`,
    defaultMessage: 'sap003_group_column2',
  },

  sap003_group_column3: {
    id: `${scope}.sap003_group_column3`,
    defaultMessage: 'sap003_group_column3',
  },

  sap_non_work_related_deaths_cases: {
    id: `${scope}.sap_non_work_related_deaths_cases`,
    defaultMessage: 'Non-work-related deaths (cases)',
  },
  //end of sap003

  //sap004
  sap004_column1: {
    id: `${scope}.sap004_column1`,
    defaultMessage: 'sap004_column1',
  },
  sap004_column2: {
    id: `${scope}.sap004_column2`,
    defaultMessage: 'sap004_column2',
  },
  sap004_column3: {
    id: `${scope}.sap004_column3`,
    defaultMessage: 'sap004_column3',
  },
  sap004_column4: {
    id: `${scope}.sap004_column4`,
    defaultMessage: 'sap004_column4',
  },
  sap004_column5: {
    id: `${scope}.sap004_column5`,
    defaultMessage: 'sap004_column5',
  },
  sap004_column6: {
    id: `${scope}.sap004_column6`,
    defaultMessage: 'sap004_column6',
  },
  sap004_column7: {
    id: `${scope}.sap004_column7`,
    defaultMessage: 'sap004_column7',
  },
  sap004_column8: {
    id: `${scope}.sap004_column8`,
    defaultMessage: 'sap004_column8',
  },
  sap004_column9: {
    id: `${scope}.sap004_column9`,
    defaultMessage: 'sap004_column9',
  },
  sap004_column10: {
    id: `${scope}.sap004_column10`,
    defaultMessage: 'sap004_column10',
  },
  sap004_column11: {
    id: `${scope}.sap004_column11`,
    defaultMessage: 'sap004_column11',
  },

  sap004_1_column1: {
    id: `${scope}.sap004_1_column1`,
    defaultMessage: 'sap004_1_column1',
  },

  sap004_1_column2: {
    id: `${scope}.sap004_1_column2`,
    defaultMessage: 'sap004_1_column2',
  },

  sap004_1_column3: {
    id: `${scope}.sap004_1_column3`,
    defaultMessage: 'sap004_1_column3',
  },

  sap004_1_column4: {
    id: `${scope}.sap004_1_column4`,
    defaultMessage: 'sap004_1_column4',
  },

  sap004_1_column5: {
    id: `${scope}.sap004_1_column5`,
    defaultMessage: 'sap004_1_column5',
  },

  sap004_1_column6: {
    id: `${scope}.sap004_1_column6`,
    defaultMessage: 'sap004_1_column6',
  },
  //end of sap004

  //iku003
  iku003_column1: {
    id: `${scope}.iku003_column1`,
    defaultMessage: 'iku003_column1',
  },
  iku003_column2: {
    id: `${scope}.iku003_column2`,
    defaultMessage: 'iku003_column2',
  },
  iku003_column3: {
    id: `${scope}.iku003_column3`,
    defaultMessage: 'iku003_column3',
  },
  iku003_column4: {
    id: `${scope}.iku003_column4`,
    defaultMessage: 'iku003_column4',
  },
  iku003_column5: {
    id: `${scope}.iku003_column5`,
    defaultMessage: 'iku003_column5',
  },
  iku003_column6: {
    id: `${scope}.iku003_column6`,
    defaultMessage: 'iku003_column6',
  },
  iku003_column7: {
    id: `${scope}.iku003_column7`,
    defaultMessage: 'iku003_column7',
  },
  iku003_column8: {
    id: `${scope}.iku003_column8`,
    defaultMessage: 'iku003_column8',
  },
  iku003_column9: {
    id: `${scope}.iku003_column9`,
    defaultMessage: 'iku003_column9',
  },
  iku003_column10: {
    id: `${scope}.iku003_column10`,
    defaultMessage: 'iku003_column10',
  },
  iku003_column11: {
    id: `${scope}.iku003_column11`,
    defaultMessage: 'iku003_column11',
  },
  iku003_column12: {
    id: `${scope}.iku003_column12`,
    defaultMessage: 'iku003_column12',
  },
  iku003_column13: {
    id: `${scope}.iku003_column13`,
    defaultMessage: 'iku003_column13',
  },
  iku003_column14: {
    id: `${scope}.iku003_column14`,
    defaultMessage: 'iku003_column14',
  },
  iku003_column15: {
    id: `${scope}.iku003_column15`,
    defaultMessage: 'iku003_column15',
  },
  iku003_column16: {
    id: `${scope}.iku003_column16`,
    defaultMessage: 'iku003_column16',
  },
  iku003_column17: {
    id: `${scope}.iku003_column17`,
    defaultMessage: 'iku003_column17',
  },
  iku003_column18: {
    id: `${scope}.iku003_column18`,
    defaultMessage: 'iku003_column18',
  },
  iku003_column19: {
    id: `${scope}.iku003_column19`,
    defaultMessage: 'iku003_column19',
  },
  iku003_column20: {
    id: `${scope}.iku003_column20`,
    defaultMessage: 'iku003_column20',
  },
  iku003_column21: {
    id: `${scope}.iku003_column21`,
    defaultMessage: 'iku003_column21',
  },
  //end of iku003

  iku001_column1: {
    id: `${scope}.iku001_column1`,
    defaultMessage: 'iku001_column1',
  },
  iku001_column2: {
    id: `${scope}.iku001_column2`,
    defaultMessage: 'iku001_column2',
  },
  iku001_column3: {
    id: `${scope}.iku001_column3`,
    defaultMessage: 'iku001_column3',
  },
  iku001_column4: {
    id: `${scope}.iku001_column4`,
    defaultMessage: 'iku001_column4',
  },
  iku001_column5: {
    id: `${scope}.iku001_column5`,
    defaultMessage: 'iku001_column5',
  },
  iku001_column6: {
    id: `${scope}.iku001_column6`,
    defaultMessage: 'iku001_column6',
  },
  iku001_column7: {
    id: `${scope}.iku001_column7`,
    defaultMessage: 'iku001_column7',
  },
  iku001_column8: {
    id: `${scope}.iku001_column8`,
    defaultMessage: 'iku001_column8',
  },
  iku001_column9: {
    id: `${scope}.iku001_column9`,
    defaultMessage: 'iku001_column9',
  },
  iku001_column10: {
    id: `${scope}.iku001_column10`,
    defaultMessage: 'iku001_column10',
  },
  iku001_column11: {
    id: `${scope}.iku001_column11`,
    defaultMessage: 'iku001_column11',
  },

  //imp006
  imp006_date_import: {
    id: `${scope}.imp006_date_import`,
    defaultMessage: 'date import',
  },
  imp006_time_import: {
    id: `${scope}.imp006_time_import`,
    defaultMessage: 'time import',
  },
  imp006_file_type: {
    id: `${scope}.imp006_file_type`,
    defaultMessage: 'file type',
  },
  imp006_id: {
    id: `${scope}.imp006_id`,
    defaultMessage: 'id',
  },
  imp006_worker: {
    id: `${scope}.imp006_worker`,
    defaultMessage: 'worker',
  },
  imp006_filename: {
    id: `${scope}.imp006_filename`,
    defaultMessage: 'filename',
  },
  imp006_record_number: {
    id: `${scope}.imp006_record_number`,
    defaultMessage: 'record number',
  },
  imp006_subsidize: {
    id: `${scope}.imp006_subsidize`,
    defaultMessage: 'subsidize',
  },
  imp006_payment_date: {
    id: `${scope}.imp006_payment_date`,
    defaultMessage: 'payment date',
  },
  imp006_status: {
    id: `${scope}.imp006_status`,
    defaultMessage: 'status',
  },
  //end of imp 006
  apa004_gr_company_cd:{
    id: `${scope}.apa004.group_company_cd`,
    defaultMessage: 'apa004_gr_company_cd',
  },
  apa004_gr_company_name:{
    id: `${scope}.apa004.group_company_name`,
    defaultMessage: 'apa004_gr_company_name',
  }
});
