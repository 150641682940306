import Const from 'config/constant';
import commonValidateMethod from './validateCommonMethod'; // do not delete this line
import {
  validateApaDate,
  validateApaFormatDate,
  validateYMFormatDate,
  validateFormatDate,
  validateImpSubmitDate,
} from './validateCommonDate';
import {
  validateEmployIdMse004,
  validateCompanyIdMse004,
  validateNameKanjiMse004,
  validateNameKanaMse004,
  validateGroupCompanyIdListScreenMse003,
  validateCompanyNameMse003,
  validateGroupCompanyMse002,
  validateTypeIdMse006,
  validateRCMSComment,
  validateZenginComment,
  validateApa003Comment,
  validateKyosaiTypeCodeSearch
} from './validateCommonMaster';

export const validateMSE004 = (obj, fieldValidate) => {
  //mse004
  if (fieldValidate?.includes('numberEmployeeMse004')) {
    obj.numberEmployeeMse004 = validateEmployIdMse004;
  }
  if (fieldValidate?.includes('idCompanyMse004')) {
    obj.idCompanyMse004 = validateCompanyIdMse004;
  }
  if (fieldValidate?.includes('nameMse004')) {
    obj.nameMse004 = validateNameKanjiMse004;
  }
  if (fieldValidate?.includes('nameKanaMse004')) {
    obj.nameKanaMse004 = validateNameKanaMse004;
  }
  return obj;
};

export const validateMSE003 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('groupCompanyIdListScreen')) {
    obj.groupCompanyIdListScreen = validateGroupCompanyIdListScreenMse003;
  }
  if (fieldValidate?.includes('companyName')) {
    obj.companyName = validateCompanyNameMse003;
  }
};

export const validateMSE002 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('groupCompanyMse002')) {
    obj.groupCompanyMse002 = validateGroupCompanyMse002;
  }
};

export const validateMSE006 = (obj, fieldValidate) => {
  //mse006
  if (fieldValidate?.includes('type_id_mse006')) {
    obj.type_id_mse006 = validateTypeIdMse006;
  }
  return obj;
};

export const validateMSE001 = (obj, fieldValidate) => {
  //mse001
  if (fieldValidate?.includes('kyosaiTypeCodeSearch')) {
    obj.kyosaiTypeCodeSearch = validateKyosaiTypeCodeSearch;
  }
  return obj;
};
export const validateApa = (obj, fieldValidate) => {
  if (fieldValidate?.includes('dateOfPayment')) {
    obj.dateOfPayment = validateApaFormatDate;
  }

  if (fieldValidate?.includes('apa001DateOfPayment')) {
    obj.apa001DateOfPayment = validateApaDate;
  }

  if (fieldValidate?.includes('rcmsComment')) {
    obj.rcmsComment = validateRCMSComment;
  }

  if (fieldValidate?.includes('zenginComment')) {
    obj.zenginComment = validateZenginComment;
  }

  if (fieldValidate?.includes('comment')) {
    obj.comment = validateApa003Comment;
  }
};

export const validateImp = (obj, fieldValidate) => {
  if (fieldValidate?.includes('impDateOfPayment')) {
    obj.impDateOfPayment = validateYMFormatDate;
  }

  if (fieldValidate?.includes('impSubmitDate')) {
    obj.impSubmitDate = validateImpSubmitDate;
  }
};
