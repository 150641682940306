import * as Yup from 'yup';
import ConstantRegex from '../constantRegex';

const passwordShiyokanoMoji = JSON.parse(localStorage.getItem('systems'))?.[0]
  ?.password_shiyokano_moji;

const regextPasswordShiyokanoMoji = /^[a-zA-Z0-9 -/:-@\[-\`\{-\~]+$/;

export const validatePasswordBeforeChange = Yup.string().required(
  '必須項目です。　変更前パスワード',
);
export const validateNewPassword = Yup.string()
  .required('必須項目です。　変更後パスワード') // pass is not empty
  .min(4, '変更後パスワードは4文字以上20文字以内で指定してください。')
  .max(20, '変更後パスワードは4文字以上20文字以内で指定してください。')
  .matches(
    ConstantRegex.regxPass,
    'パスワードは半角英大文字・半角英小文字・数字・番号で指定してください。',
  );

export const validateConfirmationPassword = Yup.string()
  .oneOf(
    [Yup.ref('newPassword'), null],
    '変更後パスワードと確認用パスワードが異なります。',
  )
  .required('必須項目です。　確認用パスワード');
