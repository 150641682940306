/**
 *
 * NetWork
 * Author: TruongTx
 *
 */

import React from 'react';
import Modal from '../../components/Modal';

import { FormattedMessage } from 'react-intl';
import messages from './messages';
import ENUMS from '../../config/enum';
import './index.css';
function NetWork() {
  return (
    <div>
      {/* <FormattedMessage {...messages.mesNetWorking} /> */}
      <Modal
        id="modal-root"
        type={ENUMS?.TYPE_MODAL?.NETWORK}
        messkey="netWork"
      />
    </div>
  );
}

NetWork.propTypes = {};

export default NetWork;
