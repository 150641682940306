import * as Yup from 'yup';
import { getMessValidate } from '../messValidate';
import ConstRegx from '../constantRegex';
export const validateKyosaiTypeName = Yup.string()
  .nullable()
  .checkEmptyString('共済タイプ名', 30, true);
// .required(getMessValidate('C0000001', ['共済タイプ名']))
// .max(30, getMessValidate('C0000007', ['共済タイプ名']));

export const validateKyosaiTypeCode = Yup.string()
  .nullable()
  .checkWithRegx(
    '共済タイプコード',
    ConstRegx.regexHalfSize,
    'C0000006',
    3,
    true,
  );
// .required('必須項目エラー共済タイプコード')
// .required(getMessValidate('C0000001', ['共済タイプコード']))
// .max(3, getMessValidate('C0000007', ['共済タイプコード']))
// .matches(
//   ConstRegx.regexHalfSize,
//   getMessValidate('C0000006', ['共済タイプコード']),
// );

export const validateKakekin = Yup.string()
  .nullable()
  .checkWithRegx('共済掛金', ConstRegx.regexHalfSize, 'C0000006', 9, true);
// .required(getMessValidate('C0000001', ['共済掛金']))
// .max(9, getMessValidate('C0000007', ['共済掛金']))
// .matches(ConstRegx.regexHalfSize, getMessValidate('C0000006', ['共済掛金']));

export const validateNenrei = Yup.string()
  .nullable()
  .checkWithRegx('加入引受年齢', ConstRegx.regexHalfSize, 'C0000006', 2, true);
// .required(getMessValidate('C0000001', ['加入引受年齢']))
// .max(2, getMessValidate('C0000007', ['加入引受年齢']))
// .matches(
//   ConstRegx.regexHalfSize,
//   getMessValidate('C0000006', ['加入引受年齢']),
// );
