/*
 *
 * InputCompany constants
 * Author: LanVt
 */
import { createConstants } from 'helper/action';
export const SEARCH_COMPANY_EXACTLY_COMMON = `SEARCH_COMPANY_EXACTLY_COMMON`;

export const [
  SEARCH_COMPANY_EXACTLY_COMMON_REQUEST,
  SEARCH_COMPANY_EXACTLY_COMMON_SUCCESS,
  SEARCH_COMPANY_EXACTLY_COMMON_ERROR,
] = createConstants(SEARCH_COMPANY_EXACTLY_COMMON);
