import * as Yup from 'yup';
import { getMessValidate } from './messValidate';
import ConstRegx from './constantRegex';

//mse006
export const validateTypeIdMse006 = Yup.string()
  .required(getMessValidate('C0000001', ['給付種別']))
  .matches(
    ConstRegx.regNumberHalfsize,
    getMessValidate('C0000003', ['給付種別']),
  );

//mse001
export const validateKyosaiTypeCodeSearch = Yup.string().max(
  3,
  getMessValidate('C0000007', ['共済タイプコード']),
);

//mse004
export const validateEmployIdMse004 = Yup.string()
  .nullable()
  .max(12, getMessValidate('C0000007', ['従業員番号']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['従業員番号']),
  );

export const validateCompanyIdMse004 = Yup.string()
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社コード']),
  );

export const validateNameKanjiMse004 = Yup.string()
  .nullable()
  .max(12, getMessValidate('C0000007', ['氏名']))
  .matches(
    ConstRegx.regxKanji,
    getMessValidate('C0000006', ['氏名']), //kanji
  );

export const validateNameKanaMse004 = Yup.string()
  .nullable()
  .max(40, getMessValidate('C0000007', ['氏名カナ']))
  .matches(
    ConstRegx.regexFullSizeKatakana,
    getMessValidate('C0000002', ['氏名カナ']),
  );

//mse003
export const validateGroupCompanyIdListScreenMse003 = Yup.string()
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社コード']),
  );

export const validateCompanyNameMse003 = Yup.string()
  .max(35, getMessValidate('C0000007', ['会社名']))
  .matches(
    ConstRegx.regxKanji,
    getMessValidate('C0000006', ['会社名']), //kanji
  );

//mse002
export const validateGroupCompanyMse002 = Yup.string()
  .max(10, getMessValidate('C0000007', ['会社グループコード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社グループコード']),
  );

//Apa
export const validateRCMSComment = Yup.string()
  .max(100, getMessValidate('C0000007', ['RCMSコメント']));

export const validateZenginComment = Yup.string()
  .max(100, getMessValidate('C0000007', ['全銀コメント']));

export const validateApa003Comment = Yup.string()
  .nullable()
  .max(100, getMessValidate('C0000007', ['コメント']));
