/**
 *
 * Table
 * Author: LanVt
 *
 */

import React, { Children } from 'react';
import { Globalize } from '@grapecity/wijmo';
import PropTypes from 'prop-types';
import ENUMS from '../../config/enum';
import Const from '../../config/constant';
import { formatStrDate } from '../../utils/common/DateUtils';
import { setPostal } from '../../utils/common/StringUtils';

function CellTemplate({
  type,
  funcOnClick,
  children,
  context,
  dataCell,
  formatCell,
  funcSetValue,
  fieldCheckOn,
  valueCheckOn,
  cssClassAllCheckOn,
  className = '',
  isHidden,
}) {
  const row = context?.row;
  const cellTemplateModule = () => {
    let cellComponent = <> </>;
    let date = '';
    let value = '';
    switch (type) {
      case ENUMS?.TYPE_CELL?.LINK:
        cellComponent = (
          <a
            onClick={(e) => funcOnClick(context, dataCell, e)}
            className={`${className} text-link`}
            hidden={isHidden}
          >
            {Children.toArray(children)}
          </a>
        );
        break;
      case ENUMS?.TYPE_CELL?.DATE:
        date = formatCell ? formatStrDate(dataCell, formatCell) : dataCell;
        cellComponent = <>{date}</>;
        break;
      case ENUMS?.TYPE_CELL?.CUSTOM_VALUE:
        value = funcSetValue(dataCell, row);
        cellComponent = <>{value}</>;
        break;
      case ENUMS?.TYPE_CELL?.CUSTOM_ON_OFF:
        cellComponent = <>{dataCell}</>;
        break;
      case ENUMS?.TYPE_CELL?.FORMAT_THOUSAND:
        let cellValue = Globalize.formatNumber(+dataCell, 'n0');
        if (dataCell === null) {
          cellValue = '';
        }
        if (Number.isNaN(+dataCell)) {
          cellValue = dataCell;
        }
        cellComponent = <p className={`${className}`}>{cellValue}</p>;
        break;
      case ENUMS?.TYPE_CELL?.FORMAT_ZIP_CODE:
        cellComponent = <p className={`${className}`}>{setPostal(dataCell)}</p>;
        break;
      case ENUMS?.TYPE_CELL?.HIDE:
        cellComponent = <></>;
        break;
      case ENUMS?.TYPE_CELL?.BIND_HTML:
        cellComponent = (
          <div
            dangerouslySetInnerHTML={{ __html: dataCell }}
            className={className}
          />
        );
        break;

      default:
        cellComponent = <p className={`${className}`}> {dataCell} </p>;
    }
    return cellComponent;
  };
  const cell = cellTemplateModule();
  if (context?.item?.[fieldCheckOn] === valueCheckOn && !row.cssClassAll) {
    row.cssClassAll = cssClassAllCheckOn;
  }
  return <>{cell}</>;
}

CellTemplate.propTypes = {
  type: PropTypes.string,
  funcOnClick: PropTypes.func,
  children: PropTypes.any,
  dataCell: PropTypes.any,
  formatCell: PropTypes.string,
  funcSetValue: PropTypes.func,
  isHidden: PropTypes.bool,
};
CellTemplate.defaultProps = {
  funcOnClick: () => {},
  funcSetValue: () => {},
  formatCell: Const?.longDateFormat?.L || '',
  isHidden: false,
};
export default CellTemplate;
