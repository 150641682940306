import React from 'react';
import messages from './messages';
import ENUM  from './../../config/enum';
import { FormattedMessage } from 'react-intl';

function TitleTopBar(
    type,
    isShowListScreen,
    keyTitle
  ) {
  let keyTitleByType = '';
  let title = {};
  if(isShowListScreen){
    keyTitleByType = `${keyTitle}List`;
    return (
      <FormattedMessage {...messages?.[keyTitleByType]} />
    )
  }

  if (type === ENUM.TYPE_SCREEN.NEW) {
    keyTitleByType = `${keyTitle}New`;
    if(!messages?.[keyTitleByType]){
      title = (
        <FormattedMessage {...messages?.[keyTitle]} />
      );
    }else{
      title = (
        <FormattedMessage {...messages?.[keyTitleByType]} />
      );
    }
  }
  if (type === ENUM.TYPE_SCREEN.EDIT) {
    keyTitleByType = `${keyTitle}Edit`;
    title = (
      <FormattedMessage {...messages?.[keyTitleByType]} />
    );
  }
  if (type === ENUM.TYPE_SCREEN.VIEW) {
    keyTitleByType = `${keyTitle}View`;
    title = (
      <FormattedMessage {...messages?.[keyTitleByType]} />
    );
  }
  return title;
}

export default TitleTopBar;

