import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import Const from 'config/constant';
import {
  validateMSE006,
  validateMSE001,
  validateMSE004,
  validateMSE003,
  validateMSE002,
  validateApa,
  validateImp,
} from './validateFormUtils';
import { validateBen } from './formBen';
import { warningBen } from './formBen/checkWarningCommonBen';
import { validatePer } from './formPer';
import { validateSea } from './formSea';
import { validateCom003 } from './formCom003/validateFormCom003';
import { validateCom002 } from './formCom002/validateFormCom002';
import { validateMEN004 } from './formMen004/validateFormMen004';
import { validateMEN005 } from './formMen005/validateFormMen005';
import { validateMEN006 } from './formMen006/validateFormMen006';
import { validateMEN003 } from './formMen003/validateFormMen003';
import { validateMEN002 } from './formMen002/validateFormMen002';
import { validateMEN001 } from './formMen001/validateFormMen001';

export const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: initialValues => ({
    fieldValidate: initialValues?.fieldValidate,
    fieldWarning: initialValues?.fieldWarning,
    screenCode: initialValues?.screenCode,
  }),
  validationSchema: Yup.lazy((value, props, items) => {
    const obj = {};
    if (value?.screenCode === Const.screenCodeAll.MEN001) {
      validateMEN001(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.MEN002) {
      validateMEN002(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.MEN003) {
      validateMEN003(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.MEN004) {
      validateMEN004(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.MEN005) {
      validateMEN005(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.MEN006) {
      validateMEN006(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.COM003) {
      validateCom003(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.COM002) {
      validateCom002(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.IMP) {
      validateImp(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.APA) {
      validateApa(obj, value?.fieldValidate);
    } else if (value?.screenCode === Const.screenCodeAll.SEA) {
      validateSea(obj, value?.fieldValidate);
    } else {
      // Master
      validateMSE006(obj, value?.fieldValidate);
      validateMSE001(obj, value?.fieldValidate);
      // validateMSE004(obj, value?.fieldValidate);
      // validateMSE003(obj, value?.fieldValidate);
      // validateMSE002(obj, value?.fieldValidate);    //tam thoi comment
      // Ben
      validateBen(obj, value?.fieldValidate, value?.screenCode);
      validatePer(obj, value?.fieldValidate, value?.screenCode);
      // Apa
    }

    warningBen(value);
    if (value !== undefined) {
      return Yup.object().shape(obj);
    }
    return yup.mixed().notRequired();
  }),
  handleSubmit: (values, { props, ...actions }) => {
    if (props.funcSubmit) {
      props.funcSubmit(values);
    }
  },
  displayName: 'MyForm', // helps with React DevTools
});
