import {
  checkWarningJiyuhasseiYmd,
  checkWarningRequestDt,
  checkStartDateBen007,
  checkEndDateBen007,
} from 'utils/common/BenUtils';
import ConstRoot from 'config/constant';
export const warningBen = value => {
  let obj = {};
  if (value?.screenCode !== ConstRoot.screenCodeAll.BEN006) {
    // validate Date of Birth
    if (value?.fieldWarning?.includes('jiyuhassei_ymd')) {
      const jiyuhassei_ymd = checkWarningJiyuhasseiYmd(
        value?.jiyuhassei_ymd, // ngay phat sinh
        value?.shinsei_kaishi_ymd, //ngay yeu cau
        value?.kumiai_taikai_ymd, //ngay roi khoi hoi cua cong ty
        value?.nyukai_ymd, //ngay vao hoi cua cong ty
        value?.taikai_ymd, //ngay roi khoi hoi cua nhan vien
        value?.kyufu_shubetsu_cd,
      );
      if (jiyuhassei_ymd) {
        obj.jiyuhassei_ymd = jiyuhassei_ymd;
      }
    }
  }

  if (value?.fieldWarning?.includes('shinsei_kaishi_ymd')) {
    const shinsei_kaishi_ymd = checkWarningRequestDt(
      value?.shinsei_kaishi_ymd, // ngay yêu cầu
      value?.jiyuhassei_ymd, //ngay phat sinh cua nhan vien
      value?.kyufu_shubetsu_cd, //ngay phat sinh cua nhan vien
    );
    if (shinsei_kaishi_ymd) {
      obj.shinsei_kaishi_ymd = shinsei_kaishi_ymd;
    }
  }
  if (value?.screenCode === ConstRoot.screenCodeAll.BEN006) {
    if (value?.fieldWarning?.includes('shogai_nintei_ymd')) {
      const shogai_nintei_ymd = checkStartDateBen007(
        value?.shogai_nintei_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000024',
      );
      if (shogai_nintei_ymd) {
        obj.shogai_nintei_ymd = shogai_nintei_ymd;
      }
    }
  }

  if (value?.screenCode === ConstRoot.screenCodeAll.BEN007) {
    if (value?.fieldWarning?.includes('kyugyo_kaishi_ymd')) {
      const kyugyo_kaishi_ymd = checkStartDateBen007(
        value?.kyugyo_kaishi_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000011',
      );
      if (kyugyo_kaishi_ymd) {
        obj.kyugyo_kaishi_ymd = kyugyo_kaishi_ymd;
      }
    }
    if (value?.fieldWarning?.includes('nyuin_kaishi_ymd')) {
      const nyuin_kaishi_ymd = checkStartDateBen007(
        value?.nyuin_kaishi_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000020',
      );
      if (nyuin_kaishi_ymd) {
        obj.nyuin_kaishi_ymd = nyuin_kaishi_ymd;
      }
    }
    if (value?.fieldWarning?.includes('sagakubed_shiyo_kaishi_ymd')) {
      const sagakubed_shiyo_kaishi_ymd = checkStartDateBen007(
        value?.sagakubed_shiyo_kaishi_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000013',
      );
      if (sagakubed_shiyo_kaishi_ymd) {
        obj.sagakubed_shiyo_kaishi_ymd = sagakubed_shiyo_kaishi_ymd;
      }
    }
    if (value?.fieldWarning?.includes('tsuin_kaishi_ymd')) {
      const tsuin_kaishi_ymd = checkStartDateBen007(
        value?.tsuin_kaishi_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000018',
      );
      if (tsuin_kaishi_ymd) {
        obj.tsuin_kaishi_ymd = tsuin_kaishi_ymd;
      }
    }

    if (value?.fieldWarning?.includes('kyugyo_shuryo_ymd')) {
      const kyugyo_shuryo_ymd = checkEndDateBen007(
        value?.kyugyo_shuryo_ymd,
        value?.shinsei_kaishi_ymd, // ngay yêu cầu
        'W0000012',
      );
      if (kyugyo_shuryo_ymd) {
        obj.kyugyo_shuryo_ymd = kyugyo_shuryo_ymd;
      }
    }
    if (value?.fieldWarning?.includes('nyuin_shuryo_ymd')) {
      const nyuin_shuryo_ymd = checkEndDateBen007(
        value?.nyuin_shuryo_ymd,
        value?.benefit_limit_dt, // ngay gioi han tro cap
        'W0000003',
        [value?.jogen_days],
      );
      if (nyuin_shuryo_ymd) {
        obj.nyuin_shuryo_ymd = nyuin_shuryo_ymd;
      }
    }
    if (value?.fieldWarning?.includes('tsuin_shuryo_ymd')) {
      const tsuin_shuryo_ymd = checkEndDateBen007(
        value?.tsuin_shuryo_ymd,
        value?.benefit_limit_dt, // ngay gioi han tro cap
        'W0000019',
        [value?.jogen_days],
      );
      if (tsuin_shuryo_ymd) {
        obj.tsuin_shuryo_ymd = tsuin_shuryo_ymd;
      }
    }
  }
  const funcSetFieldValue = value?.setFieldValue;
  if (funcSetFieldValue) {
    if (JSON.stringify(value?.objWarning) !== JSON.stringify(obj)) {
      funcSetFieldValue('objWarning', obj);
    }
  }
  return obj;
};
