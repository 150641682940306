import * as Yup from 'yup';
import { getMessValidate } from '../messValidate';
import ConstantRegex from '../constantRegex';
//Men005
export const validateUserIdMen005 = Yup.string()
  .nullable()
  .checkValidateNumber('ユーザーID', 4, true);

export const validateFullName = Yup.string()
  .nullable()
  .checkEmptyString('氏名', 30, true);
// .required(getMessValidate('C0000001', ['氏名']))
// .max(10, getMessValidate('C0000007', ['氏名']));

export const validateEmail = Yup.string()
  .nullable()
  .checkEmptyString('Emailｱﾄﾞﾚｽ', 100, true);
// .required(getMessValidate('C0000001', ['Emailｱﾄﾞﾚｽ']))
// .max(25, getMessValidate('C0000007', ['Emailｱﾄﾞﾚｽ']));
// .matches(
//   ConstantRegex.regexHalfSizeKatakana,
//   getMessValidate('C0000003', ['Emailｱﾄﾞﾚｽ']),
// );

export const validateGyomuPermission = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['業務権限']));
// .max(40, getMessValidate('C0000007', ['業務権限']));

export const validateSystemPermissions = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['ｼｽﾃﾑ権限']));
// .max(40, getMessValidate('C0000007', ['ｼｽﾃﾑ権限']));

export const validateShoinPathFileName = Yup.string()
  .nullable()
  .checkEmptyString('認印ファイル', 260, true);
// .required(getMessValidate('C0000001', ['認印ファイル']))
// .max(260, getMessValidate('C0000007', ['認印ファイル']));
// .matches(
//   ConstantRegex.regexHalfSizeKatakana,
//   getMessValidate('C0000003', ['認印ファイル']),
// );
