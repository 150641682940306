import React from 'react';
import ENUM from './../../config/enum';
import Button from '../../components/Button';
import ButtonClose from 'components/ButtonClose';

function ButtonHandle(
  type,
  isUserSystem,
  handleSubmit,
  handleCancel,
  handleEdit,
  handleResetPass,
  resetPass,
  errors,
) {
  let groupButton = <div />;
  if (type === ENUM.TYPE_SCREEN.VIEW && isUserSystem) {
    groupButton = (
      <>
        <div className="top_bar_primary_btn_wrap">
          <Button
            className="btn_primary"
            buttonName="buttonEdit"
            onClick={e => {
              handleEdit(e);
            }}
          />
          {resetPass && (
            <Button
              className="btn_primary"
              buttonName="buttonResetPass"
              onClick={e => {
                handleResetPass(e);
              }}
            />
          )}
        </div>
        {/* <div className="top_bar_secondary_btn_wrap"> */}
          <ButtonClose className="top_bar_secondary_btn_wrap" />
        {/* </div> */}
      </>
    );
  } else if (type === ENUM.TYPE_SCREEN.VIEW) {
    groupButton = (
      <>
        {/* <div className="top_bar_secondary_btn_wrap"> */}
          <ButtonClose className="top_bar_secondary_btn_wrap" />
        {/* </div> */}
      </>
    );
  }

  if (type === ENUM.TYPE_SCREEN.NEW || type === ENUM.TYPE_SCREEN.EDIT) {
    groupButton = (
      <>
        <div className="top_bar_primary_btn_wrap">
          <Button
            // className="btn_primary"
            className={
              errors && Object.keys(errors).length > 0
                ? 'btnDisable'
                : 'btn_primary'
            }
            buttonName="buttonRegistration"
            type="submit"
            onClick={handleSubmit}
          />
          <Button
            className="btn_primary"
            buttonName="buttonCancel"
            onClick={handleCancel}
          />
        </div>
      </>
    );
  }

  return <>{groupButton}</>;
}
export default ButtonHandle;
