/**
 *
 * InputUserId
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox';
import InputLabel from '../InputLabel';
import './index.css';
function InputLabelCheckBox({
  className,
  classNameInput,
  classNameInputLabel,
  nameInput,
  nameCheckBox,
  values,
  handleChange,
  handleBlur,
  autoFocus,
  keySpanCheckBox,
  keyMessLabel,
  keyMessLabelJson,
  type,
  classNameLabel,
  classNameLblSpan,
  mess,
  messWarning,
  isRequired,
  maxLength,
  pattern,
  isTypeView,
  isReadonly,
  isReadonlyCheckBox,
  isSubmitting,
  touched,
  isShowFormat,
  format,
}) {
  return (
    <div className={className}>
      <InputLabel
        className={classNameInputLabel}
        classNameInput={classNameInput}
        classNameLblSpan={classNameLblSpan}
        maxLength={maxLength}
        keyMess={keyMessLabel}
        keyMessJson={keyMessLabelJson}
        isRequired={isRequired}
        name={nameInput}
        mess={mess}
        messWarning={messWarning}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        autoFocus={autoFocus}
        type={type}
        classNameLabel={classNameLabel}
        pattern={pattern}
        isTypeView={isTypeView}
        isReadonly={isReadonly}
        isSubmitting={isSubmitting}
        touched={touched}
        isShowFormat={isShowFormat}
        format={format}
      />
      <CheckBox
        className="checkbox ml-30 mr-10"
        keySpan={keySpanCheckBox}
        name={nameCheckBox}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisable={isReadonlyCheckBox}
      />
    </div>
  );
}

InputLabelCheckBox.propTypes = {
  className: PropTypes.string,
  classNameInputLabel: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameInput: PropTypes.string,
  nameInput: PropTypes.string,
  nameCheckBox: PropTypes.string,
  keySpanCheckBox: PropTypes.string,
  keyMessLabel: PropTypes.string,
  values: PropTypes.any,
  autoFocus: PropTypes.bool,
  isShowFormat: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  type: PropTypes.string,
  mess: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  isTypeView: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.object,
  isReadonlyCheckBox: PropTypes.bool,
};

InputLabelCheckBox.defaultProps = {
  isRequired: true,
  isTypeView: false,
  isReadonly: false,
  isReadonlyCheckBox: false,
};

export default InputLabelCheckBox;
