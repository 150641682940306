import { call, put, takeLatest } from 'redux-saga/effects';
import SERVICEURLS from 'config/serviceUrls';
import authHeader from 'helper/auth-header';
import {
  searchCommpanyExactlyCommonSuccess,
  searchCommpanyExactlyCommonError,
} from './actions';
import { SEARCH_COMPANY_EXACTLY_COMMON_REQUEST } from './constants';
import request, { get } from 'utils/request';

function* searchCommpanyExactlyCommonRequestSaga(params) {
  const headers = authHeader();
  let requestURL = `${SERVICEURLS?.COMPANY}companies/${params?.payload}`;
  try {
    const datas = yield call(request, requestURL, {
      ...headers,
      isNotShowPopup: true,
    });
    yield put(searchCommpanyExactlyCommonSuccess(datas));
  } catch (error) {
    yield put(searchCommpanyExactlyCommonError(error));
  }
}
// Individual exports for testing
export default function* inputCompanySaga() {
  yield takeLatest(
    SEARCH_COMPANY_EXACTLY_COMMON_REQUEST,
    searchCommpanyExactlyCommonRequestSaga,
  );
}
