/*
 *
 * Mse003 actions
 * Author: Locnt
 */

import {
  GET_LIST_MASTER_COMPANY,
  LOAD_REPOS_SUCCESS,
  LOAD_REPOS_ERROR,
  RESET_LIST_GROUP_COMPANY
} from './constants';

export function resetCompany(){
  return {
    type: RESET_LIST_GROUP_COMPANY,
  }
}

export function getListMasterCompany(params) {
  return {
    type: GET_LIST_MASTER_COMPANY,
    params,
  };
}

export function reposLoaded(repos) {
  return {
    type: LOAD_REPOS_SUCCESS,
    repos,
  };
}

export function repoLoadingError(error) {
  return {
    type: LOAD_REPOS_ERROR,
    error,
  };
}
