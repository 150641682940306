import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const LoadingIndicator = ({ className }) => (
  <div className={className}>
    <div className="sp sp-slices" />
  </div>
);
LoadingIndicator.propTypes = {
  className: PropTypes.string,
};
LoadingIndicator.defaultProps = {
  className: 'overlay-loading',
};
export default LoadingIndicator;
