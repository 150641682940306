import * as Yup from 'yup';
import ConstantRegex from '../constantRegex';

export const validateUserId = Yup.string()
  .nullable()
  .required('必須項目です。　ユーザーID')
  .max(4, '入力文字数が範囲外です。　ユーザーID')
  .matches(ConstantRegex.regexHalfSize, '半角数値で入力してください。　ユーザーID');

export const validatePasswordLogin = Yup.string()
  .nullable()
  .required('必須項目です。　パスワード')
  .min(4, 'パスワードの長さが不正です。')
  .max(20, 'パスワードの長さが不正です。');
