/**
 *
 * ButtonClose
 * Author: LanVt
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Modal from '../Modal';
import ENUMS from '../../config/enum';
import './index.css';

function ButtonClose({
  className,
  messContent,
  isI18n,
  keyMessList,
  funcOk,
  buttonName,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOk = () => {
    open('about:blank', '_self').close();
  };
  const handleHide = () => {
    setIsOpen(false);
  };
  return (
    <div className={className}>
      {isOpen && (
        <Modal
          id="modal-root"
          handleHide={handleHide}
          handleOK={funcOk || handleClickOk}
          type={ENUMS?.TYPE_MODAL?.CONFIRM}
          messContent={messContent || 'messQ0000008'}
          isI18n={isI18n}
          keyMessList={keyMessList}
        />
      )}
      <Button
        className="js-close"
        onClick={() => {
          setIsOpen(true);
        }}
        buttonName={buttonName}
      />
    </div>
  );
}

ButtonClose.propTypes = {
  className: PropTypes.string,
  funcOk: PropTypes.func,
  buttonName: PropTypes.string,
};

ButtonClose.defaultProps = {
  buttonName: 'buttonClose',
};

export default ButtonClose;
