import {
  validateEmployeeNumber,
  validateCompanyCode,
  validateFullName002,
  validateFullNameKana002,
  validateOldName002,
  validateBirthdayDatePicker,
  validateContractTypeMen004,
  validateContentReportMen004,
  validateDocumentStoreMen004,
  validateGender002,
  validateMarryStatusMen004,
  validateDateJoinGroupMen004,
  validateLabelFullName,
  validateDateJoinCompanyMen004,
  validateRetirementDateMen004,
  validateDateLeaveGroupMen004
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';

export const validateMEN004 = (obj, fieldValidate) => {
  //men004
  if (fieldValidate?.includes('employeeNumber')) {
    obj.employeeNumber = validateEmployeeNumber;
  }
  if (fieldValidate?.includes('kaisha_cd')) {
    obj.kaisha_cd = validateCompanyCode;
  }
  if (fieldValidate?.includes('fullName')) {
    obj.fullName = validateFullName002;
  }
  if (fieldValidate?.includes('fullNameKana')) {
    obj.fullNameKana = validateFullNameKana002;
  }
  if (fieldValidate?.includes('oldName')) {
    obj.oldName = validateOldName002;
  }
  if (fieldValidate?.includes('gender')) {
    obj.gender = validateGender002;
  }
  if (fieldValidate?.includes('birthdayDatePicker')) {
    obj.birthdayDatePicker = validateBirthdayDatePicker;
  }
  if (fieldValidate?.includes('dateJoinGroupMen004')) {
    obj.dateJoinGroupMen004 = validateDateJoinGroupMen004;
  }
  if (fieldValidate?.includes('dateJoinCompanyMen004')) {
    obj.dateJoinCompanyMen004 = validateDateJoinCompanyMen004;
  }
  if (fieldValidate?.includes('retirementDateMen004')) {
    obj.retirementDateMen004 = validateRetirementDateMen004;
  }
  if (fieldValidate?.includes('dateLeaveGroupMen004')) {
    obj.dateLeaveGroupMen004 = validateDateLeaveGroupMen004;
  }
  if (fieldValidate?.includes('contractType')) {
    obj.contractType = validateContractTypeMen004;
  }
  if (fieldValidate?.includes('contentReport')) {
    obj.contentReport = validateContentReportMen004;
  }
  if (fieldValidate?.includes('documentStore')) {
    obj.documentStore = validateDocumentStoreMen004;
  }
  if (fieldValidate?.includes('marryStatus')) {
    obj.marryStatus = validateMarryStatusMen004;
  }

  return obj;
};
