import commonValidateMethod from 'helper/form/validateCommonMethod';
import {
  validateDateOfPayment,
  validateYearMonthPer03,
  validateInvoiceDatePer03,
  validateYearMonthPopupPer03,
  validateInvoiceDatePopupPer03
} from './validateCommon';

export const validatePer003 = (obj, fieldValidate, screenCode) => {
  if (fieldValidate?.includes('dateOfPayment')) {
    obj.dateOfPayment = validateDateOfPayment;
  }
  if (fieldValidate?.includes('yearMonthPer03')) {
    obj.yearMonthPer03 = validateYearMonthPer03;
  }
  if (fieldValidate?.includes('invoiceDatePer03')) {
    obj.invoiceDatePer03 = validateInvoiceDatePer03;
  }
  if (fieldValidate?.includes('yearMonthPopupPer03')) {
    obj.yearMonthPopupPer03 = validateYearMonthPopupPer03;
  }
  if (fieldValidate?.includes('invoiceDatePopupPer03')) {
    obj.invoiceDatePopupPer03 = validateInvoiceDatePopupPer03;
  }

  return obj;
};
