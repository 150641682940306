import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../Button';

function ShowMesError({ handleOK, messContent, keyMessList, isShow }) {
  return (
    <div className="overlay">
      <div className="modal-confirm">
        {/* <a href="#" title="Close" className="modal-close" onClick={handleHide}>
        X
      </a> */}
        <p
          style={{ maxHeight: 300, overflow: 'auto' }}
          className="title-confirm"
          dangerouslySetInnerHTML={{ __html: messContent }}
        >
          {/* <FormattedMessage {...messages ?.[messContent]} /> */}
        </p>
        {isShow && (
          <div className="list-btn btn-modal">
            <Button
              id={'confirm-button'}
              onClick={handleOK}
              className="button "
              buttonName="buttonConfirm"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ShowMesError.propTypes = {
  messContent: PropTypes.string,
  handleOK: PropTypes.func,
  isShow: PropTypes.bool,
};

ShowMesError.defaultProps = {
  isShow: true,
  handleOK: () => {},
};

export default ShowMesError;
