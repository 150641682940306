/*
 *
 * Sea001 reducer
 * Author: LanVt
 */
import produce from 'immer';
import { isEmpty } from 'utils/common/ObjectUtils';
import {
  SEARCH_EMPLOYEE_CODE_REQUEST,
  SEARCH_EMPLOYEE_CODE_SUCCESS,
  SEARCH_EMPLOYEE_CODE_ERROR,
  RESET_DATA,
} from './constants';

export const initialState = {
  error: {},
  dataSearch: [],
  isLoadingSearch: false,
  messSearch: '',
};

/* eslint-disable default-case, no-param-reassign */
const sea001Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_DATA:
        draft.dataSearch = [];
        draft.error = {};
        draft.isLoadingSearch = false;
        draft.messSearch = '';
        break;
      case SEARCH_EMPLOYEE_CODE_REQUEST:
        draft.dataSearch = [];
        draft.error = {};
        draft.isLoadingSearch = true;
        draft.messSearch = '';
        break;
      case SEARCH_EMPLOYEE_CODE_SUCCESS:
        draft.dataSearch = action?.params?.data?.employees;
        draft.error = {};
        draft.isLoadingSearch = false;
        draft.messSearch = action?.params?.data?.message;
        break;
      case SEARCH_EMPLOYEE_CODE_ERROR:
        draft.dataSearch = {};
        draft.error = action;
        draft.isLoadingSearch = false;
        draft.messSearch = '';
        break;
    }
  });
export default sea001Reducer;
