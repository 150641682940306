import {
  validateUserIdMen005,
  validateFullName,
  validateEmail,
  validateGyomuPermission,
  validateSystemPermissions,
  validateShoinPathFileName,
} from './validateCommon';
import validateMethod from './validateMethod';

export const validateMEN005 = (obj, fieldValidate) => {
  // validate men005
  if (fieldValidate?.includes('user_id_men005')) {
    obj.user_id_men005 = validateUserIdMen005;
  }

  if (fieldValidate?.includes('full_name_men005')) {
    obj.full_name_men005 = validateFullName;
  }

  if (fieldValidate?.includes('email_address_men005')) {
    obj.email_address_men005 = validateEmail;
  }

  if (fieldValidate?.includes('gyomu_kengen_cd_men005')) {
    obj.gyomu_kengen_cd_men005 = validateGyomuPermission;
  }
  if (fieldValidate?.includes('system_kengen_cd_men005')) {
    obj.system_kengen_cd_men005 = validateSystemPermissions;
  }

  if (fieldValidate?.includes('shoin_path_file_name_men005')) {
    obj.shoin_path_file_name_men005 = validateShoinPathFileName;
  }

  return obj;
};
