import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
import ConstReg from '../../constantRegex';
import commonValidateMethodPer from '../validateCommonMethod';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import isValid from "date-fns/isValid";
export const validateDateOfPayment = Yup.string()
  .nullable()
  .checkDateOfPaymentPer();

export const validateYearMonthPer03 = Yup.string()
  .nullable()
  //.required(getMessValidate('C0000001', ['年月']))
  //.max(7, getMessValidate('C0000007', ['年月']))
  // .matches(ConstReg.regTimeYearMonth, getMessValidate('C0000004', ['年月']));
  .test(
    'is-empty-date-men007',
    getMessValidate('C0000001', ['年月']),
    value => {
      return isEmptyAndBlank(value) ? false : true;
    },
  )
  .test(
    'is-max-length-date-men007',
    getMessValidate('C0000007', ['年月']),
    value => {
      return value?.length < 8 ? true : false;
    },
  )
  .test(
    'is-in-valid-date-men007',
    getMessValidate('C0000004', ['年月']),
    value => {
      const isValidDate = ConstReg.regYearAndMonth.test(value);
      if (isValidDate) {
        try {
          const arrDate = value?.split('/');
          if (!isValid(new Date(value))) {
            return false;
          }
        } catch (e) {
          console.log(e, '----e----');
        }
      }
      return isValidDate;
    },
  );

export const validateYearMonthPopupPer03 = Yup.string()
  .nullable()
  // .required(getMessValidate('C0000001', ['年月']))
  // .max(7, getMessValidate('C0000007', ['年月']))
  // .matches(ConstReg.regTimeYearMonth, getMessValidate('C0000004', ['年月']));
  .test(
    'is-empty-date-men007',
    getMessValidate('C0000001', ['年月']),
    value => {
      return isEmptyAndBlank(value) ? false : true;
    },
  )
  .test(
    'is-max-length-date-men007',
    getMessValidate('C0000007', ['年月']),
    value => {
      return value?.length < 8 ? true : false;
    },
  )
  .test(
    'is-in-valid-date-men007',
    getMessValidate('C0000004', ['年月']),
    value => {
      const isValidDate = ConstReg.regYearAndMonth.test(value);
      if (isValidDate) {
        try {
          const arrDate = value?.split('/');
          if (!isValid(new Date(value))) {
            return false;
          }
        } catch (e) {
          console.log(e, '----e----');
        }
      }
      return isValidDate;
    },
  );

export const validateInvoiceDatePer03 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['請求書発行日']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateInvoiceDatePopupPer03 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['請求書発行日']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );
