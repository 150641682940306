import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the sea001 state domain
 */

const selectInputCompanyDomain = state => state.inputCompany || initialState;
/**
 * Other specific selectors
 */

/**
 * Default selector used by InputCompany
 */

const makeSelectInputCompany = () =>
  createSelector(
    selectInputCompanyDomain,
    substate => substate,
  );
export default makeSelectInputCompany;
