/**
 *
 * InputCompany
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputLabel from 'components/InputLabel';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import Loading from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import reducer from './reducer';
import saga from './saga';
import makeSelectInputCompany from './selectors';
import { searchCommpanyExactlyCommonRequest } from './actions';
import { clearCompany, returnMessError } from './utils';
import { getMessValidate } from 'helper/form/messValidate';
import ENUMS from 'config/enum';
import './index.scss';

function InputCompany({
  inputCompany,
  searchCommpanyExactlyCommon,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  isRequired,
  touched,
  parentValues,
  isReadonly,
  isDisable,
  errors,
  isFocus
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const itemDefaultCompany = {
    jugyoin_no: values?.jugyoin_no,
    kaisha_cd: values?.kaisha_cd,
    kaisha_name: '',
  };
  useInjectReducer({ key: 'inputCompany', reducer });
  useInjectSaga({ key: 'inputCompany', saga });
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const onClickSelectCompanyCode = (item, value) => {
    setFieldValue('kaisha_cd', item?.kaisha_cd);
    setFieldValue('kaisha_name', item?.kaisha_name);
    setIsPopupOpen(false);
  };
  const handleBlurCompanyCodeInput = e => {
    if (e.currentTarget?.value) {
      if (errors?.kaisha_cd) {
        setFieldValue('kaisha_name', '');
        setFieldValue('messErrCompany', '');
      } else {
        searchCommpanyExactlyCommon(e.currentTarget?.value);
      }
    } else {
      setFieldValue('kaisha_name', '');
      setFieldValue('messErrCompany', '');
    }
  };
  useEffect(() => {
    if (parentValues) {
      if (parentValues?.kaisha_cd) {
        setTimeout(() => {
          setFieldValue('kaisha_cd', parentValues?.kaisha_cd);
          setFieldValue('kaisha_name', parentValues?.kaisha_name);
        }, 0);
      }
    } else {
      setFieldValue('kaisha_name', '');
    }
  }, []);
  useEffect(() => {
    if (
      !inputCompany?.isLoadingSearchCompany &&
      inputCompany?.dataSearchCompany &&
      touched?.kaisha_cd
    ) {
      returnMessError(
        itemDefaultCompany,
        inputCompany?.dataSearchCompany,
        values?.kaisha_cd,
        getMessValidate('I0000020'),
        getMessValidate('C0000051'),
        'messErrCompany',
        setFieldValue,
      );
    }
  }, [inputCompany?.isLoadingSearchCompany]);

  const classNameInput = `input_10 ${
    isRequired ? 'autofill-transparent input_required ' : ''
  }`;
  return (
    <div className="row row2 non_bd mt-2 flex-start flex-grow">
      {/* {inputCompany?.isLoadingSearchCompany && <Loading />} */}
      {isPopupOpen && (
        <Modal
          id="modal-root"
          handleHide={closePopup}
          type={ENUMS?.TYPE_MODAL?.SEARCH}
          typeModal={ENUMS?.TYPE_MODAL_SEARCH?.SEA001}
          funcOnClick={onClickSelectCompanyCode}
        />
      )}
      <InputLabel
        className="left left2 d-f w-70 flex-start "
        classNameInput={classNameInput}
        classNameLabel="lbl"
        classNameLblSpan="text-link"
        values={values}
        handleChange={handleChange}
        handleBlur={e => {
          handleBlurCompanyCodeInput(e);
          handleBlur(e);
        }}
        keyMessJson="components.Label.label_company_code"
        name="kaisha_cd"
        isSubmitting={isSubmitting}
        isDisable={isDisable}
        isReadonly={isReadonly}
        isRequired={isRequired}
        touched={touched}
        autoFocus={isFocus}
        mess={errors?.kaisha_cd || ''}
        onClickLabel={e => {
          togglePopup();
        }}
      >
        <div className="ml-10 ">
          {values?.kaisha_name || values?.messErrCompany}
        </div>
      </InputLabel>
    </div>
  );
}
InputCompany.propTypes = {
  isRequired: PropTypes.bool,
  isDisable: PropTypes.bool,
  isReadonly: PropTypes.bool,
};

InputCompany.defaultProps = {
  isRequired: true,
  isDisable: false,
  isReadonly: false,
};
InputCompany.defaultProps = {
  funcSubmit: () => {},
  values: {},
};
const mapStateToProps = createStructuredSelector({
  inputCompany: makeSelectInputCompany(),
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    searchCommpanyExactlyCommon: param =>
      dispatch(searchCommpanyExactlyCommonRequest(param)),
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default compose(withConnect)(InputCompany);
