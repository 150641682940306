import * as Yup from 'yup';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import {
  getCheckArisingAfterDetermined,
  getCheckLeaveDateCompany,
  getMessageCheckAfterDay,
  getMessageCheckAfterJoinDate,
  getMessValidate,
} from 'helper/form/messValidate';
import { isValidateDate } from 'utils/common/DateUtils';
import differenceInDays from 'date-fns/differenceInDays';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';

const checkDateOfArisingBen006 = (
  refDeterminedDt,
  refJointDt,
  refLeaveDt,
  refLeaveDtCompany,
  refCodeScreen,
) => {
  return Yup.mixed().test({
    name: 'checkDateOfArisingBen006',
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      //Ngay phát sinh ＜ Man hinh. Ngay tham gia (画面.認定日＜ 画面.発生日)
      const messageCheckAfterJoinDate = getMessageCheckAfterJoinDate(
        valueCodeScreen,
      );
      // Ngay phat sinh sau ngay roi khoi cty
      const messageCheckLeaveDateCompany = getCheckLeaveDateCompany(
        valueCodeScreen,
      );

      // Ngày phát sinh sau ngày xác định
      const mesCheckArisingAfterDetermined = getCheckArisingAfterDetermined(
        valueCodeScreen,
      );

      // kiem tra date co hop le
      const msgCheckValidDate = getMessValidate('C0000004', ['発生日']);

      //kiem tra co nhap hay khong
      const msgRequired = getMessValidate('C0000001', ['発生日']);

      if (value) {
        // 1. required
        const isValidDate = isValidateDate(value);

        const dateLeaveDt = this.resolve(refLeaveDt);

        // (画面.認定日＜ 画面.発生日)
        const ischeckAfterJoinDate = isAfter(
          new Date(this.resolve(refJointDt)),
          new Date(value),
        );

        const dateLeaveDtCompany = this.resolve(refLeaveDtCompany);

        //Ngày phát sinh sau ngày xác định
        const isCheckArisingAfterDetermined = isAfter(
          new Date(value),
          new Date(this.resolve(refDeterminedDt)),
        );

        const ischeckLeaveDate = isAfter(
          new Date(value),
          new Date(dateLeaveDt),
        );
        const ischeckLeaveDateCompany = isAfter(
          new Date(value),
          new Date(dateLeaveDtCompany),
        );

        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else if (isCheckArisingAfterDetermined) {
          return this.createError({
            message: mesCheckArisingAfterDetermined,
          });
        } else if (ischeckAfterJoinDate) {
          return this.createError({
            message: messageCheckAfterJoinDate,
          });
        } else if (ischeckLeaveDate) {
          return this.createError({
            message: messageCheckAfterJoinDate,
          });
        } else if (ischeckLeaveDateCompany) {
          return this.createError({
            message: messageCheckLeaveDateCompany,
          });
        }
      } else {
        // 1
        return this.createError({
          message: msgRequired,
        });
      }

      return true;
    },
  });
};
const checkDateOfDeterminedBen006 = (
  refJointDt,
  refLeaveDt,
  refRequestDt,
  refCodeScreen,
) => {
  return Yup.mixed().test({
    name: 'checkDateOfDeterminedBen006',
    test: function(value) {
      // Ngày xác định là ngày sau ngay yêu cầu
      const messageCheckAfterJoinDate = getMessValidate('C0000061');
      // Ngày xác định là ngày ngoài thời hạn tham gia
      const messageCheckDateOfDeterminOutJointDt = getMessValidate('C0000060');
      // kiem tra date co hop le
      const msgCheckValidDate = getMessValidate('C0000004', ['認定日']);

      //kiem tra co nhap hay khong
      const msgRequired = getMessValidate('C0000001', ['認定日']);

      if (value) {
        // 1. required
        const isValidDate = isValidateDate(value);

        const dateLeaveDt = this.resolve(refLeaveDt);

        const ischeckAfterDay = isAfter(
          new Date(value),
          new Date(this.resolve(refRequestDt)),
        );

        const ischeckAfterJoinDate = isAfter(
          new Date(this.resolve(refJointDt)),
          new Date(value),
        );

        const ischeckLeaveDate = isAfter(
          new Date(value),
          new Date(dateLeaveDt),
        );

        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else if (ischeckAfterJoinDate) {
          return this.createError({
            message: messageCheckDateOfDeterminOutJointDt,
          });
        } else if (ischeckAfterDay) {
          return this.createError({
            message: messageCheckAfterJoinDate,
          });
        } else if (ischeckLeaveDate) {
          return this.createError({
            message: messageCheckDateOfDeterminOutJointDt,
          });
        }
      } else {
        // 1
        return this.createError({
          message: msgRequired,
        });
      }

      return true;
    },
  });
};
const checkRequestDayBen006 = (
  ref,
  refCodeScreen,
  // msg1,
  msg2,
  msgCheckValidDate,
  msgRequired,
) => {
  return Yup.mixed().test({
    name: 'checkRequestDayBen006',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      const msg1 = getMessageCheckAfterDay(valueCodeScreen);

      if (!isEmptyAndBlank(value)) {
        const isValidDate = isValidateDate(value);
        const ischeckAfterDay = isAfter(
          new Date(this.resolve(ref)),
          new Date(value),
        );
        const isFut = isFuture(new Date(value));
        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else if (ischeckAfterDay) {
          return this.createError({
            message: msg1,
          });
        } else if (isFut) {
          return this.createError({
            message: msg2,
          });
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

Yup.addMethod(Yup.string, 'checkDateOfArisingBen006', checkDateOfArisingBen006);
Yup.addMethod(
  Yup.string,
  'checkDateOfDeterminedBen006',
  checkDateOfDeterminedBen006,
);
Yup.addMethod(Yup.string, 'checkRequestDayBen006', checkRequestDayBen006);
