import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { initialState as com002 } from '../../../../containers/Com002/reducer';

/**
 * Direct selector to the sea001 state domain
 */

const selectSea001Domain = state => state.sea001 || initialState;
const selectListGroupCompanys = state => {
  return state?.com002?.groupCompany || com002?.groupCompany;
};
const searchCompanys = state => {
  return state?.sea001?.dataSearch || initialState?.dataSearch;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Sea001
 */

const makeSelectSea001 = () =>
  createSelector(
    selectSea001Domain,
    substate => substate,
  );
const makeSelectListGroupCompanys = () =>
  createSelector(
    selectListGroupCompanys,
    substate => substate,
  );
const makeSelectSearchCompanys = () =>
  createSelector(
    searchCompanys,
    substate => substate,
  );

export default makeSelectSea001;
export {
  selectSea001Domain,
  makeSelectListGroupCompanys,
  makeSelectSearchCompanys,
};
