import * as Yup from 'yup';
import { getMessValidate } from '../messValidate';
import ConstRegx from '../constantRegex';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import isAfter from 'date-fns/isAfter';
//men003
export const validateCompanyIdMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['会社コード']))
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regexHalfSize,
    getMessValidate('C0000006', ['会社コード']),
  );

export const validateSortMen003 = Yup.string().checkWithRegx(
  'ソート順',
  ConstRegx.regexHalfSize,
  'C0000006',
  4,
  false,
);
// .max(4, getMessValidate('C0000007', ['ソート順']))
// .matches(ConstRegx.regexHalfSize, getMessValidate('C0000006', ['ソート順']));

export const validateCompanyNameMen003 = Yup.string()
  .nullable()
  .checkEmptyString('会社名', 35, true);
// .required(getMessValidate('C0000001', ['会社名']))
// .max(100, getMessValidate('C0000007', ['会社名']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['会社名']));

export const validateCompanyNameKanaMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['会社名カナ']))
  .max(100, getMessValidate('C0000007', ['会社名カナ']))
  .matches(
    ConstRegx.regexFullSizeKatakana,
    getMessValidate('C0000002', ['会社名カナ']),
  );
// .checkWithRegx(
//   '会社名カナ',
//   ConstRegx.regexFullSizeKatakana,
//   'C0000002',
//   100,
//   true,
// );

export const validateSortCompanyNameMen003 = Yup.string()
  .nullable()
  .checkEmptyString('会社略称', 30, true);
// .required(getMessValidate('C0000001', ['会社略称']))
// .max(30, getMessValidate('C0000007', ['会社略称']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['会社略称']));

const checkPostalCodemen003 = () => {
  return Yup.mixed().test({
    name: 'checkPostalCodemen003',
    test: function(value) {
      const flagIsNumber = ConstRegx.regxNumberOnly.test(value);
      if (isEmptyAndBlank(value)) {
        return this.createError({
          message: getMessValidate('C0000001', ['郵便番号']),
        });
      }
      if (value.length < 7 && !flagIsNumber) {
        return this.createError({
          message: getMessValidate('C0000006', ['郵便番号']),
        });
      }
      if (value.length < 7 || value.length > 8) {
        return this.createError({
          message: getMessValidate('C0000007', ['郵便番号']),
        });
      }
      if (!value.match(ConstRegx.regexPortalCode)) {
        return this.createError({
          message: getMessValidate('C0000006', ['郵便番号']),
        });
      }
      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkPostalCodemen003', checkPostalCodemen003);

export const validatePostalCodeMen003 = Yup.string()
  .nullable()
  .checkPostalCodemen003();

export const validateAddressMen003 = Yup.string()
  .nullable()
  .checkEmptyString('住所', 100, true);
// .required(getMessValidate('C0000001', ['住所']))
// .max(100, getMessValidate('C0000007', ['住所']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['住所']));

export const validateTelMen003 = Yup.string()
  .nullable()
  .checkWithRegx('TEL', ConstRegx.regexTel, 'C0000129', 15, true);
// .required(getMessValidate('C0000001', ['TEL']))
// .max(15, getMessValidate('C0000007', ['TEL']))
// .matches(ConstRegx.regexTel, getMessValidate('C0000129', ['TEL']));

export const validateFaxMen003 = Yup.string()
  .nullable()
  //.required(getMessValidate('C0000001', ['FAX']))
  .checkWithRegx('FAX', ConstRegx.regexTel, 'C0000129', 15, false);
// .max(15, getMessValidate('C0000007', ['FAX']))
// .matches(ConstRegx.regexTel, getMessValidate('C0000129', ['FAX']));

export const validateRepresentativePositionMen003 = Yup.string()
  .nullable()
  .checkEmptyString('代表者肩書', 40, true);
// .required(getMessValidate('C0000001', ['代表者肩書']))
// .max(40, getMessValidate('C0000007', ['代表者肩書']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['代表者肩書']));

export const validateRepresentativeNameMen003 = Yup.string()
  .nullable()
  .checkEmptyString('代表者氏名', 40, true);
// .required(getMessValidate('C0000001', ['代表者氏名']))
// .max(40, getMessValidate('C0000007', ['代表者氏名']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['代表者氏名']));

export const validateBillContactNameMen003 = Yup.string()
  .nullable()
  .max(60, getMessValidate('C0000007', ['担当者氏名(請求書用)']));
// .matches(
//   ConstRegx.regxKanji,
//   getMessValidate('C0000018', ['担当者氏名(請求書用)']),
// );

export const validateContactNameMen003 = Yup.string()
  .nullable()
  .max(60, getMessValidate('C0000007', ['担当者氏名']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['担当者氏名']));

export const validateContactTelMen003 = Yup.string()
  .nullable()
  .checkWithRegx('担当者TEL', ConstRegx.regexTel, 'C0000129', 15, false);
// .max(15, getMessValidate('C0000007', ['担当者TEL']))
// .matches(ConstRegx.regexTel, getMessValidate('C0000129', ['担当者TEL']));

export const validateContactFaxMen003 = Yup.string()
  .nullable()
  .checkWithRegx('担当者FAX', ConstRegx.regexTel, 'C0000129', 15, false);
// .max(15, getMessValidate('C0000007', ['担当者FAX']))
// .matches(ConstRegx.regexTel, getMessValidate('C0000129', ['担当者FAX']));

export const validateEmailMen003 = Yup.string()
  .nullable()
  .checkWithRegx(
    'メールアドレス',
    ConstRegx.regCharacterAndNumberHalfSize,
    'C0000128',
    100,
  );
// .max(100, getMessValidate('C0000007', ['メールアドレス']))
// .matches(
//   ConstRegx.regCharacterAndNumberHalfSize,
//   getMessValidate('C0000002', ['メールアドレス']),
// );

export const validateBankNameMen003 = Yup.string()
  .nullable()
  .checkEmptyString('銀行名', 100, true);
// .required(getMessValidate('C0000001', ['銀行名']))
// .max(100, getMessValidate('C0000007', ['銀行名']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['銀行']));

export const validateBankCodeMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['銀行コード']))
  .matches(ConstRegx.regexHalfSize, getMessValidate('C0000006', ['銀行コード']))
  .min(4, getMessValidate('C0000007', ['銀行コード']))
  .max(4, getMessValidate('C0000007', ['銀行コード']));
// .checkWithRegx('銀行コード', ConstRegx.regexHalfSize, 'C0000006', 4, true, 4);

export const validateBranchNameMen003 = Yup.string()
  .nullable()
  .checkEmptyString('支店名', 100, true);
// .required(getMessValidate('C0000001', ['支店名']))
// .max(100, getMessValidate('C0000007', ['支店名']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['支店']));

export const validateBranchCodeMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['支店 ｺｰﾄﾞ']))
  .matches(ConstRegx.regexHalfSize, getMessValidate('C0000006', ['支店 ｺｰﾄﾞ']))
  .min(3, getMessValidate('C0000007', ['支店 ｺｰﾄﾞ']))
  .max(3, getMessValidate('C0000007', ['支店 ｺｰﾄﾞ']));
// .checkWithRegx('支店 ｺｰﾄﾞ', ConstRegx.regexHalfSize, 'C0000006', 3, true, 3);

//validate acctype
// export const validateAccountTypeMen003 = Yup.string()
//   .nullable()
//   .required(getInfoMessage(Const.accountTypeMen003, Const.isRequire));

export const validateAccountTypeMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['口座種別']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateAccountNoMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['口座番号']))
  .max(7, getMessValidate('C0000007', ['口座番号']))
  .matches(ConstRegx.regexHalfSize, getMessValidate('C0000006', ['口座番号']));
// .checkWithRegx('口座番号', ConstRegx.regexHalfSize, 'C0000006', 7, true);

export const validateAccountHolderNameKanjiMen003 = Yup.string()
  .nullable()
  .max(20, getMessValidate('C0000007', ['口座名義人']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['口座名義人']));

export const validateAccountHolderNameKanaMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['口座名義人ｶﾅ']))
  .max(30, getMessValidate('C0000007', ['口座名義人ｶﾅ']))
  .matches(
    ConstRegx.regexHalfSizeKatakana,
    getMessValidate('C0000026', ['口座名義人ｶﾅ']),
  );
// .checkWithRegx(
//   '口座名義人ｶﾅ',
//   ConstRegx.regexHalfSizeKatakana,
//   'C0000006',
//   30,
//   true,
// );

export const validateJoinDtMen003 = Yup.string()
  .nullable()
  // .required(getMessValidate('C0000001', ['加入日']))
  // .max(10, getMessValidate('C0000007', ['加入日']))
  //.matches(ConstRegx.regDateTime, getMessValidate('C0000004', ['加入日']))
  .test(
    'is-empty-date-men003',
    getMessValidate('C0000001', ['加入日']),
    value => {
      return isEmptyAndBlank(value) ? false : true;
    },
  )
  .test(
    'is-in-valid-date-men003',
    getMessValidate('C0000004', ['加入日']),
    value => {
      const isValidDate = isValidateDate(value);
      return !isValidDate ? false : true;
    },
  );

const checkLeaveDateIsBeforJoinDateMen003 = refJoinDtMen003 => {
  return Yup.mixed().test({
    name: 'checkLeaveDateIsBeforJoinDateMen003',
    test: function(value) {
      const jointGroup = this.resolve(refJoinDtMen003);
      const isBeforJoinDate = isAfter(new Date(jointGroup), new Date(value));
      const isValidDate = isValidateDate(value);
      if (value && !isValidDate) {
        return this.createError({
          message: getMessValidate('C0000004', ['脱会日']),
        });
      }
      if (isBeforJoinDate) {
        return this.createError({
          message: getMessValidate('C0000045', ['脱会日']),
        });
      }

      return true;
    },
  });
};
Yup.addMethod(
  Yup.string,
  'checkLeaveDateIsBeforJoinDateMen003',
  checkLeaveDateIsBeforJoinDateMen003,
);

export const validateLeavingDtMen003 = Yup.string()
  .nullable()
  .checkLeaveDateIsBeforJoinDateMen003(Yup.ref('joinDtMen003'));

export const validateCompanyGroupNameMen003 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['会社グループ']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateKyosaiTypeCdMen003 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['共済タイプ']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateRequestClassifyMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['申請区分']), value => {
    return !value || value === '0' ? false : true;
  });

export const validatePaymentMethodMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['支払方法']), value => {
    return !value || value === '0' ? false : true;
  });

export const validatePaymentTermsMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['支払条件']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateShiharaijokenShimebiMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['支払条件']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateShiharaijokenShiharaibiMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['支払条件']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateRecoveryMethodMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['回収方法']), value => {
    return !value || value === '0' ? false : true;
  });

export const validatePaymentCalClassifyMen003 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['掛金算出区分']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateBillingPeriodMen003 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['請求対象期間']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateSeikyutaishokikanKaishigetsuMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['月開始']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateBillIssueDtMen003 = Yup.string()
  .nullable()
  .test(
    'is-empty-select',
    getMessValidate('C0000001', ['請求書発行日']),
    value => {
      return !value || value === '0' ? false : true;
    },
  );

export const validateKaishujokenNyukinSiteMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['回収条件']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateKaishujokenNyukimbiMen003 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['日入金']), value => {
    return !value || value === '0' ? false : true;
  });

// export const validateKeihiShiharaijokenShimebiMen003 = Yup.string()
//   .nullable()
//   .test(
//     'is-empty-select',
//     getMessValidate('C0000001', ['経費支払条件']),
//     value => {
//       return !value || value === '0' ? false : true;
//     },
//   );

// export const validateKeihiShiharaijokenShiharaiSiteMen003 = Yup.string()
//   .nullable()
//   .test(
//     'is-empty-select',
//     getMessValidate('C0000001', ['経費支払条件']),
//     value => {
//       return !value || value === '0' ? false : true;
//     },
//   );

// export const validateKeihiShiharaijokenShiharaibiMen003 = Yup.string()
//   .nullable()
//   .test(
//     'is-empty-select',
//     getMessValidate('C0000001', ['経費支払条件']),
//     value => {
//       return !value || value === '0' ? false : true;
//     },
//   );

export const validateNoteMen003 = Yup.string()
  .nullable()
  .max(800, getMessValidate('C0000007', ['備考']));
// .matches(ConstRegx.regxKanji, getMessValidate('C0000018', ['備考']));

// export const validateCompanyGroupNameMen003 = Yup.string()
// .nullable()
// .required(getInfoMessage(Const.leavingDtMen003, Const.isRequire));
