/*
 * ModalConfirm Messages
 *
 * This contains all the text for the ModalConfirm component.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.Modal';

export default defineMessages({
  messConfirmRegisterOkMen002: {
    id: `${scope}.message.mess_confirm_register_ok_men002`,
    defaultMessage: 'confirm register ok men002',
  },

  messConfirmRegisterOk: {
    id: `${scope}.message.mess_confirm_register_ok`,
    defaultMessage: 'confirm register ok',
  },

  messConfirmCancel: {
    id: `${scope}.message.mess_confirm_cancel`,
    defaultMessage: 'mess Confirm Cancel',
  },

  messConfirmLogout: {
    id: `${scope}.message.Q0000016`,
    defaultMessage: 'confirm logout',
  },

  messRegisterMen004: {
    id: `${scope}.message.mess_register_men004`,
    defaultMessage: 'register men004',
  },

  messCancelMen004: {
    id: `${scope}.message.mess_cancel_men004`,
    defaultMessage: 'cancel men004',
  },

  messSystemError: {
    id: `${scope}.message.mess_sys_err`,
    defaultMessage: 'sys err',
  },
  messQ0000008: {
    id: `${scope}.message.Q0000008`,
    defaultMessage: 'close screen',
  },
  messBack: {
    id: `${scope}.message.mess_back`,
    defaultMessage: 'back screen',
  },
  netWork: {
    id: `${scope}.netWork`,
    defaultMessage: 'netWork',
  },
  discardsData: {
    id: `${scope}.discardsData`,
    defaultMessage: 'discardsData',
  },
  mesResetPass: {
    id: `${scope}.mesResetPass`,
    defaultMessage: 'mesResetPass',
  },

  messSaveViewPer003: {
    id: `${scope}.mess_save_view_per003`,
    defaultMessage: 'mess Save View Per003',
  },

  messHandleInvoiceCreationPer003: {
    id: `${scope}.mess_handle_invoice_creation_per003`,
    defaultMessage: 'messHandleInvoiceCreationPer003',
  },

  messHandleInvoiceCreationNo5Per003: {
    id: `${scope}.mess_handle_invoice_creation_no5_per003`,
    defaultMessage: 'messHandleInvoiceCreationNo5Per003',
  },

  messConfirmSubsidyDetails: {
    id: `${scope}.message.mess_confirm_subsidy_details`,
    defaultMessage: 'mess_confirm_subsidy_details',
  },

  messHandleConfirmInvoiceCreationNo6Per003: {
    id: `${scope}.mess_handle_confirm_invoice_creation_no6_per003`,
    defaultMessage: 'mess handle confirm invoice creation no6 per003',
  },

  messQ0000039: {
    id: `${scope}.message.Q0000039`,
    defaultMessage: 'close screen',
  },

  messHandleImportMen007: {
    id: `${scope}.message.mess_handle_import_men007`,
    defaultMessage: 'mess handle import men007',
  }
});
