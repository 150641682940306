import ROUTERS from './routes';
import ENUMS from './enum';
const category = [
  { value: 1, keyMess: 'weddingGift' },
  { value: 2, keyMess: 'maternityGift' },
  { value: 3, keyMess: 'familyCondolence' },
  { value: 4, keyMess: 'princessCondolence' },
  { value: 5, keyMess: 'deathCompensation' },
  { value: 6, keyMess: 'severalDisabilityCompensation' },
  { value: 7, keyMess: 'sicknessPayment' },
  { value: 8, keyMess: 'admissionCompensation' },
  { value: 9, keyMess: 'hospitalCompensation' },
  { value: 10, keyMess: 'differenceBedCharge' },
  { value: 11, keyMess: 'disasterDonation' },
  { value: 12, keyMess: 'theftMoney' },
  { value: 13, keyMess: 'spouseMedicalExamSubsidy' },
  { value: 14, keyMess: 'unsubFee' },
];

const inforLocalStorage = {
  authority_links: 'authority_links',
  benefit_management: 'benefit_management',
  benefit_master: 'benefit_master',
  docs_extract: 'docs_extract',
  group_company: 'group_company',
  kyosai: 'kyosai',
  messages: 'messages',
  systems: 'systems',
  token: 'token',
  user: 'user',
  relationships: 'relationships',
  code_master: 'code_master',
  linked_file_management: 'linked_file_management',
  coordination_item_management: 'coordination_item_management',
};

const flagCheckBox = {
  true: 1,
  false: 0,
};

const typeScreen = {
  new: 'NEW',
  edit: 'EDIT',
  display: 'DISPLAY',
};

const months = '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
  '_',
);
const monthsShort = '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
  '_',
);
const weekdays = '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_');
const weekdaysShort = '日_月_火_水_木_金_土'.split('_');
const weekdaysMin = '日_月_火_水_木_金_土'.split('_');
const longDateFormat = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'yyyy/MM/dd',
  LF: 'yyyy/MM/dd HH:mm',
  LFS: 'yyyy/MM/dd HH:mm:ss',
  LL: 'YYYY年M月D日',
  LLL: 'YYYY年M月D日 HH:mm',
  LLLL: 'YYYY年M月D日 dddd HH:mm',
  l: 'YYYY/MM/DD',
  ll: 'YYYY年M月D日',
  lll: 'YYYY年M月D日 HH:mm',
  llll: 'YYYY年M月D日(ddd) HH:mm',
  yearMonth: 'yyyy/MM',
  yearMonthString: 'yyyy年MM月',
  monthDayString: 'MM/dd',
  LDB: 'yyyy-MM-dd',
};
const dataSubMenuAnnualProcess = [
  { link: ROUTERS?.PER001, keyName: 'buttonMutualAidBenefitPaymentList' },
  { link: ROUTERS?.PER002, keyName: 'buttonMonthlyBenefitSummaryTable' },
  // { link: ROUTERS?.HOME, keyName: 'buttonLedgerLedger' },
  { link: ROUTERS?.PER003, keyName: 'buttonMutualAidClaimManagement' },
  {
    link: ROUTERS?.PER005,
    keyName: 'buttonSubscriptionUnderwritingAgeNotification',
  },
  { link: ROUTERS?.PER006, keyName: 'buttonMemberRegistrationRegister' },
  { link: ROUTERS?.IKU003, keyName: 'buttonOrphanagePension' },
];
const dataSubMenuMasterMaintenance = [
  { link: ROUTERS.MSE001, keyName: 'buttonMutualAidTypeMaster' },
  { link: ROUTERS.MSE002, keyName: 'buttonCompanyGroupMaster' },
  { link: ROUTERS.MSE003, keyName: 'buttonCompanyMaster' },
  { link: ROUTERS.MSE004, keyName: 'buttonSubscriberMaster' },
  { link: ROUTERS?.MSE005, keyName: 'buttonUserMaster' },
  { link: ROUTERS?.MSE006, keyName: 'buttonSubmissionMaster' },
  { link: ROUTERS?.MEN007, keyName: 'buttonSubscriberMasterEXCEL' },
];
const screenCodeBen = {
  BEN002: '0010',
  BEN003: '0020',
  BEN004: '0110',
  BEN005_ARR: ['0120', '0130'],
  BEN006: '0210',
  BEN007: '0320',
  BEN007_ARR: ['0310', '0320', '0330', '0340'],
  BEN008: '0410',
  BEN009: '0510',
  BEN010: '0520',
  BEN011: '0910',
  BEN012: 'BEN012',
};

const screenCodeAll = {
  MEN001: 'MEN001',
  MEN002: 'MEN002',
  MEN003: 'MEN003',
  MEN004: 'MEN004',
  MEN005: 'MEN005',
  MEN006: 'MEN006',
  COM003: 'COM003',
  COM002: 'COM002',
  BEN007: 'BEN007',
  BEN006: 'BEN006',
  IMP: 'IMP',
  BEN005: 'BEN005',
  BEN008: 'BEN008',
  BEN011: 'BEN011',
  APA: 'APA',
  PER: 'PER',
  SEA: 'SEA',
  PER003: 'PER003',
  PER004: 'PER004',
  PER005: 'PER005',
  PER006: 'PER006',
};
const typeFormBenefit = {
  '0010': {
    nameFlagTypeBenefit: 'marriage_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.weddingGift,
    form: 'BEN002',
  },
  '0020': {
    nameFlagTypeBenefit: 'maternity_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.maternityGift,
    form: 'BEN003',
  },
  '0110': {
    nameFlagTypeBenefit: 'family_condolence_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.familyCondolence,
    form: 'BEN004',
  },
  '0120': {
    nameFlagTypeBenefit: 'self_condolence_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.selfCondolence,
    form: 'BEN005',
  },
  '0130': {
    nameFlagTypeBenefit: 'death_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.deathCompensation,
    form: 'BEN005',
  },
  '0210': {
    nameFlagTypeBenefit: 'disability_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.severalDisabilityCompensation,
    form: 'BEN006',
  },
  '0310': {
    nameFlagTypeBenefit: 'sick_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.sicknessPayment,
    form: 'BEN007',
  },
  '0320': {
    nameFlagTypeBenefit: 'admission_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.admissionCompensation,
    form: 'BEN007',
  },
  '0330': {
    nameFlagTypeBenefit: 'hospitalize_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.hospitalCompensation,
    form: 'BEN007',
  },
  '0340': {
    nameFlagTypeBenefit: 'bed_charge_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.differenceBedCharge,
    form: 'BEN007',
  },
  '0410': {
    nameFlagTypeBenefit: 'spouse_medical_exam_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.spouseMedicalExamSubsidy,
    form: 'BEN008',
  },
  '0510': {
    nameFlagTypeBenefit: 'disaster_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.disasterDonation,
    form: 'BEN009',
  },
  '0520': {
    nameFlagTypeBenefit: 'theft_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.theftMoney,
    form: 'BEN010',
  },
  '0910': {
    nameFlagTypeBenefit: 'cancellation_flg',
    typeModal: ENUMS?.TYPE_MODAL_BENEFIT?.cancellation,
    form: 'BEN011',
  },
  BEN012: {
    form: 'BEN012',
  },
};

const validMaster = {
  VALID: 1,
  IN_VALID: 0,
};

const groupCompanyCd = {
  RG: '100',
  RGP: '150', // 2020.06.02 append by ALEX_Kikawa CT122
  NRG: '200',
};

// 2020.06.05 append by ALEX_Kikawa CT122 Start
const shinseiKubunCd = {
  WAVE: '1',
  PAPER: '2',
};
// 2020.06.05 append by ALEX_Kikawa CT122 End
 
export default {
  category,
  months,
  monthsShort,
  weekdays,
  weekdaysShort,
  weekdaysMin,
  longDateFormat,
  typeScreen,
  inforLocalStorage,
  dataSubMenuAnnualProcess,
  dataSubMenuMasterMaintenance,
  flagCheckBox,
  kyufu_id_default: 9999999,
  benefit_status_new: 10,
  userSys: '01',
  typeFormBenefit,
  validMaster,
  groupCompanyCd,
  screenCodeBen,
  maxLengthCompanyCd: 4,
  maxLengthEmployeeCd: 8,
  maximumNumberDaysBen: 180,
  minTonanHigaigakuBen010: 1000000,
  maxLengthFile: 4194304,
  screenCodeAll,
  shinseiKubunCd, // 2020.06.05 append by ALEX_Kikawa CT122
};
