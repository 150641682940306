import ENUMS from 'config/enum';
//2020.05.14 append by ALEX_Nakasone IT16
import { getMessValidate } from '../../helper/form/messValidate';
export const getClassInputByModeReadonly = (
  isReadonly,
  modeReadonly,
  className,
) => {
  let clssNameInput = className;
  if (modeReadonly === ENUMS?.MODE_INPUT_READONLY?.SHOWTOOLTIP) {
    clssNameInput = `${className} tooltip--left tooltip-readonly`;
  } else {
    clssNameInput = isReadonly
      ? `${
          className ? className?.replace('input_required', '') : 'w-25'
        } rd  tooltip--left`
      : `${className} tooltip--left`;
  }
  return clssNameInput;
};

export const getClassInputFormatNumber = (
  clssNameInput,
  format,
  isShowFormat,
) => {
  clssNameInput =
    format || isShowFormat ? `${clssNameInput} input-format ` : clssNameInput;
  clssNameInput = isShowFormat
    ? `${clssNameInput} color-transparent`
    : clssNameInput;
  return clssNameInput;
};
export const getClassInputName = (
  clssNameInput,
  mess,
  messWarning,
  touched,
  isSubmitting,
  isFocus,
) => {
  let clss = clssNameInput;
  //2020.05.14 modified by ALEX_Nakasone IT16 Start
  //if (mess && (touched || isSubmitting || isFocus)) {
  if (mess && (touched || isSubmitting || isFocus || mess.indexOf(getMessValidate('C0000001').replace('{0}','')))) {
  //2020.05.14 modified by ALEX_Nakasone IT16 End
    clss = `${clssNameInput} input-error`;
  } else if (messWarning && (touched || isSubmitting || isFocus)) {
    clss = `${clssNameInput} input-warning`;
  }
  return clss;
};
