import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
import { isEmptySelect } from '../validateCommonFunction';
export const validateTonanhigaiKubun = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['被害区分']), value =>
    isEmptySelect(value),
  );
export const validateTonanHigaigaku = Yup.string()
  .nullable()
  .checkCurrency('被害額', 12, true);
