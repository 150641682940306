/**
 *
 * CheckBox
 * Author: LanVt
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from './../Label';
import messages from './messages';
import { createMessObj } from 'helper/messages';
import './index.css';

function CheckBox({
  className,
  classNameLi,
  keySpan,
  keySpanJson,
  values,
  index,
  name,
  handleChange,
  handleBlur,
  isDisplayLableRight,
  isRequired,
  mess,
  isI18n,
  value,
  key,
  isDisable,
}) {
  const id = Math.floor(Math.random() * 10000 + 1) + name + (value || '');
  let messObj = keySpanJson ? createMessObj(keySpanJson) : null;
  if (isI18n) {
    const messObjTmp = keySpan ? messages?.[keySpan] : null;
    messObj = messObj || messObjTmp || keySpan;
  }
  return (
    <li
      className={classNameLi}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {!isDisplayLableRight && (
        <Label
          keyMess={keySpan}
          keyMessJson={keySpanJson}
          isRequired={isRequired}
          mess={mess}
          isI18n={isI18n}
        />
      )}
      <div className={className}>
        <input
          type="checkbox"
          id={id}
          name={name}
          value={values?.[name] || false}
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values?.[name] || false}
          disabled={isDisable ? 'disabled' : ''}
          //disabled={isDisable ? true : false}
        />
        <label htmlFor={id} className="check-box" />
      </div>
      {isDisplayLableRight && (
        <>
          {isI18n && keySpan ? (
            <FormattedMessage {...messObj} />
          ) : (
            <> {mess} </>
          )}
        </>
      )}
    </li>
  );
}
CheckBox.propTypes = {
  className: PropTypes.string,
  classNameLi: PropTypes.string,
  keySpan: PropTypes.string,
  values: PropTypes.any,
  name: PropTypes.string,
  index: PropTypes.number,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isDisplayLableRight: PropTypes.bool,
  isRequired: PropTypes.bool,
  isI18n: PropTypes.bool,
  mess: PropTypes.string,
  value: PropTypes.string,
  isDisable: PropTypes.bool,
};

CheckBox.defaultProps = {
  isDisplayLableRight: true,
  isRequired: false,
  isI18n: true,
  mess: '',
  value: '',
  handleChange: () => {},
  isDisable: false,
};

export default CheckBox;
