/*
 *
 * Mse003 reducer
 * Author: Locnt
 */
import produce from 'immer';
import {
  GET_LIST_MASTER_COMPANY,
  LOAD_REPOS_SUCCESS,
  LOAD_REPOS_ERROR,
  RESET_LIST_GROUP_COMPANY
} from './constants';

export const initialState = {
  error: '',
  isLoading: false,
  infoListCompany: [],
};

/* eslint-disable default-case, no-param-reassign */
const mse003Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_LIST_GROUP_COMPANY:
        draft.infoListCompany = [];
        break;
      case GET_LIST_MASTER_COMPANY:
        draft.isLoading = true;
        draft.error = {};
        break;
      case LOAD_REPOS_SUCCESS:
        draft.infoListCompany = action.repos;
        draft.isLoading = false;
        draft.error = {};
        break;
      case LOAD_REPOS_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        draft.infoListCompany = [];
        break;
    }
  });

export default mse003Reducer;
