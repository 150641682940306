import * as Yup from 'yup';
import ConstRoot from 'config/constant';
import { getMessValidate } from 'helper/form/messValidate';
import ConstantRegex from 'helper/form/constantRegex';
import commonValidateMethodSea from './validateCommonMethod';

export const validateKaishaCd = Yup.string()
  .nullable()
  .checkKaishaCdOrJugyoinNoSea(
    getMessValidate('C0000006', ['会社コード']),
    getMessValidate('C0000007', ['会社コード']),
    ConstRoot.maxLengthCompanyCd,
  );
export const validateJugyoinNo = Yup.string()
  .nullable()
  // 2020.05.19 modified by ALEX_Kikawa CT137 Start
  /*.checkKaishaCdOrJugyoinNoSea(
    getMessValidate('C0000006', ['従業員番号']),
    getMessValidate('C0000007', ['従業員番号']),
    ConstRoot.maxLengthEmployeeCd,
  */
  .checkJugyoinNoSea(
    getMessValidate('C0000018', ['従業員番号']),
    getMessValidate('C0000007', ['従業員番号']),
    ConstRoot.maxLengthEmployeeCd,
    // 2020.05.19 modified by ALEX_Kikawa CT137 End
  );
export const validateNameOfKana = Yup.string()
  .nullable()
  .max(40, getMessValidate('C0000007', ['カナ氏名']))
  .matches(
    ConstantRegex.regexFullSizeKatakanaWithSpace,
    getMessValidate('C0000002', ['カナ氏名']),
  );
export const validateKanjiName = Yup.string()
  .nullable()
  .max(30, getMessValidate('C0000007', ['氏名']));
