/**
 *
 * Sea001
 * Author: LanVt
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import './index.css';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectSea002, { makeSelectSearchEmployeeCode } from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import {
  searchEmployeeCodeRequest,
  resetData,
  searchCompanyExactlySea002Request,
} from './actions';
import { formikEnhancer } from 'helper/form/validateForm';
import InputLabel from 'components/InputLabel';
import InputCompany from 'components/InputCompany';
import Table from 'components/Table';
import { dataConfigTable } from './constants';
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import ConstRoot from 'config/constant';
import { isEmpty } from 'utils/common/ObjectUtils';
export function Sea002({
  searchEmployeeCode,
  listEmployees,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  resetForm,
  funcOnClick,
  handleHide,
  sea002,
  setFieldValue,
  resetData,
  parentValues,
  touched,
  errors,
}) {
  useInjectReducer({ key: 'sea002', reducer });
  useInjectSaga({ key: 'sea002', saga });
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    resetForm();
    resetData();

    setIsSubmit(false);
    if (parentValues) {
      setFieldValue('jugyoin_no', parentValues?.jugyoin_no);
    }
    if (parentValues?.kaisha_cd || parentValues?.jugyoin_no) {
      searchEmployeeCode({
        kaisha_cd: parentValues?.kaisha_cd,
        jugyoin_no: parentValues?.jugyoin_no,
      });
    }
  }, []);
  const onSubmitForm = e => {
    e.preventDefault();
    setIsSubmit(true);
    if (isEmpty(errors) && !values?.messErrCompany) {
      searchEmployeeCode(values);
    }
    handleSubmit(e);
  };
  const onClickCell = (context, value) => {
    funcOnClick(context?.item);
  };
  return (
    <div className="popup_bg_wapper search-popup sea002">
      {(sea002?.isLoadingGroupCompany || sea002?.isLoadingSearch) && (
        <LoadingIndicator className="overlay-loading" />
      )}

      <div className="popup_content">
        <form className="" onSubmit={onSubmitForm}>
          <div className="top-bar h-32">
            <h2>従業員番号検索</h2>
          </div>
          <div className="main mt-52">
            <div className="basic_data">
              <div className="row row2 non_bd mt-32 flex-start">
                <InputLabel
                  className="left left2"
                  classNameInput="input_10 "
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyMess="labelEmployeeNumber"
                  name="jugyoin_no"
                  touched={touched}
                  isSubmitting={isSubmit}
                  mess={errors?.jugyoin_no || ''}
                />
                <div className="center center2" />
                <div className="right right2" />
                <div className="more_right">
                  <Button
                    className="button btn_gray"
                    onClick={handleHide}
                    buttonName="buttonClose"
                  />
                </div>
              </div>
              <InputCompany
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                isSubmitting={isSubmit}
                parentValues={parentValues}
                key="SEA002"
              />

              <div className="row row2 non_bd mt-2">
                <InputLabel
                  className="left left2 d-f w-55"
                  classNameInput=" w-100"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyMess="labelUpdateFullName"
                  name="fullNameKanji"
                  mess={errors?.fullNameKanji || ''}
                  touched={touched}
                  isSubmitting={isSubmit}
                />
                <div className="right right2 d-f" />
                <div className="more_right" />
              </div>
              <div className="row row2 non_bd mt-2">
                <InputLabel
                  className="left left2 d-f w-55"
                  classNameInput=" w-100"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyMess="labelNameOfKana"
                  name="fullNameKana"
                  mess={errors?.fullNameKana || ''}
                  touched={touched}
                  isSubmitting={isSubmit}
                />
                <div className=" d-f">
                  <Button
                    // className="button btn_gray ml-10"
                    type="submit"
                    buttonName="buttonSearch"
                  />
                </div>
                {/* <span className="ml-10">{sea002?.messSearch}</span> */}
              </div>
            </div>
          </div>
        </form>
        <div className="table-fix">
          <Table
            dataConfigTable={dataConfigTable(onClickCell)}
            datas={listEmployees}
            isI18n={true}
          />
        </div>
      </div>
    </div>
  );
}

Sea002.propTypes = {
  dispatch: PropTypes.func.isRequired,
  funcOnClick: PropTypes.func.isRequired,
  handleHide: PropTypes.func,
  listEmployees: PropTypes.array,
  parentValues: PropTypes.object,
};
Sea002.defaultProps = {
  funcOnClick: () => {},
  funcSubmit: () => {},
  parentValues: {},
};

const mapStateToProps = createStructuredSelector({
  sea002: makeSelectSea002(),
  listEmployees: makeSelectSearchEmployeeCode(),
  fieldValidate: () => [
    'jugyoin_no',
    'kaisha_cd',
    'fullNameKana',
    'fullNameKanji',
  ],
  screenCode: () => ConstRoot.screenCodeAll.SEA,
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    searchEmployeeCode: param => dispatch(searchEmployeeCodeRequest(param)),
    resetData: param => dispatch(resetData(param)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  formikEnhancer,
)(Sea002);
