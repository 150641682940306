import * as Yup from 'yup';
import differenceInDays from 'date-fns/differenceInDays';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';
import ConstRoot from 'config/constant';
import ConstReg from 'helper/form/constantRegex';
import {
  getMessValidate,
  getMsgLengthKyufuKingaku,
  getMsgNumKyufuKingaku,
  getMsgRegNumKyufuKingaku,
  getMsgRequiredKyufuKingaku,
  getMessageCheckAfterDay,
  getMessageCheckAfterJoinDate,
  getCheckLeaveDateCompany,
  getCheckRequestDt,
  getCheckValidDate,
  getCheckRequiredJiyuhassei,
  getCheckGreaterThanNumberYear,
  getMessCheckRequiredTaishoshaFullname,
  getMessCheckMaxLengthTaishoshaFullname,
} from 'helper/form/messValidate';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';

const checkBenefitsNo = (
  refTotalMoney,
  msg1,
  msg2,
  msgRequired,
  msgLength,
  length,
  isRequired = false,
) => {
  return Yup.mixed().test({
    name: 'checkBenefitsNo',
    exclusive: false,
    test: function(value) {
      if (!isEmptyAndBlank(value) && (value !== '0000000' || value != 0)) {
        if (value.length > length) {
          return this.createError({
            message: msgLength,
          });
        }

        const flagIsNumber = ConstReg.regxNumberOnly.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msg2,
            // path: 'check number2', // Fieldname
          });
        }
      } else {
        const refByoinTsuinDays = Yup.ref('byoin_tsuin_days');
        const byoin_tsuin_days = this.resolve(refByoinTsuinDays);
        // const totalMoney = this.resolve(refTotalMoney);
        // if (!isNaN(totalMoney)) {
        //   if (parseInt(totalMoney) < 0) {
        //     //Truong hop Tong so tien tro cap là âm và chưa input No nguon tro cap.
        //     return this.createError({
        //       message: msg1,
        //       // path: 'check number', // Fieldname
        //     });
        //   }
        // }
        if (byoin_tsuin_days == 0) {
          return this.createError({
            message: getMessValidate('C0000030'),
          });
        }
        if (isRequired) {
          return this.createError({
            message: msgRequired,
          });
        }
      }
      return true;
    },
  });
};

const checkFutureDay = msg => {
  return Yup.mixed().test({
    name: 'checkFutureDay',
    exclusive: false,
    message: msg || 'checkFutureDay',
    test: function(value) {
      return !isFuture(new Date(value));
    },
  });
};
const checkRequestDay = (
  ref,
  refCodeScreen,
  // msg1,
  msg2,
  msgCheckValidDate,
  msgRequired,
) => {
  return Yup.mixed().test({
    name: 'checkRequestDay',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      const msg1 = getMessageCheckAfterDay(valueCodeScreen);

      if (!isEmptyAndBlank(value)) {
        const isValidDate = isValidateDate(value);
        const ischeckAfterDay = isAfter(
          new Date(this.resolve(ref)),
          new Date(value),
        );
        const isFut = isFuture(new Date(value));
        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else if (
          ischeckAfterDay &&
          valueCodeScreen !== ConstRoot?.screenCodeBen?.BEN011
        ) {
          return this.createError({
            message: msg1,
          });
        } else if (isFut) {
          return this.createError({
            message: msg2,
          });
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};
// check date of birth ben008
const checkSeinengappiYmd = () => {
  return Yup.mixed().test({
    name: 'checkSeinengappiYmd',
    test: function(value) {
      const isValidDate = isValidateDate(value);
      const msgRequired = getMessValidate('C0000001', ['生年月日']);
      const msgCheckValidDate = getMessValidate('C0000004', ['生年月日']);
      if (!isEmptyAndBlank(value)) {
        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

const checkDateOfBirth = (
  refJointDt,
  refLeaveDt,
  refRequestDt,
  refLeaveDtCompany,
  refCodeScreen,
) => {
  return Yup.mixed().test({
    name: 'checkDateOfBirth',
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      const messageCheckAfterJoinDate = getMessageCheckAfterJoinDate(
        valueCodeScreen,
      );

      const msgCheckRequestDt = getCheckRequestDt(valueCodeScreen);
      const msgCheckValidDate = getCheckValidDate(valueCodeScreen);
      const msgRequired = getCheckRequiredJiyuhassei(valueCodeScreen);

      if (!isEmptyAndBlank(value)) {
        const dateLeaveDt = this.resolve(refLeaveDt);
        const isValidDate = isValidateDate(value);
        const ischeckAfterDay = isAfter(
          new Date(value),
          new Date(this.resolve(refRequestDt)),
        );
        const ischeckAfterJoinDate = isAfter(
          new Date(this.resolve(refJointDt)),
          new Date(value),
        );
        const ischeckLeaveDate = isAfter(
          new Date(value),
          new Date(dateLeaveDt),
        );

        if (!isValidDate) {
          return this.createError({
            message: msgCheckValidDate,
          });
        } else if (
          ischeckAfterDay &&
          valueCodeScreen !== ConstRoot?.screenCodeBen?.BEN011
        ) {
          return this.createError({
            message: msgCheckRequestDt,
          });
        } else if (
          ischeckAfterJoinDate &&
          valueCodeScreen !== ConstRoot?.screenCodeBen?.BEN002
        ) {
          return this.createError({
            message: messageCheckAfterJoinDate,
          });
        } else if (
          ischeckLeaveDate &&
          valueCodeScreen !== ConstRoot?.screenCodeBen?.BEN002
        ) {
          return this.createError({
            message: messageCheckAfterJoinDate,
          });
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }

      return true;
    },
  });
};

const checkValidateNumber = (txtField, length = 0, isRequired = false) => {
  return Yup.mixed().test({
    name: 'checkValidateNumber',
    test: function(value) {
      const msgCheckNum = getMessValidate('C0000006', [txtField]);
      const msgRequired = getMessValidate('C0000001', [txtField]);
      const msgRegNum = getMessValidate('C0000003', [txtField]);
      const msgLength = getMessValidate('C0000007', [txtField]);
      if (!isEmptyAndBlank(value)) {
        if (!isNaN(value)) {
          if (parseInt(value) < 0) {
            return this.createError({
              message: msgCheckNum,
            });
          }
        }
        const flagIsNumber = ConstReg.regxNumberOnly.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgRegNum,
          });
        }
        if (length) {
          if (value.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else if (isRequired) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

const checkValidateRequired = (txtField, length = 0, isRequired = false) => {
  return Yup.mixed().test({
    name: 'checkValidateRequired',
    test: function(value) {
      const msgRequired = getMessValidate('C0000001', [txtField]);
      const msgLength = getMessValidate('C0000007', [txtField]);
      if (!isEmptyAndBlank(value)) {
        if (!isNaN(value)) {
          if (parseInt(value) < 0) {
            return this.createError({
              message: msgCheckNum,
            });
          }
        }
        if (length) {
          if (value.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else if (isRequired) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

const checkTaishoshaFullname = refCodeScreen => {
  return Yup.mixed().test({
    name: 'checkTaishoshaFullname',
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      const msgRequired = getMessCheckRequiredTaishoshaFullname(
        valueCodeScreen,
      );
      const msgMaxLength = getMessCheckMaxLengthTaishoshaFullname(
        valueCodeScreen,
      );
      let length = 20;
      if (ConstRoot?.screenCodeBen?.BEN007_ARR?.includes(valueCodeScreen)) {
        length = 50;
      } else if (ConstRoot?.screenCodeBen?.BEN008 === valueCodeScreen) {
        length = 14;
      }
      if (!isEmptyAndBlank(value)) {
        if (value?.length > length) {
          return this.createError({
            message: msgMaxLength,
          });
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }

      return true;
    },
  });
};

const checkValidateKojinFutangaku = (refCodeScreen, length) => {
  return Yup.mixed().test({
    name: 'checkValidateKojinFutangaku',
    test: function(value) {
      const msgCheckNum = getMessValidate('C0000028');
      const msgRegNum = getMessValidate('C0000003', ['個人負担額']);
      const msgRequired = getMessValidate('C0000001', ['個人負担額']);
      const msgLength = getMessValidate('C0000007', ['個人負担額']);

      if (!isEmptyAndBlank(value)) {
        if (!isNaN(value)) {
          if (parseInt(value) < 0) {
            return this.createError({
              message: msgRegNum,
              // path: 'check number', // Fieldname
            });
          }
        }
        const flagIsNumber = ConstReg.regNumberWithNegative.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgRegNum,
            // path: 'check number2', // Fieldname
          });
        }
        if (length) {
          if (Math.abs(value) >= Math.pow(10, length)) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

const checkValidateKyufuKingaku = (refCodeScreen, length) => {
  return Yup.mixed().test({
    name: 'checkValidateKyufuKingaku',
    test: function(value) {
      const valueCodeScreen = this.resolve(refCodeScreen);
      const msgCheckNum = getMsgNumKyufuKingaku(valueCodeScreen);
      const msgRegNum = getMsgRegNumKyufuKingaku(valueCodeScreen);
      const msgRequired = getMsgRequiredKyufuKingaku(valueCodeScreen);
      const msgLength = getMsgLengthKyufuKingaku(valueCodeScreen);
      if (
        ConstRoot?.screenCodeBen?.BEN010 === valueCodeScreen ||
        ConstRoot?.screenCodeBen?.BEN009 === valueCodeScreen ||
        ConstRoot?.screenCodeBen?.BEN008 === valueCodeScreen
      ) {
        length = 9;
      }
      if (!isEmptyAndBlank(value)) {
        const flagIsNumber = ConstReg.regNumberWithNegative.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgRegNum,
          });
        }
        if (length) {
          if (Math.abs(value) >= Math.pow(10, length)) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};
const checkKaishaCdOrJugyoinNo = (
  refCheckIsBen012,
  msgIsNumber,
  msgRequired,
  msgLength,
  length,
  lengthBen012,
  isRequired = false,
) => {
  return Yup.mixed().test({
    name: 'checkKaishaCd',
    exclusive: false,
    test: function(value) {
      if (!isEmptyAndBlank(value)) {
        let maxLength = this.resolve(refCheckIsBen012) ? lengthBen012 : length;
        if (value.length > maxLength) {
          return this.createError({
            message: msgLength,
          });
        }
        const flagIsNumber = ConstReg.regNumberHalfsize.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgIsNumber,
          });
        }
      } else if (isRequired) {
        if (!this.resolve(refCheckIsBen012)) {
          return this.createError({
            message: msgRequired,
          });
        }
      }
      return true;
    },
  });
};

// 2020.05.19 append by ALEX_Kikawa CT137 Start
const checkJugyoinNo = (
  refCheckIsBen012,
  msgIsAlpha,
  msgRequired,
  msgLength,
  length,
  lengthBen012,
  isRequired = false,
) => {
  return Yup.mixed().test({
    name: 'checkJugyoinNo',
    exclusive: false,
    test: function(value) {
      if (!isEmptyAndBlank(value)) {
        let maxLength = this.resolve(refCheckIsBen012) ? lengthBen012 : length;
        if (value.length > maxLength) {
          return this.createError({
            message: msgLength,
          });
        }
        const flagIsAlpha = ConstReg.regAlphaAndNumberHalfSize.test(value);
        if (!flagIsAlpha) {
          return this.createError({
            message: msgIsAlpha,
          });
        }
      } else if (isRequired) {
        if (!this.resolve(refCheckIsBen012)) {
          return this.createError({
            message: msgRequired,
          });
        }
      }
      return true;
    },
  });
};

// 2020.05.19 append by ALEX_Kikawa End CT137

const checkLeaveDateIsBeforJoinDate = (refJoinDate, refLeaveDate, mess) => {
  return Yup.mixed().test({
    name: 'checkLeaveDateIsBeforJoinDate',
    test: function(value) {
      const isValidDate = isValidateDate(value);
      const messValidDate = getMessValidate('C0000004', ['脱会日']);
      const messMaxLength = getMessValidate('C0000007', ['脱会日']);
      if (!isEmptyAndBlank(value)) {
        const isAfterJoinDate = isAfter(
          new Date(this.resolve(refJoinDate)),
          new Date(this.resolve(refLeaveDate)),
        );
        if (!isValidDate) {
          return this.createError({
            message: messValidDate,
          });
        }
        if (value.length > 10) {
          return this.createError({
            message: messMaxLength,
          });
        }
        if (isAfterJoinDate) {
          return this.createError({
            message: mess,
            params: {
              reference: 'isAfterJoinDate',
            },
          });
        }
      }
      return true;
    },
  });
};
const checkValidateShobyokubun = txtField => {
  return Yup.mixed().test({
    name: 'checkValidateShobyokubun',
    test: function(value) {
      const msgRequired = getMessValidate('C0000001', [txtField]);
      if (isEmptyAndBlank(value)) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};
const checkNumber = (txtField, length = 0, isRequired = false) => {
  return Yup.mixed().test({
    name: 'checkNumber',
    test: function(value) {
      const msgCheckNum = getMessValidate('C0000003', [txtField]);
      const msgRequired = getMessValidate('C0000001', [txtField]);
      const msgRegNum = getMessValidate('C0000003', [txtField]);
      const msgLength = getMessValidate('C0000007', [txtField]);
      if (!isEmptyAndBlank(value)) {
        const flagIsNumber = ConstReg.regxNumberOnly.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgCheckNum,
          });
        }
        if (length) {
          if (value.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
        }
      } else if (isRequired) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

const checkCurrency = (txtField, length = 0, isRequired = false) => {
  return Yup.mixed().test({
    name: 'checkCurrency',
    test: function(value) {
      const msgCheckNum = getMessValidate('C0000003', [txtField]);
      const msgRequired = getMessValidate('C0000001', [txtField]);
      const msgLength = getMessValidate('C0000007', [txtField]);
      if (!isEmptyAndBlank(value)) {
        const flagIsNumber = ConstReg.regNumberWithNegative.test(value);
        if (!flagIsNumber) {
          return this.createError({
            message: msgCheckNum,
          });
        }
        if ( !isNaN(value) && Math.abs(value) >= Math.pow(10, length)) {
          return this.createError({
            message: msgLength,
          });
        }
      } else if (isRequired) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};

Yup.addMethod(
  Yup.string,
  'checkLeaveDateIsBeforJoinDate',
  checkLeaveDateIsBeforJoinDate,
);
Yup.addMethod(Yup.string, 'checkKaishaCdOrJugyoinNo', checkKaishaCdOrJugyoinNo);
Yup.addMethod(Yup.string, 'checkJugyoinNo', checkJugyoinNo);  // 2020.05.19 append by ALEX_Kikawa CT137
Yup.addMethod(Yup.string, 'checkFutureDay', checkFutureDay);
Yup.addMethod(Yup.string, 'checkRequestDay', checkRequestDay);
Yup.addMethod(Yup.string, 'checkDateOfBirth', checkDateOfBirth);
Yup.addMethod(Yup.string, 'checkSeinengappiYmd', checkSeinengappiYmd);
Yup.addMethod(Yup.string, 'checkTaishoshaFullname', checkTaishoshaFullname);
Yup.addMethod(
  Yup.string,
  'checkValidateKyufuKingaku',
  checkValidateKyufuKingaku,
);

Yup.addMethod(
  Yup.string,
  'checkValidateKojinFutangaku',
  checkValidateKojinFutangaku,
);

Yup.addMethod(Yup.string, 'checkValidateNumber', checkValidateNumber);
Yup.addMethod(Yup.string, 'checkBenefitsNo', checkBenefitsNo);

Yup.addMethod(Yup.string, 'checkValidateShobyokubun', checkValidateShobyokubun);
Yup.addMethod(Yup.string, 'checkNumber', checkNumber);
Yup.addMethod(Yup.string, 'checkCurrency', checkCurrency);
Yup.addMethod(Yup.string, 'checkValidateRequired', checkValidateRequired);
