/*
 * Sea001 Messages
 *
 * This contains all the text for the Sea001 container.
 * Author: LanVt
 */

import { defineMessages } from 'react-intl';

export const scope = 'containers.Sea002';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'This is the Sea002 container!',
  },
});
