/*
 *
 * Sea001 constants
 * Author: LanVt
 */
import ENUMS from '../../../../config/enum';
export const SEARCH_EMPLOYEE_CODE_REQUEST = 'SEARCH_EMPLOYEE_CODE_REQUEST';
export const SEARCH_EMPLOYEE_CODE_SUCCESS = 'SEARCH_EMPLOYEE_CODE_SUCCESS';
export const SEARCH_EMPLOYEE_CODE_ERROR = 'SEARCH_EMPLOYEE_CODE_ERROR';
export const RESET_DATA = 'RESET_DATA';
export const dataConfigTable = onClickCell => {
  return [
    {
      binding: 'employee_no',
      headerName: 'employeeNumber',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.LINK,
      funcOnClick: onClickCell,
    },
    {
      binding: 'fullname_kanji',
      headerName: 'fullName',
      width: '2*',
    },
    {
      binding: 'date_of_birth',
      headerName: 'birthday',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
    },
    {
      binding: 'short_company_nm',
      headerName: 'companyAbbreviation',
      width: '*',
    },
    {
      binding: 'leaving_dt',
      headerName: 'withdrawalDate2',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
    },
  ];
};
