import { createSelector } from 'reselect';
import { initialState as stateCom002 } from 'containers/Com002/reducer';

const selectMessList = state => stateCom002?.messList || {};

const makeMessList = () =>
  createSelector(
    selectMessList,
    substate => substate,
  );
export { makeMessList };
