import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
// import ConstRegx from '../constantRegex';

export const validateYear = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['年度']));

export const validateKaisha = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['会社コード']));
