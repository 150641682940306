/*
 *
 * Sea001 actions
 * Author: LanVt
 */
import {
  SEARCH_EMPLOYEE_CODE_REQUEST,
  SEARCH_EMPLOYEE_CODE_SUCCESS,
  SEARCH_EMPLOYEE_CODE_ERROR,
  RESET_DATA,
} from './constants';
export function resetData(params) {
  return {
    type: RESET_DATA,
    params,
  };
}
export function searchEmployeeCodeRequest(params) {
  return {
    type: SEARCH_EMPLOYEE_CODE_REQUEST,
    params,
  };
}
export function searchEmployeeCodeSuccess(params) {
  return {
    type: SEARCH_EMPLOYEE_CODE_SUCCESS,
    params,
  };
}
export function searchEmployeeCodeError(params) {
  return {
    type: SEARCH_EMPLOYEE_CODE_ERROR,
    params,
  };
}
