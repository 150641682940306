import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeMessList } from './selectors';
import messages from './messages';
import Button from '../Button';
function Confirm({
  handleHide,
  handleOK,
  messContent,
  isI18n,
  messList,
  keyMessList,
  isShowButtonCancel,
  isShowButtonOK,
  isLoading,
}) {
  const mess = messList?.[keyMessList]?.message_mongon_1
    ? messList?.[keyMessList]?.message_mongon_1
    : keyMessList;
  return (
    <div className="overlay">
      <div className="modal-confirm">
        {/* <a href="#" title="Close" className="modal-close" onClick={handleHide}>
        X
      </a> */}
        <p className="title-confirm">
          {isI18n && messContent && (
            <FormattedMessage {...messages?.[messContent]} />
          )}
          {!isI18n && keyMessList && (
            <div dangerouslySetInnerHTML={{ __html: mess }} />
          )}
          {!isI18n && !keyMessList && <>{messContent} </>}
        </p>
        <div className="list-btn btn-modal">
          {isShowButtonOK && (
            <Button
              onClick={handleOK}
              className="button "
              buttonName="buttonConfirm"
              isLoading={isLoading}
            />
          )}
          {isShowButtonCancel && (
            <Button
              onClick={handleHide}
              className="button btn_gray"
              buttonName="buttonCancel"
            />
          )}
        </div>
      </div>
    </div>
  );
}
Confirm.propTypes = {
  messContent: PropTypes.string,
  key: PropTypes.string,
  messList: PropTypes.any,
  handleHide: PropTypes.func,
  handleOK: PropTypes.func,
  isI18n: PropTypes.bool,
  isShowButtonOK: PropTypes.bool,
  isShowButtonCancel: PropTypes.bool,
};
Confirm.defaultProps = {
  messContent: '',
  handleHide: () => {},
  handleOK: () => {},
  isI18n: true,
  isShowButtonOK: true,
  isShowButtonCancel: true,
};
const mapStateToProps = createStructuredSelector({
  messList: makeMessList(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Confirm);
