import * as Yup from 'yup';
import differenceInDays from 'date-fns/differenceInDays';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';
import ConstRoot from 'config/constant';
import ConstReg from 'helper/form/constantRegex';
import { getMessValidate } from 'helper/form/messValidate';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
const validateJogenDays = (thiss, value) => {
  //2020.05.07 modified by ALEX_Nakasone IT16 Start
  //let jogenDays = thiss.resolve(Yup.ref('jogen_days'));
  let jogenDays = thiss.resolve(Yup.ref('jogen_days_0320'));
  //2020.05.07 modified by ALEX_Nakasone IT16 End
  if (!isNaN(jogenDays) && value > jogenDays) {
    return thiss.createError({
      message: getMessValidate('C0000029', [jogenDays]),
    });
  }
  return true;
};
//2020.04.24 append by ALEX_Nakasone IT30 Start
const validateJogenDays_0330 = (thiss, value) => {
  let jogenDays = thiss.resolve(Yup.ref('jogen_days_0330'));
  if (!isNaN(jogenDays) && value > jogenDays) {
    return thiss.createError({
      message: getMessValidate('C0000029', [jogenDays]),
    });
  }
  return true;
};
//2020.04.24 append by ALEX_Nakasone IT30 End
const validateJogenDays_0340 = (thiss, value) => {
  //let jogenDays = thiss.resolve(jogen_days_0340);
  let jogenDays = thiss.resolve(Yup.ref('jogen_days_0340'));
  let values = thiss.resolve(Yup.ref('sagakubed_shiyo_days'));

  if (!isNaN(jogenDays) && values > jogenDays) {
    return thiss.createError({
      message: getMessValidate('C0000029', [jogenDays]),
    });
  }
  return true;
};
const checkStartDateBen007 = (
  refDateOfArising, //ngay phat sinh -- 発生日
  refDateOfEnd, //ngay ket thuc -- 休業終了日
  refCheckBoxSicknessMoney, // checkbox 傷病見舞金申請
  txtField,
  msgCheckDateOfArising, //休業開始日/Ngay bat đau nghi ＜ 発生日/Ngay phat sinh
  msgCheckDateOfEnd, //休業開始日/Ngày bắt đầu nghỉ ＞ 休業終了日/Ngày kết thúc nghỉ
) => {
  return Yup.mixed().test({
    name: 'checkStartDateBen007',
    test: function(value) {
      const valueCheckBoxSicknessMoney = this.resolve(refCheckBoxSicknessMoney);
      if (valueCheckBoxSicknessMoney) {
        const msgCheckValidDate = getMessValidate('C0000004', [txtField]);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        if (!isEmptyAndBlank(value)) {
          const dateOfArising = this.resolve(refDateOfArising);
          const dateOfEnd = this.resolve(refDateOfEnd);
          const isValidDate = isValidateDate(value);
          const ischeckAfterDateOfArising = isAfter(
            new Date(dateOfArising),
            new Date(value),
          );
          const ischeckAfterDateOfEnd = isAfter(
            new Date(value),
            new Date(dateOfEnd),
          );

          if (!isValidDate) {
            return this.createError({
              message: msgCheckValidDate,
            });
          } else if (ischeckAfterDateOfArising) {
            return this.createError({
              message: msgCheckDateOfArising,
            });
          } else if (ischeckAfterDateOfEnd) {
            return this.createError({
              message: msgCheckDateOfEnd,
            });
          }
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};
const checkEndDateBen007 = (
  refRequestDate, //ngay bat dau -- 休業開始日
  refStartDate, //ngay bat dau -- 休業開始日
  refCheckBoxSicknessMoney, // checkbox 傷病見舞金申請
  txtField,
  msgCheckStartDate, //休業開始日/Ngay bat đau nghi ＞ 休業終了日/Ngay ket thuc nghi
  msgCheckRequestDt,
) => {
  return Yup.mixed().test({
    name: 'checkEndDateBen007',
    test: function(value) {
      const valueCheckBoxSicknessMoney = this.resolve(refCheckBoxSicknessMoney);
      if (valueCheckBoxSicknessMoney) {
        const msgCheckValidDate = getMessValidate('C0000004', [txtField]);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        if (!isEmptyAndBlank(value)) {
          const startDate = this.resolve(refStartDate);
          const isValidDate = isValidateDate(value);
          const ischeckAfterStartDate = isAfter(
            new Date(startDate),
            new Date(value),
          );
          if (!isValidDate) {
            return this.createError({
              message: msgCheckValidDate,
            });
          } else if (ischeckAfterStartDate) {
            return this.createError({
              message: msgCheckStartDate,
            });
          } else if (msgCheckRequestDt && refRequestDate) {
            const valueRequestDt = this.resolve(refRequestDate);
            const isValidDateRequestDt = isValidateDate(valueRequestDt);
            if (isValidDateRequestDt) {
              const ischeckAfterRequestDt = isAfter(
                new Date(value),
                new Date(valueRequestDt),
              );
              if (ischeckAfterRequestDt) {
                return this.createError({
                  message: msgCheckRequestDt,
                });
              }
            }
          }
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};
const checkEndDateSagakubedShiyoShuryoYmd = (
  refRequestDt, // ngay yeu cau ---- 申請日
  refStartDate, //ngay bat dau -- 休業開始日
  refCheckBoxSicknessMoney, // checkbox 傷病見舞金申請
  txtField,
  msgCheckRequestDt, //申請日/Ngay yeu cau ＜ 休業終了日/Ngay ket thuc nghi
  msgCheckStartDate, //休業開始日/Ngay bat đau nghi ＞ 休業終了日/Ngay ket thuc nghi
  codeMsgLimitDate, //Ngay gioi han tro cap not null && ＜ Ngay ket thuc nhap vien
) => {
  return Yup.mixed().test({
    name: 'checkEndDateSagakubedShiyoShuryoYmd',
    test: function(value) {
      const valueCheckBoxSicknessMoney = this.resolve(refCheckBoxSicknessMoney);
      if (valueCheckBoxSicknessMoney) {
        const msgCheckValidDate = getMessValidate('C0000004', [txtField]);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        if (!isEmptyAndBlank(value)) {
          const refMaximumDaysBen = Yup.ref('benefit_limit_dt');
          const valueLimitDate = this.resolve(refMaximumDaysBen);
          const requestDt = this.resolve(refRequestDt);
          const startDate = this.resolve(refStartDate);
          const isValidDate = isValidateDate(value);
          const ischeckAfterRequestDt = isAfter(
            new Date(value),
            new Date(requestDt),
          );
          const ischeckAfterStartDate = isAfter(
            new Date(startDate),
            new Date(value),
          );
          if (!isValidDate) {
            return this.createError({
              message: msgCheckValidDate,
            });
          } else if (ischeckAfterRequestDt) {
            return this.createError({
              message: msgCheckRequestDt,
            });
          } else if (ischeckAfterStartDate) {
            return this.createError({
              message: msgCheckStartDate,
            });
          } else if (codeMsgLimitDate && valueLimitDate) {
            const isValidDateLimit = isValidateDate(valueLimitDate);
            if (isValidDateLimit) {
              const ischeckAfterLimitDate = isAfter(
                new Date(value),
                new Date(valueLimitDate),
              );
              if (ischeckAfterLimitDate) {
                //2020.05.12 modified by ALEX_Nakasone IT376 Start
                /*const numberDay = Math.abs(
                  differenceInDays(new Date(value), new Date(valueLimitDate)),
                );*/
                const numberDay = this.resolve(Yup.ref('jogen_days'));
                //2020.05.12 modified by ALEX_Nakasone IT376 End
                const msgCheckLimitDate = getMessValidate(codeMsgLimitDate, [
                  numberDay,
                ]);
                return this.createError({
                  message: msgCheckLimitDate,
                });
              }
            }
          }
          const sagakubed_shiyo_days = this.resolve(Yup.ref('sagakubed_shiyo_days'));
          //2020.05.12 deleted by ALEX_Nakasone IT377 Start 
          //return validateJogenDays_0340(this,sagakubed_shiyo_days);
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};
const checkNumDaysBen007 = (
  refCheckBox,
  refPeriod,
  messMaximumDayBen, // So ngay gioi han ＜ So ngay su dung
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkNumDaysBen007',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        // const refJogenDays = Yup.ref('jogen_days');
        // const jogen_days = this.resolve(refJogenDays);
        const period = this.resolve(refPeriod);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (value > period) {
            return this.createError({
              message: messMaximumDayBen,
            });
          }
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};

const checkDateOutpatient = (
  refCheckBox,
  refDayHospitalize, // so ngay nhap vien
  msgCheckDayHospitalize, // So ngay nhap vien ＜ So ngay ngoai tru
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkDateOutpatient',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const dayHospitalize = this.resolve(refDayHospitalize);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (value > dayHospitalize) {
            return this.createError({
              message: msgCheckDayHospitalize,
            });
          }
        }
        // else {
        //   return this.createError({
        //     message: msgRequired,
        //   });
        // }
      }

      return true;
    },
  });
};

const checkActualHospitalizeDays = (
  refCheckBox,
  refDayHospitalize, // so ngay nhap vien
  refDaysOutpatient, // so ngay ngoai tru
  msgCheckActualHospitalizeDays, // So ngay nhap vien -So ngay ngoai tru  ＜ So ngay nhap vien thuc
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkActualHospitalizeDays',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const dayHospitalize = this.resolve(refDayHospitalize);
        const daysOutpatient = this.resolve(refDaysOutpatient);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (!isNaN(dayHospitalize)) {
            const numDaysOutpatient = !isNaN(daysOutpatient)
              ? parseInt(daysOutpatient)
              : 0;
            if (value > parseInt(dayHospitalize) - numDaysOutpatient) {
              return this.createError({
                message: msgCheckActualHospitalizeDays,
              });
            }
          }
          return validateJogenDays(this, value);
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};
const checkByoinTsuinDays = (
  refCheckBox,
  refNoBenefit, // no tro cap
  refTotalDay,
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkByoinTsuinDays',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const noBenefit = this.resolve(refNoBenefit);
        const totalDay = this.resolve(refTotalDay);
        const refPeriod = Yup.ref('examination_period');
        const refBenefitNo = Yup.ref('moto_kyufu_id');
        const benefitNo = this.resolve(refBenefitNo);
        const period = this.resolve(refPeriod);
        // const refJogenDays = Yup.ref('jogen_days');
        // const jogen_days = this.resolve(refJogenDays);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (totalDay > period) {
            return this.createError({
              message: getMessValidate('C0000048'),
            });
          }
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};

const checkSeikotsuinTsuinDays = (
  refCheckBox,
  refTotalDay,
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkSeikotsuinTsuinDays',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const totalDay = this.resolve(refTotalDay);
        // const refJogenDays = Yup.ref('jogen_days');
        // const jogen_days = this.resolve(refJogenDays);
        const refPeriod = Yup.ref('examination_period');
        const period = this.resolve(refPeriod);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (totalDay > period) {
            return this.createError({
              message: getMessValidate('C0000048'),
            });
          }
        }
        // else {
        //   return this.createError({
        //     message: msgRequired,
        //   });
        // }
      }

      return true;
    },
  });
};
const checkIsNumberDecBen007 = (
  refCheckBox,
  txtField,
  length,
  isRequired = true,
) => {
  return Yup.mixed().test({
    name: 'checkIsNumberDecBen007',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          // if (value > jogen_days) {
          //   return this.createError({
          //     message: messMaximumDayBen,
          //   });
          // }
        } else {
          if (isRequired) {
            return this.createError({
              message: msgRequired,
            });
          }
        }
      }

      return true;
    },
  });
};
const checkIsMoneyDecBen007 = (
  refCheckBox,
  txtField,
  length,
  isRequired = true,
) => {
  return Yup.mixed().test({
    name: 'checkIsMoneyDecBen007',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (Math.abs(value) >= Math.pow(10, length)) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regNumberWithNegative.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          // if (value > jogen_days) {
          //   return this.createError({
          //     message: messMaximumDayBen,
          //   });
          // }
        } else {
          if (isRequired) {
            return this.createError({
              message: msgRequired,
            });
          }
        }
      }

      return true;
    },
  });
};

const checkHospitalDaysBen007 = (refCheckBox, txtField, length) => {
  return Yup.mixed().test({
    name: 'checkHospitalDaysBen007',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);

        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          // if (value > jogen_days) {
          //   return this.createError({
          //     message: messMaximumDayBen,
          //   });
          // }
          //2020.04.24 modified by ALEX_Nakasone IT30 Start
          //return validateJogenDays(this, value);
          return validateJogenDays_0330(this, value);
          //2020.04.24 modified by ALEX_Nakasone IT30 End
        }
        // else {
        //   return this.createError({
        //     message: msgRequired,
        //   });
        // }
      }

      return true;
    },
  });
};

const checkSagakubedShiyoDaysBen007 = (
  refCheckBox,
  refPeriod,
  messMaximumDayBen, // So ngay gioi han ＜ So ngay su dung
  txtField,
  length,
) => {
  return Yup.mixed().test({
    name: 'checkSagakubedShiyoDaysBen007',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);

      if (valueCheckBox) {
        const sagakubed_shiyo_days = this.resolve(Yup.ref('sagakubed_shiyo_days'));
        const period = this.resolve(refPeriod);
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        if (!isEmptyAndBlank(value)) {
          if (value?.toString()?.length > length) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regxNumberOnly.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (value > period) {
            return this.createError({
              message: messMaximumDayBen,
            });
          }
          const jogen_0340 = validateJogenDays_0340(this, sagakubed_shiyo_days);
          if (jogen_0340) {
          } else {
            return false;
          }
          //2020.05.11 modified by ALEX_Nakasone IT377 Start
          //return validateJogenDays(this, value);
          return validateJogenDays_0340(this, sagakubed_shiyo_days);
          //2020.05.11 modified by ALEX_Nakasone IT377 End
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
      }

      return true;
    },
  });
};
const checkServiceBedMone = (refCheckBox, txtField, length) => {
  return Yup.mixed().test({
    name: 'checkServiceBedMone',
    test: function(value) {
      const valueCheckBox = this.resolve(refCheckBox);
      if (valueCheckBox) {
        const sagakubed_shiyo_days = this.resolve(Yup.ref('sagakubed_shiyo_days'));
        const msgRequired = getMessValidate('C0000001', [txtField]);
        const msgLength = getMessValidate('C0000007', [txtField]);
        const msgCheckNum = getMessValidate('C0000003', [txtField]);
        const refMaxKyufuKingaku = Yup.ref('max_kyufu_kingaku_sickness');
        const refSagakubedShiyoDays = Yup.ref('sagakubed_shiyo_days');

        let maxKyufuKingaku = this.resolve(refMaxKyufuKingaku); // 給付金マスタに登録がない場合はNULLとし上限金額の計算はしない
        const sagakubedShiyoDays = this.resolve(refSagakubedShiyoDays) || 0;
        const maxMoney = !isNaN(sagakubedShiyoDays)
          ? parseInt(sagakubedShiyoDays) * maxKyufuKingaku
          : 0;
        if (!isEmptyAndBlank(value)) {
          if (Math.abs(value) >= Math.pow(10, length)) {
            return this.createError({
              message: msgLength,
            });
          }
          const flagIsNumber = ConstReg.regNumberWithNegative.test(value);
          if (!flagIsNumber) {
            return this.createError({
              message: msgCheckNum,
            });
          }
          if (
            value > maxMoney &&
            !isNaN(maxKyufuKingaku) &&
            !isNaN(sagakubedShiyoDays) &&
            sagakubedShiyoDays > 0
          ) {
            return this.createError({
              message: getMessValidate(
                'C0000011',
                [maxKyufuKingaku, sagakubedShiyoDays],
                [
                  { textReplace: '<BR>{2}', text: maxKyufuKingaku },
                  { textReplace: '({3}×{4})', text: maxMoney },
                ],
              ),
            });
          }
        } else {
          return this.createError({
            message: msgRequired,
          });
        }
        //2020.05.07 deleted by ALEX_Nakasone IT39 金額表示項目には不要なメッセージのため削除
        //return validateJogenDays_0340(this,sagakubed_shiyo_days);
      }

      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkStartDateBen007', checkStartDateBen007);
Yup.addMethod(Yup.string, 'checkEndDateBen007', checkEndDateBen007);
Yup.addMethod(Yup.string, 'checkNumDaysBen007', checkNumDaysBen007);
Yup.addMethod(Yup.string, 'checkDateOutpatient', checkDateOutpatient);
Yup.addMethod(
  Yup.string,
  'checkActualHospitalizeDays',
  checkActualHospitalizeDays,
);
Yup.addMethod(Yup.string, 'checkByoinTsuinDays', checkByoinTsuinDays);
Yup.addMethod(Yup.string, 'checkSeikotsuinTsuinDays', checkSeikotsuinTsuinDays);
Yup.addMethod(Yup.string, 'checkIsNumberDecBen007', checkIsNumberDecBen007);
Yup.addMethod(Yup.string, 'checkHospitalDaysBen007', checkHospitalDaysBen007);
Yup.addMethod(Yup.string, 'checkServiceBedMone', checkServiceBedMone);
Yup.addMethod(
  Yup.string,
  'checkEndDateSagakubedShiyoShuryoYmd',
  checkEndDateSagakubedShiyoShuryoYmd,
);
Yup.addMethod(
  Yup.string,
  'checkSagakubedShiyoDaysBen007',
  checkSagakubedShiyoDaysBen007,
);
Yup.addMethod(Yup.string, 'checkIsMoneyDecBen007', checkIsMoneyDecBen007);
