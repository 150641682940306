/*
 *
 * InputCompany reducer
 * Author: LanVt
 */
import produce from 'immer';
import { isEmpty } from 'utils/common/ObjectUtils';
import {
  SEARCH_COMPANY_EXACTLY_COMMON_REQUEST,
  SEARCH_COMPANY_EXACTLY_COMMON_SUCCESS,
  SEARCH_COMPANY_EXACTLY_COMMON_ERROR,
} from './constants';

export const initialState = {
  isLoadingSearch: false,
  messSearch: '',
  errorCompany: {},
  dataSearchCompany: null,
  isLoadingSearchCompany: false,
};

/* eslint-disable default-case, no-param-reassign */
const inputCompanyReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SEARCH_COMPANY_EXACTLY_COMMON_REQUEST:
        draft.dataSearchCompany = {};
        draft.errorCompany = action;
        draft.isLoadingSearchCompany = true;
        break;
      case SEARCH_COMPANY_EXACTLY_COMMON_SUCCESS:
        draft.dataSearchCompany = !isEmpty(action?.payload?.data)
          ? action?.payload?.data
          : {};
        draft.errorCompany = {};
        draft.isLoadingSearchCompany = false;
        break;
      case SEARCH_COMPANY_EXACTLY_COMMON_ERROR:
        draft.dataSearchCompany = {};
        draft.errorCompany = action;
        draft.isLoadingSearchCompany = false;
        break;
    }
  });
export default inputCompanyReducer;
