import { FormattedMessage } from 'react-intl';
import messages from './messages';
import messagesLable from './../../components/Label/messages';
import React from 'react';

const getInfoMessage = (label, message, onlyMessage) => {
  return (
    <>
      {!onlyMessage && (
        <>
          <FormattedMessage {...messages?.[message]} />
          <FormattedMessage {...messagesLable?.[label]} />
        </>
      )}
      {onlyMessage && <FormattedMessage {...messages?.[message]} />}
    </>
  );
};

getInfoMessage.defaultProps = {
  onlyMessage: false,
};

export { getInfoMessage };
