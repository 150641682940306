import * as Yup from 'yup';
import { getInfoMessage } from '../infoMessageErrorI8n';
import { getMessValidate } from '../messValidate';
import ConstRegx from '../constantRegex';
import Const from '../constant';
import { isValidateDate } from 'utils/common/DateUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import isFuture from 'date-fns/isFuture';
import isAfter from 'date-fns/isAfter';
import commonValidateMethod from 'helper/form/validateCommonMethod';

export const validateCompanyIdMen003 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['会社コード']))
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社コード']),
  );

export const validateEmployeeNumber = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['従業員番号']))
  .max(8, getMessValidate('C0000007', ['従業員番号']))
  // 2020.05.19 delete by ALEX_Kikawa CT137 Start
  /*.matches(
    ConstRegx.regNumberHalfsize,
    getMessValidate('C0000003', ['従業員番号']),
  */
  // 2020.05.19 delete by ALEX_Kikawa CT137 End
  .matches(ConstRegx.regAlphaAndNumberHalfSize,getMessValidate('C0000018', ['従業員番号']) // 2020.05.19 append by ALEX_Kikawa CT137
  );

export const validateCompanyCode = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['会社コード']))
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regNumberHalfsize,
    getMessValidate('C0000003', ['会社コード']),
  );

export const validateFullName002 = Yup.string()
  .nullable()
  .checkEmptyString('氏名', 30, true);
export const validateFullNameKana002 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['氏名カナ']))
  .max(40, getMessValidate('C0000007', ['氏名カナ']))
  .matches(
    ConstRegx.regexFullSizeKatakanaWithSpaceFullHaftSize,
    getMessValidate('C0000002', ['氏名カナ']),
  );
export const validateOldName002 = Yup.string()
  .nullable()
  //.max(20, getMessValidate('C0000007', ['旧姓']));
  .checkEmptyString('旧姓', 20, false);

export const validateGender002 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['性別']), value => {
    return !value || value === '0' ? false : true;
  });


  // const checkBirthday = () => {
  //   return Yup.mixed().test({
  //     name: 'checkBirthday',
  //     test: function(value) {
  //       const ischeckAfterDay = isAfter(new Date(value), new Date());
  //       const isValidDate = isValidateDate(value);

  //       if(isEmptyAndBlank(value)){
  //         return this.createError({
  //         message: getMessValidate('C0000001', ['生年月日']),
  //         })
  //       }
  //       if (value && !isValidDate) {
  //         return this.createError({
  //           message: getMessValidate('C0000004', ['生年月日']),
  //         });
  //       }
  //       if (ischeckAfterDay) {
  //         return this.createError({
  //           message: getMessValidate('C0000050', ['生年月日']),
  //         });
  //       }
  //       return true;
  //     },
  //   });
  // };
  // Yup.addMethod(Yup.string, 'checkBirthday', checkBirthday);

//loc
export const validateBirthdayDatePicker = Yup.string()
  .nullable()
  //.checkBirthday();
  .test('is-empty-date-men004', getMessValidate('C0000001', ['生年月日']), value => {
    return isEmptyAndBlank(value) ? false : true;
  })
  .test('is-in-valid', getMessValidate('C0000004', ['生年月日']), value => {
    const isValidDate = isValidateDate(value);
    return !isValidDate ? false : true;
  })
  .test(
    'is-after-curren-date',
    getMessValidate('C0000040', ['生年月日']),
    value => {
      const ischeckAfterDay = isAfter(new Date(value), new Date());
      return ischeckAfterDay ? false : true;
    },
  );

export const validateDateJoinGroupMen004 = Yup.string()
  .nullable()
  .test(
    'is-empty-date-join-group',
    getMessValidate('C0000001', ['入会年月日']),
    value => {
      return isEmptyAndBlank(value) ? false : true;
    },
  )
  .test('is-in-valid', getMessValidate('C0000004', ['入会年月日']), value => {
    const isValidDate = isValidateDate(value);
    return !isValidDate ? false : true;
  })
  .test(
    'is-after-curren-date-join-group',
    getMessValidate('C0000057', ['入会年月日']),
    value => {
      const ischeckAfterDay = isAfter(new Date(value), new Date());
      return ischeckAfterDay ? false : true;
    },
  );

export const validateDateJoinCompanyMen004 = Yup.string()
  // .Yup.ref('nyukai_ymd')
  .nullable()
  .test('is-in-valid', getMessValidate('C0000004', ['入社年月日']), value => {
    const isValidDate = isValidateDate(value);
    return value && !isValidDate ? false : true;
  })
  .test(
    'is-after-curren-date-join-company',
    getMessValidate('C0000058', ['入社年月日']),
    value => {
      const ischeckAfterDay = isAfter(new Date(value), new Date());
      return ischeckAfterDay ? false : true;
    },
  );

const checkRetirementDate = refJointGroup => {
  return Yup.mixed().test({
    name: 'checkRetirementDate',
    test: function(value) {
      const jointGroup = this.resolve(refJointGroup);
      const ischeckAfterDay = isAfter(new Date(jointGroup), new Date(value));
      const isValidDate = isValidateDate(value);
      if (value && !isValidDate) {
        return this.createError({
          message: getMessValidate('C0000004', ['定年再雇用日']),
        });
      }
      if (ischeckAfterDay) {
        return this.createError({
          message: getMessValidate('C0000050', ['定年再雇用日']),
        });
      }
      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkRetirementDate', checkRetirementDate);

export const validateRetirementDateMen004 = Yup.string()
  .nullable()
  .checkRetirementDate(Yup.ref('dateJoinGroupMen004'));

const checkDateLeaveGroup = (refJointGroup, refRetirementDate)  => {
  return Yup.mixed().test({
    name: 'checkDateLeaveGroup',
    test: function(value) {
      const jointGroup = this.resolve(refJointGroup);
      const retirementDate = this.resolve(refRetirementDate);

      const isBeforJoinDate = isAfter(new Date(jointGroup), new Date(value));
      const isBeforRetirementDate = isAfter(new Date(retirementDate), new Date(value));
      const isValidDate = isValidateDate(value);

      if (value && !isValidDate) {
        return this.createError({
          message: getMessValidate('C0000004', ['退会年月日']),
        });
      }

      if (isBeforJoinDate) {
        return this.createError({
          message: getMessValidate('C0000044', ['退会年月日']),
        });
      }

      if (retirementDate && isBeforRetirementDate) {
        return this.createError({
          message: getMessValidate('C0000043', ['退会年月日']),
        });
      }

      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkDateLeaveGroup', checkDateLeaveGroup);

export const validateDateLeaveGroupMen004 = Yup.string()
  .nullable()
  .checkDateLeaveGroup(
    Yup.ref('dateJoinGroupMen004'),
    Yup.ref('retirementDateMen004'),
  );

export const validateMarryStatusMen004 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['婚姻状況']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateContractTypeMen004 = Yup.string()
  .nullable()
  .max(50, getMessValidate('C0000007', ['契約タイプ']));
  //.matches(ConstRegx.regxKanji, getMessValidate('C0000006', ['契約タイプ']));

export const validateContentReportMen004 = Yup.string()
  .nullable()
  .max(200, getMessValidate('C0000007', ['内容']));
  //.matches(ConstRegx.regxKanji, getMessValidate('C0000006', ['内容']));

export const validateDocumentStoreMen004 = Yup.string()
  .nullable()
  .max(100, getMessValidate('C0000007', ['書類保管場所']));
  //.matches(ConstRegx.regxKanji, getMessValidate('C0000006', ['書類保管場所']));
