import commonValidateMethod from './validateCommonMethod';
import {
  validateShobyoKubunBen005,
  validateShobyoNameBen005,
  validateKyufuKingakuDeath,
  validateKyufuKingakuSelf,
} from './validateCommon';

export const validateBen005 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('shobyo_kubun')) {
    obj.shobyo_kubun = validateShobyoKubunBen005;
  }
  if (fieldValidate?.includes('shobyo_name')) {
    obj.shobyo_name = validateShobyoNameBen005;
  }
  if (fieldValidate?.includes('kyufu_kingaku_death')) {
    obj.kyufu_kingaku_death = validateKyufuKingakuDeath;
  }
  if (fieldValidate?.includes('kyufu_kingaku_self')) {
    obj.kyufu_kingaku_self = validateKyufuKingakuSelf;
  }
};
