import { isArrayEmpty } from 'utils/common/ArrayUtils';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
import { isEmpty } from 'utils/common/ObjectUtils';
import { formatStrDate } from 'utils/common/DateUtils';
export const clearCompany = setFieldValue => {
  setTimeout(() => {
    setFieldValue('kaisha_cd', '');
    setFieldValue('kaisha_group_cd', '');
    setFieldValue('kaisha_name', '');
    setFieldValue('kyosai_type_cd', '');
    setFieldValue('kumiai_taikai_ymd', '');
    setFieldValue('kumiai_kanyu_ymd', '');
    setFieldValue('kyosai_type_cd', '');
  }, 0);
};
export const returnMessError = (
  itemDefault,
  data,
  kaisha_cd,
  messNoData,
  messHasMultiLine,
  nameFieldMess,
  setFieldValue,
) => {
  let mess = '';
  let item = itemDefault;
  if (isEmpty(data)) {
    if (!isEmptyAndBlank(kaisha_cd)) {
      mess = messNoData;
    }
  } else {
    item = data;
    mess = '';
  }
  updateValues(setFieldValue, item);
  setFieldValue(nameFieldMess, mess);
};
export const updateValues = (setFieldValue, item) => {
  const kaisha_cd = item?.company_cd || item?.kaisha_cd || '';
  setFieldValue('kaisha_cd', kaisha_cd);
  setFieldValue(
    'kaisha_name',
    item?.company_nm || item?.company_name || item?.kaisha_name || '',
  );
  // setFieldValue(
  //   'kaisha_group_cd',
  //   item?.company_group_cd || item?.kaisha_group_cd || '',
  // );
  setFieldValue(
    'kumiai_taikai_ymd',
    formatStrDate(item?.kumiai_taikai_ymd) || '',
  );
  setFieldValue(
    'kumiai_kanyu_ymd',
    formatStrDate(item?.kumiai_kanyu_ymd) || '',
  );
  setFieldValue('kyosai_type_cd', item?.kyosai_type_cd || '');
};
