/*
 *
 * InputCompany actions
 * Author: LanVt
 */
import { createReduxActions } from 'helper/action';
import { SEARCH_COMPANY_EXACTLY_COMMON } from './constants';

export const [
  searchCommpanyExactlyCommonRequest,
  searchCommpanyExactlyCommonSuccess,
  searchCommpanyExactlyCommonError,
] = createReduxActions(SEARCH_COMPANY_EXACTLY_COMMON);
