import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
import { isEmptySelect } from '../validateCommonFunction';

export const validateShobyoKubunBen005 = Yup.string()
  .nullable()
  .checkValidateShobyokubunBen005('死亡区分');

export const validateShobyoNameBen005 = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['死因']))
  .max(80, getMessValidate('C0000007', ['死因']));
export const validateKyufuKingakuDeath = Yup.string()
  .nullable()
  .checkCurrency('死亡補償金', 9, true);
export const validateKyufuKingakuSelf = Yup.string()
  .nullable()
  .checkCurrency('本人弔慰金', 9, true);
