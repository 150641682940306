/**
 *
 * InputLabel
 * Author: LanVt
 *
 */

import React, { Children, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import Input from '../Input';
import Textarea from '../Textarea';
import './index.css';

function InputLabel({
  className,
  classNameInput,
  classNameTextarea,
  keyMess,
  keyMessJson,
  name,
  isRequired,
  values,
  handleChange,
  handleBlur,
  isReadonly,
  autoFocus,
  isInputType,
  type,
  classNameLabel,
  classNameLblSpan,
  mess,
  messWarning,
  onClickLabel,
  children,
  keyLabelNote,
  keyLabelNoteJson,
  dataNote,
  pattern,
  maxLength,
  isI18n,
  isTypeView,
  valueInputReadOnly,
  isSubmitting,
  touched,
  format,
  isShowFormat,
  isDisable,
  nameParent,
  styleLabel,
  modeReadonly,
  ischeckArea,
}) {

  useEffect(() => {
  }, [classNameTextarea]);

  return (
    <div className={className} style={{ position: 'relative' }}>
      <Label
        styleLabel={styleLabel}
        keyMess={keyMess}
        keyMessJson={keyMessJson}
        isRequired={isRequired}
        className={classNameLabel}
        classNameSpan={classNameLblSpan}
        onClickLabel={onClickLabel}
        isI18n={isI18n}
      />

      {isInputType ? (
        <Input
          className={classNameInput}
          type={type}
          maxLength={maxLength}
          pattern={pattern}
          name={name}
          nameParent={nameParent}
          mess={mess}
          messWarning={messWarning}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isReadonly={isReadonly}
          autoFocus={autoFocus}
          isTypeView={isTypeView}
          valueInputReadOnly={valueInputReadOnly}
          isSubmitting={isSubmitting}
          isDisable={isDisable}
          touched={touched}
          format={format}
          isShowFormat={isShowFormat}
          modeReadonly={modeReadonly}
        />
      ) : (
        <Textarea
          className={classNameTextarea}
          type={type}
          maxLength={maxLength}
          pattern={pattern}
          name={name}
          mess={mess}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          isReadonly={isReadonly}
          autoFocus={autoFocus}
          isSubmitting={isSubmitting}
          touched={touched}
          format={format}
          isDisable={isDisable}
          ischeckArea={ischeckArea}
        />
      )}
      {keyLabelNote && (
        <>
          <Label
            keyMess={keyLabelNote}
            keyMessJson={keyLabelNoteJson}
            className="lbl-note"
          />
          {dataNote}
        </>
      )}
      {Children.toArray(children)}
    </div>
  );
}

InputLabel.propTypes = {
  className: PropTypes.string,
  mess: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classNameInput: PropTypes.string,
  classNameLabel: PropTypes.string,
  type: PropTypes.string,
  keyMess: PropTypes.string,
  name: PropTypes.string,
  nameParent: PropTypes.string,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  values: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onClickLabel: PropTypes.func,
  isInputType: PropTypes.bool,
  children: PropTypes.node,
  keyLabelNote: PropTypes.string,
  dataNote: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  isI18n: PropTypes.bool,
  isTypeView: PropTypes.bool,
  isShowFormat: PropTypes.bool,
  format: PropTypes.string,
  styleLabel: PropTypes.object,
  classNameTextarea: PropTypes.string,
  ischeckArea: PropTypes.bool,
};

InputLabel.defaultProps = {
  isInputType: true,
  isI18n: true,
  type: 'text',
  classNameInput: 'input_50 input_required',
  classNameTextarea: '',
  format: '',
  onClickLabel: () => {
  },
  isTypeView: false,
  styleLabel: {},
  ischeckArea: false,
};

export default InputLabel;
