import {
  validateGroupCompanyId,
  validateGroupCompanyName,
  validateAddressOne,
  validateAddressTwo,
  validateTel,
  validateFax,
  validateRepresentativeShoulderBook,
  validateNameOfRepresentative,
  validateNameOfExecutivePos,
  validateNameOfExecutiveNm,
  validateContactNameInvoice,
  validateContactName,
  validatePersonTEL,
  validatePersonFAX,
  validateMailAddress,
  validateBank,
  validateBankCode,
  validateBranch,
  validateBranchCode,
  validateTypeAccountMen002,
  validateNumberAccount,
  validateAccountHolderKana,
  validateConsignorCode,
  validateNoteMen002,
  validateCompanySealFile,
  validateSymbolFile,
  validateGroupCompanyIdListScreen,
  validateCompanyName,
  validateSourceNameFile
} from './validateCommon';
import commonValidateMethod from '../validateCommonMethod';
export const validateMEN002 = (obj, fieldValidate) => {
  //men002
  if (fieldValidate?.includes('groupCompanyId')) {
    obj.groupCompanyId = validateGroupCompanyId;
  }
  if (fieldValidate?.includes('groupCompanyName')) {
    obj.groupCompanyName = validateGroupCompanyName;
  }
  if (fieldValidate?.includes('sourceNameFile')) {
    obj.sourceNameFile = validateSourceNameFile;
  }
  if (fieldValidate?.includes('addressOne')) {
    obj.addressOne = validateAddressOne;
  }
  if (fieldValidate?.includes('addressTwo')) {
    obj.addressTwo = validateAddressTwo;
  }
  if (fieldValidate?.includes('tel')) {
    obj.tel = validateTel;
  }
  if (fieldValidate?.includes('fax')) {
    obj.fax = validateFax;
  }
  if (fieldValidate?.includes('representativeShoulderBook')) {
    obj.representativeShoulderBook = validateRepresentativeShoulderBook;
  }
  if (fieldValidate?.includes('nameOfRepresentative')) {
    obj.nameOfRepresentative = validateNameOfRepresentative;
  }
  if (fieldValidate?.includes('nameOfExecutivePos')) {
    obj.nameOfExecutivePos = validateNameOfExecutivePos;
  }
  if (fieldValidate?.includes('nameOfExecutiveNm')) {
    obj.nameOfExecutiveNm = validateNameOfExecutiveNm;
  }
  if (fieldValidate?.includes('contactNameInvoice')) {
    obj.contactNameInvoice = validateContactNameInvoice;
  }
  if (fieldValidate?.includes('contactName')) {
    obj.contactName = validateContactName;
  }
  if (fieldValidate?.includes('personTEL')) {
    obj.personTEL = validatePersonTEL;
  }
  if (fieldValidate?.includes('personFAX')) {
    obj.personFAX = validatePersonFAX;
  }
  if (fieldValidate?.includes('mailAddress')) {
    obj.mailAddress = validateMailAddress;
  }
  if (fieldValidate?.includes('bank')) {
    obj.bank = validateBank;
  }
  if (fieldValidate?.includes('bankCode')) {
    obj.bankCode = validateBankCode;
  }
  if (fieldValidate?.includes('branch')) {
    obj.branch = validateBranch;
  }
  if (fieldValidate?.includes('branchCode')) {
    obj.branchCode = validateBranchCode;
  }
  if (fieldValidate?.includes('typeAccount')){
    obj.typeAccount = validateTypeAccountMen002;
  }
  if (fieldValidate?.includes('numberAccount')) {
    obj.numberAccount = validateNumberAccount;
  }
  if (fieldValidate?.includes('accountHolderKana')) {
    obj.accountHolderKana = validateAccountHolderKana;
  }
  if (fieldValidate?.includes('consignorCode')) {
    obj.consignorCode = validateConsignorCode;
  }
  if (fieldValidate?.includes('noteMen002')) {
    obj.noteMen002 = validateNoteMen002;
  }
  if (fieldValidate?.includes('companySealFile')) {
    obj.companySealFile = validateCompanySealFile;
  }
  if (fieldValidate?.includes('symbolFile')) {
    obj.symbolFile = validateSymbolFile;
  }
  if (fieldValidate?.includes('groupCompanyIdListScreen')) {
    obj.groupCompanyIdListScreen = validateGroupCompanyIdListScreen;
  }
  if (fieldValidate?.includes('companyName')) {
    obj.companyName = validateCompanyName;
  }
  if (fieldValidate?.includes('companyCd')) {
    obj.symbolFile = validateSymbolFile;
  }
  return obj;
};
