import * as Yup from 'yup';
import { getMessValidate } from '../messValidate';
import ConstRegx from '../constantRegex';
import { isEmptyAndBlank } from 'utils/common/StringUtils';

export const validateGroupCompanyMse002 = Yup.string()
  .max(10, getMessValidate('C0000007', ['会社グループコード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社グループコード']),
  );
//validate MEN002
export const validateGroupCompanyId = Yup.string()
  .nullable()
  .checkNumber('会社グループコード', 3, true);

export const validateGroupCompanyName = Yup.string()
  .nullable()
  .checkEmptyString('会社グループ名', 20, true);

export const validateSourceNameFile = Yup.string()
  .nullable()
  .checkEmptyString('書類発信元名', 50, true);

// const isEmptyAndBlank = str => {
//     if (str === undefined || str === null || str === '') {
//       return true;
//     } else {
//       return isBlank(str);
//     }
//   };

const checkPostalCodeMen004 = () => {
  return Yup.mixed().test({
    name: 'checkPostalCodeMen004',
    test: function(value) {
      const flagIsNumber = ConstRegx.regxNumberOnly.test(value);
      if (isEmptyAndBlank(value)) {
        return this.createError({
          message: getMessValidate('C0000001', ['郵便番号']),
        });
      }
      if (value.length < 7 && !flagIsNumber) {
        return this.createError({
          message: getMessValidate('C0000006', ['郵便番号']),
        });
      }
      if (value.length < 7 || value.length > 8) {
        return this.createError({
          message: getMessValidate('C0000007', ['郵便番号']),
        });
      }
      if (!value.match(ConstRegx.regexPortalCode)) {
        return this.createError({
          message: getMessValidate('C0000006', ['郵便番号']),
        });
      }
      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkPostalCodeMen004', checkPostalCodeMen004);

export const validateAddressOne = Yup.string()
  .nullable()
  .checkPostalCodeMen004();
//.checkWithRegx('郵便番号', ConstRegx.regexPortalCode, 'C0000006', 8, true);

export const validateAddressTwo = Yup.string()
  .nullable()
  .checkEmptyString('住所', 100, true);

export const validateTel = Yup.string()
  .nullable()
  .checkWithRegx('TEL', ConstRegx.regexTel, 'C0000003', 15, true);

export const validateFax = Yup.string()
  .nullable()
  .checkWithRegx('FAX', ConstRegx.regexTel, 'C0000003', 15, false);

export const validateRepresentativeShoulderBook = Yup.string()
  .nullable()
  .checkEmptyString('代表者肩書', 20, true);
export const validateNameOfRepresentative = Yup.string()
  .nullable()
  .checkEmptyString('代表者氏名', 30, true);
export const validateNameOfExecutivePos = Yup.string()
  .nullable()
  .checkEmptyString('事務局長肩書', 30, false);
export const validateNameOfExecutiveNm = Yup.string()
  .nullable()
  .checkEmptyString('事務局長氏名', 30, false);
export const validateContactNameInvoice = Yup.string()
  .nullable()
  .max(30, getMessValidate('C0000007', ['担当者氏名(請求書用)']));
export const validateContactName = Yup.string()
  .nullable()
  .max(30, getMessValidate('C0000007', ['担当者氏名']));
export const validatePersonTEL = Yup.string()
  .nullable()
  // .max(15, getMessValidate('C0000007', ['担当者TEL']))
  // .matches(
  //   ConstRegx.regNumberHalfsize,
  //   getMessValidate('C0000018', ['担当者TEL']),
  // );
  .checkWithRegx(
    '担当者TEL',
    ConstRegx.regexTel,
    'C0000003',
    15,
    false,
  );

export const validatePersonFAX = Yup.string()
  .nullable()
  // .max(15, getMessValidate('C0000007', ['担当者FAX']))
  // .matches(
  //   ConstRegx.regNumberHalfsize,
  //   getMessValidate('C0000018', ['担当者FAX']),
  // );
  .checkWithRegx(
    '担当者FAX',
    ConstRegx.regexTel,
    'C0000003',
    15,
    false,
  );
export const validateMailAddress = Yup.string()
  .nullable()
  // .max(100, getMessValidate('C0000007', ['メールアドレス']))
  // .matches(
  //   ConstRegx.regCharacterAndNumberHalfSize,
  //   getMessValidate('C0000128', ['メールアドレス']),
  // );
  .checkWithRegx(
    'メールアドレス',
    ConstRegx.regCharacterAndNumberHalfSize,
    'C0000128',
    100,
    false,
  );
export const validateBank = Yup.string()
  .nullable()
  .checkEmptyString('銀行名', 30, true);
export const validateBankCode = Yup.string()
  .nullable()
  .checkWithRegx(
    '銀行コード',
    ConstRegx.regNumberHalfsize,
    'C0000003',
    4,
    true,
    4,
  );
export const validateBranch = Yup.string()
  .nullable()
  .checkEmptyString('支店名', 30, true);
export const validateBranchCode = Yup.string()
  .nullable()
  .checkWithRegx(
    '支店コード',
    ConstRegx.regNumberHalfsize,
    'C0000003',
    3,
    true,
    3,
  );

export const validateTypeAccountMen002 = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['口座種別']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateNumberAccount = Yup.string()
  .nullable()
  .checkWithRegx('口座番号', ConstRegx.regNumberHalfsize, 'C0000003', 7, true);

export const validateAccountHolderKana = Yup.string()
  .nullable()
  .checkWithRegx(
    '口座名義人ｶﾅ',
    ConstRegx.regexHalfSizeKatakana,
    'C0000026',
    30,
    true,
  );
export const validateConsignorCode = Yup.string()
  .nullable()
  .checkWithRegx(
    '委託者コード',
    ConstRegx.regNumberHalfsize,
    'C0000003',
    10,
    true,
    10,
  );
export const validateNoteMen002 = Yup.string()
  .nullable()
  .max(100, getMessValidate('C0000007', ['備考']));

const checkCompanySealFile = refAttachmentCompanySealFile => {
  return Yup.mixed().test({
    name: 'checkCompanySealFile',
    test: function(value) {
      const attachmentCompanySealFile = this.resolve(
        refAttachmentCompanySealFile,
      );
      var str = attachmentCompanySealFile?.data;

      return true;
    },
  });
};
Yup.addMethod(Yup.string, 'checkCompanySealFile', checkCompanySealFile);

export const validateCompanySealFile = Yup.string()
  .nullable()
  .checkCompanySealFile(Yup.ref('attachmentCompanySealFile'));
//.max(260, getMessValidate('C0000007', ['社印ファイル']));

export const validateSymbolFile = Yup.string()
  .nullable()
  .max(260, getMessValidate('C0000007', ['シンボルファイル']));

export const validateGroupCompanyIdListScreen = Yup.string()
  .nullable()
  .max(4, getMessValidate('C0000007', ['会社コード']))
  .matches(
    ConstRegx.regexNumberFullSizeHalfSize,
    getMessValidate('C0000006', ['会社コード']),
  );

export const validateCompanyName = Yup.string()
  .nullable()
  .max(35, getMessValidate('C0000007', ['会社名']));
//
