/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.Button';

export default defineMessages({
  buttonBenefit: {
    id: `${scope}.benefit`,
    defaultMessage: 'Benefit ',
  },
  buttonBenefitTop: {
    id: `${scope}.benefit_top`,
    defaultMessage: 'benefit top ',
  },
  buttonImportHistory: {
    id: `${scope}.import_history`,
    defaultMessage: 'Import History',
  },
  buttonBenefitApprovHistory: {
    id: `${scope}.benefit_approv_history`,
    defaultMessage: 'Benefit Approval History',
  },
  buttonPaymentHistory: {
    id: `${scope}.payment_history`,
    defaultMessage: 'Payment History',
  },
  buttonMonthlyAnnualProcess: {
    id: `${scope}.monthly_annual_process`,
    defaultMessage: 'Monthly Annual Process',
  },
  buttonMasterMaintenance: {
    id: `${scope}.master_maintenance`,
    defaultMessage: 'Master Maintenance',
  },
  buttonSystemManagement: {
    id: `${scope}.system_management`,
    defaultMessage: 'System Management',
  },
  buttonCancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'cancel',
  },
  buttonCancelHalfSize: {
    id: `${scope}.cancel_haftsize`,
    defaultMessage: 'cancel haftsize',
  },
  buttonConfirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'confirm',
  },
  buttonTempSave: {
    id: `${scope}.temp_save`,
    defaultMessage: 'Temporarily saved',
  },
  buttonBenefitFixed: {
    id: `${scope}.benefit_fixed`,
    defaultMessage: 'Benefit fixed',
  },
  buttonConfirmRelease: {
    id: `${scope}.confirm_release`,
    defaultMessage: 'Confirm release',
  },
  buttonDeleteData: {
    id: `${scope}.delete_data`,
    defaultMessage: 'Delete data',
  },
  buttonReset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset data',
  },
  buttonClose: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  buttonBack: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  buttonEdit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  buttonRegistration: {
    id: `${scope}.registration`,
    defaultMessage: 'registration',
  },
  buttonSearch: {
    id: `${scope}.search`,
    defaultMessage: 'search',
  },
  buttonClearCondition: {
    id: `${scope}.clear_condition`,
    defaultMessage: 'clearcondition',
  },
  buttonCreateNew: {
    id: `${scope}.create_new`,
    defaultMessage: 'Create New!',
  },
  buttonChangePass: {
    id: `${scope}.change_pass`,
    defaultMessage: 'change pass',
  },
  buttonLogout: {
    id: `${scope}.logout`,
    defaultMessage: 'logout',
  },
  buttonLogin: {
    id: `${scope}.login`,
    defaultMessage: 'login',
  },
  buttonChange: {
    id: `${scope}.change`,
    defaultMessage: 'change',
  },
  buttonReturn: {
    id: `${scope}.return`,
    defaultMessage: 'return',
  },
  buttonNewRegistration: {
    id: `${scope}.new_registration`,
    defaultMessage: 'new_registration',
  },
  buttonNew: {
    id: `${scope}.new`,
    defaultMessage: 'new',
  },
  buttonMutualAidBenefitPaymentList: {
    id: `${scope}.mutual_aid_benefit_payment_list`,
    defaultMessage: 'mutual_aid_benefit_payment_list',
  },
  buttonMonthlyBenefitSummaryTable: {
    id: `${scope}.monthly_benefit_summary_table`,
    defaultMessage: 'monthly_benefit_summary_table',
  },
  buttonLedgerLedger: {
    id: `${scope}.buttonLedgerLedger`,
    defaultMessage: 'buttonLedgerLedger',
  },
  buttonMutualAidClaimManagement: {
    id: `${scope}.mutual_aid_claim_management`,
    defaultMessage: 'mutual_aid_claim_management',
  },
  buttonSubscriptionUnderwritingAgeNotification: {
    id: `${scope}.subscription_underwriting_age_notification`,
    defaultMessage: 'subscription_underwriting_age_notification',
  },
  buttonMemberRegistrationRegister: {
    id: `${scope}.member_registration_register`,
    defaultMessage: 'member_registration_register',
  },
  buttonOrphanagePension: {
    id: `${scope}.orphanage_pension`,
    defaultMessage: 'orphanage_pension',
  },
  buttonMutualAidTypeMaster: {
    id: `${scope}.mutual_aid_type_master`,
    defaultMessage: 'Mutual Aid Type Master',
  },
  buttonCompanyGroupMaster: {
    id: `${scope}.company_group_master`,
    defaultMessage: 'Company Group Master',
  },
  buttonCompanyMaster: {
    id: `${scope}.company_master`,
    defaultMessage: 'company master',
  },
  buttonSubscriberMaster: {
    id: `${scope}.subscriber_master`,
    defaultMessage: ' Subscriber Master',
  },
  buttonUserMaster: {
    id: `${scope}.user_master`,
    defaultMessage: '  User Master',
  },
  buttonSubmissionMaster: {
    id: `${scope}.submission_master`,
    defaultMessage: 'Submission Master',
  },
  buttonSubscriberMasterEXCEL: {
    id: `${scope}.subscriber_master_EXCEL`,
    defaultMessage: 'Subscriber Master EXCEL',
  },
  buttonPaymentDataCreation: {
    id: `${scope}.payment_data_creation`,
    defaultMessage: 'Payment Data Creation',
  },
  buttonResetPass: {
    id: `${scope}.buttonResetPass`,
    defaultMessage: 'buttonResetPass',
  },
  buttonApprove: {
    id: `${scope}.approve`,
    defaultMessage: 'approve',
  },
  buttonCancelApprove: {
    id: `${scope}.cancel_approve`,
    defaultMessage: 'cancel approve',
  },
  //2020.05.21 append by ALEX_Nakasone CT38 Start
  buttonCancelApprovehinin: {
    id: `${scope}.cancel_approve_hinin`,
    defaultMessage: 'cancel approve',
  },
  //2020.05.21 append by ALEX_Nakasone CT38 End
  buttonChangePaymentDate: {
    id: `${scope}.change_payment_date`,
    defaultMessage: 'change payment date',
  },
  buttonListPayment: {
    id: `${scope}.list_payment`,
    defaultMessage: 'list payment',
  },
  buttonImport: {
    id: `${scope}.buttonImport`,
    defaultMessage: 'Button Import',
  },
  buttonSubsidyOutput: {
    id: `${scope}.subsidy_output`,
    defaultMessage: 'Button subsidy output',
  },
  buttonImportImp: {
    id: `${scope}.import_imp`,
    defaultMessage: 'Button import',
  },
  buttonCheckDataImp: {
    id: `${scope}.check_data_imp`,
    defaultMessage: 'Button check data',
  },
  buttonSaveTempImp: {
    id: `${scope}.save_temporary_imp`,
    defaultMessage: 'Button save temporary',
  },
  buttonConfirmImp: {
    id: `${scope}.confirm_subsidize_imp`,
    defaultMessage: 'Button confirm subsidize',
  },
  buttonCancelImp: {
    id: `${scope}.cancel_approve_imp`,
    defaultMessage: 'Button cancel approve',
  },
  buttonDeleteImp: {
    id: `${scope}.delete_data_imp`,
    defaultMessage: 'Button delete data',
  },
  buttonLostMember: {
    id: `${scope}.buttonLostMember`,
    defaultMessage: 'buttonLostMember',
  },

  buttonChargeCalculation: {
    id: `${scope}.change_calculation`,
    defaultMessage: 'Charge Calculation',
  },
  buttonSave: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  buttonInvoiceCreation: {
    id: `${scope}.invoice_creation`,
    defaultMessage: 'Invoice creation',
  },

  buttonRegisterImp006: {
    id: `${scope}.imp006_register`,
    defaultMessage: 'Register',
  },
  buttonImportFileImp: {
    id: `${scope}.import_file_imp`,
    defaultMessage: 'Import',
  },
});
