/*
 *
 * Sea001 reducer
 * Author: LanVt
 */
import produce from 'immer';
import {
  SEARCH_CODE_COMPANY_REQUEST,
  SEARCH_CODE_COMPANY_SUCCESS,
  SEARCH_CODE_COMPANY_ERROR,
  RESET_DATA,
} from './constants';

export const initialState = {
  error: {},
  dataListGroupCompany: [],
  dataSearch: [],
  messSearch: '',
  isLoadingSearch: false,
};

/* eslint-disable default-case, no-param-reassign */
const sea001Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_DATA:
        draft.dataListGroupCompany = [];
        draft.dataSearch = [];
        draft.error = {};
        draft.messSearch = '';
        draft.isLoadingGroupCompany = false;
        draft.isLoadingSearch = false;
        break;
      case SEARCH_CODE_COMPANY_REQUEST:
        draft.dataSearch = [];
        draft.error = {};
        draft.messSearch = '';
        draft.isLoadingSearch = true;
        break;
      case SEARCH_CODE_COMPANY_SUCCESS:
        draft.dataSearch = action?.params?.data?.companies;
        draft.error = {};
        draft.messSearch = action?.params?.data?.message;
        draft.isLoadingSearch = false;
        break;
      case SEARCH_CODE_COMPANY_ERROR:
        draft.dataSearch = [];
        draft.error = action;
        draft.messSearch = '';
        draft.isLoadingSearch = false;
        break;
    }
  });
export default sea001Reducer;
