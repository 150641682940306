/*
 * NetWork Messages
 *
 * This contains all the text for the NetWork component.
 * Author: TruongTx
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.NetWork';

export default defineMessages({
  mesNetWorking: {
    id: `${scope}.mesNetWorking`,
    defaultMessage: 'Not connection networking!',
  },
});
