import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
import { isEmptyAndBlank } from 'utils/common/StringUtils';
const checkValidateShobyokubunBen005 = txtField => {
  return Yup.mixed().test({
    name: 'checkValidateShobyokubunBen005',
    test: function(value) {
      const msgRequired = getMessValidate('C0000001', [txtField]);
      if (isEmptyAndBlank(value)) {
        return this.createError({
          message: msgRequired,
        });
      }
      return true;
    },
  });
};
Yup.addMethod(
  Yup.string,
  'checkValidateShobyokubunBen005',
  checkValidateShobyokubunBen005,
);
