export const createConstants = name => [
  `${name}_REQUEST`,
  `${name}_SUCCESS`,
  `${name}_ERROR`,
];

export const createReduxActions = name => [
  payload => ({ type: `${name}_REQUEST`, payload }),
  payload => ({ type: `${name}_SUCCESS`, payload }),
  payload => ({ type: `${name}_ERROR`, payload }),
];
