export const createMessObj = (id, defaultMessage) => ({
  id,
  defaultMessage: defaultMessage || id,
});

export const getMessI18n = (name, intl, isI18n = true) => {
  try {
    return name && isI18n ? intl?.formatMessage(name) : '';
  } catch (e) {
    return name;
  }
};
