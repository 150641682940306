import * as Yup from 'yup';
import ConstRoot from 'config/constant';
import { getMessValidate } from 'helper/form/messValidate';
import ConstantRegex from 'helper/form/constantRegex';
import commonValidateMethodBen from './validateCommonMethod';

export const validateTaishoshaFullname = Yup.string()
  .nullable()
  .checkTaishoshaFullname(Yup.ref('kyufu_shubetsu_cd'));

export const validateKyufuKingaku = Yup.string()
  .nullable()
  .checkValidateKyufuKingaku(Yup.ref('kyufu_shubetsu_cd'), 7);

export const validateKojinFutangaku = Yup.string()
  .nullable()
  .checkValidateKojinFutangaku(Yup.ref('kyufu_shubetsu_cd'), 9);

export const validateBenefitsNo = Yup.string()
  .nullable()
  .checkBenefitsNo(
    Yup.ref('kyufu_gokei_kingaku'),
    getMessValidate('C0000028'),
    getMessValidate('C0000003', ['元給付No']),
    getMessValidate('C0000001', ['元給付No']),
    getMessValidate('C0000007', ['元給付No']),
    7,
  );

export const validateBenefitsNote = Yup.string()
  .nullable()
  .max(500, getMessValidate('C0000007', ['備考']));

export const validateKanjiName = Yup.string()
  .nullable()
  .max(30, getMessValidate('C0000007', ['漢字氏名']));

export const validateNameOfKana = Yup.string()
  .nullable()
  .max(40, getMessValidate('C0000007', ['カナ氏名']))
  .matches(
    ConstantRegex.regexFullSizeKatakanaWithSpace,
    getMessValidate('C0000002', ['カナ氏名']),
  );

export const validateKaishaCd = Yup.string()
  .nullable()
  .checkKaishaCdOrJugyoinNo(
    Yup.ref('isBen012'),
    getMessValidate('C0000003', ['会社コード']),
    getMessValidate('C0000001', ['会社コード']),
    getMessValidate('C0000007', ['会社コード']),
    ConstRoot.maxLengthCompanyCd,
    ConstRoot.maxLengthCompanyCd,
    true,
  );
export const validateJugyoinNo = Yup.string()
  .nullable()
  .checkJugyoinNo(
    Yup.ref('isBen012'),
    //getMessValidate('C0000003', ['従業員番号']),  // 2020.05.19 delete by ALEX_Kikawa CT137
    getMessValidate('C0000018', ['従業員番号']),    // 2020.05.19 append by ALEX_Kikawa CT137
    getMessValidate('C0000001', ['従業員番号']),
    getMessValidate('C0000007', ['従業員番号']),
    ConstRoot.maxLengthEmployeeCd,
    ConstRoot.maxLengthEmployeeCd,
    true,
  );
export const validateShobyoKubun = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['傷病区分']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateTsuzukiganaCode = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['続柄']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateHisaiJokyoCode = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['災害状況']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateMeigiKubun = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['名義区分']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateSeibetsuCd = Yup.string()
  .nullable()
  .test('is-empty-select', getMessValidate('C0000001', ['性別']), value => {
    return !value || value === '0' ? false : true;
  });

export const validateShobyoName = Yup.string()
  .nullable()
  .checkValidateRequired('傷病名', 80, true);

export const validateHaigushaNendoNenrei = Yup.string()
  .nullable()
  .checkNumber('年度年齢', 2, true);

export const validateRequestDate = Yup.string()
  .nullable()
  .checkRequestDay(
    Yup.ref('jiyuhassei_ymd'),
    Yup.ref('kyufu_shubetsu_cd'),
    // getMessValidate('C0000077'),
    getMessValidate('C0000039'),
    getMessValidate('C0000004', ['申請日']),
    getMessValidate('C0000001', ['申請日']),
  );

export const validateSeinengappiYmd = Yup.string()
  .nullable()
  .checkSeinengappiYmd();

// ngay phat sinh
export const validateDateOfBirth = Yup.string()
  .nullable()
  .checkDateOfBirth(
    Yup.ref('nyukai_ymd'),
    Yup.ref('taikai_ymd'),
    Yup.ref('shinsei_kaishi_ymd'),
    Yup.ref('kumiai_taikai_ymd'),
    Yup.ref('kyufu_shubetsu_cd'),
  );
