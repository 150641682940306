/**
 *
 * Select
 * Author: LanVt
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { getInfoMessage } from '../../helper/form/infoMessageErrorI8n';
import Const from '../../helper/form/constant';
import './index.scss';
function Select({
  className,
  name,
  valueDefault,
  onChange,
  handleBlur,
  isDisable,
  data,
  isI18n,
  keyMess,
  keyValue,
  valueRequireDefaultSelect,
  touched,
  isSubmitting,
  messError,
  nameSelectName,
  setFieldValue,
  isRequired,
  isShowNone,
}) {
  const selectRef = useRef(null);
  const clssTooltipDefault = 'tooltip';
  const clssTooltipFocus = 'tooltip tooltip-focus';
  const [classTooltip, setClassTooltip] = useState(clssTooltipDefault);
  const [isFocus, setIsFocus] = useState(false);
  const leftPosition = selectRef?.current?.offsetLeft + 'px';
  const idTooltip = 'tooltip-id' + name;
  const isError = (touched?.[name] || isSubmitting || isFocus) && messError;
  useEffect(() => {
    if (isFocus) {
      const clsTooltip = isError ? clssTooltipFocus : clssTooltipDefault;
      if (clsTooltip != classTooltip) {
        setClassTooltip(clsTooltip);
      }
    } else {
      if (clssTooltipDefault != classTooltip) {
        setClassTooltip(clssTooltipDefault);
      }
    }
  }, [isFocus]);
  let classNameSelect = isError ? `${className} input-error` : className;
  let styleSelectByRequired = {};

  if (isRequired) {
    styleSelectByRequired = {
      maxHeight: 100,
      // border: '2px solid rgba(64, 101, 171, 0.5) ',
      // backgroundColor: 'rgba(88, 135, 225, 0.2)',
    };
  } else {
    styleSelectByRequired = {
      maxHeight: 100,
      //backgroundColor: '#fff'
    };
  }

  return (
    <>
      <select
        style={styleSelectByRequired}
        ref={selectRef}
        className={isDisable ? className : classNameSelect}
        name={name}
        value={valueDefault}
        onChange={e => {
          if (setFieldValue && nameSelectName) {
            const textContent = e?.currentTarget?.textContent;
            setFieldValue(nameSelectName, textContent);
          }

          onChange(e);
        }}
        disabled={isDisable ? 'disabled' : ''}
        onBlur={e => {
          if (!isDisable) {
            setIsFocus(false);
            handleBlur(e);
          }
        }}
        onFocus={e => {
          if (!isDisable) {
            setIsFocus(true);
          }
        }}
      >
        {isShowNone && (
          <>
            {valueRequireDefaultSelect ? (
              <FormattedMessage id={messages?.['default']?.id} key={0}>
                {message => <option value="">{message}</option>}
              </FormattedMessage>
            ) : (
              <option value="" key={name} />
            )}
          </>
        )}

        {data?.map((value, index) => {
          const key = value?.[keyMess];
          const mess = messages?.[key];
          const valueItem = value?.[keyValue];
          if (isI18n) {
            return (
              <FormattedMessage id={mess?.id} key={index}>
                {message => <option value={valueItem}>{message}</option>}
              </FormattedMessage>
            );
          }
          return (
            <option value={valueItem} key={index}>
              {key}
            </option>
          );
        })}
      </select>
      {isError && (
        <div
          id={idTooltip}
          className={classTooltip}
          role="tooltip"
          aria-hidden="true"
          style={{ left: leftPosition }}
        >
          {messError}
        </div>
      )}
    </>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  nameSelectName: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isDisable: PropTypes.bool,
  data: PropTypes.array,
  isI18n: PropTypes.bool,
  keyMess: PropTypes.string,
  keyValue: PropTypes.string,
  valueRequireDefaultSelect: PropTypes.bool,
  isRequired: PropTypes.bool,
  isShowNone: PropTypes.bool,
  keyMessLabel: PropTypes.string,
};

Select.defaultProps = {
  className: '',
  name: '',
  isDisable: false,
  isI18n: false,
  valueRequireDefaultSelect: false,
  isRequired: false,
  isShowNone: true,
  keyMessLabel: '',
  handleBlur: () => {},
};

export default Select;
