export default function authHeader(method = 'GET') {
  // return authorization header with jwt token
  const token = localStorage.getItem('token');
  let objHeader = { method };
  if (token) {
    objHeader = { ...objHeader, headers: { Authorization: token } };
    if (method === 'PATCH') {
      objHeader = {
        ...objHeader,
      };
    }
    return objHeader;
  }
  return objHeader;
}
