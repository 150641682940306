import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';

export const validateKyugyoKaishiYmd = Yup.string()
  .nullable()
  .checkStartDateBen007(
    Yup.ref('jiyuhassei_ymd'), //ngay phat sinh -- 発生日
    Yup.ref('kyugyo_shuryo_ymd'), //ngay ket thuc -- 休業終了日
    Yup.ref('check_require_shobyomimaikin'), // checkbox 傷病見舞金申請)
    '休業開始日',
    getMessValidate('C0000025'),
    getMessValidate('C0000076'),
  );
export const validateKyugyoShuryoYmd = Yup.string()
  .nullable()
  .checkEndDateBen007(
    null,
    Yup.ref('kyugyo_kaishi_ymd'), //ngay bat dau -- 休業開始日
    Yup.ref('check_require_shobyomimaikin'), // checkbox 傷病見舞金申請)
    '休業終了日',
    getMessValidate('C0000076'),
  );
export const validateShinseiKikanNissu = Yup.string()
  .nullable()
  .checkNumDaysBen007(
    Yup.ref('check_require_shobyomimaikin'),
    Yup.ref('vacation_period'),
    getMessValidate('C0000027'),
    '休業日数',
    5,
  );
export const validateNyuinKaishiYmd = Yup.string()
  .nullable()
  .checkStartDateBen007(
    Yup.ref('jiyuhassei_ymd'), //ngay phat sinh -- 発生日
    Yup.ref('nyuin_shuryo_ymd'), //ngay ket thuc -- 休業終了日
    Yup.ref('check_require_nyuinhoshokin'), // checkbox 入院補償金)
    '入院開始日',
    getMessValidate('C0000052'),
    getMessValidate('C0000053'),
  );
export const validateNyuinShuryoYmd = Yup.string()
  .nullable()
  .checkEndDateBen007(
    Yup.ref('shinsei_kaishi_ymd'), //ngay bat dau -- 休業開始日
    Yup.ref('nyuin_kaishi_ymd'), //ngay bat dau -- 休業開始日
    Yup.ref('check_require_nyuinhoshokin'), // checkbox 入院補償金)
    '入院終了日',
    getMessValidate('C0000053'),
    getMessValidate('C0000055'),
  );
export const validateNyuinDays = Yup.string()
  .nullable()
  .checkNumDaysBen007(
    Yup.ref('check_require_nyuinhoshokin'),
    Yup.ref('hospitalize_period'),
    getMessValidate('C0000054'),
    '入院日数',
    5,
  );

export const validateSagakubedShiyoKaishiYmd = Yup.string()
  .nullable()
  .checkStartDateBen007(
    Yup.ref('jiyuhassei_ymd'), //ngay phat sinh -- 発生日
    Yup.ref('sagakubed_shiyo_shuryo_ymd'), //ngay ket thuc -- 休業終了日
    Yup.ref('check_require_sagakubedryo'), // checkbox 入院補償金)
    '使用開始日',
    getMessValidate('C0000031'), //休業開始日/Ngay bat đau nghi ＜ 発生日/Ngay phat sinh
    getMessValidate('C0000032'), //休業開始日/Ngày bắt đầu nghỉ ＞ 休業終了日/Ngày kết thúc nghỉ
  );

export const validateTsuinKaishiYmd = Yup.string()
  .nullable()
  .checkStartDateBen007(
    Yup.ref('jiyuhassei_ymd'), //ngay phat sinh -- 発生日
    Yup.ref('tsuin_shuryo_ymd'), //ngay ket thuc -- 休業終了日
    Yup.ref('check_require_tsuinhoshokin'), // checkbox 入院補償金)
    '通院開始日',
    getMessValidate('C0000046'),
    getMessValidate('C0000047'),
  );
export const validateSagakubedShiyoShuryoYmd = Yup.string()
  .nullable()
  .checkEndDateSagakubedShiyoShuryoYmd(
    Yup.ref('shinsei_kaishi_ymd'), // ngay yeu cau ---- 申請日
    Yup.ref('sagakubed_shiyo_kaishi_ymd'), //ngay bat dau -- 休業開始日
    Yup.ref('check_require_sagakubedryo'), // checkbox 入院補償金)
    '使用終了日',
    getMessValidate('C0000035'),
    getMessValidate('C0000032'),
    'C0000034',
  );
export const validateTsuinShuryoYmd = Yup.string()
  .nullable()
  .checkEndDateBen007(
    Yup.ref('shinsei_kaishi_ymd'), // ngay yeu cau ---- 申請日
    Yup.ref('tsuin_kaishi_ymd'), //ngay bat dau -- 休業開始日
    Yup.ref('check_require_tsuinhoshokin'), // checkbox 入院補償金)
    '通院終了日',
    getMessValidate('C0000047'),
    getMessValidate('C0000049'),
  );
export const validateSagakubedShiyoDays = Yup.string()
  .nullable()
  .checkSagakubedShiyoDaysBen007(
    Yup.ref('check_require_sagakubedryo'),
    Yup.ref('bed_using_period'),
    getMessValidate('C0000033'),
    '使用日数',
    5,
  );
export const validateGaihakuDays = Yup.string()
  .nullable()
  .checkDateOutpatient(
    Yup.ref('check_require_nyuinhoshokin'), // checkbox 入院補償金))
    Yup.ref('nyuin_days'), // so ngay nhap vien
    getMessValidate('C0000056'),
    '外泊日数',
    5,
  );
export const validateActualHospitalizeDays = Yup.string()
  .nullable()
  .checkActualHospitalizeDays(
    Yup.ref('check_require_nyuinhoshokin'), // checkbox 入院補償金))
    Yup.ref('nyuin_days'), // so ngay nhap vien
    Yup.ref('gaihaku_days'), // so ngay ngoai tru
    getMessValidate('C0000012'),
    '入院実日数',
    5,
  );

export const validateByoinTsuinDays = Yup.string()
  .nullable()
  .checkByoinTsuinDays(
    Yup.ref('check_require_tsuinhoshokin'), // checkbox 入院補償金)
    Yup.ref('moto_kyufu_id'),
    Yup.ref('hospital_days'),
    '病院通院日数',
    5,
  );

export const validateSeikotsuinTsuinDays = Yup.string()
  .nullable()
  .checkSeikotsuinTsuinDays(
    Yup.ref('check_require_tsuinhoshokin'), // checkbox 入院補償金)
    Yup.ref('hospital_days'),
    '整骨院通院日数',
    5,
  );
export const validateShiharaigaku = Yup.string()
  .nullable()
  .checkIsNumberDecBen007(Yup.ref('check_require_sagakubedryo'), '支払額', 9);
export const validateSicknessMoney = Yup.string()
  .nullable()
  .checkIsMoneyDecBen007(
    Yup.ref('check_require_shobyomimaikin'),
    '傷病見舞金',
    9,
  );
export const validateHospitalMoney = Yup.string()
  .nullable()
  .checkIsMoneyDecBen007(
    Yup.ref('check_require_nyuinhoshokin'),
    '入院補償金',
    9,
  );
export const validateHospitalDays = Yup.string()
  .nullable()
  .checkHospitalDaysBen007(
    Yup.ref('check_require_tsuinhoshokin'),
    '合計通院日数',
    6,
  );
export const validateBenefitMoney = Yup.string()
  .nullable()
  .checkIsMoneyDecBen007(
    Yup.ref('check_require_tsuinhoshokin'),
    '給付金算出日数',
    6,
    false,
  );
export const validateExaminationMoney = Yup.string()
  .nullable()
  .checkIsMoneyDecBen007(
    Yup.ref('check_require_tsuinhoshokin'),
    '通院補償金',
    9,
  );
export const validateServiceBedMoney = Yup.string()
  .nullable()
  .checkServiceBedMone(
    Yup.ref('check_require_sagakubedryo'),
    '差額ベッド料',
    9,
  );
