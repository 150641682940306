import { createConstants } from '../../helper/action';
import { env, STAGE } from '../../config/global';
export const GET_USER_PERMISSION = 'app/GET_USER_PERMISSION';
export const [
  GET_USER_PERMISSION_REQUEST,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_ERROR,
] = createConstants(GET_USER_PERMISSION);
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

export const DEFAULT_TITLE = 'Kyousaikai';

export const mapScreenTitle = {
  '': '共済会システムメニュー',
  BEN002: '結婚祝金登録',
  BEN003: '出産祝金登録',
  BEN004: '家族弔慰金登録',
  BEN005: '本人弔慰金登録',
  BEN006: '後遺障害補償金登録',
  BEN007: '傷病/入院/通院/差額ベッド登録',
  BEN008: '配偶者健診補助金',
  BEN009: '災害見舞金登録',
  BEN010: '盗難見舞金登録',
  BEN011: '退会餞別金登録',
  BEN012: '給付登録/給付履歴',

  IMP002: 'WAVE給付申請データ取込',
  IMP003: '一括申請データ取込 家族弔慰金',
  IMP004: '一括申請データ取込 配偶者健診補助金',
  IMP005: '一括申請データ取込 退会餞別金',
  IMP006: '連携ファイル取込履歴',

  APA001: '給付明細作成',
  APA002: '承認一覧',
  APA003: '承認登録',
  APA004: '支払データ一覧',
  APA005: '支払データ作成',

  MEN001: '共済タイプマスタ登録',
  MEN002: '会社グループマスタ登録',
  MEN003: '会社マスタ登録',
  MEN004: '加入者マスタ登録',
  MEN005: 'ユーザーマスタ登録',
  MEN006: '提出書類マスタ登録',
  MEN007: '加入者マスタ(EXCEL入出力)',

  MSE001: '共済タイプマスタ一覧',
  MSE002: '会社グループマスタ一覧',
  MSE003: '会社マスタ一覧',
  MSE004: '加入者マスタ一覧',
  MSE005: 'ユーザーマスタ一覧',
  MSE006: '提出書類マスタ一覧',

  SAP001: 'SAP会員データ取込',
  SAP002: 'SAP連携予定データ取込',
  SAP003: 'SAP連携予定データ照合',
  SAP004: 'SAP共済掛金集計データ取込',

  PER001: '共済給付金支払一覧作成',
  PER002: '月別給付集計表作成',
  PER003: '共済掛金請求管理',
  PER004: '共済掛金台帳',
  PER005: '加入引受年齢到達通知書作成',
  PER006: '加入者一覧作成',

  IKU001: '遺児育英年金_給付申請登録',
  IKU003: '遺児育英年金_給付申請一覧',

  HIS001: '給付データ出力（一覧）',
  HIS002: '給付データ出力（詳細）',

  COM001: '共済会システムメニュー',
  COM002: '共済会システムログイン',
  COM003: 'パスワード変更',
};
const dataWijimoKey = {
  [env.DEVELOP]:
    'alex-rgfukushikai.com,684199426846347#B0YIJVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUrFneDFUS6UVa8lXMLlXa7BzKZZGZ9YkbS5WVxcWbvJ5K73WS7QnT5Mmb6ImV4JHU6QXcGJWN6gzU63mZvBXR9NXZRljS7clZz2mUVZUQvY6bZRmbzgXe7dmbxgDS8ZETTtGVxBVYuhmVCNDN8l6UWRlYZV4bqhVONJlRrkGTX9mVUxkWih5ZKRDa43GR83SVNlXYPRHZSFmZwI4UXFkbuFVMyMkZGpHSyJjZ8BlUmVjb8J5VldnNKplbud4RTtCVylnTBFlemh4Q8NWR7YzYEFnU9d7RvkWQktma8smRDBnSNlETKZVRu3iMVNTNPZ5NzQjdLRVZKRUdX54Y5smeLJnS8Z7QrJmTitiVpNmZrBHTzgncmBzLWhTNronSCZGcKJHO6sSNkVWY5RWWE9GdGZzU5RDNYd5Rrh4LMNlNP3EbrpVdQVDUZBlQT3SNXZ6ROh6LaJkI0IyUiwiIBNjM4IUQzEjI0ICSiwSM4cjNxYDO5ETM0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI4MDN4ITMgcDMwEDNyAjMiojI4J7QiwiIt36YukWYrlGazV7a5Z6Zy5CelxWYiojIz5GRiwiIIO88zO88hO888K88NO88eO889K88vK88DO88sO88iK88iojIh94QiwiI7QzM6QDO6IDN9kTM4gjNiojIklkIs4XXbpjInxmZiwiIyYHNyAIMJo',
  [env.STAGING]:
    'stg.fukushikai.com,684199426846347#B0MVYdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZO3mQDhGURNWQ9YmZOV5RvdzMRZDSMhGSq54RnJTMSxGSNV6Nr3GT4p4LykTWnJ7L6xWRQd7LtdmaVhkaKRnUCNnQmx4NkdFVyRGZLNXYHdWdRBHU92SMuVDROR5M4RFMBNEe4kkZ4g4RxNlR8MXcnBDWSRje6lXahtCMVVVdzVGUD5kQJlHMzQWT82SZwoEZIJTSxJXTnpFSxQ4ZPBDOuNGWrsWNvYHdJRXYmxUTwREbVJ6R896YmFFbzEnaLpFRplzUC3CUo9EVEJHcNpme8EkaWFUM0ZnUHJFeBtGUNdEd5IWWVtiQ4UFUCZFU496bwcGcsFnbzxmUax4MycXZ6IVWHpGTqt6LhticLZDRYJjdEZkcoFjUEtyYx9WcplmawlUM694UXx6RvYTN4dnYpF7Txp5V7QGb44GcF3ESKFTaaJjSlp5ZNFlNxclVrhXSPFEaQNmI0IyUiwiIGZjMEREO7YjI0ICSiwCOyQzMzIzMwETM0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI5MDNzEDMgcDMwEDNyAjMiojI4J7QiwiIt36YukWYrlGazV7a5ZmLnR7ciojIz5GRiwiIIO88zO88hO888K88NO88eO889K88vK88DO88sO88iK88iojIh94QiwiI7QzM6QDO6IDN9kTM4gjNiojIklkIs4XXbpjInxmZiwiIyYHNyAjMJoII',
  [env.PRODUCTION]:
    'prd.fukushikai.com,684199426846347#B0LcIZnIsU6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3EkNxMkN7dFUFhTSI54VKNGb4sGVxkmNwNHOxkzTuhkM8AVeUh4d09GeydnbEhVUZN7NHJjV9NDexgkVvJzczNzdrMEaydkWsp4ax9GS8VVck96Y9lTc42kN8lTR5ETZ5lEemBzMyQGNzVlS5x4VkV7Uzc4U9QzL8NkaK3Sazh7QLpkQUJ5VPJFerMUcM9kTUlGZPlGbapFUxkFNKBXMrkzdjZmU8kDe0pHSvgkYIRHb7siYsFTNoh6K4QlTIdFe8h7R8UkWqJES48kYDR6aCp7YLNXcsVnd9IkTrYXW0FVb9kTealTTkNncalFZQVFZyVTbEhFcUNHeOVHdyVzbGZURIV5VEFWVrZTSTtyaDdDe0J4calVRSNlSjVERQ3ERrxUYuBDUxQmeulEZwVnMzInUCF4LFRlUiB7VLZlZ0VTZBl5V7kGV5FTcqtyMuJ6dvVzaH3mUQJiOiMlIsIyQGRUM6kTR6IiOigkIsIDM8kTM5kjN0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISOxUzMxADI7ADMxQjMwIjI0ICdyNkIsISbvNmLpF6aph6c5tWdm9CZyBnI0IyctRkIsICiDO+sDOeoDOOuCOejDOunDOeuCO+rCO+gDOOrDOuoCOuI0ISYONkIsIyN4MjN4gjNyQTO9EDN8YjI0ICZJJCL355W0IyZsZmIsIiM6RjMwI7IJI',
};
export const WIJIMO_KEY = dataWijimoKey[STAGE];
