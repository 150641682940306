import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserPermissionSuccess, getUserPermissionError } from './actions';
import { GET_USER_PERMISSION_REQUEST } from './constants';
import request from '../../utils/request';
import authHeader from '../../helper/auth-header';
import SERVICEURLS from '../../config/serviceUrls';
// Individual exports for testing

function* getUserPermissonRequestSaga() {
  const header = authHeader('GET');
  const headers = {
    ...header,
  };
  const requestURL = `${SERVICEURLS.COM}user-permissions`;
  try {
    const data = yield call(request, requestURL, {
      ...headers,
      isNotShowPopup: true,
    });
    yield put(getUserPermissionSuccess(data));
  } catch (error) {
    yield put(getUserPermissionError(error));
  }
}

export default function* appSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(GET_USER_PERMISSION_REQUEST, getUserPermissonRequestSaga);
}
