import ConstRegx from '../../helper/form/constantRegex';

export const truncString = (max, str) => {
  if (str) {
    const txtAdd = '...';
    const line = str.length > max ? str.substring(0, max) + txtAdd : str;
    return line;
  }
  return str;
};
export const convertStrToInt = val => {
  if (val === '0') {
    return 0;
  }
  return val ? parseInt(val) : val;
};
export const parseCompanyCode = (value, maxLength) => {
  if (isNaN(parseInt(value))) {
    return value;
  }
  const strValue = value.toString();
  if (strValue.length < maxLength) {
    let parsedValue = strValue;
    for (let i = 0; i < maxLength - strValue.length; i++) {
      parsedValue = '0' + parsedValue;
    }

    return parsedValue;
  }

  return value;
};

export const setPostal = value => {
  let result = '';
  if (value) {
    if (value.length === 7) {
      if(ConstRegx.regNumberHalfsize.test(value))
      {
        if (value.includes('-')) {
          value = value.replace('-', '');
        }
        result = value.substr(0, 3) + '-' + value.substr(3);
      }
      else
      {
        result = value;
      }
    } else {
      result = value;
    }
  }

  return result;
};

export const isEmptyAndBlank = str => {
  if (str === undefined || str === null || str === '') {
    return true;
  } else {
    return isBlank(str);
  }
};
export const isBlank = str => {
  return /^\s*$/.test(str);
};
export const getValueField = value => {
  return isEmptyAndBlank(value) ? '' : value;
};
