// import { take, call, put, select } from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_LIST_MASTER_COMPANY } from './constants';
import authHeader from '../../helper/auth-header';
import ENUM from '../../config/enum';
import Const from '../../config/constant';
import { repoLoadingError, reposLoaded } from './actions';
import request from '../../utils/request';
import { formatDataRes } from './ulti';
import SERVICEURLS from '../../config/serviceUrls';

export function* getInfoListMasterCompany(infoParam) {
  const companyCd = `?company_cd=${
    infoParam?.params?.groupCompanyIdListScreen
      ? infoParam?.params?.groupCompanyIdListScreen
      : ''
  }`;
  const invalidFlg = `&invalid_flg=${
    infoParam?.params?.groupCompanyFlag
      ? Const.flagCheckBox.true
      : Const.flagCheckBox.false
  }`;
  const groupCd = `&group_cd=${
    infoParam?.params?.groupCompany ? infoParam?.params?.groupCompany : ''
  }`;
  const companyNm = `&company_nm=${
    infoParam?.params?.companyName ? infoParam?.params?.companyName : ''
  }`;
  const includeHisFlg = `&include_his_flg=${
    infoParam?.params?.outputIncludeHistory
      ? Const.flagCheckBox.true
      : Const.flagCheckBox.false
  }`;

  // const headers = authHeader();
  const header = authHeader('GET');
  const headers = {
    ...header,
  };

  const requestURL = `${
    SERVICEURLS.COMPANY
  }maintenance-companies${companyCd}${invalidFlg}${groupCd}${companyNm}${includeHisFlg}`;
  try {
    const dataListMasterCompany = yield call(request, requestURL, headers);
    if (
      (Object.keys(dataListMasterCompany).length > 0 &&
        dataListMasterCompany.status_code === ENUM.TYPE_STATUS.OK) ||
      dataListMasterCompany.data.companies.length > 0
    ) {
      const formatData = formatDataRes(dataListMasterCompany);
      yield put(reposLoaded(formatData));
    }

    // if (
    //   Object.keys(dataListMasterCompany).length > 0 &&
    //   dataListMasterCompany.status_code === ENUM.TYPE_STATUS.NG
    // ) {
    //   yield put(repoLoadingError(dataListMasterCompany));
    // }
  } catch (error) {
    yield put(repoLoadingError(error));
  }
}

export default function* mse003Saga() {
  yield takeLatest(GET_LIST_MASTER_COMPANY, getInfoListMasterCompany);
}
