import { env, STAGE } from './global';

const dataServiceDomain = {
  [env.DEVELOP]: {
    COM: 'https://dev-api.alex-rgfukushikai.com/auth/v1/',
    GROUP_COMPANY: 'https://dev-api.alex-rgfukushikai.com/group-company/v1/group-companies',
    COMPANY: 'https://dev-api.alex-rgfukushikai.com/companies/v1/',
    EMPLOYEE: 'https://dev-api.alex-rgfukushikai.com/employee/v1/',
    USER: 'https://dev-api.alex-rgfukushikai.com/user/v1/',
    APA: 'https://dev-api.alex-rgfukushikai.com/apa/v1/',
    BEN: 'https://dev-api.alex-rgfukushikai.com/benefit/v1/',
    BEN01: 'https://dev-api.alex-rgfukushikai.com/benefit01/v1/',
    BEN02: 'https://dev-api.alex-rgfukushikai.com/benefit02/v1/',
    BEN_BENIFIT: 'https://dev-api.alex-rgfukushikai.com/benefit-extend/v1/',
    SAP: 'https://dev-api.alex-rgfukushikai.com/sap/v1/',
    IMP: 'https://dev-api.alex-rgfukushikai.com/imp/v1/',
    IMP01: 'https://dev-api.alex-rgfukushikai.com/imp01/v1/',
    IMP02: 'https://dev-api.alex-rgfukushikai.com/imp02/v1/',
    IKU: 'https://dev-api.alex-rgfukushikai.com/iku/v1/',
    PER: 'https://dev-api.alex-rgfukushikai.com/per/v1/',
    HIS: 'https://dev-api.alex-rgfukushikai.com/his/v1/',
    MSE001: 'https://dev-api.alex-rgfukushikai.com/insurances/v1/insurance-types',
    MSE006: 'https://dev-api.alex-rgfukushikai.com/document/v1/submitDoc',
    MEN007: 'https://dev-api.alex-rgfukushikai.com/employee/v1/employees-master',
    MEN007_GET_GROUP_COMPANY: 'https://dev-api.alex-rgfukushikai.com/companies/v1/export-companies',
    DOWNLOAD_PAYMENT: 'https://dev-node20-kyousaikai-document.s3.ap-northeast-1.amazonaws.com/payment',
  },
  [env.STAGING]: {
    COM: 'https://stg-api.rgfukushikai.com/auth/v1/',
    GROUP_COMPANY: 'https://stg-api.rgfukushikai.com/group-company/v1/group-companies',
    COMPANY: 'https://stg-api.rgfukushikai.com/companies/v1/',
    EMPLOYEE: 'https://stg-api.rgfukushikai.com/employee/v1/',
    USER: 'https://stg-api.rgfukushikai.com/user/v1/',
    APA: 'https://stg-api.rgfukushikai.com/apa/v1/',
    BEN: 'https://stg-api.rgfukushikai.com/benefit/v1/',
    BEN01: 'https://stg-api.rgfukushikai.com/benefit01/v1/',
    BEN02: 'https://stg-api.rgfukushikai.com/benefit02/v1/',
    BEN_BENIFIT: 'https://stg-api.rgfukushikai.com/benefit-extend/v1/',
    SAP: 'https://stg-api.rgfukushikai.com/sap/v1/',
    IMP: 'https://stg-api.rgfukushikai.com/imp/v1/',
    IMP01: 'https://stg-api.rgfukushikai.com/imp01/v1/',
    IMP02: 'https://stg-api.rgfukushikai.com/imp02/v1/',
    IKU: 'https://stg-api.rgfukushikai.com/iku/v1/',
    PER: 'https://stg-api.rgfukushikai.com/per/v1/',
    HIS: 'https://stg-api.rgfukushikai.com/his/v1/',
    MSE001: 'https://stg-api.rgfukushikai.com/insurances/v1/insurance-types',
    MSE006: 'https://stg-api.rgfukushikai.com/document/v1/submitDoc',
    MEN007: 'https://stg-api.rgfukushikai.com/employee/v1/employees-master',
    MEN007_GET_GROUP_COMPANY: 'https://stg-api.rgfukushikai.com/companies/v1/export-companies',
    DOWNLOAD_PAYMENT: 'https://stg-node20-kyousaikai-document.s3.ap-northeast-1.amazonaws.com/payment',
  },
  [env.PRODUCTION]: {
    COM: 'https://prd-api.rgfukushikai.com/auth/v1/',
    GROUP_COMPANY: 'https://prd-api.rgfukushikai.com/group-company/v1/group-companies',
    COMPANY: 'https://prd-api.rgfukushikai.com/companies/v1/',
    EMPLOYEE: 'https://prd-api.rgfukushikai.com/employee/v1/',
    USER: 'https://prd-api.rgfukushikai.com/user/v1/',
    APA: 'https://prd-api.rgfukushikai.com/apa/v1/',
    BEN: 'https://prd-api.rgfukushikai.com/benefit/v1/',
    BEN01: 'https://prd-api.rgfukushikai.com/benefit01/v1/',
    BEN02: 'https://prd-api.rgfukushikai.com/benefit02/v1/',
    BEN_BENIFIT: 'https://prd-api.rgfukushikai.com/benefit-extend/v1/',
    SAP: 'https://prd-api.rgfukushikai.com/sap/v1/',
    IMP: 'https://prd-api.rgfukushikai.com/imp/v1/',
    IMP01: 'https://prd-api.rgfukushikai.com/imp01/v1/',
    IMP02: 'https://prd-api.rgfukushikai.com/imp02/v1/',
    IKU: 'https://prd-api.rgfukushikai.com/iku/v1/',
    PER: 'https://prd-api.rgfukushikai.com/per/v1/',
    HIS: 'https://prd-api.rgfukushikai.com/his/v1/',
    MSE001: 'https://prd-api.rgfukushikai.com/insurances/v1/insurance-types',
    MSE006: 'https://prd-api.rgfukushikai.com/document/v1/submitDoc',
    MEN007: 'https://prd-api.rgfukushikai.com/employee/v1/employees-master',
    MEN007_GET_GROUP_COMPANY: 'https://prd-api.rgfukushikai.com/companies/v1/export-companies',
    DOWNLOAD_PAYMENT: 'https://prd-kyousaikai-document.s3.ap-northeast-1.amazonaws.com/payment',
  },
};
export default dataServiceDomain[STAGE];
