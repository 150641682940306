import React from 'react';
import PropTypes from 'prop-types';
import ENUMS from '../../../config/enum';
import SEA001 from './Sea001';
import SEA002 from './Sea002';
import MSE003 from '../../../containers/Mse003';
import './index.css';
function Search({ handleHide, typeModal, funcOnClick, parentValues }) {
  const modal = () => {
    let modalComponent = <> </>;
    switch (typeModal) {
      case ENUMS?.TYPE_MODAL_SEARCH?.SEA001:
        modalComponent = (
          <SEA001 handleHide={handleHide} funcOnClick={funcOnClick} />
        );
        break;
      case ENUMS?.TYPE_MODAL_SEARCH?.SEA002:
        modalComponent = (
          <SEA002
            handleHide={handleHide}
            funcOnClick={funcOnClick}
            parentValues={parentValues}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_SEARCH?.MSE003:
        modalComponent = (
          // <div className="taaaaa" style={{ position: 'absolute', top: 96 }}>
          <MSE003
          isPopupScreen={true}
          handleHide={handleHide}
          funcOnClick={funcOnClick}
          //parentValues={parentValues}
          />
          // </div>
        );
        break;
      default:
    }
    return modalComponent;
  };

  const modalComponent = modal();
  return modalComponent;
}
Search.propTypes = {
  messContent: PropTypes.string,
  handleHide: PropTypes.func,
  funcOnClick: PropTypes.func,
  parentValues: PropTypes.object,
};
Search.defaultProps = {
  handleHide: () => {},
  funcOnClick: () => {},
  parentValues: {},
};

export default Search;
