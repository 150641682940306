/*
 *
 * Sea001 actions
 * Author: LanVt
 */

import {
  RESET_DATA,
  SEARCH_CODE_COMPANY_REQUEST,
  SEARCH_CODE_COMPANY_SUCCESS,
  SEARCH_CODE_COMPANY_ERROR,
} from './constants';
export function searchCodeCompanyRequest(params) {
  return {
    type: SEARCH_CODE_COMPANY_REQUEST,
    params,
  };
}
export function searchCodeCompanySuccess(params) {
  return {
    type: SEARCH_CODE_COMPANY_SUCCESS,
    params,
  };
}
export function searchCodeCompanyError(params) {
  return {
    type: SEARCH_CODE_COMPANY_ERROR,
    params,
  };
}
export function resetData(params) {
         return {
           type: RESET_DATA,
           params,
         };
       }
