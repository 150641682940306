/*
 * ValidateForm Messages
 *
 * This contains all the text for the ValidateForm container.
 * Author: truongtx
 */

import { defineMessages } from 'react-intl';

export const scope = 'containers.ValidateForm';

export default defineMessages({
  userIdRequired: {
    id: `${scope}.userIdRequired`,
    defaultMessage: 'This is the userIdRequired!',
  },
  userIdMax: {
    id: `${scope}.userIdMax`,
    defaultMessage: 'This is the userIdMax!',
  },
  passwordRequired: {
    id: `${scope}.passwordRequired`,
    defaultMessage: 'This is the passwordRequired!',
  },
  passwordMin: {
    id: `${scope}.passwordMin`,
    defaultMessage: 'This is the passwordMin!',
  },
  passwordMax: {
    id: `${scope}.passwordMax`,
    defaultMessage: 'This is the passwordMax!',
  },
  isRequiredC0000001: {
    id: `${scope}.required_c0000001`,
    defaultMessage: 'is Required!',
  },
  maxValueC0000007: {
    id: `${scope}.max_value_c0000007`,
    defaultMessage: 'max value wanning!',
  },
  numberValueC0000006: {
    id: `${scope}.number_value_c0000006`,
    defaultMessage: 'is not number!',
  },
  kanaHaflsizeValueC0000026: {
    id: `${scope}.kana_haflsize_value_c0000026`,
    defaultMessage: 'is not kana haftsize character!',
  },
  numberAndCharacterHaftsizeValueC0000018: {
    id: `${scope}.number_and_character_haftsize_value_c0000018`,
    defaultMessage: 'is not number of character haftsize value!',
  },
  kanjiValue: {
    id: `${scope}.kanji_value`,
    defaultMessage: 'is not input kanji value!',
  },
  kanaFullsizeValueC0000002: {
    id: `${scope}.kana_fullsize_value_c0000002`,
    defaultMessage: 'is not kana fullsize character!',
  },
  birthDayIsAfterCurrenDateC0000040: {
    id: `${scope}.birthday_is_after_curren_date_C0000040`,
    defaultMessage: 'birthday is after current date!',
  },

  dateTimeValueC0000004: {
    id: `${scope}.date_time_value_c0000004`,
    defaultMessage: 'dateTimeValueC0000004',
  },

  userId_C0000001: {
    id: `${scope}.userId_C0000001`,
    defaultMessage: 'userId is not empty',
  },
  password_C0000001: {
    id: `${scope}.password_C0000001`,
    defaultMessage: 'userId is not empty',
  },
  userId_C0000007: {
    id: `${scope}.userId_C0000007`,
    defaultMessage: 'userId exceeded quantity',
  },
  password_C0000067: {
    id: `${scope}.password_C0000067`,
    defaultMessage: 'password exceeded quantity',
  },
  matchPass_C0000080: {
    id: `${scope}.matchPass_C0000080`,
    defaultMessage: 'password do not matching',
  },
  lengthPassC0000078: {
    id: `${scope}.lengthPassC0000078`,
    defaultMessage: 'length of pass',
  },
  regxPassCurrentPassC0000079: {
    id: `${scope}.regxPassCurrentPassC0000079`,
    defaultMessage: 'halfsize of pass',
  },
  regxNewPassC0000079: {
    id: `${scope}.regxNewPassC0000079`,
    defaultMessage: 'halfsize of pass',
  },
  currentPassC0000078: {
    id: `${scope}.currentPassC0000078`,
    defaultMessage: 'currentPassC0000078',
  },
  newPassC0000078: {
    id: `${scope}.newPassC0000078`,
    defaultMessage: 'newPassC0000078',
  },
  confirmPassC0000078: {
    id: `${scope}.confirmPassC0000078`,
    defaultMessage: 'confirmPassC0000078',
  },

});

export const getErrorMess = ({ errors, touched, field }) =>
  errors[field] && touched[field] ? errors[field] : '';
