/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { openLink } from '../../utils/common/WindowUtils';
import './index.css';
import LoadingImg from './loading.gif';

function Button({
  type,
  className,
  onClick,
  buttonName,
  isSubMenu,
  dataSubMenu,
  isLoading,
  isDisabled,
  id = null,
}) {
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);
  const buttonRef = useRef(null);
  const toggleSub = () => {
    setIsShowSubMenu(!isShowSubMenu);
  };

  const customProps = id ? { id } : {};

  return (
    <button
      className={className}
      onClick={onClick}
      type={type}
      onFocus={toggleSub}
      onBlur={toggleSub}
      disabled={isLoading || isDisabled}
      ref={buttonRef}
      {...customProps}
    >
      <FormattedMessage {...messages?.[buttonName]} />
      {isShowSubMenu && isSubMenu && (
        <ul className="submenu">
          {dataSubMenu?.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  openLink(item?.link);
                }}
              >
                <FormattedMessage {...messages?.[item?.keyName]} />
              </li>
            );
          })}
        </ul>
      )}
      <img
        className={`button-loading ${isLoading ? '' : 'btn-hide'}`}
        src={LoadingImg}
        alt="loading"
      />
    </button>
  );
}

Button.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  buttonName: PropTypes.string,
  isSubMenu: PropTypes.bool,
  dataSubMenu: PropTypes.array,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
Button.defaultProps = {
  type: 'button',
  isSubMenu: false,
  dataSubMenu: [],
  onClick: () => {},
  isLoading: false,
  isDisabled: false,
};
export default Button;
