const regDate = /^([0-9]{4})\/([0-9]{2})\/([0-9]{2})$/;
const regYearAndMonth = /^([0-9]{4})\/([0-9]{2})$/;
const regexKanaWithSpace = /^[\u30a0-\u30ff\uff61-\uff9f　]*$/;
const regxPass = /^[A-Za-z0-9~!@#$%^&*_\-+=`/|\\(){}[\]:;"'<>,.?]*$/;
const regexHalfSize = /^[0-9]*$/;
const regxEmail = /^([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@(([0-9a-zA-Z][-\w]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9]{2,17})$/;
const regxEmailHelp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regxNumberOnly = /^\d+$/;
const regxMonth = /^([1-9]|1[012])$/;
const currentYear = 19;
const regxYear = /^(19|2[0-9])$/;
const regxKanji = /^[一-龥]+$/; // /[一-龥]/
const regNumberHalfsize = /^[0-9]+$/;
const regexHalfSizeKatakana = /^[ｧ-ﾝﾞﾟ()]*$/;
const regexNumberFullSizeHalfSize = /^[0-9０-９]+$/;
const regexFullSizeKatakana = /^[ァ-ンー（）]*$/;
const regexFullSizeKatakanaWithSpace = /^[ァ-ンー　（）]*$/;
const regexFullSizeKatakanaWithSpaceFullHaftSize = /^[ァ-ンー　\s()（）]*$/;
const regexPortalCode = /^[0-9]{3}[-][0-9]{4}$/;
const regCharacterAndNumberHalfSize = /[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/;
const regFullHalfSizeKatakana = /^[ｧ-ﾝﾞﾟa-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/;
const regDateTime = /^\d{4}(\/)(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])$/i;
const regTimeYearMonth = /[\d]{4}\/[\d]{2}/;
const regDateTimeNew = /[\d]{4}\/[\d]{2}\/[\d]{2}/;
const patternDate = /^(\d{4})(\d{1,2})(\d{1,2})$/;
const regexFullSize = /^[^ -~｡-ﾟx00-x1f	]+$/;
const regexTel = /^[0-9\-]*$/;
const regNumberWithNegative = /^-?\d*\.?\d+$/;
const regDDMMYYYYExcel = /^[\d]{1,2}\/[\d]{1,2}\/[\d]{2}$/;
const regYYYYMMDDExcelWithYearFourCharacter = /^[\d]{4}\/[\d]{1,2}\/[\d]{1,2}$/;
const regexTimestamp = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/
//const regDDMMYYYYExcelWithMoreSixCharacter = /^[\d]{1,2}\/[\d]{1,2}\/[\d]{4}$/;
const regAlphaAndNumberHalfSize = /^[a-zA-Z0-9]+$/;  // 2020.05.19 append by ALEX_Kikawa CT137
export default {
  regexTimestamp,
  regDate,
  regexKanaWithSpace,
  regxPass,
  regexHalfSize,
  regxEmail,
  regxEmailHelp,
  regxNumberOnly,
  regxMonth,
  currentYear,
  regxYear,
  regxKanji,
  regNumberHalfsize,
  regexHalfSizeKatakana,
  regexNumberFullSizeHalfSize,
  regexFullSizeKatakana,
  regexPortalCode,
  regCharacterAndNumberHalfSize,
  regDateTime,
  regexFullSizeKatakanaWithSpace,
  regYearAndMonth,
  regTimeYearMonth,
  regDateTimeNew,
  regexFullSize,
  regexFullSizeKatakanaWithSpaceFullHaftSize,
  regFullHalfSizeKatakana,
  regexTel,
  patternDate,
  regNumberWithNegative,
  regDDMMYYYYExcel,
  regYYYYMMDDExcelWithYearFourCharacter,
  //regDDMMYYYYExcelWithMoreSixCharacter
  regAlphaAndNumberHalfSize  // 2020.05.19 append by ALEX_Kikawa CT137
};
