import * as Yup from 'yup';
import { getMessValidate } from 'helper/form/messValidate';
export const validatekoiShogaiTokyuCd = Yup.string()
  .nullable()
  .required(getMessValidate('C0000001', ['後遺障害等級']));

export const validateKyufuKingakuBen006 = Yup.string()
  .nullable()
  .checkCurrency('後遺障害補償', 9, true);

export const validateRequestDateDeterminBen006 = Yup.string()
  .nullable()
  .checkRequestDayBen006(
    Yup.ref('shogai_nintei_ymd'),
    Yup.ref('kyufu_shubetsu_cd'),
    // getMessValidate('C0000077'),
    getMessValidate('C0000039'),
    getMessValidate('C0000004', ['申請日']),
    getMessValidate('C0000001', ['申請日']),
    // getMessValidate('W0000014'),
  );
export const validateDateOfDetermined = Yup.string()
  .nullable()
  .checkDateOfDeterminedBen006(
    Yup.ref('nyukai_ymd'), // Ngày tham gia hội
    Yup.ref('taikai_ymd'), // Ngày rời khỏi hội
    Yup.ref('shinsei_kaishi_ymd'), // Ngày yêu câu
    Yup.ref('kyufu_shubetsu_cd'), // Mã loại trợ cấp
  );

export const validateDateOfArising = Yup.string()
  .nullable()
  .checkDateOfArisingBen006(
    Yup.ref('shogai_nintei_ymd'), // Ngày xác định
    Yup.ref('nyukai_ymd'), // Ngày tham gia hội
    Yup.ref('taikai_ymd'), // Ngày rời khỏi hội
    Yup.ref('kumiai_taikai_ymd'), //cong ty
    Yup.ref('kyufu_shubetsu_cd'), // Mã loại trợ cấp
  );
