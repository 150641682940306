import { validateTaikaikubunCd, validateKyufuKingaku } from './validateCommon';
import commonValidateMethod from './validateCommonMethod';

export const validateBen011 = (obj, fieldValidate) => {
  if (fieldValidate?.includes('taikaikubun_cd')) {
    obj.taikaikubun_cd = validateTaikaikubunCd;
  }
  if (fieldValidate?.includes('kyufu_kingaku')) {
    obj.kyufu_kingaku = validateKyufuKingaku;
  }
};
