import ENUMS from 'config/enum';
import ConstRoot from 'config/constant';
import { formatStrDate } from 'utils/common/DateUtils';
import { parseCompanyCode, isEmptyAndBlank } from 'utils/common/StringUtils';
import {
  isArrayEmpty,
  arrayFilterMultiValue,
  getArrValueByKey,
} from 'utils/common/ArrayUtils';
import differenceInDays from 'date-fns/differenceInDays';
import ROUTERS from 'config/routes';
import {
  getCheckGreaterThanNumberYear,
  getMessValidate,
  getCheckLeaveDateCompany,
} from 'helper/form/messValidate';
import isAfter from 'date-fns/isAfter';
const setSubscriptionPeriodDateOfBirth = (
  period_days,
  period_month,
  period_year,
) => {
  const textEmpty = '__';
  if (period_year && period_month && period_days) {
    return `${period_year}年${period_month}ヵ月${period_days}日`;
  }
  return '';
};
export const mapFieldsDetail = (setFieldValue, item, type, screenCode) => {
  const maternity =
    item?.maternity ||
    item?.marriage ||
    item?.condolences ||
    item?.disasterBenefit ||
    item?.basic ||
    item?.theft_money ||
    item?.disability ||
    item?.medical_examination ||
    item?.leave_company_benefit;

  if (type === ENUMS?.TYPE_SCREEN?.NEW) {
    // setFieldValue('kyufu_id', Const?.kyufu_id_default);
  } else {
    setFieldValue('kyufu_id', maternity?.kyufu_id || '');
    const data_moto_kyufu_id = parseCompanyCode(
      maternity?.moto_kyufu_id || '',
      7,
    );
    setFieldValue('moto_kyufu_id', data_moto_kyufu_id);
  }
  setFieldValue('kyufu_shubetsu_cd', screenCode);
  setFieldValue('uketsuke_keiro_cd', maternity?.uketsuke_keiro_cd);
  setFieldValue('kyufu_shubetsu_id', maternity?.kyufu_shubetsu_id || '');
  setFieldValue('uketsuke_id', maternity?.uketsuke_id || '');
  setFieldValue('statusCode', maternity?.shorijotai_cd || '');
  setFieldValue('shorijotai_cd', maternity?.shorijotai_cd || '');
  setFieldValue('shorijotai_name', maternity?.shorijotai_name || '');
  setFieldValue('kaisha_cd', maternity?.kaisha_cd || '');
  setFieldValue('kaisha_group_cd', maternity?.kaisha_group_cd || '');
  setFieldValue('kaisha_name', maternity?.kaisha_name || '');
  setFieldValue('jugyoin_no', maternity?.jugyoin_no || '');
  setFieldValue('employeeName', maternity?.jugyoin_fullname_kanji || '');
  setFieldValue('employeeNameKana', maternity?.jugyoin_fullname_kana || '');
  setFieldValue(
    'jiyuhassei_ymd',
    formatStrDate(maternity?.jiyuhassei_ymd) || '',
  );
  setFieldValue(
    'seinengappi_ymd',
    formatStrDate(maternity?.seinengappi_ymd) || '',
  );
  setFieldValue(
    'seinengappi_ymd_top_info',
    formatStrDate(maternity?.seinengappi_ymd),
  );

  setFieldValue('shobyo_kubun', maternity?.shobyo_kubun || '');
  setFieldValue('shobyo_name', maternity?.shobyo_name || '');
  setFieldValue('koishogai_tokyu_cd', maternity?.koishogai_tokyu_cd || '');
  setFieldValue(
    'shogai_nintei_ymd',
    formatStrDate(maternity?.shogai_nintei_ymd) || '',
  );

  const taikai_ymd = maternity?.teinen_saikoyo_ymd
    ? ''
    : maternity?.taikai_ymd || '';
  setFieldValue('taikai_ymd', formatStrDate(taikai_ymd));
  setFieldValue('nyukai_ymd', formatStrDate(maternity?.nyukai_ymd) || '');
  setFieldValue('sendOff', setValueSendOff(maternity?.is_hand_over));
  setFieldValue('seibetsu_cd', maternity?.seibetsu_cd || '');
  setFieldValue('gender_name', maternity?.gender_name || '');
  setFieldValue('birthday', formatStrDate(maternity?.shinsei_kaishi_ymd) || '');
  setFieldValue('taishosha_fullname', maternity?.taishosha_fullname || '');
  setFieldValue('kyufu_gokei_kingaku', maternity?.kyufu_kingaku || '');
  setTimeout(() => {
    setFieldValue('kyufu_kingaku', maternity?.kyufu_kingaku || '');
  }, 0);
  setFieldValue('biko_kyufu', maternity?.biko_kyufu || '');
  setFieldValue(
    'shinsei_kaishi_ymd',
    formatStrDate(maternity?.shinsei_kaishi_ymd) || '',
  );
  setFieldValue('kyosai_type_cd', maternity?.kyosai_type_cd || '');
  setFieldValue(
    'kumiai_taikai_ymd',
    formatStrDate(maternity?.kumiai_taikai_ymd) || '',
  );
  setFieldValue(
    'kumiai_kanyu_ymd',
    formatStrDate(maternity?.kumiai_kanyu_ymd) || '',
  );
  setFieldValue(
    'uketsuke_biko_outsourcing',
    maternity?.uketsuke_biko_outsourcing || '',
  );
  setFieldValue(
    'uketsuke_biko_shinseisha',
    maternity?.uketsuke_biko_shinseisha || '',
  );
  setFieldValue('age_at_birth', maternity?.age_at_birth || '');

  const subscriptionPeriodDateOfBirth = setSubscriptionPeriodDateOfBirth(
    maternity?.period_days,
    maternity?.period_month,
    maternity?.period_year,
  );
  setFieldValue(
    'subscriptionPeriodDateOfBirth',
    subscriptionPeriodDateOfBirth || '',
  );
  convertBottomBen(setFieldValue, maternity);
  //documents
  item?.documents?.map((itemFile, index) => {
    const no = itemFile?.tempu_file_no;
    if (no == '1') {
      setFieldValue('attachment1', itemFile);
    } else if (no == '2') {
      setFieldValue('attachment2', itemFile);
    } else if (no == '3') {
      setFieldValue('attachment3', itemFile);
    }
  });
};
const convertBottomBen = (setFieldValue, items) => {
  setFieldValue(
    'saishu_koshin_ts',
    formatStrDate(items?.saishu_koshin_ts, ConstRoot?.longDateFormat?.LFS),
  );
  setFieldValue(
    'shinki_sakusei_ts',
    formatStrDate(items?.shinki_sakusei_ts, ConstRoot?.longDateFormat?.LFS),
  );
  setFieldValue(
    'jimukyokucho_shonin_ts',
    formatStrDate(
      items?.jimukyokucho_shonin_ts,
      ConstRoot?.longDateFormat?.LFS,
    ),
  );
  setFieldValue(
    'tantojoshi_shonin_ts',
    formatStrDate(items?.tantojoshi_shonin_ts, ConstRoot?.longDateFormat?.LFS),
  );
  setFieldValue(
    'tantosha_shonin_ts',
    formatStrDate(items?.tantosha_shonin_ts, ConstRoot?.longDateFormat?.LFS),
  );
  setFieldValue('shiharai_ymd', formatStrDate(items?.shiharai_ymd));
  // bottom info
  setFieldValue(
    'tantosha_shonin_user_name',
    items?.tantosha_shonin_user_name || '',
  );
  setFieldValue(
    'tantosha_shonin_user_id',
    items?.tantosha_shonin_user_id || '',
  );
  setFieldValue(
    'tantojoshi_shonin_user_id',
    items?.tantojoshi_shonin_user_id || '',
  );
  setFieldValue(
    'tantojoshi_shonin_user_name',
    items?.tantojoshi_shonin_user_name || '',
  );
  setFieldValue(
    'jimukyokucho_shonin_user_name',
    items?.jimukyokucho_shonin_user_name || '',
  );
  setFieldValue(
    'jimukyokucho_shonin_user_id',
    items?.jimukyokucho_shonin_user_id || '',
  );
  setFieldValue(
    'shinki_sakusei_user_name',
    items?.shinki_sakusei_user_name || '',
  );
  setFieldValue('shinki_sakusei_user_id', items?.shinki_sakusei_user_id || '');
  setFieldValue(
    'saishu_koshin_user_name',
    items?.saishu_koshin_user_name || '',
  );
  setFieldValue('saishu_koshin_user_id', items?.saishu_koshin_user_id || '');
  setFieldValue('wave_wf_key', items?.wave_wf_key || '');
};
const setValueSendOff = dataSendOff => {
  return dataSendOff == '1' ? '有' : '';
};
export const getShorijotaiCd = (isTemp, setFieldValue) => {
  let shorijotai_cd = '10';
  if (isTemp) {
    setFieldValue('statusCode', '10');
    // setFieldValue('shorijotai_cd', '10');
    shorijotai_cd = '10';
  } else {
    setFieldValue('statusCode', '20');
    // setFieldValue('shorijotai_cd', '20');
    shorijotai_cd = '20';
  }
  return shorijotai_cd;
};
export const resetFormClick = (codeForm, codeScreen) => {
  location.replace(
    ROUTERS?.[codeForm]
      .replace(':type', ENUMS.TYPE_SCREEN.NEW)
      .replace(':id', ConstRoot.kyufu_id_default)
      .replace(':codeScreen', codeScreen),
  );
};
export const setDataDropDown = (
  arrKyufuShubetsuCds,
  hanyoKubunArr,
  keyCompare,
  funcSetData,
) => {
  if (!isArrayEmpty(arrKyufuShubetsuCds)) {
    const arr = getArrValueByKey(arrKyufuShubetsuCds, keyCompare);
    const arrValues = arrayFilterMultiValue(
      hanyoKubunArr,
      'hanyo_kubun_cd',
      arr,
    );
    funcSetData(arrValues);
  } else {
    funcSetData([]);
  }
};
export const saveBen = (
  e,
  values,
  id,
  setFieldValue,
  addNewBen,
  updateBen,
  type,
  isTemp = false,
  ignoreWarn = false,
) => {
  let shorijotai_cd = getShorijotaiCd(isTemp, setFieldValue);
  setTimeout(() => {
    if (type === ENUMS?.TYPE_SCREEN?.NEW) {
      addNewBen({
        values,
        shorijotai_cd,
        ignoreWarn,
      });
    } else if (type === ENUMS?.TYPE_SCREEN?.EDIT) {
      updateBen({
        values,
        id,
        shorijotai_cd,
        ignoreWarn,
      });
    }
  }, 0);
};
export const checkWarningJiyuhasseiYmd = (
  value,
  valueRequestDt,
  valueLeaveCompany,
  valueJointDt,
  valueLeaveDt,
  valueCodeScreen,
) => {
  if (!isEmptyAndBlank(value)) {
    const msgGreaterThanNumberYear = getCheckGreaterThanNumberYear(
      valueCodeScreen,
    );
    const messageCheckLeaveDateCompany = getCheckLeaveDateCompany(
      valueCodeScreen,
    );
    const numberYear = Math.abs(
      differenceInDays(new Date(value), new Date(valueRequestDt)),
    );
    const ischeckLeaveDateCompany = isAfter(
      new Date(value),
      new Date(valueLeaveCompany),
    );
    const ischeckAfterJoinDate = isAfter(
      new Date(valueJointDt),
      new Date(value),
    );
    const ischeckAfterRequestDay = isAfter(
      new Date(value),
      new Date(valueRequestDt),
    );
    const ischeckLeaveDate = isAfter(new Date(value), new Date(valueLeaveDt));
    if (numberYear >= 365) {
      return msgGreaterThanNumberYear;
    } else if (ischeckLeaveDateCompany) {
      return messageCheckLeaveDateCompany;
    } else if (
      (ischeckAfterJoinDate || ischeckLeaveDate) &&
      valueCodeScreen === ConstRoot?.screenCodeBen?.BEN002
    ) {
      return getMessValidate('W0000007');
    } else if (
      ischeckAfterRequestDay &&
      valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011
    ) {
      return getMessValidate('W0000017');
    }
  }
  return null;
};
export const checkWarningRequestDt = (
  value,
  valueJiyuhasseiYmd,
  valueCodeScreen,
) => {
  if (!isEmptyAndBlank(value)) {
    const ischeckAfterDay = isAfter(
      new Date(valueJiyuhasseiYmd),
      new Date(value),
    );

    const numberYear = Math.abs(differenceInDays(new Date(value), new Date()));
    if (numberYear >= 365) {
      return getMessValidate('W0000014');
    } else if (
      ischeckAfterDay &&
      valueCodeScreen === ConstRoot?.screenCodeBen?.BEN011
    ) {
      return getMessValidate('W0000017');
    }
  }
  return null;
};
export const checkAfterDate = (value, valueRequestDt, keyMess) => {
  if (!isEmptyAndBlank(value)) {
    // Ngày xác định là ngày hơn 1 năm trước dựa theo Ngày yêu cầu
    const messageCheckGreaterThanNumberYear = getMessValidate('W0000024');
    const numberYear = Math.abs(
      differenceInDays(new Date(value), new Date(this.resolve(refRequestDt))),
    );
    if (numberYear >= 365) {
      return this.createError({
        message: messageCheckGreaterThanNumberYear,
      });
    }
  }
  return null;
};

export const checkStartDateBen007 = (value, valueRequestDt, keyMess) => {
  if (!isEmptyAndBlank(value)) {
    const numberYear = Math.abs(
      differenceInDays(new Date(value), new Date(valueRequestDt)),
    );
    if (numberYear >= 365) {
      return getMessValidate(keyMess);
    }
  }
  return null;
};
export const checkEndDateBen007 = (
  value,
  valueBenefitLimitDt,
  keyMess,
  arrMess = [],
) => {
  if (!isEmptyAndBlank(value)) {
    //Ngay gioi han tro cap not null && ＜ Ngay ket thuc nhap vien
    const numberDay = Math.abs(
      differenceInDays(new Date(value), new Date(valueBenefitLimitDt)),
    );
    const ischeckAfterBenefitLimitDt = isAfter(
      new Date(value),
      new Date(valueBenefitLimitDt),
    );
    if (ischeckAfterBenefitLimitDt) {
      return getMessValidate(keyMess, arrMess);
    }
  }
  return null;
};
