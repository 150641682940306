import produce from 'immer';
import {
  GET_USER_PERMISSION_REQUEST,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_ERROR,
  CLEAR_PERMISSION,
} from './constants';
import { arrayFilter, arraySort } from '../../utils/common/ArrayUtils';
import ConstCommon from '../../config/constant';

// The initial state of the App
export const initialState = {
  userPermission: {},
  errorPermission: {},
  hanyoKubunArr: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
  ),
  koishogaiTokyuCdArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.benefit_master),
    ),
    'kyufu_shubetsu_cd',
    '0210',
  ),
  benifitKyufuKingakuDeath: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.benefit_master),
    ),
    'kyufu_shubetsu_cd',
    '0130',
  ),
  injuryClassificationArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0023',
  ),
  tonanhigaiKubunArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0026',
  ),
  classificationOfDeathArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0024',
  ),
  waveWfKeyArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0021',
  ),
  benefitMaster: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.benefit_master),
  ),
  docsExtractArr: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.docs_extract),
  ),
  // phan loai yeu cau 申請区分 array
  shinseiKubunArr: arrayFilter(
    JSON.parse(
      localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
    ),
    'hanyo_bunrui_cd',
    '0201',
  ),
  // tinh hinh thiet hai do thien tai
  typeStatusDisaster: arraySort(
    arrayFilter(
      JSON.parse(
        localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
      ),
      'hanyo_bunrui_cd',
      '0009',
    ),
    'hyoji_order',
    'asc',
  ),
  // data phân loại thiên tai
  typeNominalDistinction: arraySort(
    arrayFilter(
      JSON.parse(
        localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
      ),
      'hanyo_bunrui_cd',
      '0025',
    ),
    'hyoji_order',
    'asc',
  ),

  // gender ben008
  typeGender: arraySort(
    arrayFilter(
      JSON.parse(
        localStorage.getItem(ConstCommon.inforLocalStorage?.code_master),
      ),
      'hanyo_bunrui_cd',
      '0005',
    ),
    'hyoji_order',
    'asc',
  ),

  // group company
  groupCompany: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.group_company),
  ),

  typeFamilyRelationShip: JSON.parse(
    localStorage.getItem(ConstCommon.inforLocalStorage?.relationships),
  ),
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USER_PERMISSION_REQUEST: {
        draft.isLoading = true;
        draft.userPermission = {};
        draft.errorPermission = {};
        break;
      }
      case GET_USER_PERMISSION_SUCCESS:
        draft.errorPermission = {};
        draft.userPermission = action?.payload?.data?.[0];
        draft.isLoading = false;
        break;
      case GET_USER_PERMISSION_ERROR:
        draft.errorPermission = action.error;
        draft.userPermission = {};
        draft.isLoading = false;
        break;
      case CLEAR_PERMISSION:
        draft.isLoading = false;
        draft.userPermission = {};
        draft.errorPermission = {};
        break;
    }
  });

export default appReducer;
