import { call, put, takeLatest } from 'redux-saga/effects';
import SERVICEURLS from 'config/serviceUrls';
import authHeader from 'helper/auth-header';
import { searchEmployeeCodeSuccess, searchEmployeeCodeError } from './actions';
import { SEARCH_EMPLOYEE_CODE_REQUEST } from './constants';
import request, { get } from 'utils/request';
function* searchEmployeeCodeRequestSaga(params) {
  const headers = authHeader();
  const requestParams = {
    employee_cd: params?.params?.jugyoin_no || '',
    company_cd: params?.params?.kaisha_cd || '',
    full_name_kanji: params?.params?.fullNameKanji || '',
    full_name_kana: params?.params?.fullNameKana || '',
  };
  try {
    const data = yield get(
      `${SERVICEURLS?.EMPLOYEE}employees`,
      requestParams,
      headers,
    );
    yield put(searchEmployeeCodeSuccess(data));
  } catch (error) {
    yield put(searchEmployeeCodeError(error));
  }
}

// Individual exports for testing
export default function* sea001Saga() {
  yield takeLatest(SEARCH_EMPLOYEE_CODE_REQUEST, searchEmployeeCodeRequestSaga);
}
