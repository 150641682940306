/*
 *
 * Mse003 constants
 * Author: Locnt
 */
import ENUMS from '../../config/enum';
import CONST from '../../config/constant';


const dataConfigTable = onClickCellMse003 => {
  return [
    {
      binding: 'company_cd',
      headerName: 'companyCode',
      width: 115,
      cellTemplateType: ENUMS?.TYPE_CELL?.LINK,
      funcOnClick: onClickCellMse003,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'is_disable_text',
      headerName: 'inValidMse003',
      width: 100,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
      cellTemplateType: ENUMS?.TYPE_CELL?.CUSTOM_ON_OFF,
    },
    {
      binding: 'sort',
      headerName: 'sort',
      width: 100,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'company_name',
      headerName: 'companyNameMse003',
      width: 290,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'company_name_kana',
      headerName: 'companyNameKana',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'short_company_name',
      headerName: 'shortCompanyName',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'postal_cd',
      headerName: 'postalCd',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'address',
      headerName: 'address',
      width: 250,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'tel',
      headerName: 'tel',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'fax_no',
      headerName: 'faxNo',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'representative_position',
      headerName: 'representativePosition',
      width: 180,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'representative_name',
      headerName: 'representativeName',
      width: 180,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'bill_contact_name',
      headerName: 'billContactName',
      width: 200,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'contact_name',
      headerName: 'contactName',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'contact_tel',
      headerName: 'contactTel',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'contact_fax',
      headerName: 'contactFax',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'email',
      headerName: 'email',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'bank_name',
      headerName: 'bankName',
      width: 160,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'bank_cd',
      headerName: 'bankCd',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'bank_branch_name',
      headerName: 'bankBranchName',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'bank_branch_cd',
      headerName: 'bankBranchCd',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'account_type',
      headerName: 'accountType',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'account_no',
      headerName: 'accountNo',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'account_holder_name_kanji',
      headerName: 'accountHolderName',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'account_holder_name_kana',
      headerName: 'accountHolderNameKana',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'join_dt',
      headerName: 'joinDt',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'leaving_dt',
      headerName: 'leavingDt',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
      formatCell: CONST?.longDateFormat?.L,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'company_group_name',
      headerName: 'companyGroupMse003',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'kyosai_type_name',
      headerName: 'kyosaiType',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'request_classify',
      headerName: 'requestClassify',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'payment_method',
      headerName: 'paymentMethod',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'payment_cdt',
      headerName: 'paymentCdt',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'payment_web',
      headerName: 'paymentWeb',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'payment_cdt_dt',
      headerName: 'paymentCdtDt',
      width: 170,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'recovery_method',
      headerName: 'recoveryMethod',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'seikyutaishokikan',
      headerName: 'seikyutaishokikan',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'deposit_web',
      headerName: 'depositWeb',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },

    {
      binding: 'recovery_cdt_deposit_dt',
      headerName: 'recoveryCdtDepositDt',
      width: 170,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'payment_cal_classify_nm',
      headerName: 'kakekinsanshutsukubun',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'seikyusho_hakkobi',
      headerName: 'seikyushohakkobi',
      width: 170,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'expense_payment_cdt',
      headerName: 'expensePaymentCdt',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      //sua
      //binding: 'expense_payment_web',
      binding: 'cost_payment_web',
      headerName: 'expensePaymentWeb',
      width: 150,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'expense_payment_cdt_payment_dt',
      headerName: 'expensePaymentCdtPaymentDt',
      width: 200,
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'note',
      headerName: 'note',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'create_dt_time',
      headerName: 'createDtTime',
      width: 180,
      // cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
      formatCell: CONST?.longDateFormat?.LFS,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'create_user_id',
      headerName: 'createUserId',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'create_user_name',
      headerName: 'createUserName',
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
      // width: '*',
      // cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
    },

    {
      binding: 'update_dt_time',
      headerName: 'updateDtTime',
      width: 200,
      // cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
      formatCell: CONST?.longDateFormat?.LFS,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'update_user_id',
      headerName: 'updateUserId',
      width: '*',
      cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
    },
    {
      binding: 'update_user_name',
      headerName: 'updateUserName',
      fieldCheckOn: 'invalid_flg',
      valueCheckOn: 1,
      cssClassAllCheckOn: 'bg-gray',
      // width: '*',
      // cellTemplateType: ENUMS?.TYPE_CELL?.NONE,
    },
  ];
};

const DEFAULT_ACTION = 'app/Mse003/DEFAULT_ACTION';
const GET_LIST_MASTER_COMPANY = 'app/Mse003/GET_LIST_MASTER_COMPANY';
const LOAD_REPOS_SUCCESS = 'app/Mse003/LOAD_REPOS_SUCCESS';
const LOAD_REPOS_ERROR = 'app/Mse003/LOAD_REPOS_ERROR';
const RESET_LIST_GROUP_COMPANY = 'app/Mse003/RESET_LIST_GROUP_COMPANY';

export {
  dataConfigTable,
  GET_LIST_MASTER_COMPANY,
  LOAD_REPOS_SUCCESS,
  LOAD_REPOS_ERROR,
  RESET_LIST_GROUP_COMPANY,
};
