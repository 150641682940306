import ENUMS from 'config/enum';
import ROUTERS from 'config/routes';
import EventEmitter, { SET_ERROR_MESSAGE } from '../helper/EventEmitter';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  directPathError(response);
  return response;
}
const directPathError = response => {
  const pathName = location.pathname;
  switch (response.status) {
    case 404:
      if (pathname !== ROUTERS?.PAGE404) {
        location.replace(ROUTERS?.PAGE404);
      }
      break;
    // case 500:
    //   if (pathName !== ROUTERS?.PAGE500) {
    //     location.replace(ROUTERS?.PAGE500);
    //   }
    case 599:
      if (pathName !== ROUTERS?.PAGE500) {
        location.replace(ROUTERS?.PAGE500);
      }
      break;
  }
};
/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @param options
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response, options) {
  if (response.status >= 200 && response.status < 300) {
    return response.json().then(data => {
      if (
        data.status_code === ENUMS.TYPE_STATUS.NG &&
        !options.isNotShowPopup
      ) {
        let { message } = data;
        if (typeof message === 'object') {
          message = Object.values(data.message).find(mess => mess);
        } else {
          const { data: extraInfo } = data;
          if (extraInfo?.item_mes) {
            message = message.replace(
              '{0}',
              `<br>${extraInfo.item_mes?.[0]}` || '',
            );
          }
        }

        EventEmitter.dispatch(SET_ERROR_MESSAGE, message);
      }

      return data;
    });
  }
  directPathError(response);
  const error = new Error(response.statusText);
  error.response = response;
  response.json().then(data => {
    if (!options.isNotShowPopup) {
      EventEmitter.dispatch(SET_ERROR_MESSAGE, data.message);
    }
  });
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(response => checkStatus(response, options))
    .then(parseJSON);
}

const encodeUrl = (url, params) => {
  if (!Object.keys(params).length) {
    return url;
  }
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => {
    searchParams.set(key, params[key] || '');
  });

  return `${url}?${searchParams.toString().replace(/\+/g, '%20')}`;
};

export const get = (url, params = {}, options) =>
  request(encodeUrl(url, params), options);
