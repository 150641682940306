import React from 'react';
import PropTypes from 'prop-types';
import ENUMS from '../../../config/enum';
import { convertStrToInt } from '../../../utils/common/StringUtils';
// import Ben003 from './Ben003';
// import Ben006 from './Ben006';
function Benefit({ handleHide, typeModal, typeStatusBenefit }) {
  const modal = () => {
    let modalComponent = <> </>;
    switch (convertStrToInt(typeModal)) {
      case ENUMS?.TYPE_MODAL_BENEFIT?.weddingGift:
        modalComponent = (
          <Confirm
            handleHide={handleHide}
            messContent={messContent}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      // case ENUMS?.TYPE_MODAL_BENEFIT?.maternityGift:
      //   modalComponent = (
      //     <Ben003
      //       handleHide={handleHide}
      //       typeStatusBenefit={typeStatusBenefit}
      //     />
      //   );
      //   break;
      // case ENUMS?.TYPE_MODAL_BENEFIT?.familyCondolence:
      //   modalComponent = (
      //     <Benefit
      //       handleHide={handleHide}
      //       typeModal={typeModal}
      //       typeStatusBenefit={typeStatusBenefit}
      //     />
      //   );
      //   break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.princessCondolence:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.deathCompensation:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.severalDisabilityCompensation:
        modalComponent = (
          <Ben006
            handleHide={handleHide}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.sicknessPayment:
        modalComponent = (
          <Benefit handleHide={handleHide} typeModal={typeModal} />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.admissionCompensation:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.hospitalCompensation:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.differenceBedCharge:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.disasterDonation:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.theftMoney:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
      case ENUMS?.TYPE_MODAL_BENEFIT?.spouseMedicalExamSubsidy:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      case ENUMS?.TYPE_MODAL_BENEFIT?.unsubFee:
        modalComponent = (
          <Benefit
            handleHide={handleHide}
            typeModal={typeModal}
            typeStatusBenefit={typeStatusBenefit}
          />
        );
        break;
      default:
    }
    return modalComponent;
  };
  const modalComponent = modal();
  return modalComponent;
}
Benefit.propTypes = {
  messContent: PropTypes.string,
  handleHide: PropTypes.func,
};

export default Benefit;
