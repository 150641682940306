export default {
  HOME: '/',
  COM001: '/COM001',
  COM002: '/COM002',
  COM003: '/COM003',

  BEN012: '/BEN012',
  BEN002: '/BEN002/:type/:id/:codeScreen',
  BEN003: '/BEN003/:type/:id/:codeScreen',
  BEN006: '/BEN006/:type/:id/:codeScreen',
  BEN005: '/BEN005/:type/:id/:codeScreen',
  BEN007: '/BEN007/:type/:id/:codeScreen',
  BEN004: '/BEN004/:type/:id/:codeScreen',
  BEN008: '/BEN008/:type/:id/:codeScreen',
  BEN009: '/BEN009/:type/:id/:codeScreen',
  BEN010: '/BEN010/:type/:id/:codeScreen',
  BEN011: '/BEN011/:type/:id/:codeScreen',

  SEA001: '/SEA001',
  SEA002: '/SEA002',

  MEN002: '/MEN002/:type/:id',
  MEN003: '/MEN003/:type/:id',
  MEN005: '/MEN005/:type/:id',
  MEN006: '/MEN006/:type/:id',
  MEN004: '/MEN004/:type/:id/:idCompany',
  MEN001: '/MEN001/:type/:id',
  MEN007: '/MEN007',

  MSE002: '/MSE002',
  MSE003: '/MSE003',
  MSE001: '/MSE001',
  MSE005: '/MSE005',
  MSE004: '/MSE004',
  MSE006: '/MSE006',

  APA001: '/APA001',
  APA002: '/APA002',
  APA004: '/APA004',
  APA005: '/APA005',
  APA003: '/APA003',

  SAP001: '/SAP001',
  SAP002: '/SAP002',
  SAP003: '/SAP003',
  SAP004: '/SAP004',

  IMP002: '/IMP002',
  IMP003: '/IMP003',
  IMP004: '/IMP004',
  IMP006: '/IMP006',
  IMP005: '/IMP005',

  IKU001: '/IKU001',
  IKU003: '/IKU003',

  PER002: '/PER002',
  PER003: '/PER003',
  PER001: '/PER001',
  PER004: '/PER004',
  PER005: '/PER005',
  PER006: '/PER006',

  HIS001: '/HIS001',
  HIS002: '/HIS002',

  PAGE500: '/500',
  PAGE404: '/404',
};
