import Const from 'config/constant';

import commonValidateMethod from 'helper/form/validateCommonMethod';
import { validateFormatDate } from 'helper/form/validateCommonDate';
import {
  validateKyufuKingaku,
  validateBenefitsNo,
  validateBenefitsNote,
  validateKanjiName,
  validateNameOfKana,
  validateJugyoinNo,
  validateKaishaCd,
  validateTaishoshaFullname,
  validateShobyoKubun,
  validateTsuzukiganaCode,
  validateHisaiJokyoCode,
  validateMeigiKubun,
  validateHaigushaNendoNenrei,
  validateSeibetsuCd,
  validateKojinFutangaku,
  validateRequestDate,
  validateDateOfBirth,
  validateSeinengappiYmd,
} from './validateCommonBen';
import { validateBen007 } from './Ben007';
import { validateBen010 } from './Ben010';
import { validateBen005 } from './Ben005';
import { validateBen006 } from './Ben006';
import { validateBen011 } from './Ben011';

export const validateBen = (obj, fieldValidate, screenCode) => {
  // ----- validate BEN ------
  if (fieldValidate?.includes('kaisha_cd')) {
    obj.kaisha_cd = validateKaishaCd;
  }
  if (fieldValidate?.includes('jugyoin_no')) {
    obj.jugyoin_no = validateJugyoinNo;
  }
  // validate Request Date
  if (fieldValidate?.includes('shinsei_kaishi_ymd')) {
    obj.shinsei_kaishi_ymd  = validateRequestDate;
  }
  // validate Date of Birth
  if (fieldValidate?.includes('jiyuhassei_ymd')) {
    obj.jiyuhassei_ymd = validateDateOfBirth;
  }
  // validate ChildName
  if (fieldValidate?.includes('taishosha_fullname')) {
    obj.taishosha_fullname = validateTaishoshaFullname;
  }
  // validate Maternity gift
  if (fieldValidate?.includes('kyufu_kingaku')) {
    obj.kyufu_kingaku = validateKyufuKingaku;
  }
  // validate Benefits No
  if (fieldValidate?.includes('moto_kyufu_id')) {
    obj.moto_kyufu_id = validateBenefitsNo;
  }
  // validate Benefits Note
  if (fieldValidate?.includes('biko_kyufu')) {
    obj.biko_kyufu = validateBenefitsNote;
  }
  // validate date of payment
  if (fieldValidate?.includes('dateOfPayment')) {
    obj.dateOfPayment = validateFormatDate;
  }
  if (fieldValidate?.includes('kanjiName')) {
    obj.kanjiName = validateKanjiName;
  }
  if (fieldValidate?.includes('nameOfKana')) {
    obj.nameOfKana = validateNameOfKana;
  }
  if (fieldValidate?.includes('shobyo_kubun')) {
    obj.shobyo_kubun = validateShobyoKubun;
  }
  if (fieldValidate?.includes('tsuzukigara_cd')) {
    obj.tsuzukigara_cd = validateTsuzukiganaCode;
  }
  if (fieldValidate?.includes('hisai_jokyo_cd')) {
    obj.hisai_jokyo_cd = validateHisaiJokyoCode;
  }
  if (fieldValidate?.includes('meigi_kubun')) {
    obj.meigi_kubun = validateMeigiKubun;
  }
  if (fieldValidate?.includes('seinengappi_ymd')) {
    obj.seinengappi_ymd = validateSeinengappiYmd;
  }
  if (fieldValidate?.includes('partner_seinengappi_ymd')) {
    obj.partner_seinengappi_ymd = validateSeinengappiYmd;
  }

  if (fieldValidate?.includes('haigusha_nendo_nenrei')) {
    obj.haigusha_nendo_nenrei = validateHaigushaNendoNenrei;
  }
  if (fieldValidate?.includes('seibetsu_cd')) {
    obj.seibetsu_cd = validateSeibetsuCd;
  }
  if (fieldValidate?.includes('kojin_futangaku')) {
    obj.kojin_futangaku = validateKojinFutangaku;
  }
  switch (screenCode) {
    case Const.screenCodeAll.BEN007:
      validateBen007(obj, fieldValidate);
      break;
    case Const.screenCodeAll.BEN010:
      validateBen010(obj, fieldValidate);
      break;
    case Const.screenCodeAll.BEN005:
      validateBen005(obj, fieldValidate);
      break;
    case Const.screenCodeAll.BEN006:
      validateBen006(obj, fieldValidate);
      break;
    case Const.screenCodeAll.BEN011:
      validateBen011(obj, fieldValidate);
      break;
    default:
  }
  //----end validate BEN------
  return obj;
};
