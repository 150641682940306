import * as Yup from 'yup';
import { getInfoMessage } from './infoMessageErrorI8n';
import { getMessValidate } from './messValidate';
import Const from './constant';
const regxPass = /^[A-Za-z0-9~!@#$%^&*_\-+=`/|\\(){}[\]:;"'<>,.?]*$/;
const regxEmail = /^([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@(([0-9a-zA-Z][-\w]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9]{2,17})$/;

const regNumberHalfsize = /^[0-9]+$/;
export const validateEmail = Yup.string()
  .nullable()
  .email('メールアドレスを正しく入力してください')
  .required('メールアドレスを入力してください。') // email is not empty
  .max(128, 'メールアドレスは128文字以下で入力してください。') // Email is too long - should be 255 chars maximum.
  .matches(
    regxEmail,
    'メールアドレスを正しく入力してください。', // Email incorrect format.
  );

export const validatePassLogin = Yup.string()
  .required('パスワードを入力してください。') // pass is not empty
  .min(8, '半角英数の8文字以上20文字以下で入力してください。')
  .max(20, '半角英数の8文字以上20文字以下で入力してください。')
  .matches(regxPass, 'パスワードを正しく入力してください。');

export const validatePass = Yup.string()
  .nullable()
  .required('パスワード を入力してください。') // pass is not empty
  .min(8, 'パスワードは半角英数の8文字以上20文字以下で入力してください。')
  .max(20, 'パスワードは半角英数の8文字以上20文字以下で入力してください。')
  .matches(regxPass, 'パスワードを正しく入力してください。');

export const validateNumber = Yup.string()
  .nullable()
  .max(15, getInfoMessage(Const.personFAX, Const.maxValue))
  .matches(
    regNumberHalfsize,
    getInfoMessage(Const.personFAX, Const.numberValue),
  );

export const validateBiko = Yup.string().max(
  100,
  getMessValidate('C0000007', ['備考']),
);
