/**
 *
 * Mse003
 * Author: Locnt
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './index.scss';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import {
  makeSelectMse003,
  makeSelectCompanyMaster,
  makeSelectGroupCompany,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import TopBarMaintenanceDetail from '../../components/TopBarMaintenanceDetail';
import Body from './body';
import { formikEnhancer } from '../../helper/form/validateForm';
import { getListMasterCompany, resetCompany } from './actions';
import ROUTERS from '../../config/routes';
import ENUM from '../../config/enum';
import Loading from '../../components/LoadingIndicator/index';
import { openLink } from 'utils/common/WindowUtils';

export function Mse003({ ...props }) {
  useInjectReducer({ key: 'mse003', reducer });
  useInjectSaga({ key: 'mse003', saga });
  const {
    values,
    fieldValidate,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    userInfo,
    errors,
    onSearchCompany,
    listCompanyMaster,
    history,
    mse003,
    isPopupScreen,
    handleHide,
    funcOnClick,
    resetCompany,
    setFieldValue,
    typeGroupCompany,
    setSubmitting,
  } = props;

  // const url = new URL(window.location.href);
  // const paramUrl = url.searchParams.get('valueBen');
  // const paramValue = JSON.parse(decodeURIComponent(paramUrl));

  useEffect(() => {
    // if (paramValue && Object.values(paramValue?.employeeNo).length > 0) {
    //   setFieldValue('groupCompanyIdListScreen', paramValue?.companyCd);
    //   setFieldValue('groupCompany', paramValue?.kaishaGroupCd);
    //   setFieldValue('companyName', paramValue?.companyNm);
    //   onSearchCompany({
    //     groupCompanyIdListScreen: paramValue?.companyCd,
    //     groupCompany: paramValue?.kaishaGroupCd,
    //     companyName: paramValue?.companyNm,
    //   });
    // } else {
      onSearchCompany(values);
    //}
  }, []);
  //change spec 2020/02/20 : when ben view click button 会社マスタ -> change open mse003 -> men003

  const handleSearchCompany = () => {
    if (Object.keys(errors).length === 0) {
      onSearchCompany(values);
    } else {
      resetCompany();
    }
  };
  const onClickCellMse003 = items => {
    if (isPopupScreen) {
      // run when search from mse004
      funcOnClick(items?.item);
    } else {
      const formatUrl = `${ROUTERS.MEN003.replace(
        ':type',
        ENUM.TYPE_SCREEN.VIEW,
      ).replace(':id', items?.item?.company_cd)}`;
      openLink(formatUrl);
    }
  };

  const onSubmitForm = e => {
    e.target.blur();
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      onSearchCompany(values);
    } else {
      resetCompany();
    }
    setTimeout(() => {
      //buttonRef.current.blur();
    }, 0);
    setSubmitting(false);
  };

  return (
    <form className="" onSubmit={onSubmitForm}>
    <div className={`mse003 ${isPopupScreen ? 'popup_bg_wapper' : ''}`}>
      {mse003?.isLoading && <Loading className="overlay-loading" />}
      <div
        className={`${isPopupScreen ? 'popup_content' : ''}`}
        style={{ marginTop: isPopupScreen ? 20 : 0 }}
      >
        <TopBarMaintenanceDetail
          keyTitle="titleMSE003"
          isShowListScreen={true}
          // handleAdd={handleAddNew}
          linkAdd={ROUTERS?.MEN003.replace(
            ':type',
            ENUM.TYPE_SCREEN.NEW,
          ).replace(':id', 0)}
          isHandleBackPreScreen={isPopupScreen}
        />
        <Body
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          handleSubmit={handleSearchCompany}
          listCompanyMaster={listCompanyMaster}
          onClickCellMse003={onClickCellMse003}
          typeGroupCompany={typeGroupCompany}
        />
      </div>
    </div>
    </form>
  );
}

Mse003.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSearchCompany: PropTypes.func,
  isPopupScreen: PropTypes.bool,
  funcOnClick: PropTypes.func,
};

Mse003.defaultProps = {
  isPopupScreen: false,
  handleHide: () => {},
  funcOnClick: () => {},
};

const mapStateToProps = createStructuredSelector({
  typeGroupCompany: makeSelectGroupCompany(),
  mse003: makeSelectMse003(),
  listCompanyMaster: makeSelectCompanyMaster(),
  fieldValidate: () => ['groupCompanyIdListScreen', 'companyName'],
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onSearchCompany: infoParam => dispatch(getListMasterCompany(infoParam)),
    resetCompany: () => dispatch(resetCompany()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(formikEnhancer(Mse003));
