/*
 *
 * Sea001 constants
 * Author: LanVt
 */
import ENUMS from '../../../../config/enum';

export const SEARCH_CODE_COMPANY_REQUEST = 'SEARCH_CODE_COMPANY_REQUEST';
export const SEARCH_CODE_COMPANY_SUCCESS = 'SEARCH_CODE_COMPANY_SUCCESS';
export const SEARCH_CODE_COMPANY_ERROR = 'SEARCH_CODE_COMPANY_ERROR';

export const RESET_DATA = 'RESET_DATA';

export const dataConfigTable = onClickCell => {
  return [
    {
      binding: 'kaisha_cd',
      headerNameObj: 'components.Table.code',
      width: 90,
      maxWidth: 90,
      cellTemplateType: ENUMS?.TYPE_CELL?.LINK,
      funcOnClick: onClickCell,
    },
    {
      binding: 'kaisha_group_name',
      headerNameObj: 'components.Table.company_group',
      width: 200,
    },
    {
      binding: 'kaisha_name',
      headerNameObj: 'components.Table.company_name',
      width: 500,
    },
    {
      binding: 'kumiai_taikai_ymd',
      headerNameObj: 'components.Table.withdrawal_date',
      width: 155,
      cellTemplateType: ENUMS?.TYPE_CELL?.DATE,
    },
  ];
};
