import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      exact
      {...rest}
      render={props => {
        const isAuthenticated = localStorage.getItem('token') ? true : false;
        return (
          <>
            {isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to="/Com002" />
            )}
          </>
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
